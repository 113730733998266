import CoreApiClient from "./clients/CoreApiClient";

export const createWhatsappTemplate = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/whatsapptemplate",
    data: body,
  });
};

export const createWhatsAppDesktopChannel = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/organization/waDesktopChannel",
    data: body,
  });
};
