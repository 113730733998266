import React from "react";
import styles from "./SegmentationDrawer.module.scss";
import { VerticalAlignTopOutlined } from "@ant-design/icons";
import { useQuery, useSubscription } from "@apollo/client";
import { useSelector } from "react-redux";
import SegmentationItem from "./components/SegmentationItem";
import { GET_ALL_SEGMENTS } from "src/graphql/segment";
import { getActiveOrgId } from "src/store/slices/userSlice";

function convertDataToSegmentsArray(data) {
  const segmentsArray = [];

  if (data && data.getOrganization && data.getOrganization.segments) {
    const segments = data.getOrganization.segments;

    segments.forEach((segment) => {
      const { id, name, segmentInformation } = segment;
      segmentsArray.push({ id, name, length: segmentInformation?.length });
    });
  }

  return segmentsArray;
}

const SegmentationDrawer = ({
  handleCloseSegmentDrawer,
  handleSetSelectedSegment,
  selectedSegment,
  dataForCountingSegmentation,
}) => {
  const organizationId = useSelector(getActiveOrgId);
  const {
    loading,
    refetch: refetchAttributes,
    error,
    data,
    networkStatus,
  } = useSubscription(GET_ALL_SEGMENTS, {
    variables: {
      organizationId: organizationId,
      notifyOnNetworkStatusChange: true,
    },
  });
  const handleSegmentSelection = (id) => {
    // fetchSegmentId(id)
    handleSetSelectedSegment(id);
  };

  const contactsData = useSelector((state) => state?.contactReducer);

  return (
    <div className={styles.SegmentationDrawer}>
      <div className={styles.SegmentationDrawer__header}>
        <h4>Segments</h4>
        <span
          onClick={handleCloseSegmentDrawer}
          style={{
            cursor: "pointer",
            fontSize: "16px",
            transform: "rotate(269deg)",
          }}
        >
          <VerticalAlignTopOutlined />
        </span>
      </div>
      <div className={styles.SegmentationDrawer__body}>
        {/* <div className={styles.SegmentationDrawer__body__header}>
          <span>All</span>
          <p>{convertDataToSegmentsArray(data)?.length}</p>
        </div> */}
        <div className={styles.SegmentationDrawer__body__list}>
          <ul>
            <li
              style={{
                background:
                  contactsData?.globalSelectedSegment == "all"
                    ? "#BAE7FF"
                    : "#FFFFFF",
              }}
              className={styles.SegmentationItem}
              onClick={() => handleSegmentSelection("all")}
            >
              <span style={{ fontWeight: "bold" }}>All</span>
              <p>{dataForCountingSegmentation?.initialTableData?.length}</p>
            </li>
            {convertDataToSegmentsArray(data)?.map((item) => {
              return (
                <SegmentationItem
                  dataForCountingSegmentation={dataForCountingSegmentation}
                  item={item}
                  handleSegmentSelection={handleSegmentSelection}
                  contactsData={contactsData}
                  organizationId={organizationId}
                />
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SegmentationDrawer;
