import React, { useEffect, useState } from "react";
import styles from "./NameCell.module.scss";
import ProfilePicPlaceholder from "../../ContactsPage/components/ProfilePicPlaceholder/ProfilePicPlaceholder";
import { Button, Checkbox } from "antd";
import "../../../helpers/featureFlag";
import featureFlag from "../../../helpers/featureFlag";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeOnBoardingStepNumber } from "src/store/slices/onboardingSlice";

const NameCell = (props) => {
  const dispatch = useDispatch();
  const onBoardingStatus = useSelector((state) => {
    return state?.onBoarding;
  });

  const {
    updateContacts,
    index,
    showDetailsDrawer,
    selectedContacts,
    handleSelectedContact,
    value,
    contactsData,
  } = props;
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const handleViewDetails = () => {
    // console.log(value)
    handleSelectedContact(value);
    showDetailsDrawer();
  };

  const navigateToChatScreen = () => {
    navigate(`/chat/${value}`);
    if (
      onBoardingStatus?.isStarted === true &&
      onBoardingStatus?.data?.current === "communicateContact" &&
      onBoardingStatus?.data?.currentStepIndex === 0
    ) {
      setTimeout(() => {
        dispatch(changeOnBoardingStepNumber(1));
      }, 800);
    }
  };

  return (
    <>
      <div className={`${styles.NameCell}`}>
        <div className={styles.NameCell__img}>
          <ProfilePicPlaceholder
            id={value}
            name={
              contactsData?.filter((item) => item?.id === value)[0]?.username
            }
            width={"22px"}
            height={"22px"}
            fontSize={"9.625px"}
          />
        </div>
        <div className={styles.NameCell__right}>
          <span onClick={navigateToChatScreen}>
            {contactsData?.filter((item) => item?.id === value)[0]?.username}
          </span>

          {/* <div className={styles.NameCell__right__btn} >
                    <h6 onClick={()=>handleViewDetails()}>View details</h6>
                </div> */}
        </div>
      </div>
    </>
  );
};

export default NameCell;
