import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isStarted: false,
  isRedirectedFromStarted: false,
  data: {
    current: "",
    currentStepIndex: 0,
  },
  error: null,
};

const onboardingTypes = [
  "connectChannel",
  "createContact",
  "communicateContact",
];

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    resetOnboarding: () => initialState,
    moveToNextStep: (state) => {
    },
    setOnboarding: (state, action) => {
      const { type, steps } = action.payload;
      if (!type || !Array.isArray(steps)) return;
      state.data.current = type;
      state.data.steps = steps;
      state.data.currentStepIndex = 0;
    },
    setOpened: (state, action) => {
      if (action.payload !== true && action.payload !== false) {
        return;
      }
      state.isStarted = action.payload;
    },
    setIsRedirectedFromStarted: (state, action) => {
      if (action.payload !== true && action.payload !== false) {
        return;
      }
      state.isRedirectedFromStarted = action.payload;
    },
    changeOnBoardingType: (state, action) => {
      if (onboardingTypes.indexOf(action.payload) === -1) {
        return;
      }
      state.data.current = action.payload;
    },
    changeOnBoardingStepNumber: (state, action) => {
      state.data.currentStepIndex = action.payload;
    },
  },
});

export const {
  resetOnboarding,
  moveToNextStep,
  setOnboarding,
  setOpened,
  changeOnBoardingType,
  changeOnBoardingStepNumber,
  setIsRedirectedFromStarted,
} = onboardingSlice.actions;
export default onboardingSlice.reducer;
