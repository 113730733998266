import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "antd/dist/reset.css";

import { Form, Input, Button, Alert, Row, Col } from "antd";
import { LoginOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { createNewOrganization } from "src/api/OrganizationApi";

import FeatureCarousel from "../FeatureCarousel/FeatureCarousel";
import styles from "./CreateOrganization.module.scss";
import { setUserData } from "src/store/slices/userSlice";
import { useDispatch } from "react-redux";
import ProtectRoute from "src/components/ProtectRoute/ProtectRoute";

const CreateOrganization = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 980px)" });

  const [disable, setDisable] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    createNewOrganization({ name: values.organizationName })
      .then(({ data }) => {
        let { organizationId } = data;
        dispatch(setUserData({ newOrganizationId: organizationId }));
        navigate("/");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = ({ target }) => {
    if (target.value.length) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  };

  return (
    <ProtectRoute>
      <div className={styles.CreateOrganization}>
        <div className={styles.CreateOrganization__formContainer}>
          <Row>
            <Col span={24}>
              <Row
                className={
                  styles.CreateOrganization__formContainer__fullsignup
                }
              >
                <Col className="signin-options" span={12}>
                  <div className={styles.CreateOrganization__Header}>
                    <div
                      className={styles.CreateOrganization__Header__Title}
                    >
                      Create organization
                    </div>
                    <Form
                      form={form}
                      layout={"vertical"}
                      onFinish={onFinish}
                      className={styles.CreateNewOrganization__formContainer}
                    >
                      <Form.Item
                        name="organizationName"
                        className={styles.CreateOrganization__Wrapper}
                      >
                        <Input
                          className={styles.CreateOrganization__Input}
                          placeholder=""
                          data-testid="createOrgInput"
                          onChange={handleChange}
                        />
                      </Form.Item>
                      <Form.Item shouldUpdate>
                        {() => (
                          <div
                            className={
                              styles.CreateNewOrganization__formContainer__btnContainer
                            }
                          >
                            <Button
                              className={
                                styles.CreateOrganization__SubmitButton
                              }
                              type="primary"
                              htmlType="submit"
                              disabled={disable}
                              data-testid="createOrg"
                              loading={loading}
                            >
                              {!disable && <LoginOutlined />}
                              Create Organisation
                            </Button>
                          </div>
                        )}
                      </Form.Item>
                    </Form>
                  </div>
                </Col>
                {isDesktopOrLaptop && (
                  <Col
                    className={
                      styles.CreateOrganization__formContainer__fullsignup__carousel
                    }
                    span={12}
                  >
                    <FeatureCarousel {...props} />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </ProtectRoute>
  );
};

CreateOrganization.propTypes = {};

CreateOrganization.defaultProps = {};

export default CreateOrganization;
