import React, { useState, useEffect } from "react";
import styles from "./TagsSelect.module.scss";
import { Select, Tag } from "antd";

const { Option } = Select;

const TagsSelect = ({ options, handleFetchValues,value }) => {
  const getColor = (value) => {
    let res = options.filter((item) => item.id == value)[0];
    return res?.color;
  };
  const [selected, setSelected] = useState([]);

  const handleChange = (value) => {
    handleFetchValues(value);
    // setSelected(value);
  };

  // useEffect(() => {
  //   if (selected) {
  //   }
  // }, [selected]);

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={getColor(value)}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {label}
      </Tag>
    );
  };
  return (
    <div className={styles.TagsSelect}>
      <Select
        mode="multiple"
        style={{
          width: "100%",
        }}
        tagRender={tagRender}
        placeholder="select tags"
        defaultValue={[]}
        value={value}
        onChange={handleChange}
        optionLabelProp="label"
        width="96%"
      >
        {options?.map((item) => {
          return (
            <Option value={item.id} label={item.name}>
              <Tag color={item.color}>{item.name}</Tag>
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default TagsSelect;
