import React, { useEffect, useState } from "react";
import {
  Drawer,
  Form,
  Input,
  Button,
  Select,
  notification,
  Checkbox,
  message,
  Modal,
} from "antd";
import Dropdown from "react-dropdown";
import { useNavigate } from "react-router-dom";
import styles from "./CreateEmailExtraction.module.scss";
import {
  useSubscription,
  useQuery,
  useLazyQuery,
  useApolloClient,
} from "@apollo/client";
import { UpOutlined, DownOutlined, EditOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import {
  createAIParsing,
  createAttributeMapping,
  createEmailExtraction,
} from "src/api/EmailApi";
import Checkcircle from "src/assets/svgs/Checkcircle";
import {
  EXTRACTION_DATA_SUBSCRIPTION,
  GET_ORGANIZATION_ATTRIBUTES,
} from "src/graphql/organization";
import { getActiveOrgId } from "src/store/slices/userSlice";
import getConfig from "src/helpers/config";

const { TextArea } = Input;
const { REACT_APP_USER_EMAIL } = getConfig();
const CreateEmailExtraction = ({ isVisible, setShowDrawer, fetch }) => {
  const navigate = useNavigate();
  const organizationId = useSelector(getActiveOrgId);
  const [isVerified, setIsVerified] = useState(false);
  const [isSetup, setIsSetup] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [isSpecial, setIsSpecial] = useState(false);
  const [loading, setLoading] = useState(false);
  const [extractLoading, setExtractLoading] = useState(false);
  const [aiParsedId, setAIParsedId] = useState(null);
  const [fetchedId, setFetchedId] = useState(0);
  const [specialInstructions, setSpecialInstructions] = useState("");
  const [listOfAttributes, setListOfAttributes] = useState("");
  const [normalAttributes, setNormalAttributes] = useState(null);
  const [fetchedMail, setFetchedMail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const {
    data,
    loading: extractionDataLoading,
    error,
  } = useSubscription(EXTRACTION_DATA_SUBSCRIPTION, {
    variables: {
      channelId: fetchedId,
    },
    skip: !fetchedId, // This will skip the subscription if fetchedId is empty
  });
  const {
    loading: organizationLoading,
    refetch: refetchAttributes,
    error: organizationError,
    data: organizationData,
    networkStatus,
  } = useQuery(GET_ORGANIZATION_ATTRIBUTES, {
    variables: {
      organizationId: organizationId,
      notifyOnNetworkStatusChange: true,
    },
  });
  useEffect(() => {
    const organizationAttribute =
      organizationData?.getOrganization?.normalAttribute;
    if (organizationAttribute) {
      setNormalAttributes(organizationAttribute);
    }
  }, [organizationData]);

  const [channelAttribute, setChannelAttribute] = useState(null);
  const [isSetUpEmailChannel, setIsSetUpEmailChannel] = useState(false);

  useEffect(() => {
    if (data) {
      let isVerif = data?.getEmailExtractionChannel?.isEmailVerified;

      const channelAttributeResponse =
        data?.getEmailExtractionChannel?.aiParsing[0]?.attributeMapping;
      if (isVerif) {
        setLoading(false);
        setIsVerified(true);
      }
      if (
        Array.isArray(channelAttributeResponse) &&
        channelAttributeResponse.length > 0 &&
        channelAttributeResponse
      ) {
        setExtractLoading(false);
        setChannelAttribute([]);
        setChannelAttribute(channelAttributeResponse);
      }
    }
  }, [data]);
  const handleSpecialInstructions = (e) => {
    setSpecialInstructions(e.target.value);
  };

  const selectChange = (attrMapId, e, items) => {
    const { value, label } = e;
    if (!value || !label) {
      return;
    }
    if (value === "1" && label === "New Attribute") {
      setShowModal(true);
    } else {
      let index = channelAttribute.findIndex((tx) => tx?.id === attrMapId);
      if (index !== -1) {
        channelAttribute[index].attribute = value;
        let newChannelAttribute = [...channelAttribute];
        setChannelAttribute([...newChannelAttribute]);
      }
    }
  };

  const handleListChange = (e) => {
    setListOfAttributes(e.target.value);
  };
  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    isValid,
    // resetForm,
  } = useFormik({
    initialValues: {
      channelName: "Email",
      userName: "",
      email: "",
      agree: false,
    },
    validationSchema: Yup.object().shape({
      channelName: Yup.string().required("channel name is required"),
      email: Yup.string()
        .required("Email is required")
        .email("Invalid email format"),
      agree: Yup.boolean().oneOf(
        [true],
        "You must accept the terms and conditions"
      ), // industry: Yup.string().required("This field is required"),
    }),
    onSubmit: (formValues) => {
      submitForm(formValues);
    },
  });

  const submitForm = (values) => {
    setLoading(true);
    let body = {
      emailAddress: values?.email,
      source: values?.channelName,
      organizationId: organizationId,
    };
    createEmailExtraction(body)
      ?.then((res) => {
        setLoading(false);
        setIsVerified(true);
        setFetchedId(res?.data?.id);
        setFetchedMail(res?.data?.extractionEmail);
      })
      .catch((e) => {
        if (e?.response?.data?.message && e?.response?.data?.message !== "") {
          message.error(e?.response?.data?.message);
        } else {
          message.error("Something went wrong . Try again later.");
        }
        setLoading(false);
      });
  };

  const handleExtraction = () => {
    setExtractLoading(true);
    let body = {
      channelId: fetchedId,
      listOfAttributes: listOfAttributes,
      specialInstructions: specialInstructions,
    };
    createAIParsing(body)
      ?.then((res) => {
        setAIParsedId(res?.data?.aiParsing);
      })
      .catch((e) => {
        if (e?.response?.data?.message && e?.response?.data?.message !== "") {
          message.error(e?.response?.data?.message);
        } else {
          message.error("Something went wrong . Try again later.");
        }
        setExtractLoading(false);
      });
  };

  const handleValidationStatus = (key) => {
    return touched[key] && errors[key] ? "error" : "";
  };

  const hideDrawer = () => {
    setShowDrawer(false);
  };

  const completeChannelSetup = async () => {
    try {
      setIsSetUpEmailChannel(true);
      if (!aiParsedId) {
        setIsSetUpEmailChannel(false);
        return;
      }
      let data = [];
      for (let i = 0; i < channelAttribute.length; ++i) {
        if (
          channelAttribute[i].attribute === null ||
          typeof channelAttribute[i].attribute !== "string"
        ) {
          message.error("All attributes are not mapped");
          setIsSetUpEmailChannel(false);
          return;
        }
        data.push({
          id: channelAttribute[i].id,
          attributeId: channelAttribute[i].attribute,
        });
      }
      let requestBody = {
        attributeMapping: data,
        AIParsingId: aiParsedId,
      };
      const response = await createAttributeMapping(requestBody);
      if (response?.data?.success === true) {
        message.success("The channel has been set up successfully");
      }
      setIsSetUpEmailChannel(false);
    } catch (err) {
      message.error(err?.response?.data?.message);
      setIsSetUpEmailChannel(false);
      return;
    }
  };

  return (
    <>
      {showModal === true && (
        <Modal
          title="Information"
          open={showModal}
          onOk={() => {}}
          onCancel={() => {}}
          footer={null}
          closable={false}
        >
          <div className={styles.CreateEmailExtraction__ErrorModalText}>
            You will be redirected to contacts page to create new attribute.
          </div>
          <div className={styles.CreateEmailExtraction__ButtonContainer}>
            <Button
              type="primary"
              onClick={() => {
                navigate("/contacts/");
              }}
            >
              Ok
            </Button>
            <Button
              onClick={() => {
                setShowModal(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      )}
      <Drawer
        className={styles.CreateEmailExtraction__drawer}
        headerStyle={{ display: "none" }}
        placement="right"
        open={isVisible}
        onClose={() => setShowDrawer(false)}
        destroyOnClose
        footer={
          <>
            {isSetup ? (
              <div className={styles.CreateEmailExtraction__drawer__footer}>
                <Button type="ghost" onClick={hideDrawer}>
                  Back
                </Button>
                <Button
                  type="primary"
                  disabled={isSetUpEmailChannel}
                  onClick={completeChannelSetup}
                >
                  Setup Email Channel with AI
                </Button>
              </div>
            ) : isVerified ? (
              <div className={styles.CreateEmailExtraction__drawer__footer}>
                <Button type="ghost" onClick={hideDrawer}>
                  Back
                </Button>
                <Button type="primary" onClick={hideDrawer}>
                  Setup Email Channel without AI
                </Button>
              </div>
            ) : (
              <></>
            )}
          </>
        }
      >
        <div className={styles.CreateEmailExtraction}>
          <div className={styles.CreateEmailExtraction__Header}>
            Import data using emails
          </div>
          <div className={styles.CreateEmailExtraction__SubHeader}>
            Enrich your customer data using incoming emails and automate
            conversations.{" "}
          </div>

          <Form
            layout="vertical"
            className={styles.ChannelForm}
            onFinish={handleSubmit}
          >
            <Form.Item
              name="channelName"
              label="Source"
              // validateStatus={handleValidationStatus("channelName")}
            >
              <Select
                defaultValue="Email"
                style={{ width: "100%" }}
                onChange={handleChange}
                value={values?.channelName}
                options={[
                  {
                    value: "Email",
                    label: "Email",
                  },
                ]}
              />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email address"
              required
              validateStatus={handleValidationStatus("email")}
              help={touched.email && errors.email}
            >
              <Input
                name="email"
                placeholder="Enter email address"
                onChange={handleChange}
                value={values.email}
                required
              />
              <span className={styles.CreateEmailExtraction__inputfooter}>
                Any email sent to this address will show up as a chat in HC
                Inbox.
              </span>
            </Form.Item>
            {!isVerified && (
              <Form.Item
                name="agree"
                validateStatus={handleValidationStatus("agree")}
                help={touched.agree && errors.agree}
                // validateStatus={handleValidationStatus("agree")}
              >
                <Checkbox
                  name="agree"
                  onChange={handleChange}
                  value={values.agree}
                >
                  Yes, I have forwarded this address to{" "}
                  <b>{REACT_APP_USER_EMAIL}</b>
                </Checkbox>
              </Form.Item>
            )}

            {!isVerified && (
              <div className={styles.ChannelForm__ButtonContainer}>
                {/* <Button type="secondary" onClick={() => setShowDrawer(false)}>
                {" "}
                Cancel{" "}
              </Button> */}
                <div></div>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={values?.email == ""}
                  style={{backgroundColor:'var(--primary-color)', color: 'white'}}
                >
                  {" "}
                  Verify email{" "}
                </Button>
              </div>
            )}
          </Form>
          {isVerified && (
            <div className={styles.CreateEmailExtraction__autoforward}>
              <div
                className={
                  styles.CreateEmailExtraction__autoforward__successcontainer
                }
              >
                <Checkcircle />
                <div
                  className={
                    styles.CreateEmailExtraction__autoforward__successcontainer__text
                  }
                >
                  <h4>Email Auto-forwarding Verified</h4>
                  <span>A few seconds ago</span>
                </div>
              </div>
              <span
                className={styles.CreateEmailExtraction__autoforward__footer}
              >
                Email sent to booking@sales.com automatically become chats in HC
                Inbox. You can edit or add other email addresses in{" "}
                <span>{"Settings > Channels > Emails"}</span> at any time.
              </span>
            </div>
          )}
          {!isSetup && isVerified && (
            <div className={styles.CreateEmailExtraction__parsing}>
              <span className={styles.CreateEmailExtraction__parsing__head}>
                Setup AI Parsing
              </span>
              <h4 className={styles.CreateEmailExtraction__parsing__text}>
                AI parsing lets you extract data from your emails automatically
                and store it as attributes in CRM. These attributes can be used
                to automate your communication with clients.
              </h4>
              <div className={styles.CreateEmailExtraction__parsing__footer}>
                <div></div>
                <Button onClick={() => setIsSetup(true)} type="secondary">
                  Setup AI Parsing
                </Button>
              </div>
            </div>
          )}
          {isSetup && (
            <div className={styles.CreateEmailExtraction__parsing}>
              <div className={styles.CreateEmailExtraction__parsing__header}>
                <span className={styles.CreateEmailExtraction__parsing__head}>
                  Setup AI Parsing
                </span>
                {isOpen ? (
                  <UpOutlined onClick={() => setIsOpen(false)} />
                ) : (
                  <DownOutlined onClick={() => setIsOpen(true)} />
                )}
              </div>
              <h4 className={styles.CreateEmailExtraction__parsing__text}>
                AI parsing lets you extract data from your emails automatically
                and store it as attributes in CRM. These attributes can be used
                to automate your communication with clients.
              </h4>
              {isOpen && (
                <div className={styles.CreateEmailExtraction__parsing__body}>
                  <span
                    className={
                      styles.CreateEmailExtraction__parsing__body__forward
                    }
                  >
                    Forward an email to be parsed to <span>{fetchedMail}</span>
                  </span>
                  <span
                    className={
                      styles.CreateEmailExtraction__parsing__body__list
                    }
                  >
                    List of attributes you need extracted from your emails
                  </span>
                  <TextArea
                    className={
                      styles.CreateEmailExtraction__parsing__body__textarea
                    }
                    rows={4}
                    onChange={handleListChange}
                    value={listOfAttributes}
                  />
                  <div
                    className={
                      styles.CreateEmailExtraction__parsing__body__instructioncontainer
                    }
                  >
                    <div></div>
                    <Button
                      disabled={isSpecial}
                      onClick={() => setIsSpecial(true)}
                      type="link"
                    >
                      <EditOutlined />
                      Add special instructions
                    </Button>
                  </div>
                  {isSpecial && (
                    <>
                      <span
                        className={
                          styles.CreateEmailExtraction__parsing__body__specialtext
                        }
                      >
                        Add special instruction to AI here
                      </span>
                      <TextArea
                        className={
                          styles.CreateEmailExtraction__parsing__body__textarea
                        }
                        rows={4}
                        placeholder="Enter instruction"
                        value={specialInstructions}
                        onChange={handleSpecialInstructions}
                      />
                    </>
                  )}
                  <div
                    className={
                      styles.CreateEmailExtraction__parsing__body__extractcontainer
                    }
                  >
                    <div></div>
                    <Button
                      type="default"
                      disabled={listOfAttributes == "" || aiParsedId !== null}
                      loading={extractLoading}
                      onClick={handleExtraction}
                    >
                      Extract
                    </Button>
                  </div>
                </div>
              )}
              {Array.isArray(channelAttribute) &&
                channelAttribute.length > 0 && (
                <div
                  className={styles.CreateEmailExtraction__AttributeMapping}
                >
                  <div
                    className={
                      styles.CreateEmailExtraction__AttributeMapping__Header
                    }
                  >
                    <div
                      className={
                        styles.CreateEmailExtraction__AttributeMapping__Header__Column
                      }
                    >
                        Mapping to attributes
                    </div>
                    <div
                      className={
                        styles.CreateEmailExtraction__AttributeMapping__Header__Column
                      }
                    >
                        Attributes extracted
                    </div>
                  </div>
                  {channelAttribute?.map((tx) => {
                    let items = normalAttributes
                      ?.filter((t) => t?.type === tx?.attributeType)
                      .map((m) => {
                        return { value: m?.id, label: m?.name };
                      });
                    items = [
                      { value: "1", label: "New Attribute" },
                      ...items,
                    ];
                    const selectHandler = (e) => {
                      const { id } = tx;
                      if (id) {
                        selectChange(id, e, items);
                      }
                    };
                    return (
                      <div
                        className={
                          styles.CreateEmailExtraction__AttributeMapping__Row
                        }
                      >
                        <div
                          className={
                            styles.CreateEmailExtraction__AttributeMapping__Row__Column
                          }
                        >
                          <Dropdown options={items} onChange={selectHandler}>
                              Hover me
                          </Dropdown>
                        </div>
                        <div
                          className={
                            styles.CreateEmailExtraction__AttributeMapping__Row__Column
                          }
                        >
                          <p
                            className={
                              styles.CreateEmailExtraction__AttributeMapping__Row__Column__Name
                            }
                          >
                            {tx?.attributeName}
                          </p>

                          <p
                            className={
                              styles.CreateEmailExtraction__AttributeMapping__Row__Column__Value
                            }
                          >
                            {tx?.parsedAttributeValue}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
};

CreateEmailExtraction.propTypes = {};

CreateEmailExtraction.defaultProps = {};

export default CreateEmailExtraction;
