import React from "react";
import styles from "./InterfaceOptions.module.scss";
import PeopleBody from "../../PeopleBody/PeopleBody";
import InterfaceOptionBody from "../../InterfaceOptionBody/InterfaceOptionBody";
import { useTranslation } from "react-i18next";
import { getActiveStaffOrg } from "src/store/slices/userSlice";
import { useSelector } from "react-redux";
import { checkFeatureAvailability } from "src/helpers/checkFeatureAvailability";

const InterfaceOptions = () => {
  const { t } = useTranslation();
  const activeStaffOrg = useSelector(getActiveStaffOrg);
  const orgUrl = useSelector((state) => state?.user?.data?.staffOrganization?.[0]?.organization?.domain || '');

  return (
    <div className={styles.InterfaceOptionsPage}>
      <div className={styles.InterfaceOptionsPage__header}>
        <h3>{t("Interface Options")}</h3>
        <p>{t("Control interface options")}</p>
      </div>
      <div className={styles.InterfaceOptionsPage__body}>
        <div className={styles.InterfaceOptionsPage__body__container}>
        {checkFeatureAvailability("upgradeInterfaceOption",orgUrl) && <div className={styles.InterfaceOptionsPage__body__container__DisabledOverlay} />}
          {activeStaffOrg !== null &&
          (activeStaffOrg?.roleName === "admin" ||
            activeStaffOrg?.roleName === "member") ? (
              <InterfaceOptionBody />
            ) : null}
        </div>
      </div>
    </div>
  );
};

export default InterfaceOptions;
