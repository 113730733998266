import React, { useState, useEffect } from 'react';
import { Modal, Button, message, Input, Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { EditOutlined } from '@ant-design/icons';
import { setupDomain } from 'src/api/BrandingAPI';
import { useDispatch } from 'react-redux';
import { setUserData, updateStaffOrganizationUrl } from 'src/store/slices/userSlice';
import { Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

const DomainSetupModal = ({ visible, onClose }) => {
    const [domainName, setDomainName] = useState('');
    const { id, domain } = useSelector((state) => state?.user?.data?.staffOrganization?.[0]?.organization || {});
    const [isLoading, setIsLoading] =useState(false)
    const location = window.location.href;
    
    useEffect(() => {
      if (!domain && location) {
        const fullUrl = location.split('/');
        const baseUrl = fullUrl?.[2];
        setDomainName(baseUrl)
      } else {
        setDomainName(domain)
      }
    }, [domain])

    const dispatch = useDispatch()

    const handleSave = async () => {
        if (!domainName.match(/^[a-zA-Z0-9.-]+$/)) {
            message.error('Please enter a valid domain name!');
            return;
        }
        setIsLoading(true)
        const data = {domainUrl : domainName, organizationId: id}
        try {
            const res = await setupDomain(data);
            if (res.data.success === true) {
                message.success('Domain name saved successfully')
                dispatch(updateStaffOrganizationUrl({ url: domainName }))
                onClose();
            }
            }catch(err){
                console.error('Error updating domain:', err.message);
                message.error('Failed to update domain name')
            }finally{
              setIsLoading(false)
            }
    };

    return (
        <Modal
          title="Setup Organization-Specific Domain"
          visible={visible}
          onCancel={onClose}
          footer={[
            <Button key="cancel" onClick={onClose}>
              Cancel
            </Button>,
            <Button key="save" type="primary" onClick={handleSave} style={{ backgroundColor: 'var(--primary-color)' }}>
              {!isLoading? "Save": <Spin indicator={<LoadingOutlined style={{color: 'white' }} spin />} />}
            </Button>

          ]}
        >
           <Row align="middle" style={{ margin: "2em 0" }} gutter={16}>
            <Col span={8} offset={2}>
              <label htmlFor="domainName" style={{ lineHeight: '32px' }}>Enter Domain Name</label>
            </Col>
            <Col span={12}>
              <Input
                id="domainName"
                value={domainName}
                onChange={(e) => setDomainName(e.target.value)}
                placeholder="example.com"
                prefix={<EditOutlined />}
              />
            </Col>
          </Row>
        </Modal>
      );
}

export default DomainSetupModal;