import React, { useState } from "react";

import { Button, Input, message, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { createSegmentation } from "src/api/SegmentationApi";
import styles from "./SegmentationModal.module.scss";
import { setGlobalSelectedSegment } from "src/store/slices/contactSlice";
import { getActiveOrgId } from "src/store/slices/userSlice";

const SegmentationModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  selectedFilters,
  onClose,
  handleOpenSegmentDrawer,
}) => {
  const organizationId = useSelector(getActiveOrgId);
  const [state, setState] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setState(e.target.value);
  };

  const handleCreateSegmentation = () => {
    setLoading(true);
    let obj = {
      name: state,
      organization: {
        id: organizationId,
      },
      segmentInformation: selectedFilters?.map(
        ({ subCategory, id, ...item }) => ({
          ...item,
          subCategory: subCategory !== null ? subCategory : "",
        })
      ),
    };
    createSegmentation(obj)
      ?.then((res) => {
        dispatch(
          setGlobalSelectedSegment(
            res?.data?.data?.addSegments?.segments[0]?.id
          )
        );
        message.success("Segment applied!");
        handleCancel();
        onClose();
        handleOpenSegmentDrawer();
        setLoading(false);
      })
      .catch((e) => {
        if (e?.response?.data?.message && e?.response?.data?.message !== "") {
          message.error(e?.response?.data?.message);
        } else {
          message.error("Something went wrong .Try again later");
        }
        setLoading(false);
      });
  };

  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={false}
      footer={null}
      className={styles.SegmentationModal}
    >
      <div className={styles.SegmentationModal__body}>
        <h3>Save as segment</h3>
        <p>Segment Name</p>
        <Input onChange={handleChange} value={state} />
      </div>
      <div className={styles.SegmentationModal__footer}>
        <Button onClick={handleCancel} type="ghost">
          Cancel
        </Button>
        <Button
          type="primary"
          disabled={state == "" || loading}
          onClick={handleCreateSegmentation}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default SegmentationModal;
