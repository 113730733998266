import React, { useEffect, useState } from "react";
import styles from "./TimesheetView.module.scss";

import { Avatar, Space, Table, Typography } from "antd";
import {
  formatTotalTime,
  timeSheetTimerConfig,
} from "../../configs/dashboardConfig";

import RangePickerWidget from "./timesheet-view-widgets//widgets/RangePickerWidget/RangePickerWidget";
import { Button } from "antd";
import AddManualTimeButton from './timesheet-view-widgets/widgets/ManualTimeWidget/ManualTimeButton';
import DropdownBox from "./timesheet-view-widgets/widgets/DropdownBox/DropdownBox";

import { extractTimeLogs } from "src/api/TimesheetApi";
import { getStaffList } from "src/api/OrganizationApi";
import DataTable from "./timesheet-view-widgets/widgets/DataTable/DataTable";
import DownnloadReportsButton from "./timesheet-view-widgets/widgets/DownloadReportWidget/DownloadReportButton";
import { useDispatch, useSelector } from "react-redux";
import Statistics from "./timesheet-view-widgets/widgets/statistics/Statistics";
import { useQuery } from "@apollo/client";
import { GET_ORGANIZATION_STAFF } from "src/graphql/staff";
import { getActiveOrgId } from "src/store/slices/userSlice";


const { Text } = Typography;

const TimesheetView = () => {
  const [selectedRange, setSelectedRange] = useState(timeSheetTimerConfig);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [staffList, setStaffList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [avgActivity, setAvgActivity] = useState(0);
  const [totalTime, setTotalTime] = useState("00:00:00"); // Total time of all projects

  const { email } = useSelector((state) => state?.user?.data);
  const organizationId = useSelector(getActiveOrgId);

  const {
    loading: staffLoading,
    refetch: staffRefetch,
    error: staffError,
    data: staffData,
  } = useQuery(GET_ORGANIZATION_STAFF, {
    variables: {
      organizationId: organizationId,
    },
  });

  useEffect(() => {
    if (selectedUserId) {
      fetchTableData();
    }
  }, [selectedUserId, selectedRange]);

  useEffect(() => {
    if (staffData && staffData.getOrganization) {
      setStaffList(staffData.getOrganization.organizationStaff);
    }
  }, [staffData]);
 
  useEffect(() => {
    if (staffList.length > 0 && email) {
      for (const orgStaff of staffList) {
        if (orgStaff.staff.email === email) {
          setSelectedUserId(orgStaff.staff.keycloakUserId);
          break;
        }
      }
    }
  }, [staffList, email]);

  

  const fetchTableData = async () => {
    try {
      setIsLoading(true);
      console.log("selectedUserId",selectedUserId)

      let payload = {
        userid: selectedUserId,
        startDate: selectedRange.startDate,
        endDate: selectedRange.endDate,
      };
      const response = await extractTimeLogs(payload);
      setTableData(response.data);
      setTotalTime(formatTotalTime(response.data.totalDuration));
      setAvgActivity(response.data.averageActivity);
    } catch (error) {
      console.error("Error fetching data from API:", error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      const startDate = dates[0].startOf("day").format(); 
      const endDate = dates[1].endOf("day").format();
      setSelectedRange({
        startDate,
        endDate,
      });
    }
  };
  
  

  return (
    <div className={styles.dashboard}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: "20px" }} type="secondary">
          View timesheets
        </Text>
        <div style={{ display: "flex", padding: "10px" }}>
          <DropdownBox
            staffList={staffList}
            setSelectedUserId={setSelectedUserId}
            selectedUserId={selectedUserId}
          />
          <AddManualTimeButton
            selectedUserId={selectedUserId}
            fetchTableData={fetchTableData}
            staffList={staffList}
          />
        </div>
      </div>
      <section style={{ display: "flex", justifyContent: "space-between" }}>
        <div className={styles.widgets}>
          <RangePickerWidget
            value={selectedRange}
            onDateRangeChange={handleRangeChange}
          />
        </div>
        <div className={styles.statistic}>
          <Statistics avgActivity={avgActivity} totalTime={totalTime} />
        </div>
      </section>
      <section className="reports">
        <DataTable
          fetchTableData={fetchTableData}
          tableData={tableData}
          selectedDateRange={selectedRange}
          setSelectedUserId={setSelectedUserId}
          selectedUserId={selectedUserId}
          isLoading={isLoading}
        />
        <DownnloadReportsButton tableData={tableData} />
      </section>
    </div>
  );
};

export default TimesheetView;
