import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";

const Loader = ({loader}) => {
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path:  loader ? loader : "https://lottie.host/81a86a54-693c-4c05-a25f-eae94b4867d8/LTcP89Zwtz.json"
    });

    return () => lottie.destroy();
  }, []);

  return <div ref={container}></div>;
};

export default Loader;
