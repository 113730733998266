import React from "react"
import { Card, Col, Row, Statistic } from "antd"
import styles from "./Statistics.module.scss"

const Statistics = ({avgActivity,totalTime}) => {
  return(
    <div>
            
      <Row gutter={16}>
        <Col span={12}>
          <Card>
            <Statistic
              title="AVG. ACTIVITY"
              value={avgActivity}
              precision={2}
              valueStyle={{
                color: "#3f8600",
              }}
              suffix="%"
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Statistic
              title="Total Hours"
              value={totalTime}
              precision={2}
              valueStyle={{
                color: "#3f8600",
              }}
              suffix=""
            />
          </Card>
        </Col>
      </Row>
    </div>

  )
}
export default Statistics