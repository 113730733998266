import React, { useState } from "react";

import { notification } from "antd";
import { useTranslation } from "react-i18next";

import { updateContact } from "src/api/ContactApi";

import styles from "./DateCell.module.scss";
import { DateAttributeInlineEditingField } from "src/components/Conversation/Conversation";

const DateCell = ({
  value,
  allAttrData,
  row,
  accessor,
  attrId,
  allRefetch,
}) => {
  const { t } = useTranslation();
  const [isClicked, setIsClicked] = useState(false);

  const onDateSubmit = (val) => {
    const { id, name, value, valueId } = val;
    if (!id || !name) {
      return;
    }
    onSubmit([
      {
        id: valueId,
        name: name,
        value: value,
        attributeId: id,
        errorMessage: "",
        type: "Date",
      },
    ]);
  };
  const submitForm = async (data) => {
    try {
      let res = await updateContact(data, row?.original?.leadId);
      if (!res?.data?.success) {
        throw new Error("Encountered error while updating contact");
      }
    } catch (err) {
      notification.error({
        message: <b>Notification</b>,
        description: <b>Encountered server error while updating</b>,
      });
      return;
    }
  };
  const onSubmit = async (value) => {
    try {
      if (value.length === 0) {
        return;
      }
      // console.log(value);
      const data = {
        normalAttribute: value,
      };
      let isValid = true;
      value.map((tx) => {
        if (tx?.errorMessage.trim() !== "") {
          isValid = false;
        }
      });
      if (!isValid) {
        return;
      }
      // console.log(data)
      let res = await submitForm(data);
    } catch (err) {
      console.log(err);
      return;
    }
  };

  return (
    <span className={styles.DateCell}>
      {/* {!isClicked ? <div className={styles.DateCell__text} onClick={()=>setIsClicked(true)}>{value[0]?.value || "---"}</div>: */}
      <div className={styles.DateCell__container}>
        {value !== undefined && (
          <DateAttributeInlineEditingField
            attributeId={attrId?.id}
            label={accessor}
            valueId={value[0]?.id}
            initialValue={value[0]?.value}
            onSubmit={onDateSubmit}
            t={t}
            onDeleteForm={() => {}}
            isEdit={true}
            isTableElement={true}
          />
        )}
      </div>
      {/* } */}
    </span>
  );
};

export default DateCell;
