import React, { useEffect, useState } from "react";
import {
  formatTotalTime,
  timeSheetTimerConfig,
} from "../configs/dashboardConfig";
import { Button, Spin, Tag } from "antd";
import styles from "./TimeAndActivity.module.scss";
import {
  Avatar,
  Card,
  Col,
  Row,
  Space,
  Statistic,
  Table,
  Typography,
} from "antd";
import RangePickerWidget from "../components/timesheetview/timesheet-view-widgets/widgets/RangePickerWidget/RangePickerWidget";
import { extractNestedTimeAndActivityReports } from "src/api/TimesheetApi";
import { ClockCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import Statistics from "../components/timesheetview/timesheet-view-widgets/widgets/statistics/Statistics";
import { getActiveOrgId } from "src/store/slices/userSlice";
import { useQuery } from "@apollo/client";
import { GET_ORGANIZATION_STAFF } from "src/graphql/staff";


const { Text } = Typography;

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { day: "numeric", month: "long", year: "numeric" };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

const TimeAndActivity = () => {
  const [selectedRange, setSelectedRange] = useState(timeSheetTimerConfig);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalTime, setTotalTime] = useState("00:00:00"); // Total time of all projects
  const [clickedProject, setClickedProject] = useState(null);
  const [avgActivity, setAvgActivity] = useState(0);
  const [staff, setStaff] = useState([]);
  const organizationId = useSelector(getActiveOrgId);
 
  const {
    loading: staffLoading,
    refetch: staffRefetch,
    error: staffError,
    data: staffData,
  } = useQuery(GET_ORGANIZATION_STAFF, {
    variables: {
      organizationId: organizationId,
    },
  });
  console.log("data",staffData)

  useEffect(() => {
    if (staffData && staffData.getOrganization) {
      setStaff(staffData.getOrganization.organizationStaff);
    }
  }, [staffData]);


  const fetchNestedTableData = async () => {
    try {
      setIsLoading(true);
      let payload = {startDate: selectedRange.startDate,endDate: selectedRange.endDate}
      const response = await extractNestedTimeAndActivityReports(payload);
      setData(response.data.data);

      setTotalTime(response.data.TotalDurartion);
      setAvgActivity(response.data.AverageActivity);
    } catch (error) {
      console.error("Error fetching data from API:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNestedTableData();
  }, [selectedRange]);

  const handleRangeChange = (dates) => {
    setSelectedRange({
      startDate: dates[0].toISOString(),
      endDate: dates[1].toISOString(),
    });
    setClickedProject(null);
  };

  const onProjectClick = (project) => {
    setClickedProject(project);
  };

  const renderChildren = (record) => {
    if (record.children && record.children.length > 0) {
      return record.children.map((child) => {
        let shouldRenderTable = child.details && child.details.length > 0;
        return (
          <div key={child.id}>
            {shouldRenderTable && (
              <div style={{ padding: "18px" }}>
                <Avatar
                  style={{ marginRight: "7px", backgroundColor: "#055b7f" }}
                >
                  {child.name.charAt(0)}
                </Avatar>
                <Text style={{ fontSize: "18px", fontWeight: "normal" }}>
                  {" "}
                  {child.name} {"<"} {record.name}{" "}
                </Text>
                <Text
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    marginLeft: "10px",
                  }}
                >
                  <Tag
                    id="individual-childtotalDuration-project-time"
                    icon={<ClockCircleOutlined />}
                    color="default"
                  >
                    {getTotalDurationFromDetails(child?.details)}
                  </Tag>
                </Text>
              </div>
            )}
            <Spin spinning={isLoading}>
              {shouldRenderTable && (
                <Table
                  bordered={true}
                  columns={detailsColumns}
                  dataSource={child.details}
                  pagination={false}
                  expandable={false}
                />
              )}
              {renderChildren(child)}
            </Spin>
          </div>
        );
      });
    }
    return null;
  };

  const columns = [
    {
      title: "Project Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Space>
          <Avatar style={{ backgroundColor: "#87d068" }}>
            {text.charAt(0)}
          </Avatar>
          <span
            id="parent-project"
            style={{ cursor: "pointer" }}
            onClick={() => onProjectClick(record)}
          >
            {text}
          </span>
        </Space>
      ),
    },
    {
      title: "Total Time",
      dataIndex: "totalDuration",
      key: "totalDuration",
      render: (text) => formatTotalTime(text),
    },
  ];

  const detailsColumns = [
    {
      title: "Member",
      dataIndex: "email",
      key: "email",
      filters: staff.map((staff) => ({
        text: staff.staff.email,
        value: staff.staff.email,
      })),
      onFilter: (text, record) => {
        return record.email === text;
      },
      render: (text) => (
        <Space>
          <Avatar style={{ backgroundColor: "#87d068" }}>
            {text.charAt(0)}
          </Avatar>
          <span>{text}</span>
        </Space>
      ),
    },
    {
      title: "Date",
      dataIndex: "startTime",
      key: "startTime",
      render: (text) => formatDate(text),
    },
    {
      title: "Total Hours",
      dataIndex: "duration",
      render: (text) => formatTotalTime(text),
    },
    {
      title: "Activity %",
      dataIndex: "activityPercentage",
      key: "activityPercentage",
    },
  ];
  const paginationConfig = {
    pageSize: 5,
  };

  function getTotalDurationFromDetails(details) {
    let totalSeconds = 0;

    details?.forEach((detail) => {
      const durationParts = detail.duration
        .split(":")
        .map((part) => parseFloat(part));
      const seconds =
        durationParts[5] +
        durationParts[4] * 60 +
        durationParts[3] * 3600 +
        durationParts[2] * 86400 +
        durationParts[1] * 2592000 +
        durationParts[0] * 31104000;
      totalSeconds += seconds;
    });

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  }

  return (
    <div className={styles.dashboard}>
      <section className="heading">
        <Text style={{ fontSize: "20px" }} type="secondary">
          Time and Activity reports
        </Text>
      </section>
      <section className={styles.widgets}>
        <RangePickerWidget
          value={selectedRange}
          onDateRangeChange={handleRangeChange}
        />
      </section>
      <section className="reports">
        <div className={styles.reportsHeader}>
          <div className={styles.mainTable}>
            <Spin spinning={isLoading}>
              <Table
                columns={columns}
                dataSource={data}
                pagination={paginationConfig}
              />
            </Spin>
          </div>

          <div className={styles.statistic}>
            <Statistics avgActivity={avgActivity} totalTime={totalTime} />
          </div>
        </div>
      </section>
      <section>
        <div className={styles.selectedProject}>
          {clickedProject && clickedProject.details && (
            <div>
              <Avatar
                style={{ marginRight: "7px", backgroundColor: "#055b7f" }}
              >
                {clickedProject.name.charAt(0)}
              </Avatar>
              <Text
                style={{
                  fontSize: "18px",
                  fontWeight: "normal",
                  marginRight: "14px",
                }}
              >
                {clickedProject.name}
              </Text>
              <Tag
                id="individual-project-time"
                style={{ fontSize: "10px" }}
                icon={<ClockCircleOutlined />}
                color="default"
              >
                {getTotalDurationFromDetails(clickedProject?.details)}
              </Tag>
            </div>
          )}
        </div>
        {clickedProject && clickedProject.details && (
          <div className={styles.projectDetails}>
            <Spin spinning={isLoading}>
              <Table
                columns={detailsColumns}
                dataSource={clickedProject.details}
                pagination={false}
              />
            </Spin>
          </div>
        )}
        {clickedProject && <>{renderChildren(clickedProject)}</>}
      </section>
    </div>
  );
};

export default TimeAndActivity;
