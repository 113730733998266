import React from "react";
import { message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";

const DownnloadReportsButton = ({ tableData }) => {
  const headers = [
    { label: "ID", key: "id" },
    { label: "Email", key: "email" },
    { label: "Start Time", key: "startTime" },
    { label: "End Time", key: "endTime" },
    { label: "Username", key: "username" },
    { label: "Project", key: "node.title" },
    { label: "Activity Type", key: "activityType" },
    { label: "Duration", key: "duration" },
  ];
  const formatDuration = (durationString) => {
    const [hours, minutes, seconds] = durationString.split(":");
    return `${parseInt(hours)} hours ${parseInt(minutes)} minutes ${parseInt(
      seconds
    )} seconds`;
  };

  const data =
    tableData.data &&
    tableData.data.map((item) => ({
      id: item.id,
      email: item.email,
      username: item.username,
      startTime: item.startTime,
      endTime: item.endTime,
      "node.title": item.node.title,
      activityType: item.activityType,
      duration: formatDuration(item.duration),
    }));
  const handleDownload = () => {
    if (!data || data.length === 0) {
      message.warning("Data not available for selected date");
    } else {
      message.success("Download complete");
    }
  };

  return (
    <div>
      {data && data.length > 0 && (
        <CSVLink
          data={data}
          headers={headers}
          filename={"time_logs.csv"}
          onClick={handleDownload}
        >
          <DownloadOutlined /> Download CSV
        </CSVLink>
      )}
    </div>
  );
};

export default DownnloadReportsButton;
