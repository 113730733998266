import { gql } from "@apollo/client";

export const GET_TIMEZONE = gql`
  subscription ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      id
      timezone
    }
  }
`;

export const GET_AGENT_TIMEZONE = gql`
  subscription ($staffId: ID!) {
    getHC_Staff(id: $staffId) {
      id
      timezone
    }
  }
`;

export const GET_ORG_LANGUAGE = gql`
  subscription ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      id
      language
    }
  }
`;

export const AUTOMATION_RULE_BY_ORGANISATION = gql`
  query ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      automationRule {
        name
        id
      }
    }
  }
`;

export const GET_STAFF_ORGANISATION = gql`
  subscription ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      id
      name
      organizationStaff {
        id
        roleName
        staff {
          id
          firstName
        }
      }
    }
  }
`;

export const EXTRACTION_DATA_SUBSCRIPTION = gql`
  subscription ($channelId: ID!) {
    getEmailExtractionChannel(id: $channelId) {
      id
      email
      organization {
        id
      }
      active
      isEmailVerified
      verificationId
      isAttributeMapping
      aiParsing {
        source
        listOfAttributes
        specialInstructions
        attributeMapping {
          id
          attributeName
          attributeType
          parsedAttributeValue
          attribute {
            id
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_ATTRIBUTES = gql`
  query ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      labels {
        id
        name
        color
      }
      normalAttribute {
        id
        name
        type
        createdAt
        attributeType
      }
      multiSelectAttribute {
        id
        name
        createdAt
        attributeType
        labels {
          id
          name
          color
          category {
            id
            name
          }
        }
      }
      userAttribute {
        id
        name
        createdAt
        attributeType
      }
    }
  }
`;

export const CHECK_PIPELINE_STATUS = gql`
  subscription ($organizationId: ID!, $pipelineName: String!) {
    getOrganization(id: $organizationId) {
      id
      labellingFeature
      nlxPipeline(filter: { pipelineName: { eq: $pipelineName } }) {
        id
        pipelineName
        disabled
      }
    }
  }
`;

export const UPDATE_CUE_CARD_STATUS = gql`
  mutation updateOrganization($organizationId: ID!, $cueCardStatus: Boolean! ) {
    updateOrganization(
      input: {
          filter: { id: [$organizationId] }
          set: { cueCardFeature: $cueCardStatus }
        }
    ){
      organization{
          cueCardFeature
      }
    }
  }
`