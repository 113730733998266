import ChatApiClient from "./clients/ChatApiClient";
import CoreApiClient from "./clients/CoreApiClient";

export const sendMessage = async (body, handleDownloadProgress) => {
  return ChatApiClient({
    method: "POST",
    url: "/message/send",
    data: body,
    headers: { "Content-Type": "multipart/form-data" },
    onDownloadProgress: handleDownloadProgress,
  });
};

export const updateCannedTemplate = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: `/messagetemplates/update`,
    data: body,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const createCannedTemplate = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: `/messagetemplates/create`,
    data: body,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const deleteCannedTemplateAPI = async (body) => {
  return CoreApiClient({
    method: "DELETE",
    url: "/messagetemplates/delete",
    data: body,
  });
};

export const uploadAttachment = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/messagetemplates/upload",
    data: body,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getUnrepliedCount = async (url, body, headers) => {
  return CoreApiClient({
    method: "GET",
    url: `/conversation/unreplied-counts/${body.organizationId}`,
    headers: headers,
  });
};

export const getQueryMessages = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/conversation/adjacent-messages",
    data: body,
  });
};

export const resolveChatApi = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/chat/resolve-chat",
    data: body,
  });
};

export const getSortSearchResults = async ({
  query,
  sortValue,
  page,
  limit,
}) => {
  return ChatApiClient({
    method: "GET",
    url: `/conversation/messages?searchTerm=${query}&sort=${sortValue}&page=${page}&limit=${limit}`,
  });
};
