import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./ShowContactDrawer.module.scss";
import { Button, Drawer, Form, Input, message, Spin } from "antd";
import ProfilePicPlaceholder from "../ProfilePicPlaceholder/ProfilePicPlaceholder";
import OpenChat from "src/assets/svgs/OpenChat";
import EditContactIcon from "src/assets/svgs/EditContactIcon";
import MergeContactIconbar from "src/assets/svgs/MergeContactIconbar";
import DeleteContactIcon from "src/assets/svgs/DeleteContactIcon";
import WhatsappIcon from "src/assets/svgs/WhatsappIcon";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Avatar, Tag } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import Normal from "./components/Normal/Normal";
import Tags from "./components/Tags/Tags";
import User from "./components/User/User";
import { GET_LEAD_DATA } from "src/graphql/lead";

const ShowContactDrawer = ({
  onClose,
  open,
  selectedContact,
  showEditDetailsDrawer,
  onDetailsClose,
  fetchSelectedContactData,
  editForm,
  staffData,
  type,
  handleClose,
  leadsData,
  t,
}) => {
  const navigate = useNavigate();

  const [lead, setLead] = useState({});

  // useEffect(() => {
  //   editForm?.resetFields();
  // }, [selectedContact]);

  const { loading, refetch, error, data } = useQuery(GET_LEAD_DATA, {
    variables: {
      leadId: selectedContact,
    },
  });

  // console.log(selectedContact)

  useEffect(() => {
    if (leadsData?.length !== 0 && selectedContact !== "") {
      // console.log(leadsData,selectedContact)
      let res = leadsData?.filter(
        (item) => item.leadId === selectedContact
      )?.[0];
      // delete res?.leadId;
      if (res && typeof res !== "undefined") {
        setLead(res);
      }
    }
  }, [leadsData, selectedContact]);

  // console.log(lead)

  const handleEditClick = () => {
    onDetailsClose();
    showEditDetailsDrawer();
  };

  const redirectChat = () => {
    navigate(`/chat/${data?.queryHC_Lead[0]?.id}`);
  };
  function isURL(string) {
    var regexp =
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (regexp.test(string)) {
      return true;
    } else {
      return false;
    }
  }
  function handleClick() {
    handleClose(selectedContact);
  }

  function RenderComponent(key, value, item) {
    switch (item?.mainType) {
    case "normalAttribute":
      return <Normal name={key} value={value} />;
    case "multiSelectAttribute":
      return <Tags name={key} value={value} />;
    case "userAttribute":
      return <User usersData={staffData} name={key} value={value} />;
    default:
      return null;
    }
  }

  const handleOnClose = () => {
    setLead({});
    onClose();
  };

  let renderedKeys = [];

  // console.log(lead)
  // console.log( Object.entries(lead)
  // .filter(([key, value]) => key !== "leadId")
  // .reduce((acc, [key, value]) => {
  //   let hasKey = false;
  //   acc.forEach(obj => {
  //     if (Object.keys(obj)[0] === key) hasKey = true;
  //   });
  //   if (!hasKey) acc.push({ [key]: value });
  //   return acc;
  // }, []))

  // console.log(lead)

  return (
    <div className={styles.ShowContactDrawer} data-testid="ShowContactDrawer">
      {type === "conversation" ? (
        <>
          {" "}
          {!loading ? (
            <>
              <div className={styles.ShowContactDrawer__drawer__close}>
                <CloseOutlined onClick={() => handleClick()} />
              </div>
              <div className={styles.ShowContactDrawer__drawer__head}>
                <div
                  className={
                    styles.ShowContactDrawer__drawer__head__piccontainer
                  }
                >
                  <ProfilePicPlaceholder
                    id={data?.queryHC_Lead[0]?.id}
                    name={data?.queryHC_Lead[0]?.username}
                    width={"52px"}
                    height={"52px"}
                    fontSize={"20px"}
                  />
                  <span>{data?.queryHC_Lead[0]?.username}</span>
                </div>
                <div
                  className={
                    styles.ShowContactDrawer__drawer__head__btncontainer
                  }
                >
                  <div onClick={() => redirectChat()}>
                    <OpenChat />
                  </div>
                  <div onClick={() => handleEditClick()}>
                    <EditContactIcon />
                  </div>
                  <div>
                    <MergeContactIconbar />
                  </div>
                  <div>
                    <DeleteContactIcon />
                  </div>
                </div>
              </div>
              <div className={styles.ShowContactDrawer__drawer__details}>
                {lead !== "undefined" &&
                  Object?.entries(lead)?.length !== 0 &&
                  Object.entries(lead)?.map(([key, value]) =>
                    value.map((item) => (
                      <div
                        className={
                          styles.ShowContactDrawer__drawer__details__item
                        }
                      >
                        <p>{key}</p>
                        {RenderComponent(key, value, item)}
                      </div>
                    ))
                  )}
              </div>
            </>
          ) : (
            <Spin></Spin>
          )}
        </>
      ) : (
        <>
          {" "}
          <Drawer
            destroyOnClose
            placement="right"
            onClose={handleOnClose}
            open={open}
            visible={open}
            className={styles.ShowContactDrawer__drawer}
          >
            {!loading ? (
              <>
                <div className={styles.ShowContactDrawer__drawer__head}>
                  <div
                    className={
                      styles.ShowContactDrawer__drawer__head__piccontainer
                    }
                  >
                    <ProfilePicPlaceholder
                      id={data?.queryHC_Lead[0]?.id}
                      name={data?.queryHC_Lead[0]?.username}
                      width={"52px"}
                      height={"52px"}
                      fontSize={"20px"}
                    />
                    <span>{data?.queryHC_Lead[0]?.username}</span>
                  </div>
                  <div
                    className={
                      styles.ShowContactDrawer__drawer__head__btncontainer
                    }
                  >
                    <div onClick={() => redirectChat()}>
                      <OpenChat />
                    </div>
                    <div onClick={() => handleEditClick()}>
                      <EditContactIcon />
                    </div>
                    <div>
                      <MergeContactIconbar />
                    </div>
                    <div>
                      <DeleteContactIcon />
                    </div>
                  </div>
                </div>
                <div className={styles.ShowContactDrawer__drawer__details}>
                  {lead !== "undefined" &&
                    Object?.entries(lead)?.length !== 0 &&
                    Object.entries(lead)
                      ?.filter(([key, value]) => key !== "leadId")
                      .map(([key, value]) =>
                        value.map((item) => {
                          if (
                            Object.keys(item).length !== 1 ||
                            !("mainType" in item)
                          ) {
                            // console.log(key)
                            if (
                              !renderedKeys.includes(key) ||
                              (item.mainType === "multiselect" &&
                                !renderedKeys.includes(item.name))
                            ) {
                              renderedKeys.push(key);
                              if (item.mainType === "multiselect") {
                                renderedKeys.push(item.name);
                              }
                              return (
                                <div
                                  className={
                                    styles.ShowContactDrawer__drawer__details__item
                                  }
                                >
                                  <p>{key}</p>
                                  {RenderComponent(key, value, item)}
                                </div>
                              );
                            }
                          }
                          return null;
                        })
                      )}
                </div>
              </>
            ) : (
              <Spin></Spin>
            )}
          </Drawer>
        </>
      )}
    </div>
  );
};

ShowContactDrawer.propTypes = {};

ShowContactDrawer.defaultProps = {};

export default ShowContactDrawer;
