import React from "react";
import PropTypes from "prop-types";
import styles from "./CountrySelect.module.scss";
import { Select } from "antd";
import countries from "src/helpers/countries.json";

const { Option } = Select;
const CountrySelect = ({ setFieldValue, values, disabled }) => (
  <Select
    showSearch
    style={{ width: "120px", fontSize: "14px", padding: "0 5px" }}
    placeholder="Search to Select"
    optionFilterProp="children"
    onChange={(value) => setFieldValue("dialCodeText", value)}
    value={values.dialCodeText}
    defaultValue={values.dialCodeText}
    name="dialCodeText"
    disabled={disabled === undefined ? false : disabled}
  >
    {countries.map((country) => (
      <Option
        key={country.isoCode}
        value={`${country.dialCode},${country.isoCode}`}
      >
        {country.dialCode} <img style={{ width: "20px" }} src={country.flag} />
      </Option>
    ))}
  </Select>
);

CountrySelect.propTypes = {};

CountrySelect.defaultProps = {};

export default CountrySelect;
