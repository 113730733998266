import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetAuth } from "src/store/slices/authSlice";
import { resetChannels } from "src/store/slices/channelSlice";
import { resetPeople } from "src/store/slices/peopleSlice";
import { resetTimer } from "src/store/slices/timerSlice";
import { resetUser } from "src/store/slices/userSlice";

const useAuthRedirect = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const redirectToLogin = () => {
    dispatch(resetAuth());
    dispatch(resetChannels());
    dispatch(resetPeople());
    dispatch(resetUser());
    dispatch(resetTimer());
    navigate("/login");
  };

  return { redirectToLogin };
};

export default useAuthRedirect;
