import React, { useEffect, useState } from "react";

import { Button, Drawer, message } from "antd";
import { isEqual } from "lodash";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { ArrowLeftOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";

import { updateSegmentation } from "src/api/SegmentationApi";
import QuerySelector from "../QuerySelector/QuerySelector";
import styles from "./EditFilterDrawer.module.scss";
import {
  convertDataFormat,
  convertDataFormatChannels,
  extractLabels,
  flattenAttributes,
  formHasError,
  generateRandomNumber,
  getStaffArray,
  getTagsId,
} from "./helpers/helpers";
import { setGlobalSelectedSegment } from "src/store/slices/contactSlice";
import {
  GET_MULTISELECTS,
  GET_ORGANIZATION_DATA,
  GET_ORGANIZATION_LABELS,
} from "src/graphql/contact";
import { GET_ORGANIZATION_STAFF } from "src/graphql/staff";
import { GET_ONE_SEGMENT } from "src/graphql/segment";
import { LOAD_CHANNELS } from "src/graphql/channel";
import { getActiveOrgId } from "src/store/slices/userSlice";

const randomColor = "#" + Math.random().toString(16).substr(-6);

const EditFilterDrawer = ({
  handleOpenSegmentDrawer,
  onClose,
  open,
  handleFetchFilters,
  initialFilters,
}) => {
  const organizationId = useSelector(getActiveOrgId);

  const {
    loading,
    error,
    data: multiSelectsData,
  } = useQuery(GET_MULTISELECTS, {
    variables: {
      organizationId: organizationId,
    },
  });
  const {
    loadingAssignees,
    errorAssignees,
    data: assigneesData,
  } = useQuery(GET_ORGANIZATION_STAFF, {
    variables: {
      organizationId: organizationId,
    },
  });

  const {
    data: channelsData,
    loading: loadingChannels,
    refetch: refetchChannels,
  } = useQuery(LOAD_CHANNELS, {
    variables: {
      organizationId: organizationId,
    },
  });

  const {
    loading: orgDataLoading,
    error: orgError,
    data: orgData,
    refetch: refetchOrg,
  } = useQuery(GET_ORGANIZATION_DATA, {
    variables: {
      organizationId: organizationId,
    },
  });

  const dispatch = useDispatch();

  const [tagsId, setTagsId] = useState("");
  const [allLabels, setAllLabels] = useState([]);
  const [allAssignees, setAllAssignees] = useState([]);
  const [allChannels, setAllChannels] = useState([]);
  const [attrAndTypes, setAttrAndTypes] = useState([]);
  const [isSegmentModalOpen, setIsSegmentModalOpen] = useState(false);
  const [saveLoading, setLoading] = useState(false);

  const showModal = () => {
    const errorMessages = formHasError(selectedFilters, attrAndTypes);
    if (errorMessages.length > 0) {
      errorMessages.forEach((errorMessage) => {
        message.warning(errorMessage);
      });
    } else {
      setIsSegmentModalOpen(true);
    }
  };
  const handleOk = () => {
    setIsSegmentModalOpen(false);
  };
  const handleCancel = () => {
    setIsSegmentModalOpen(false);
  };

  useEffect(() => {
    if (
      multiSelectsData !== undefined &&
      Object.keys(multiSelectsData)?.length !== 0
    ) {
      setTagsId(getTagsId(multiSelectsData));
    }
  }, [multiSelectsData]);

  const {
    data: tagsData,
    loading: attributeLoading,
    error: attributeError,
    refetch,
  } = useQuery(GET_ORGANIZATION_LABELS, {
    variables: {
      organizationId: organizationId,
      multiselectId: tagsId,
    },
    skip: !organizationId || !tagsId,
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (tagsData !== undefined && Object.keys(tagsData)?.length !== 0) {
      setAllLabels(extractLabels(tagsData));
    }
  }, [tagsData]);

  useEffect(() => {
    if (assigneesData && Object.keys(assigneesData).length !== 0) {
      setAllAssignees(getStaffArray(assigneesData));
    }
  }, [assigneesData]);

  useEffect(() => {
    if (channelsData && Object.keys(channelsData).length !== 0) {
      setAllChannels(convertDataFormatChannels(channelsData));
    }
  }, [channelsData]);

  useEffect(() => {
    if (orgData && Object.keys(orgData).length !== 0) {
      setAttrAndTypes(flattenAttributes(orgData.getOrganization));
    }
  }, [orgData]);

  const [selectedFilters, setSelectedFilters] = useState([
    {
      id: generateRandomNumber(),
      category: null,
      subCategory: null,
      operator: null,
      values: [],
      combination: "and",
      contactFilterValue: "",
    },
  ]);

  const [initState, setInitState] = useState([]);

  useEffect(() => {
    if (initialFilters && initialFilters?.getSegments) {
      setSelectedFilters(
        initialFilters?.getSegments?.segmentInformation.map((info) => {
          // Create a copy of the object without the 'id' property
          const { id, ...newInfo } = info;
          return newInfo;
        })
      );
      setInitState(
        initialFilters?.getSegments?.segmentInformation.map((info) => {
          // Create a copy of the object without the 'id' property
          const { id, ...newInfo } = info;
          return newInfo;
        })
      );
    }
  }, [initialFilters]);

  const handleAddFilter = () => {
    const newId = generateRandomNumber();
    setSelectedFilters([
      ...selectedFilters,
      {
        id: newId,
        category: null,
        subCategory: null,
        operator: null,
        values: [],
        combination: "and",
        contactFilterValue: "",
      },
    ]);
  };

  const handleChangeFilter = (val, key) => {
    let res = selectedFilters.map((item) => {
      if (item.id == key) {
        return {
          ...item,
          category: val.category,
          subCategory: val.subCategory,
          operator: val.operator,
          values: val.values,
          combination: val.combination,
          contactFilterValue: val.contactFilterValue,
        };
      }
      return item;
    });
    setSelectedFilters(res);
  };

  const handleRemoveFilter = (filterId) => {
    // console.log(filterId)
    // console.log(selectedFilters)
    const updatedFilters = selectedFilters.filter(
      (filter) => filter.id !== filterId
    );
    // console.log(updatedFilters)

    // handleChangeFilter(updatedFilters, filterId);
    setSelectedFilters(updatedFilters);
  };

  const handleClearAll = () => {
    dispatch(setGlobalSelectedSegment("all"));
    setSelectedFilters([
      {
        id: 1,
        category: null,
        subCategory: null,
        operator: null,
        values: [],
        combination: "and",
        contactFilterValue: "",
      },
    ]);
    onClose();
  };

  const handleApply = () => {
    // handleFetchFilters(selectedFilters)
    // onClose()

    const errorMessages = formHasError(selectedFilters, attrAndTypes);
    // console.log(selectedFilters)
    if (errorMessages.length > 0) {
      errorMessages.forEach((errorMessage) => {
        message.warning(errorMessage);
      });
    } else {
      message.success("Filters applied");
      handleFetchFilters(selectedFilters);
      dispatch(setGlobalSelectedSegment(""));
      onClose();
    }
  };

  const selectedSegment = useSelector(
    (state) => state?.contactReducer?.globalSelectedSegment
  );

  const {
    data: segmentData,
    loading: segmentDataLoading,
    error: segmentDataError,
  } = useQuery(GET_ONE_SEGMENT, {
    variables: {
      organizationId: organizationId,
      segmentId: selectedSegment,
    },
  });

  useEffect(() => {
    if (
      selectedSegment !== "all" &&
      segmentData?.getSegments?.segmentInformation !== undefined
    ) {
      setSelectedFilters(segmentData?.getSegments?.segmentInformation);
    }
  }, [selectedSegment]);

  const handleUpdateSegment = () => {
    // setLoading(true);

    let obj = {
      id: initialFilters?.getSegments?.id,
      organizationId: organizationId,
      segment: {
        name: initialFilters?.getSegments?.name,
        organization: {
          id: organizationId,
        },
        segmentInformation: selectedFilters.map((info) => {
          // Create a copy of the object without the 'id' property
          const { id, __typename, ...newInfo } = info;
          return newInfo;
        }),
      },
    };
    updateSegmentation(obj)
      ?.then((res) => {
        message.success("Update successful!");
        handleCancel();
        onClose();
        setLoading(false);
        refetch();
      })
      .catch((e) => {
        if (e?.response?.data?.message && e?.response?.data?.message !== "") {
          message.error(e?.response?.data?.message);
        } else {
          message.error("Something went wrong .Try again later");
        }
        setLoading(false);
      });
  };

  const handleChangeFilterSpecific = (val, key, it) => {
    let res = selectedFilters.map((item) => {
      if (item.id == key) {
        return {
          ...item,
          [it]: val,
        };
      }
      return item;
    });
    setSelectedFilters(res);
  };

  function setPropertyToEmptyInLastItem(propertyName) {
    if (Array.isArray(selectedFilters) && selectedFilters.length > 0) {
      // console.log("jnjknk")
      setSelectedFilters(
        selectedFilters.map((item, index) => {
          if (index === selectedFilters.length - 1) {
            // For the last item in the array, set the specified property to empty.
            return {
              ...item,
              [propertyName]: "",
            };
          } else {
            return item;
          }
        })
      );
    } else {
      // If the array is empty or not an array, return it as is.
      return;
    }
  }

  // useEffect(()=>{
  //   setPropertyToEmptyInLastItem('combination')
  // },[])

  return (
    <Drawer
      title={null}
      placement="right"
      onClose={onClose}
      visible={open}
      // width={500}
      closable={false}
      className={styles.EditFilterDrawer}
      data-testid="EditFilterDrawer"
    >
      <div className={styles.EditFilterDrawer__header}>
        <div className={styles.EditFilterDrawer__header__left}>
          <ArrowLeftOutlined
            className={styles.EditFilterDrawer__header__left__arrow}
            onClick={onClose}
          />
        </div>
        <Button
          disabled={saveLoading || isEqual(initState, selectedFilters)}
          type="primary"
          onClick={handleUpdateSegment}
        >
          Update Segment
        </Button>
      </div>

      <div className={styles.EditFilterDrawer__body}>
        {selectedFilters.map((filter) => (
          <>
            <QuerySelector
              key={filter.id}
              items={selectedFilters}
              isEdit={true}
              addFilter={handleAddFilter}
              filterId={filter.id}
              onRemoveFilter={handleRemoveFilter}
              labels={allLabels}
              allAssignees={convertDataFormat(allAssignees)}
              handleChangeFilter={handleChangeFilter}
              handleChangeFilterSpecific={handleChangeFilterSpecific}
              setPropertyToEmptyInLastItem={setPropertyToEmptyInLastItem}
              multiSelectsData={multiSelectsData}
              allChannels={allChannels}
              attrAndTypes={attrAndTypes?.map((item) => ({
                ...item,
                label: item.name,
                value: item.id,
              }))}
              filter={filter}
              segmentData={initialFilters}
            />
          </>
        ))}
      </div>
    </Drawer>
  );
};

EditFilterDrawer.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

EditFilterDrawer.defaultProps = {
  onClose: () => {},
  open: false,
};

export default EditFilterDrawer;
