import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { InfoCircleOutlined, CloseOutlined } from "@ant-design/icons";
import styles from "./MultiSelectInlineFields.module.scss";
import SelectOptions from "../../../ContactsPage/components/SelectOptions/SelectOptions";
import { Tooltip } from "antd";
import { isEqual } from "lodash";

const MultiSelectInlineFields = ({
  reftechData,
  allRefetch,
  attributeId,
  label,
  tags,
  t,
  onMultiSelectSubmit,
  onDeleteForm,
  isDefault,
  isEdit,
  isTableElement = false
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [data, setData] = useState([]); // Updated state to store the selected data
  const [currentId, setCurrentId] = useState(""); // Updated state to store the selected data

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleMultiSelectChange = (id, res) => {
    // console.log(id,res)
    let selectedData = [];
    setCurrentId(id)
    res?.map((tx) => {
      if (tx.isSelected) {
        selectedData.push({
          id: tx?.id,
          color: tx?.color,
          name: tx?.name,
          multiSelectAttribute: {
            id: id
          }
        });
      }
    });
    // const areEqual = isEqual(selectedData, data);
    // console.log(areEqual,selectedData,data)
    // if(!areEqual){
    onMultiSelectSubmit(selectedData, currentId);
    // }
    setData(selectedData); // Update the data state with the selected data
  };

  const onDeleteClick = () => {
    let data = [];
    tags.map((tx) => data.push(tx?.id));
    onDeleteForm("MultiSelectAttributeValue", data, attributeId, label);
  };

  return (
    <div
      // ref={componentRef}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      className={`${styles.MultiSelectInlineFields} ${isHovering ? styles.hover : ""}`}
      style={{ height: isHovering ? "auto" : "auto" }}
    >
      <div className={styles.MultiSelectInlineFields__DataField}>
        {!isTableElement && (
          <div className={styles.MultiSelectInlineFields__Container}>
            <div className={styles.MultiSelectInlineFields__Label}>{label}</div>
            <div className={styles.MultiSelectInlineFields__IconContainer}>
              <Tooltip title="Source: Internal MultiSelect">
                <InfoCircleOutlined className={styles.MultiSelectInlineFields__InfoIcon} />
              </Tooltip>
            </div>
          </div>
        )}
        <div className={styles.MultiSelectInlineFields__selectbox}>
          <SelectOptions
            reftechData={reftechData}
            allRefetch={allRefetch}
            isTableElement={isTableElement}
            handleMultiSelectChange={handleMultiSelectChange}
            item={{
              id: attributeId,
              type: "Multiselect",
              name: label,
              options: tags,
              value: "",
              isEdit: isEdit
            }}
            t={t}
          />
        </div>
      </div>
    </div>
  );
};

MultiSelectInlineFields.propTypes = {};

MultiSelectInlineFields.defaultProps = {};

export default MultiSelectInlineFields;
