import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    timeFilter: null,
    upvotedResponse: false,
    downvotedResponse: false,
};

export const traningFilterSlice = createSlice({
    name: "trainingFilter",
    initialState,
    reducers: {
        setTimeFilter(state, action) {
            state.timeFilter = action.payload;
        },
        setUpvotedResponse(state, action) {
            state.upvotedResponse = action.payload;
        },
        setDownvotedResponse(state, action) {
            state.downvotedResponse = action.payload;
        },
    },

})

export const { setTimeFilter, setUpvotedResponse, setDownvotedResponse } = traningFilterSlice.actions;
export default traningFilterSlice.reducer;