import { getTypeFromFilterAndArrTypes } from "../../../../Table/helpers/helpers";

export function getTagsId(data) {
  const multiSelectAttributes = data.getOrganization.multiSelectAttribute;

  for (const attribute of multiSelectAttributes) {
    if (attribute.name === "Tags") {
      return attribute.id;
    }
  }

  return null; // Return null if the "Tags" multiSelectAttribute is not found
}

export const getAttributeIdFromAttrAndTypes = (data, id) => {
  for (const attribute of data) {
    if (attribute.id === id) {
      return attribute.type;
    }
  }

}

export function getMultiTagId(data, name) {
  const multiSelectAttributes = data.getOrganization.multiSelectAttribute;

  for (const attribute of multiSelectAttributes) {
    if (attribute.name === name) {
      return attribute.id;
    }
  }

  return null; // Return null if the "Tags" multiSelectAttribute is not found
}

export function extractLabels(data) {
  const multiSelectAttributes = data.getOrganization.multiSelectAttribute;

  if (multiSelectAttributes.length > 0 && multiSelectAttributes[0].labels) {
    return multiSelectAttributes[0].labels;
  }

  return [];
}

export const getIconByType = (type) => {
  switch (type) {
  case "whatsappOfficial":
    return "whatsapp-icon"; // Replace with the appropriate icon for WhatsApp
  case "apiChannel":
    return "api-icon"; // Replace with the appropriate icon for API Channel
  default:
    return "default-icon"; // Replace with a default icon or handle other types
  }
};

export const convertDataFormatChannels = (data) => {
  const convertedData = [];

  if (
    data &&
    data.getOrganization &&
    data.getOrganization.channelData
  ) {
    const { waChannel, apiChannel } = data.getOrganization.channelData;

    if (Array.isArray(waChannel)) {
      waChannel.forEach((channel) => {
        const { id, name, phone } = channel;
        const type = "whatsappOfficial";
        const value = name;
        const icon = getIconByType(type);

        convertedData.push({ id, label: name, phone, type, value, icon });
      });
    }

    if (Array.isArray(apiChannel)) {
      apiChannel.forEach((channel) => {
        const { id, name } = channel;
        const type = "apiChannel";
        const value = name;
        const icon = getIconByType(type);

        convertedData.push({ id, label: name, phone: "", type, value, icon });
      });
    }
  }

  return convertedData;
};


export const getStaffArray = (data) => {
  // Check if the required properties exist in the data
  if (
    data &&
    data.getOrganization &&
    data.getOrganization.organizationStaff &&
    Array.isArray(data.getOrganization.organizationStaff)
  ) {
    // Extract the staff objects from the organizationStaff array
    const staffArray = data.getOrganization.organizationStaff.map((staffObj) => {
      // Check if the staff object has the required properties
      if (
        staffObj &&
        staffObj.staff &&
        staffObj.staff.__typename === "HC_Staff"
      ) {
        // Extract the necessary properties from the staff object
        const { id, username, email, firstName, lastName } = staffObj.staff;
        return {
          id,
          username,
          email,
          firstName,
          lastName,
        };
      }
      return null;
    });

    // Remove any null values from the staffArray
    const filteredStaffArray = staffArray.filter((staff) => staff !== null);

    return filteredStaffArray;
  }

  // Return an empty array if the required properties are not found
  return [];
};

export const convertDataFormat = (data) => {
  if (Array.isArray(data)) {
    return data.map((item) => {
      const label = `${item.firstName} ${item.lastName}`;
      const { id } = item;
      return { label, value: id };
    });
  }

  return [];
};

export function flattenAttributes(data) {
  let allAttributes = [];
  allAttributes = allAttributes.concat(
    data.normalAttribute,
    data.multiSelectAttribute,
    data.userAttribute
  );
  return allAttributes?.map((item) => {
    return {
      name: item.name,
      id: item.id,
      type: item.__typename,
      attrType: item?.type ? item?.type : ""
    }
  })
}

export function formHasError(filters, attrAndTypes) {
  const errorMessages = [];
  let type;
  filters.forEach((filter) => {
    type = getTypeFromFilterAndArrTypes(filter, attrAndTypes)
    if (!filter.category) {
      errorMessages.push("Category is not defined.");
    }
    if (filter.category && !filter.operator) {
      errorMessages.push("Operator is not defined.");
    }
    if (filter.category === "Contact field" && !filter.subCategory) {
      errorMessages.push("Subcategory is not defined for 'Contact field' category.");
    }
    if (!["exists", "does not exist"].includes(filter.operator) && type == "Numbers" && !isNumber(filter.contactFilterValue)) {
      errorMessages.push("Number filter input is invalid");
    }
    if (!["exists", "does not exist"].includes(filter.operator) && (type === "MultiSelectAttribute" || type === "UserAttribute") && filter.values.length === 0) {
      errorMessages.push("Tags are empty.");
    }
    if (!["exists", "does not exist"].includes(filter.operator) && type !== "MultiSelectAttribute" && type !== "UserAttribute" && filter.contactFilterValue === "") {
      errorMessages.push("Filter input value is empty");
    }
  });

  function isNumber(value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }


  return errorMessages;
}



export function generateRandomString() {
  const length = 6;
  const characters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let randomString = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters[randomIndex];
  }

  return randomString;
}

export function generateRandomNumber (){
  return Math.floor(Math.random() * 100000) + 1
}




