import React from "react";
import Profile from "../Profile/Profile";
import PropTypes from "prop-types";
import styles from "./UserProfile.module.scss";

const UserProfile = () => {
  return (
    <div data-testid="profile_setting" className={styles.UserProfile}>
      <Profile />
    </div>
  );
};

UserProfile.propTypes = {};

UserProfile.defaultProps = {};

export default UserProfile;
