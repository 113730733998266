import React from 'react';
import style from './VisitorStatistics.module.scss';
import PieChartStats from '../Charts/PieChart';
import { Empty } from 'antd';
import {  Button } from 'antd';
import { CSVLink } from 'react-csv';
import ExportCSVButton from './exportCSVButton';


const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

const VisitorStatistics = ({ data,leadData,selectedRange }) => {
  const {
    interacted_with_chatbot = 0,
    false_lead_interacted_with_chatbot = 0,
    percentage_of_users_interacted_with_chatbot
  } = data;

  const chartData = [
    { name: 'No. of visitors interacted w/Chatbot', value: interacted_with_chatbot },
    { name: 'No. of False lead interacted w/Chatbot', value: false_lead_interacted_with_chatbot },

  ];
  const isDataEmpty = chartData[0].value === 0 && chartData[1].value === 0;

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB', options).format(date); // Formats as DD/MM/YYYY
  };

  // Prepare the CSV data
  const formattedStartDate = formatDate(selectedRange.startDate);
  const formattedEndDate = formatDate(selectedRange.endDate);

  const csvHeading = `List of leads visited chatbots from ${formattedStartDate} to ${formattedEndDate}`;
  const csvHeader = ['Name'];
  const csvData = [
    [csvHeading], // Add heading as the first line
    csvHeader,    // CSV header
    ...leadData.map((lead) => [lead]) // Each lead in its own row
  ];

  return (
    <div className={style.statistics}>
      <div className={style.statItem}>
      <div>
          <h3 className={style.chartHeader}>Visitor Statistics</h3>
        </div>
        <ExportCSVButton
          data={csvData}
          filename={`leadData_${formattedStartDate}_to_${formattedEndDate}.csv`}
          label="Export"
        />
       
      </div>
      <div>
      {isDataEmpty ? (
          <div className={style.noData}>
            <Empty description={
      <span>
        No vistor statistics
      </span>
    } />
          </div>
        ) : (
      <PieChartStats data={chartData} COLORS={COLORS} label={`${parseFloat(percentage_of_users_interacted_with_chatbot).toFixed()} % `} labelTitle="Interacted" />
        )}
      </div>  
      <div className={style.statItem}>
       
        <div>
          <h1>{false_lead_interacted_with_chatbot}
            <span style={{ backgroundColor: COLORS[2], width: '20px', height: '26px', display: 'inline-block', marginLeft: '10px', borderRadius:'4px' }}></span>
          </h1>
          <h3 className={style.chartHeader}>No. of False lead interacted w/Chatbot</h3>
        </div>
        <div>
          <h1>{interacted_with_chatbot}
            <span style={{ backgroundColor: COLORS[0], width: '20px', height: '26px', display: 'inline-block', marginLeft: '10px',borderRadius:'4px' }}></span>
          </h1>
          <h3 className={style.chartHeader}>No. of visitors interacted w/Chatbot</h3>
        </div>
      </div>
    </div>
  );
};

export default VisitorStatistics;
