import React from "react";

const WikiContent = ({ projectWikiLink }) => {
  return (
    <iframe
      src={projectWikiLink}
      title="Wiki Link"
      width="100%"
      height="100vh"
      style={{ overflowX: "hidden", border: "none", height: "100vh" }}
    />
  );
};

export default React.memo(WikiContent);
