import { gql } from "@apollo/client";

export const UPDATE_RESOLVED_STATUS = gql`
  mutation updateThread($patch: UpdateThreadInput!) {
    updateThread(input: $patch) {
      thread {
        id
        currentStatus
      }
    }
  }
`;

export const UPDATE_RESOLVED_HISTORY = gql`
  mutation ($variables: [AddThreadResolveHistoryInput!]!) {
    addThreadResolveHistory(input: $variables) {
      threadResolveHistory {
        id
        thread {
          id
        }
        updatedBy
        status
        time
      }
    }
  }
`;

export const LOAD_UNREPLIEDCOUNTS = gql`
  subscription ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      leads {
        id
        unRepliedCount
      }
    }
  }
`;
