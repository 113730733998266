import React, { useState } from "react";
import { Breadcrumb, Layout, Menu } from "antd";
import { items2, iconMapping } from "./configs/dashboardConfig";
import TimesheetView from "./components/timesheetview/TimesheetView";
import TimeAndActivity from "./time-and-activity-dashboard/TimeAndActivity";
import { Link } from "react-router-dom";
import {  LeftOutlined } from "@ant-design/icons";

const { Header, Content, Sider } = Layout;

const Dashboard = () => {
  const [selectedView, setSelectedView] = useState("view-and-edit");

  const handleMenuClick = (e) => {
    setSelectedView(e.key);
  };

  const renderContent = () => {
    switch (selectedView) {
    case "view-and-edit":
      return <TimesheetView />;
    case "timeandactivity":
      return <TimeAndActivity />;
    default:
      return <div>Content</div>;
    }
  };

  return (
    <Layout style={{ height: "100vh"}}>
      <Layout>
        <Sider
          width={330}
          style={{
            background: "white",
          }}
        >
          <div style={{ padding: "16px", display: "flex", alignItems: "center", justifyContent: "flex-start", fontSize:"18px" }}>
            <Link to="/node" key="node">
              <LeftOutlined data-cy="reports-back-button" style={{ height: "45px", width: "auto", fontSize:"20px" }} /> <span data-cy="reports-back-title" style={{color:"rgba(0, 0, 0, 0.45)",marginLeft:"6px"}}>Workspace</span> 
            </Link>
          </div>
          <Menu
            mode="inline"
            defaultSelectedKeys={["view-and-edit"]}
            defaultOpenKeys={["sub1"]}
            style={{
              height: "100%",
              borderRight: 0,
            }}
            onClick={handleMenuClick}
          >
            {items2.map((item) => (
              <Menu.SubMenu key={item.key} icon={React.createElement(iconMapping[item.icon])} title={item.label}>
                {item.children.map((child) => (
                  <Menu.Item key={child.key}>{child.label}</Menu.Item>
                ))}
              </Menu.SubMenu>
            ))}
          </Menu>
        </Sider>
        <Layout
          style={{
            padding: "0 24px 24px",
            height:"fit-content"
          }}
        >
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 580,
              background: "#fff",
              borderRadius: 50,
            }}
          >
            <Breadcrumb style={{ margin: "16px 0",fontSize:"20px" }}>
              <Breadcrumb.Item>workspace</Breadcrumb.Item>
              <Breadcrumb.Item>reports</Breadcrumb.Item>
              {/* <Breadcrumb.Item>App</Breadcrumb.Item> */}
            </Breadcrumb>
            {renderContent()}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
