import React from "react";
import PropTypes from "prop-types";
import styles from "./socialmediasignup.css";
import "antd/dist/reset.css";
import { Button, Row, Col, Divider } from "antd";
import { GoogleOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

const Socialmediasignup = (props) => {
  const {
    textColor,
    textSize,
    label,
    googleIconColor,
    phoneIconColor,
    whatsappIconColor,
    iconBackground,
  } = props;
  return (
    <div className={styles.Socialmediasignup}>
      <Divider className="divider" />
      <div className="social-center">
        <Row className="socialsign-flex">
          <Col className="socialsignup-header" span={9}>
            <p className={textSize} style={{ color: textColor }}>
              {label}
            </p>
          </Col>

          {/*Section for social media icons*/}
          <Col span={15}>
            <Row className="icon-section" gutter={[24, 16]}>
              <Col span={8}>
                <Button
                  shape="circle"
                  size="large"
                  href="#"
                  icon={<GoogleOutlined />}
                  style={{ color: googleIconColor, background: iconBackground }}
                ></Button>
              </Col>
              <Col span={8}>
                <Button
                  shape="circle"
                  size="large"
                  href="#"
                  style={{ color: phoneIconColor, background: iconBackground }}
                >
                  <FontAwesomeIcon icon={faPhone} />
                </Button>
              </Col>
              <Col span={8}>
                <Button
                  shape="circle"
                  size="large"
                  href="#"
                  icon={<WhatsAppOutlined />}
                  style={{
                    color: whatsappIconColor,
                    background: iconBackground,
                  }}
                ></Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

Socialmediasignup.propTypes = {
  textColor: PropTypes.string,
  textSize: PropTypes.string,
  googleIconColor: PropTypes.string,
  phoneIconColor: PropTypes.string,
  whatsappIconColor: PropTypes.string,
  iconBackground: PropTypes.string,
  label: PropTypes.string,
};

Socialmediasignup.defaultProps = {
  textColor: "#000",
  textSize: "small",
  googleIconColor: "red",
  phoneIconColor: "#1890FF",
  whatsappIconColor: "green",
  iconBackground: "#FAFAFA",
  label: "Sign up with",
};

export default Socialmediasignup;
