import React from "react"

const CheckIcon = () => {
  return (
    <svg width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.92128 4.51563H9.18846C9.02909 4.51563 8.87753 4.59219 8.78378 4.72344L6.32752 8.12969L5.21501 6.58594C5.12126 6.45625 4.97126 6.37813 4.81032 6.37813H4.07751C3.97595 6.37813 3.91657 6.49375 3.97595 6.57656L5.92283 9.27656C5.96882 9.34076 6.02945 9.39306 6.09969 9.42914C6.16994 9.46523 6.24777 9.48405 6.32674 9.48405C6.4057 9.48405 6.48354 9.46523 6.55378 9.42914C6.62402 9.39306 6.68465 9.34076 6.73064 9.27656L10.0213 4.71406C10.0822 4.63125 10.0228 4.51563 9.92128 4.51563Z" fill="#389E0D" />
      <path d="M7 0C3.13438 0 0 3.13438 0 7C0 10.8656 3.13438 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13438 10.8656 0 7 0ZM7 12.8125C3.79063 12.8125 1.1875 10.2094 1.1875 7C1.1875 3.79063 3.79063 1.1875 7 1.1875C10.2094 1.1875 12.8125 3.79063 12.8125 7C12.8125 10.2094 10.2094 12.8125 7 12.8125Z" fill="#389E0D" />
    </svg>
  )
}

export default CheckIcon