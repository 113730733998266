import { Settings as ProfileSettings } from "src/components/Profile/Profile";
import React from "react";

const Settings = () => {
  return (
    <div className="App">
      <ProfileSettings />
    </div>
  );
};

Settings.propTypes = {};

Settings.defaultProps = {};

export default Settings;
