import React from "react";
import PropTypes from "prop-types";
import styles from "./ContactTableSelect.module.scss";
import { Button, Checkbox } from "antd";
import featureFlag from "../../helpers/featureFlag";

const ContactTableSelect = ({ updateContacts, value, selectedContacts }) => {
  const onChange = (e) => {
    updateContacts(value);
  }
  return (
    <>
      {
        featureFlag.ContactTableHeader &&
        <Checkbox checked={selectedContacts.indexOf(value) !== -1} className={styles.NameCell__Checkbox} onChange={onChange}></Checkbox>
      }
    </>
  );
}

ContactTableSelect.propTypes = {};

ContactTableSelect.defaultProps = {};

export default ContactTableSelect;
