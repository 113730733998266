import React from "react";
import Timezonebody from "../../Timezonebody/Timezonebody";
import styles from "./timezoneAndLanguage.module.scss";

import { useSubscription } from "@apollo/client";
import { useTranslation } from "react-i18next";
import {
  GET_AGENT_TIMEZONE,
  GET_ORG_LANGUAGE,
  GET_TIMEZONE,
} from "src/graphql/organization";
import { getActiveOrgId, getActiveStaffOrg } from "src/store/slices/userSlice";
import { useSelector } from "react-redux";

const TimezoneAndLanguages = () => {
  const { t } = useTranslation();
  const organizationId = useSelector(getActiveOrgId);
  const activeStaffOrg = useSelector(getActiveStaffOrg);

  const { data } = useSubscription(GET_TIMEZONE, {
    variables: {
      organizationId: organizationId,
    },
  });
  const { data: agentData } = useSubscription(GET_AGENT_TIMEZONE, {
    variables: {
      staffId: activeStaffOrg?.id,
    },
  });
  const { data: language } = useSubscription(GET_ORG_LANGUAGE, {
    variables: {
      organizationId: organizationId,
    },
  });
  const [orgTimezone, setOrgTimezone] = React.useState({});
  const [agentTimezone, setAgentTimezone] = React.useState({});
  const [orgLanguage, setOrgLanguage] = React.useState(null);

  React.useEffect(() => {
    let tmpData = data?.getOrganization?.timezone;
    let timezoneData = tmpData?.split(">");
    if (
      timezoneData !== null &&
      timezoneData !== undefined &&
      Array.isArray(timezoneData) &&
      timezoneData.length === 5
    ) {
      let timezoneObj = {
        abbrev: timezoneData[0],
        altName: timezoneData[1],
        label: timezoneData[2],
        offset: timezoneData[3],
        value: timezoneData[4],
      };
      setOrgTimezone(timezoneObj);
    }
  }, [data]);

  React.useEffect(() => {
    let tmpData = agentData?.getHC_Staff?.timezone;
    let timezoneData = tmpData?.split(">");
    if (
      timezoneData !== null &&
      timezoneData !== undefined &&
      Array.isArray(timezoneData) &&
      timezoneData.length === 5
    ) {
      let timezoneObj = {
        abbrev: timezoneData[0],
        altName: timezoneData[1],
        label: timezoneData[2],
        offset: timezoneData[3],
        value: timezoneData[4],
      };
      setAgentTimezone(timezoneObj);
    }
  }, [agentData]);

  React.useEffect(() => {
    let lang = language?.getOrganization?.language;
    if (lang !== "") {
      setOrgLanguage(lang);
    }
  }, [language]);

  return (
    <div className={styles.TimezoneAndLanguagePage}>
      <div className={styles.TimezoneAndLanguagePage__header}>
        <h3>{t("Timezone & Language")}</h3>
        <p>{t("Manage your timezone and system language")}</p>
      </div>
      <div className={styles.TimezoneAndLanguagePage__body}>
        <Timezonebody
          orgTimezone={orgTimezone}
          agentTimezone={agentTimezone}
          orgLanguage={orgLanguage}
        />
      </div>
    </div>
  );
};

export default TimezoneAndLanguages;
