import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./ContactDrawerAccordion.module.scss";
import { UpOutlined, DownOutlined } from "@ant-design/icons";

const ContactDrawerAccordion = ({
  title,
  children,
  openByDefault,
  AccordionIcon
}) => {
  const [isOpen, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!isOpen);
  }
  useEffect(() => {
    if (!openByDefault) return;
    if (openByDefault === true) setOpen(true);
  }, [openByDefault]);
  return (
    <div className={styles.ContactDrawerAccordion}>
      <div className={styles.ContactDrawerAccordion__Header}>
        <div className={styles.ContactDrawerAccordion__Header__Title}>
          {typeof AccordionIcon === "function" && AccordionIcon()}  {title !== null && title !== undefined && title}
        </div>
        {isOpen === true ?
          <UpOutlined onClick={toggleDrawer} />
          :
          <DownOutlined onClick={toggleDrawer} />
        }

      </div>
      <div className={`${styles.ContactDrawerAccordion__Body} ${isOpen === true && styles.ContactDrawerAccordion__FullWidth}`}>
        {children}
      </div>
    </div>
  );
}
ContactDrawerAccordion.propTypes = {};

ContactDrawerAccordion.defaultProps = {};

export default ContactDrawerAccordion;
