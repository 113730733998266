import React, { useEffect, useState } from "react";

import { notification } from "antd";
import { useTranslation } from "react-i18next";

import { updateContact } from "src/api/ContactApi";
import styles from "./UserCell.module.scss";
import { UserSelectInlineField } from "src/components/Conversation/Conversation";

const UserCell = ({
  value,
  allAttrData,
  row,
  accessor,
  usersData,
  attrId,
  allRefetch,
}) => {
  // console.log(value, row, accessor, usersData);
  // console.log(allAttrData);

  const { t } = useTranslation();

  function convertData(data) {
    let convertedData = {
      __typename: "UserAttribute",
      id: data[0]?.attributeId,
      name: data[0]?.attributeName,
      createdAt: Date.now().toString(),
      attributeType: "DefaultOutsideDrawer",
      values: [],
      options: [],
      value: [],
    };
    // console.log(data);

    data.forEach((item) => {
      let valueItem = {
        id: item.id,
        username: item.username,
        __typename: "HC_Staff",
        label: item.id,
        value: item.id,
      };
      if (item.id !== undefined) {
        convertedData.values.push(valueItem);
      }
    });
    convertedData.options = convertedData.values;
    convertedData.value = convertedData.values;

    return [convertedData];
  }

  const [convertedData, setConvertedData] = useState([]);

  useEffect(() => {
    if (value !== undefined) {
      setConvertedData(convertData(value));
    }
  }, [value]);

  const handleUserChange = async (i, agent, fieldInfo) => {
    const { id, name } = attrId;
    // console.log(name,id)
    if (!name || !id) {
      return;
    }
    let payload = {
      userAttribute: {
        attributeId: id,
        name: name,
        value: agent,
      },
    };
    let res = await submitForm(payload);
    //updatecontactinstance({ type: Actions.UPDATE_USERSELECT, payload: payload });
  };

  const submitForm = async (data) => {
    try {
      let res = await updateContact(data, row?.original?.leadId);
      if (!res?.data?.success) {
        throw new Error("Encountered error while updating contact");
      } else {
        // allRefetch()
      }
    } catch (err) {
      notification.error({
        message: <b>Notification</b>,
        description: <b>Encountered server error while updating</b>,
      });
      return;
    }
  };

  // console.log(getAttributeId(allAttrData,accessor))

  // console.log(convertedData);

  return (
    <span className={styles.UserCell}>
      <UserSelectInlineField
        t={t}
        handleUserChange={handleUserChange}
        item={convertedData[0]}
        isTableElement={true}
        isDefault={
          convertedData?.attributeType === "Default" ||
          convertedData?.attributeType === "DefaultOutsideDrawer"
        }
        onDelete={() => {}}
      />
    </span>
  );
};

export default UserCell;
