import React, { lazy, Suspense } from "react";
import Loader from "../Loader/Loader";
import { Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import SuspenseSpinner from "src/components/SuspenseSpinners/SuspenseSpinner";

const LazyChatShell = lazy(() => import("./ChatShell"));

const ChatShell = props => (
  <Suspense fallback={<SuspenseSpinner/>}>
    <LazyChatShell {...props} />
  </Suspense>
);

export default ChatShell;
