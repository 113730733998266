import React, { useEffect, useState } from "react";
import {
  Modal,
  Drawer,
  Form,
  Input,
  Button,
  Tooltip,
  notification,
} from "antd";
import styles from "./EditWhatsappDesktopChannel.module.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CopyOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { createWhatsAppDesktopChannel } from "src/api/WhatsappApi";
import successIcon from "src/assets/desktop_success.png";
import CountrySelect from "src/components/CountrySelect/CountrySelect";
import { getActiveOrg } from "src/store/slices/userSlice";

const EditWhatsappDesktopChannel = ({ detail, isVisible, setShowDrawer }) => {
  const [successModal, setSuccessModal] = useState(false);

  const activeOrg = useSelector(getActiveOrg);
  const submitForm = async () => {
    const [dialCode, isoCountryCode] = values.dialCodeText.split(",");
    let data = {
      organizationId: activeOrg?.id,
      isoCountryCode: isoCountryCode,
      dialCode: dialCode,
      phone: values.phoneNumber,
      name: values.channelName,
    };
    try {
      const res = await createWhatsAppDesktopChannel(data);
      if (res.data.success === true) {
        notification.success({
          message: <b>Notification</b>,
          description: "Channel Created Successfully",
        });
      } else {
        if (res.data.error) {
          notification.error({
            message: <b>Notification</b>,
            description: res.data.error,
          });
        } else {
          notification.error({
            message: <b>Notification</b>,
            description:
              "We're encountering some problem processing your request",
          });
        }
        resetForm();
      }
    } catch (err) {
      notification.error({
        message: <b>Notification</b>,
        description: "We're encountering some problem processing your request",
      });
    }
  };
  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    resetForm,
  } = useFormik({
    initialValues: {
      dialCodeText: `${detail.dialCode},${detail.isoCountryCode}`,
      phoneNumber: detail.phone,
      channelName: detail.name,
    },
    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string()
        .matches(/^[0-9]*$/, "Only numbers are allowed")
        .min(10, "Invalid Phone Number")
        .required("Required field"),
      channelName: Yup.string()
        .min(2, "Too Short!")
        .max(70, "Too Long!")
        .required("Required field"),
    }),
    onSubmit: submitForm,
  });

  const handleValidationStatus = (key) => {
    return !touched[key] && !errors[key]
      ? ""
      : touched[key] && !errors[key]
      ? "success"
      : touched[key] && errors[key]
      ? "error"
      : "";
  };

  useEffect(() => {
    if (
      values.phoneNumber.trim() === "" ||
      values.channelName.trim() === "" ||
      isValid === false
    ) {
      // setLoading(true);
    } else {
      // setLoading(false);
    }
  }, [values, isValid]);

  const LabelStyle = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "22px",
    paddingBottom: "0px",
    marginBottom: "0px",
    color: "#595959",
  };

  const copyToClipBoard = (data) => {
    navigator.clipboard
      .writeText(data)
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const redirectToVDI = () => {
    window.open(`https://${detail.vdiUrl}`, "_blank");
    setShowDrawer({
      detail: {},
      shown: false,
    });
    setSuccessModal(true);
  };

  const closeModal = () => {
    setSuccessModal(false);
  };

  return (
    <>
      <Modal
        visible={successModal}
        footer={null}
        width="405px"
        closable={false}
      >
        <div className={styles.WhatsappModal__ImageContainer}>
          <img
            className={styles.WhatsappModal__ImageIcon}
            src={successIcon}
            alt="whatsapp"
          />
        </div>
        <h3 className={styles.WhatsappModal__Heading}>
          Your channel is connected
        </h3>
        <p className={styles.WhatsappModal__Paragraph}>
          You can now engage with your customers through your new channel.
        </p>
        <div className={styles.ResultInfo}>
          <ExclamationCircleFilled className={styles.WhatsappModal__Icon} />
          <p>
            Only new incoming messages will be received. No chat history will be
            imported.
          </p>
        </div>
        <div className={styles.WhatsappModal__ButtonContainer}>
          <Button type="primary" onClick={closeModal}>
            Done
          </Button>
        </div>
      </Modal>
      <Drawer
        width={534}
        headerStyle={{ display: "none" }}
        placement="right"
        open={isVisible}
        onClose={() =>
          setShowDrawer({
            detail: {},
            shown: false,
          })
        }
      >
        <div className={styles.EditWhatsappDesktopChannel}>
          <div className={styles.EditWhatsappDesktopChannel__Header}>
            Edit WhatsApp Desktop Inbox
          </div>
          <Form
            layout="vertical"
            onFinish={handleSubmit}
            className={styles.ChannelForm}
          >
            <Form.Item
              name="channelName"
              label={
                <p style={LabelStyle}>
                  Channel Name
                  {/* <span style={{ "color": "#CF1322" }} className={styles.ChannelForm__formContainer}>*</span> */}
                </p>
              }
              validateStatus={handleValidationStatus("channelName")}
              hasFeedback
              style={{ flexDirection: "column" }}
            >
              <Input
                name="channelName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.channelName}
                placeholder="Enter inbox name"
              />
              {touched.channelName && errors.channelName ? (
                <div className={styles.ChannelForm__formContainer__error}>
                  {errors.channelName}
                </div>
              ) : null}
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label={
                <p style={LabelStyle}>
                  Phone Number
                  {/* <span style={{ "color": "#CF1322" }} className={styles.ChannelForm__formContainer}>*</span> */}
                </p>
              }
              className={styles.ChannelForm__formContainer}
              validateStatus={handleValidationStatus("phoneNumber")}
              hasFeedback
            >
              <Input
                disabled={true}
                id="phoneNumber"
                addonBefore={
                  <CountrySelect
                    disabled={true}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                }
                name="phoneNumber"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phoneNumber}
                placeholder="9048084872"
              />
              {touched.phoneNumber && errors.phoneNumber ? (
                <div className={styles.ChannelForm__formContainer__error}>
                  {errors.phoneNumber}
                </div>
              ) : null}
            </Form.Item>
            <p className={styles.ChannelForm__PhoneNumberHelper}>
              Enter phone number without any space. eg: +19036003866
            </p>
            {/* {!successData.generated &&
              <div className={styles.ChannelForm__ButtonContainer}>

                <Button type="secondary" onClick={setShowDrawer}>Cancel</Button>
                {submitting === true ?
                  <Button type="primary"><LoadingOutlined /></Button>
                  :
                  <Button type="primary" disabled={loading} htmlType="submit">
                    Step 1/2: Initiate virtual machine
                  </Button>
                }

              </div>
            } */}
          </Form>

          <div className={styles.ResultInfo}>
            <ExclamationCircleFilled style={{ color: "#FAAD14" }} />
            <p>
              In the next step, you will be asked for a username and password.
              Use these credentials to set up your channel.
            </p>
          </div>

          <div className={styles.ResultDetails}>
            <div className={styles.ResultDetails__Placeholder}>Username</div>
            <Input.Group compact>
              <Input
                style={{
                  width: "calc(100% - 31px)",
                }}
                disabled={true}
                defaultValue={detail.vdiId}
              />
              <Tooltip title="Copy Username">
                <Button
                  onClick={() => copyToClipBoard(detail.vdiId)}
                  icon={<CopyOutlined />}
                />
              </Tooltip>
            </Input.Group>
            <div className={styles.ResultDetails__Placeholder}>Password</div>
            <Input.Group compact>
              <Input
                style={{
                  width: "calc(100% - 31px)",
                }}
                disabled={true}
                defaultValue={detail?.password}
              />
              <Tooltip title="Copy Password">
                <Button
                  onClick={() => copyToClipBoard(detail?.password)}
                  icon={<CopyOutlined />}
                />
              </Tooltip>
            </Input.Group>
          </div>

          <div className={styles.ChannelForm__ButtonContainer}>
            <Button
              type="secondary"
              onClick={() => {
                setShowDrawer({
                  detail: {},
                  shown: false,
                });
              }}
            >
              Cancel
            </Button>
            <Button type="primary" onClick={() => redirectToVDI()}>
              Go to virtual machine
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
};

EditWhatsappDesktopChannel.propTypes = {};

EditWhatsappDesktopChannel.defaultProps = {};

export default EditWhatsappDesktopChannel;
