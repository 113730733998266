import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./ContactDrawerAttributeButton.module.scss";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";

const ContactDrawerAttributeButton = ({ setAttributeDrawer }) => {
  const [expandSearch, setExpandSearch] = useState(false);
  const onClickSearchTrigger = () => {
    setExpandSearch(true);
  }
  return (
    <div className={styles.ContactDrawerAttributeButton}>
      <div onClick={setAttributeDrawer} className={`${styles.ContactDrawerAttributeButton__NewAttributeButton} ${expandSearch === true ? styles.ContactDrawerAttributeButton__Collapse : ""}`}>
        <PlusOutlined />
        {
          !expandSearch && <p>New attribute</p>
        }
      </div>
      <div onClick={onClickSearchTrigger}
        className={`${styles.ContactDrawerAttributeButton__SearchTrigger} ${expandSearch === true ?
          styles.ContactDrawerAttributeButton__Expand : ""}`}>
        {expandSearch && <input className={styles.ContactDrawerAttributeButton__Input} type="text" />}
        <SearchOutlined />
      </div>
    </div>
  );
}

ContactDrawerAttributeButton.propTypes = {};

ContactDrawerAttributeButton.defaultProps = {};

export default ContactDrawerAttributeButton;
