import React from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { UserOutlined } from "@ant-design/icons";

const SearchBar = ({ value, onChange }) => {
  const { Search } = Input;
  return (
    <>
      <Input
        placeholder="Search Projects"
        size="small"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onSearch={(value) => onChange(value)}
        style={{padding: "10px",margin: "4px 0px 8px 10px",borderRadius:"10px",backgroundColor: "#ffffff"}}
        prefix={<SearchOutlined />}
      />
    
    </>
    
  );
};

export default SearchBar;
