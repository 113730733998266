import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  data: {
    access_token: null,
    refresh_token: null,
  },
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuth: (state) => Object.assign(state, initialState),
    setAuthdata: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
  },
});

export const { resetAuth, setAuthdata } = authSlice.actions;
export default authSlice.reducer;
