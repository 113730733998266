import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  globalSelectedSegment: "all",
  replyId: "",
};

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    setGlobalSelectedSegment: (state, action) => {
      state.globalSelectedSegment = action.payload;
    },
    setReplyId: (state, action) => {
      state.replyId = action.payload;
    },
  },
});

export const { setGlobalSelectedSegment, setReplyId } = contactSlice.actions;
export default contactSlice.reducer;
