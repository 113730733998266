import React from "react"
import styles from "./Normal.module.scss"
import { Avatar, Tag } from "antd";

const Normal = (props) => {
   
  return <span className={styles.Normal}>{props?.value[0]?.value || "---"}</span>
}


export default Normal
