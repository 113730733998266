import React from "react";
import style from './IntentionChart.module.scss';
import IntentionBarGraph from '../Charts/IntentionBarChart';

const IntentionChart = ({ data }) => {
  const sortedData = data?.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
  const formattedData = sortedData.map(item => ({
    name: new Date(item.timestamp).toLocaleDateString('en-US', { month: 'short', day: 'numeric' }),
    "Product related Queries": item?.product_related_queries,
    "Non-product Queries": item?.non_product_related_queries,
    "Contact a representative": item?.interacted_with_sales_rep || 0 
  }));

  return (
    <div className={style.intentionChart}>
      <h2 className={style.chartHeader}>Intention Triggered by the Visitors Interacting with Chatbot</h2>
      <div className={style.chartPlaceholder}>
        <IntentionBarGraph data={formattedData} />
      </div>
    </div>
  );
};

export default IntentionChart;
