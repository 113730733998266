import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  data: {},
  error: null,
};

export const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    resetBilling: (state) => Object.assign(state, initialState),
    setBilling: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { resetBilling, setBilling } = billingSlice.actions;
export default billingSlice.reducer;
