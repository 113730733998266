import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./Signup.module.scss";

import AuthLayout from "../AuthLayout/AuthLayout";

import { useFormik } from "formik";
import { LoginOutlined } from "@ant-design/icons";
import { setAuthdata } from "src/store/slices/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Col, Form, Input, Row } from "antd";

import * as Yup from "yup";
import "antd/dist/reset.css";
import { getTokenBySignup, getTokenBySignupInvitation } from "src/api/AuthApi";

const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { invitationId } = useParams();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ success: null, error: null });

  const handleSignup = async (body) => {
    let res = {};

    setLoading(true);
    if (invitationId) {
      body = { ...body, invitationId };
      res = await getTokenBySignupInvitation(body);
    } else {
      res = await getTokenBySignup(body);
    }
    setLoading(false);

    if (!res?.data?.success) {
      setMessage({
        success: false,
        error: res?.data?.error || "Unknown network error",
      });
    } else {
      let { access_token, refresh_token } = res.data;
      dispatch(setAuthdata({ access_token, refresh_token }));
      navigate("/");
    }
  };

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: { firstName: "", lastName: "", email: "", password: "" },
      validationSchema: Yup.object().shape({
        firstName: Yup.string()
          .matches(/^[\p{L}][[ \p{L}0-9]*$/u, "Invalid First Name")
          .required("First name required"),
        lastName: Yup.string().matches(
          /^[\p{L}0-9][[ \p{L}0-9]*$/u,
          "Invalid Last Name"
        ),
        email: Yup.string()
          .email("Invalid email address format")
          .required("Email required"),
        password: Yup.string()
          .matches(
            /^(?=.*[A-Za-z])(?=.*[@$!%*#?&])[A-Za-z0-9@$!%*#?&]{6,}$/,
            "Password should be atleast 6 characters long and should contain a special symbol!"
          )
          .required("Password required"),
      }),
      onSubmit: handleSignup,
    });

  const handleValidationStatus = (key) => {
    return !touched[key] && !errors[key]
      ? ""
      : touched[key] && !errors[key]
      ? "success"
      : touched[key] && errors[key]
      ? "error"
      : "success";
  };

  const printValidationMsg = (key) => {
    if (touched[key] && errors[key]) {
      return (
        <div
          role="alert"
          className={styles.Signup__signInContainer__formItem__error}
        >
          {errors[key]}
        </div>
      );
    } else return null;
  };

  return (
    <AuthLayout>
      <div className={styles.Signup}>
        <div className={styles.Signup__signUpContainer}>
          <h3>Create account</h3>
          <div className={styles.Signup__signUpContainer__helpMsg}>
            Already have an account?{" "}
            <a onClick={() => navigate("/login")}>Login</a>
          </div>
          <form onSubmit={handleSubmit}>
            {/*Full name section*/}
            <Row className={styles.Signup__signUpContainer__name} gutter={[16]}>
              <Col span={12}>
                <Form.Item
                  className={`${styles.Signup__signUpContainer__formItem} signUp__form`}
                  label={
                    <span className={styles.Signup__InputLabel}>
                      First name
                    </span>
                  }
                  name="firstName"
                  validateStatus={handleValidationStatus("firstName")}
                  help={printValidationMsg("firstName")}
                >
                  <Input
                    id="firstName"
                    name="firstName"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    placeholder="input first name"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className={`${styles.Signup__signUpContainer__formItem} signUp__form`}
                  label={
                    <span className={styles.Signup__InputLabel}>Last name</span>
                  }
                  name="lastName"
                  help={printValidationMsg("lastName")}
                  validateStatus={handleValidationStatus("lastName")}
                >
                  <Input
                    id="lastName"
                    name="lastName"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    placeholder="input last name"
                  />
                </Form.Item>
              </Col>
            </Row>
            {/*Sub-component:-Email*/}
            <Form.Item
              className={`${styles.Signup__signUpContainer__formItem} signUp__form`}
              label={
                <span className={styles.Signup__InputLabel}>Email address</span>
              }
              name="email"
              validateStatus={handleValidationStatus("email")}
              help={printValidationMsg("email")}
            >
              <Input
                id="email"
                name="email"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                placeholder="example@gmail.com"
              />
            </Form.Item>

            {/*Sub-component:-Password*/}
            <Form.Item
              className={`${styles.Signup__signUpContainer__formItem} signUp__form`}
              label={
                <span className={styles.Signup__InputLabel}>
                  Create a password
                </span>
              }
              name="password"
              validateStatus={handleValidationStatus("password")}
              help={printValidationMsg("password")}
            >
              <Input.Password
                id="password"
                name="password"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                placeholder="Enter password"
              />
            </Form.Item>
            <Form.Item
              className={`${styles.Signup__signUpContainer__formItem} signUp__form`}
            >
              {/*Sub-component:-Accept Invite Button*/}
              <div
                className={
                  styles.Signup__signUpContainer__formItem__signUpBtnContainer
                }
              >
                <Button
                  data-testid="sign-up-btn"
                  style={{ background: "rgba(9, 109, 217, 1)", color: "white" }}
                  loading={loading}
                  htmlType="submit"
                  icon={<LoginOutlined />}
                >
                  {invitationId ? "Accept Invite" : "Create account"}
                </Button>
              </div>
            </Form.Item>
            {message.success === false ? (
              <div className={styles.alert}>
                <Alert
                  message="Error"
                  description={message.error}
                  className={styles.Signup__signUpContainer__errorMsg}
                  type="error"
                  showIcon
                />
              </div>
            ) : null}
          </form>
          <footer className={styles.Signup__footer} data-testid="footer">
            Need help? <span>Feel free to contact us</span>&nbsp;
            <a
              href="https://calendly.com/hybrid-chat/15min"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact Us!
            </a>
          </footer>
        </div>
      </div>
    </AuthLayout>
  );
};

Signup.propTypes = {};

Signup.defaultProps = {};

export default Signup;
