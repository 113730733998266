import React, { useEffect, useState } from "react";

import { Button, Divider, Drawer, message, Spin } from "antd";
import { useSelector } from "react-redux";

import {
  BorderlessTableOutlined,
  CalendarOutlined,
  FontSizeOutlined,
  GlobalOutlined,
  LinkOutlined,
  MailOutlined,
  PhoneOutlined,
  PlusOutlined,
  ReloadOutlined,
  TagsOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useQuery } from "@apollo/client";

import { addNewContact } from "src/api/ContactApi";
import styles from "./CreateContactDrawer.module.scss";
import { GET_ORGANIZATION_ATTRIBUTES } from "src/graphql/organization";
import { GET_NUMBER_OF_LEADS } from "src/graphql/lead";
import { getActiveOrgId } from "src/store/slices/userSlice";
import { ProfilePicPlaceholder } from "src/components/Profile/Profile";
import {
  ContactDrawerAccordion,
  ContactDrawerAttributeButton,
  DateAttributeInlineEditingField,
  MultiSelectInlineFields,
  NormalAttributeInlineEditingField,
  UserSelectInlineField,
} from "src/components/Conversation/Conversation";

const Actions = {
  UPDATE_TITLE: "update_title",
  UPDATE_USERSELECT: "update_userselect",
  UPDATE_MULTISELECT: "update_multiselect",
  UPDATE_NORMALATTRIBUTE: "update_normalattribute",
  UPDATE_DATE: "update_date",
  REMOVE_NORMALATTRIBUTE: "remove_normalattribute",
  REMOVE_USERSELECT: "remove_userselect",
  REMOVE_DATEATTRIBUTE: "remove_dateattribute",
  REMOVE_MULTISELECT: "remove_multiselect",
};

const CreateContactDrawer = ({
  setIsSuccess,
  open,
  onClose,
  t,
  refetchAttributes,
  setAttributeDrawer,
}) => {
  const organizationId = useSelector(getActiveOrgId);

  const [defaultMultiSelectAttribute, setDefaultMultiSelectAttribute] =
    useState([]);
  const [defaultUserSelectAttribute, setDefaultUserSelectAttribute] = useState(
    []
  );
  const [defaultNormalAttribute, setDefaultNormalAttribute] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [nameLoading, setNameLoading] = useState(false);

  const [contactDataInstance, setContactDataInstance] = useState({
    userAttribute: [],
    multiSelectAttribute: [],
    contactAttributes: [],
  });
  const [attributeListData, setAttributeListData] = useState([]);
  const [filteredAttributes, setFilteredAttributes] = useState([]);
  const [username, setUsername] = useState("");

  const {
    data: organizationAttribute,
    loading: attributeLoading,
    error: attributeError,
  } = useQuery(GET_ORGANIZATION_ATTRIBUTES, {
    variables: {
      organizationId,
    },
  });
  const [labels, setLabels] = useState([]);
  const { data: numberOfAgentsData } = useQuery(GET_NUMBER_OF_LEADS, {
    variables: {
      organizationId,
    },
  });

  useEffect(() => {
    setNameLoading(true);
    let noOfAgents = numberOfAgentsData?.getOrganization?.leadsAggregate?.count;
    if (!noOfAgents) {
      setNameLoading(false);
      return;
    }
    setUsername(`Customer ${noOfAgents}`);
    setNameLoading(false);
    // updatecontactinstance({
    //   type: Actions.UPDATE_TITLE,
    //   payload: `Customer ${noOfAgents}`,
    // });
  }, [numberOfAgentsData]);

  useEffect(() => {
    if (!organizationAttribute) {
      return;
    }
    setLabels(organizationAttribute?.getOrganization.labels);
    let attributeList = [];
    let currentDefaultNormalAttribute = defaultNormalAttribute;
    organizationAttribute?.getOrganization?.normalAttribute
      .filter(
        (t) =>
          t.attributeType === "DefaultOutsideDrawer" ||
          t.attributeType === "Default"
      )
      .map((tx) => {
        let index = currentDefaultNormalAttribute?.findIndex(
          (obj) => obj?.id === tx?.id
        );
        if (index === -1) {
          currentDefaultNormalAttribute.push({
            id: tx?.id,
            name: tx?.name,
            attributeType: tx?.attributeType,
            type: tx?.type,
          });
        }
      });

    setDefaultNormalAttribute([...currentDefaultNormalAttribute]);
    let currentDefaultUserSelectAttribute = defaultUserSelectAttribute;
    organizationAttribute?.getOrganization?.userAttribute
      ?.filter(
        (t) =>
          t.attributeType === "DefaultOutsideDrawer" ||
          t.attributeType === "Default"
      )
      ?.map((tx) => {
        let index = currentDefaultUserSelectAttribute?.findIndex(
          (obj) => obj?.id === tx?.id
        );
        if (index === -1) {
          currentDefaultUserSelectAttribute.push({
            id: tx?.id,
            name: tx?.name,
            attributeType: tx?.attributeType,
            value: [],
          });
        }
      });
    setDefaultUserSelectAttribute([...currentDefaultUserSelectAttribute]);
    let currentDefaultMultiSelectAttribute = defaultMultiSelectAttribute;
    organizationAttribute?.getOrganization?.multiSelectAttribute
      ?.filter(
        (t) =>
          t.attributeType === "DefaultOutsideDrawer" ||
          t.attributeType === "Default"
      )
      ?.map((tx) => {
        let index = currentDefaultMultiSelectAttribute?.findIndex(
          (obj) => obj?.id === tx?.id
        );
        if (index === -1) {
          currentDefaultMultiSelectAttribute.push({
            id: tx?.id,
            name: tx?.name,
            attributeType: tx?.attributeType,
            tags: [],
          });
        }
      });
    setDefaultMultiSelectAttribute([...currentDefaultMultiSelectAttribute]);

    organizationAttribute?.getOrganization?.normalAttribute?.map((tx) => {
      attributeList.push({
        id: tx?.id,
        scope: "Internal",
        name: tx?.name,
        type: tx?.type,
        attributeType: tx?.attributeType,
        __typename: "NormalAttribute",
        values: [],
      });
    });
    organizationAttribute?.getOrganization?.multiSelectAttribute?.map((tx) => {
      attributeList.push({
        id: tx?.id,
        scope: "Internal",
        name: tx?.name,
        type: "MultiSelect",
        attributeType: tx?.attributeType,
        __typename: "MultiSelectAttribute",
        values: [],
      });
    });
    organizationAttribute?.getOrganization?.userAttribute?.map((tx) => {
      attributeList.push({
        id: tx?.id,
        scope: "Internal",
        name: tx?.name,
        type: "UserAttribute",
        attributeType: tx?.attributeType,
        __typename: "UserAttribute",
        values: [],
      });
    });
    setAttributeListData(attributeList);
  }, [organizationAttribute]);

  useEffect(() => {
    if (
      !attributeListData ||
      !defaultMultiSelectAttribute ||
      !defaultNormalAttribute ||
      !defaultUserSelectAttribute
    ) {
      return;
    }
    let clean_attribute = attributeListData?.filter((tx) => {
      if (tx?.attributeType === "MultiSelectAttribute") {
        let isFound = true;
        defaultMultiSelectAttribute?.map((t) => {
          if (t?.id === tx?.id) {
            isFound = false;
          }
        });
        return isFound;
      } else if (tx?.attributeType === "UserAttribute") {
        let isFound = true;
        defaultUserSelectAttribute?.map((t) => {
          if (t?.id === tx?.id) {
            isFound = false;
          }
        });
        return isFound;
      } else {
        let isFound = true;
        defaultNormalAttribute?.map((t) => {
          if (t?.id === tx?.id) {
            isFound = false;
          }
        });
        return isFound;
      }
    });
    setFilteredAttributes(clean_attribute);
  }, [
    attributeListData,
    defaultMultiSelectAttribute,
    defaultNormalAttribute,
    defaultUserSelectAttribute,
  ]);

  const onNormalAttributeChange = (val, name) => {
    updatecontactinstance({
      type: Actions.UPDATE_NORMALATTRIBUTE,
      payload: {
        value: val,
        name: name,
      },
    });
  };

  const returnTextAttribute = (
    attributeId,
    label,
    value,
    attributeType,
    isDefault
  ) => {
    if (!label || !attributeId || !attributeType) {
      return <></>;
    }
    return (
      <NormalAttributeInlineEditingField
        attributeId={attributeId}
        label={label}
        initialValue={value}
        attributeType={attributeType}
        onSubmit={onNormalAttributeChange}
        onDeleteForm={onDeleteForm}
        isDefault={isDefault === "Default"}
      />
      // <div className={styles.ContactDrawer__DataField}>
      //   <div className={styles.ContactDrawer__DataField__Label}>{label}</div>
      //   <div className={styles.ContactDrawer__DataField__Value}>{value}</div>
      // </div>
    );
  };
  const onMultiSelectSubmit = (value, id) => {
    if (value.length === 0) {
      let attribute_name = attributeListData?.filter((item) => item.id == id)[0]
        ?.name;
      let payload = { name: attribute_name, value: [] };
      updatecontactinstance({
        type: Actions.UPDATE_MULTISELECT,
        payload: payload,
      });
    }
    // let finished_data = [];
    let attribute_id = value[0]?.multiSelectAttribute?.id;
    // let fetch_attribute = defaultMultiSelectAttribute.filter((tx) => tx?.id === attribute_id);
    // if (fetch_attribute.length !== 1) {
    //   return;
    // }
    let attribute_name = attributeListData?.filter(
      (item) => item.id == attribute_id
    )[0]?.name;
    let values = [];
    value.map((tx) => {
      values.push({
        id: tx?.id,
        color: tx?.color,
        name: tx?.name,
        labelId: tx?.id,
      });
    });
    let payload = { name: attribute_name, value: values };
    updatecontactinstance({
      type: Actions.UPDATE_MULTISELECT,
      payload: payload,
    });
    let index_in_state = defaultMultiSelectAttribute.findIndex(
      (tx) => tx?.id === attribute_id
    );
    if (index_in_state !== -1) {
      const selected_options = [];
      values.map((tx) => {
        selected_options.push({
          ...tx,
          isSelected: true,
          label: tx?.name,
          id: tx?.id,
          value: tx?.id,
        });
      });
      defaultMultiSelectAttribute[index_in_state].tags = selected_options;
      setDefaultMultiSelectAttribute([...defaultMultiSelectAttribute]);
    }
  };
  const onDeleteForm = (attribute_type, attributeId, attribute_name) => {
    if (attributeId.length === 0) {
      return;
    }
    let currentNormalAttribute = [...defaultNormalAttribute];
    currentNormalAttribute = currentNormalAttribute.filter(
      (tx) => tx?.id !== attributeId[0]
    );
    setDefaultNormalAttribute(currentNormalAttribute);
    updatecontactinstance({
      type: Actions.REMOVE_NORMALATTRIBUTE,
      payload: {
        attributeName: attribute_name,
      },
    });
  };
  const onDeleteMultiSelect = (attribute_type, data, attributeId, label) => {
    if (!attributeId) {
      return;
    }
    let currentMultiSelectAttribute = [...defaultMultiSelectAttribute];
    currentMultiSelectAttribute = currentMultiSelectAttribute.filter(
      (tx) => tx?.id !== attributeId
    );
    setDefaultMultiSelectAttribute(currentMultiSelectAttribute);
    updatecontactinstance({
      type: Actions.REMOVE_MULTISELECT,
      payload: {
        name: label,
      },
    });
  };
  const handleUserChange = (id, agent, fieldInfo) => {
    const { name } = fieldInfo;
    if (!name) {
      return;
    }
    let payload = {
      name: name,
      value: agent,
    };
    updatecontactinstance({
      type: Actions.UPDATE_USERSELECT,
      payload: payload,
    });
    let index_in_state = defaultUserSelectAttribute.findIndex(
      (tx) => tx?.id === id
    );
    if (index_in_state !== -1) {
      defaultUserSelectAttribute[index_in_state].value = payload?.value;
      setDefaultUserSelectAttribute([...defaultUserSelectAttribute]);
    }
  };
  const [expandAttributeList, setExpandAttributeList] = useState(false);
  const expandByLoadMore = () => {
    setExpandAttributeList(true);
  };
  const addAttribute = (attributeId, attributeType, attribute) => {
    if (attribute?.attributeType === "NormalAttribute") {
      let currentDefaultNormalAttribute = [...defaultNormalAttribute];
      currentDefaultNormalAttribute.push({
        id: attribute?.id,
        name: attribute?.name,
        type: attribute?.type,
        attributeType: "Normal",
      });
      setDefaultNormalAttribute(currentDefaultNormalAttribute);
    } else if (attribute?.attributeType === "MultiSelectAttribute") {
      let currentMultiSelectAttribute = [...defaultMultiSelectAttribute];
      currentMultiSelectAttribute.push({
        id: attribute?.id,
        attributeType: "Normal",
        name: attribute?.name,
        tags: [],
      });
      setDefaultMultiSelectAttribute(currentMultiSelectAttribute);
    } else if (attribute?.attributeType === "UserAttribute") {
      let currentUserSelectAttribute = [...defaultUserSelectAttribute];
      currentUserSelectAttribute.push({
        id: attribute?.id,
        name: attribute?.name,
        attributeType: "Normal",
      });
      setDefaultUserSelectAttribute(currentUserSelectAttribute);
    }
  };
  const returnIcon = (scope, type) => {
    switch (type) {
    case "Text":
      return <FontSizeOutlined className={styles.ContactDrawer__icons} />;
    case "Numbers":
      return (
        <BorderlessTableOutlined className={styles.ContactDrawer__icons} />
      );
    case "MultiSelect":
      return <TagsOutlined className={styles.ContactDrawer__icons} />;
    case "Status":
      return <ReloadOutlined className={styles.ContactDrawer__icons} />;
    case "Date":
      return <CalendarOutlined className={styles.ContactDrawer__icons} />;
    case "User":
      return <UserOutlined className={styles.ContactDrawer__icons} />;
    case "PhoneNumber":
      return <PhoneOutlined className={styles.ContactDrawer__icons} />;
    case "EmailAddress":
      return <MailOutlined className={styles.ContactDrawer__icons} />;
    case "SocialMedia":
      return <GlobalOutlined className={styles.ContactDrawer__icons} />;
    case "URL":
      return <LinkOutlined className={styles.ContactDrawer__icons} />;
    default:
      return null;
    }
  };
  const content = (
    <div className={styles.PopoverContent}>
      <ContactDrawerAttributeButton
        setAttributeDrawer={() => {
          onClose();
          setAttributeDrawer(true);
        }}
      />
      <div
        className={`${styles.ContactDrawer__AttributeList} ${
          expandAttributeList === true
            ? styles.ContactDrawer__AttributeList__Expand
            : ""
        }`}
      >
        {filteredAttributes.length === 0 ? (
          <div className={styles.ContactDrawer__Placeholder}>
            No Attributes are available for the contact
          </div>
        ) : (
          filteredAttributes.map((tx) => {
            return (
              <div
                onClick={() => addAttribute(tx?.id, tx?.type, tx)}
                className={styles.ContactDrawer__AttributeList__Tag}
              >
                {returnIcon(tx?.scope, tx?.type)}
                {tx?.name}
              </div>
            );
          })
        )}
      </div>
      {!expandAttributeList && filteredAttributes.length > 4 && (
        <div className={styles.ContactDrawer__AttributeList__Footer}>
          <span onClick={expandByLoadMore}>Load more</span>
        </div>
      )}
    </div>
  );

  const returnMultiSelectAttribute = (id, label, tags, attributeType) => {
    if (!label || !tags) {
      return <></>;
    }
    return (
      <MultiSelectInlineFields
        attributeId={id}
        allRefetch={refetchAttributes}
        label={label}
        tags={tags}
        t={t}
        onMultiSelectSubmit={onMultiSelectSubmit}
        onDeleteForm={onDeleteMultiSelect}
        isDefault={
          attributeType === "Default" ||
          attributeType === "DefaultOutsideDrawer"
        }
      />
      // <div className={styles.ContactDrawer__DataField}>
      //   <div className={styles.ContactDrawer__DataField__Label}>{label}</div>
      //   <div className={`${styles.ContactDrawer__DataField__Value} ${styles.ContactDrawer__DataField__TagsContainer}`}>
      //     {tags.map((tx) => <span className={styles.ContactDrawer__DataField__Tags}>{tx}</span>)}
      //   </div>
      // </div>
    );
  };

  useEffect(() => {
    if (!contactDataInstance) {
      return;
    }
  }, [contactDataInstance]);

  useEffect(() => {
    let res = username;
    if (!res || res?.trim() === "") {
      setIsDisabled(true);
      return;
    } else {
      setIsDisabled(false);
    }
  }, [username]);

  const cleanNormalAttributes = (array, valueToDelete) => {
    let indexesToDelete = [];

    for (let i = 0; i < array.length; i++) {
      if (array[i].name === valueToDelete) {
        indexesToDelete.push(i);
      }
    }

    // Delete objects in the array
    indexesToDelete.reverse().forEach(function (index) {
      array.splice(index, 1);
    });
    return array;
  };

  function hasEmptyMultiSelectAttribute(data) {
    if (data.multiSelectAttribute) {
      for (let attribute of data.multiSelectAttribute) {
        if (attribute.value && attribute.value.length > 0) {
          return false;
        }
      }
    }

    return true;
  }

  const submitForm = async () => {
    try {
      setIsDisabled(true);
      if (
        contactDataInstance.contactAttributes.length === 0 &&
        hasEmptyMultiSelectAttribute(contactDataInstance) &&
        contactDataInstance.userAttribute.length === 0
      ) {
        // message.error("Specify Atleast one value to create a contact");
        // return;
      }
      let isErrorExist = false;
      contactDataInstance?.contactAttributes?.map((tx) => {
        if (tx?.errorMessage !== "") {
          isErrorExist = true;
        }
        if (tx?.value === "") {
          isErrorExist = true;
        }
      });

      if (isErrorExist) {
        message?.error("Some fields contain error");
        setIsDisabled(false);
        return;
      }
      let data = {
        ...contactDataInstance,
        username: username,
        organization: {
          id: organizationId,
        },
      };

      let res = await addNewContact(data);
      if (res?.data?.success === true) {
        message.success("Contact created successfully");
        setIsSuccess(true);
        setTimeout(() => {
          setIsSuccess(false);
        }, [3000]);
        refetchAttributes();
        onClose();
        setIsDisabled(false);
      }
    } catch (err) {
      console.log(err);
      return;
    }
  };

  const updatecontactinstance = (action) => {
    const { type, payload } = action;
    const currentContactDataInstance = { ...contactDataInstance };
    switch (type) {
    // case Actions.UPDATE_TITLE:
    //   currentContactDataInstance.username = payload;
    //   break;
    case Actions.UPDATE_MULTISELECT:
      // const { name, value } = payload;
      if (!payload?.name) return;

      const eii = currentContactDataInstance?.multiSelectAttribute.findIndex(
        (tx) => tx?.name === payload?.name
      );
      if (eii !== -1) {
        // Replace the existing object with the new object
        currentContactDataInstance?.multiSelectAttribute.splice(eii, 1, {
          name: payload?.name,
          value: payload?.value,
        });
      } else {
        // Add a new object to multiSelectAttribute
        const newMultiSelectAttribute = {
          name: payload?.name,
          value: payload?.value,
        };
        currentContactDataInstance?.multiSelectAttribute.push(
          newMultiSelectAttribute
        );
      }
      break;

    case Actions.UPDATE_NORMALATTRIBUTE:
      const { value } = payload;
      if (!value || !Array.isArray(value)) return;

      const cleanedData = value.filter((tx) => tx?.value.trim() !== "");
      cleanedData.forEach((updatedItem) => {
        const existingIndex =
            currentContactDataInstance.contactAttributes.findIndex(
              (attr) => attr.id === updatedItem.id
            );
        if (existingIndex !== -1) {
          // Update the existing object with the new values
          currentContactDataInstance.contactAttributes[existingIndex] =
              updatedItem;
        } else {
          // Add a new object to contactAttributes
          currentContactDataInstance.contactAttributes.push(updatedItem);
        }
      });
      break;

    case Actions.UPDATE_USERSELECT:
      let existingIndex =
          currentContactDataInstance?.userAttribute?.findIndex(
            (tx) => tx?.name === payload?.name
          );
      if (existingIndex !== -1) {
        currentContactDataInstance?.userAttribute?.splice(existingIndex, 1);
      }
      if (payload?.value?.length > 0) {
        currentContactDataInstance?.userAttribute?.push(payload);
      }
      break;
    case Actions.UPDATE_DATE:
      if (!payload.id || !payload.name) {
        return;
      }
      const isExist =
          currentContactDataInstance?.contactAttributes?.findIndex(
            (tx) => tx?.name === payload.name
          );
      if (isExist !== -1) {
        currentContactDataInstance?.contactAttributes.splice(isExist, 1);
      }
      currentContactDataInstance?.contactAttributes.push({
        name: payload?.name,
        value: payload?.value,
        type: "Date",
        errorMessage: "",
      });
      break;
    case Actions.REMOVE_NORMALATTRIBUTE:
      const { attributeName } = payload;
      if (!attributeName) return;
      currentContactDataInstance.contactAttributes =
          currentContactDataInstance?.contactAttributes?.filter(
            (tx) => tx?.name !== attributeName
          );
      break;
    case Actions.REMOVE_USERSELECT:
      if (!payload.name) {
        return;
      }
      currentContactDataInstance.userAttribute =
          currentContactDataInstance.userAttribute.filter(
            (tx) => tx.name !== payload.name
          );
      break;
    case Actions.REMOVE_DATEATTRIBUTE:
      if (!payload.name) {
        return;
      }
      currentContactDataInstance.contactAttributes =
          currentContactDataInstance.contactAttributes.filter(
            (tx) => tx.name !== payload.name
          );
      break;
    case Actions.REMOVE_MULTISELECT:
      if (!payload.name) {
        return;
      }
      currentContactDataInstance.multiSelectAttribute =
          currentContactDataInstance.multiSelectAttribute.filter(
            (tx) => tx?.name !== payload.name
          );
      break;
    default:
      break;
    }
    setContactDataInstance(currentContactDataInstance);
  };

  const onUserDelete = (item) => {
    if (!item) {
      return;
    }
    const { id, name } = item;
    if (!id || !name) {
      return;
    }
    let currentUserSelectAttribute = [...defaultUserSelectAttribute];
    currentUserSelectAttribute = currentUserSelectAttribute?.filter(
      (tx) => tx?.id !== id
    );
    setDefaultUserSelectAttribute(currentUserSelectAttribute);
    updatecontactinstance({
      type: Actions.REMOVE_USERSELECT,
      payload: {
        name: name,
      },
    });
  };
  const returnUserAttribute = (item, attributeType) => {
    const userSchema = {
      ...item,
      options: item?.value,
      value: item?.value,
    };
    return (
      // <></>
      <UserSelectInlineField
        isCreate={true}
        t={t}
        handleUserChange={handleUserChange}
        item={userSchema}
        isDefault={
          attributeType === "Default" ||
          attributeType === "DefaultOutsideDrawer"
        }
        onDelete={onUserDelete}
      />
    );
  };
  const onDateSubmit = (data) => {
    const { id, name, value } = data;
    if (!id || !name || !value) {
      return;
    }
    updatecontactinstance({
      type: Actions.UPDATE_DATE,
      payload: {
        id: id,
        name: name,
        value: value,
      },
    });
  };
  const onDateRemove = (attributeType, attributeId, attributeName) => {
    if (attributeId.length === 0 || !attributeName) {
      return;
    }

    let currentNormalAttribute = [...defaultNormalAttribute];
    currentNormalAttribute = currentNormalAttribute.filter(
      (tx) => tx?.id !== attributeId[0]
    );
    setDefaultNormalAttribute(currentNormalAttribute);
    updatecontactinstance({
      type: Actions.REMOVE_DATEATTRIBUTE,
      payload: {
        name: attributeName,
      },
    });
  };
  const returnDateAttribute = (attributeId, label, value) => {
    if (!label || !attributeId) {
      return <></>;
    }

    return (
      <DateAttributeInlineEditingField
        attributeId={attributeId}
        label={label}
        initialValue={value}
        onSubmit={onDateSubmit}
        t={t}
        onDeleteForm={onDateRemove}
      />
      // <div className={styles.ContactDrawer__DataField}>
      //   <div className={styles.ContactDrawer__DataField__Label}>{label}</div>
      //   <div className={styles.ContactDrawer__DataField__Value}>{value}</div>
      // </div>
    );
  };

  return (
    <Drawer
      destroyOnClose
      headerStyle={{ display: "none" }}
      placement="right"
      onClose={onClose}
      open={open}
      visible={open}
      className={styles.CreateContactDrawer__drawer}
    >
      <div className={styles.CreateContactDrawer}>
        <div className={styles.CreateContactDrawer__Header}>
          Create new contact
        </div>
        <div className={styles.UsernameContainer}>
          <ProfilePicPlaceholder
            height="34px"
            width="34px"
            name={username}
            fontSize="1em"
          />

          {nameLoading ? (
            <Spin />
          ) : (
            <input
              value={username}
              className={styles.UsernameContainer__Input}
              type="text"
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
          )}
        </div>
        {defaultMultiSelectAttribute
          ?.filter((tx) => tx?.attributeType === "DefaultOutsideDrawer")
          .map((tx) => {
            return returnMultiSelectAttribute(
              tx?.id,
              tx?.name,
              tx?.tags,
              tx?.attributeType
            );
          })}
        {defaultUserSelectAttribute
          ?.filter((tx) => tx?.attributeType === "DefaultOutsideDrawer")
          ?.map((tx) => {
            return returnUserAttribute(tx, tx?.attributeType);
          })}
        {defaultNormalAttribute
          ?.filter((tx) => tx?.attributeType === "DefaultOutsideDrawer")
          ?.map((tx) => {
            return returnTextAttribute(
              tx?.id,
              tx?.name,
              "",
              tx?.type,
              tx?.attributeType
            );
          })}

        <Divider className={styles.Separator} />

        <ContactDrawerAccordion openByDefault={true} title="Contact details">
          {/* {defaultMultiSelectAttribute?.filter((tx) => tx?.attributeType !== "DefaultOutsideDrawer").map((tx) => {
            return returnMultiSelectAttribute(tx?.id, tx?.name, tx?.tags, tx?.attributeType);
          })}
          {
            defaultUserSelectAttribute?.filter((tx) => tx?.attributeType !== "DefaultOutsideDrawer")?.map((tx) => {
              return returnUserAttribute(tx, tx?.attributeType);
            })
          }
          {
            defaultNormalAttribute?.filter((tx) => tx?.attributeType !== "DefaultOutsideDrawer")?.map((tx) => {
              return tx?.type === "Date" ?
                returnDateAttribute(tx?.id, tx?.name, moment().format('MMMM DD, YYYY'))
                :
                returnTextAttribute(tx?.id, tx?.name, "", tx?.type, tx?.attributeType);
            })
          }
           */}
          {attributeListData
            ?.filter(
              (tx) =>
                tx?.attributeType === "Default" &&
                tx.__typename === "MultiSelectAttribute"
            )
            .map((tx) => {
              return returnMultiSelectAttribute(
                tx?.id,
                tx?.name,
                tx?.values,
                tx?.attributeType
              );
            })}
          {attributeListData
            ?.filter(
              (tx) =>
                tx?.attributeType === "Default" &&
                tx.__typename === "UserAttribute"
            )
            ?.map((tx) => {
              return returnUserAttribute(tx, tx?.attributeType);
            })}
          {attributeListData
            ?.filter(
              (tx) =>
                tx?.attributeType === "Default" &&
                tx.__typename === "NormalAttribute"
            )
            ?.map((tx) => {
              return tx?.type === "Date"
                ? returnDateAttribute(tx?.id, tx?.name, "", tx)
                : returnTextAttribute(
                  tx?.id,
                  tx?.name,
                  tx?.values,
                  tx?.type,
                  tx?.attributeType
                );
            })}
          {attributeListData
            ?.filter(
              (tx) =>
                tx?.attributeType === "Normal" &&
                tx.__typename === "MultiSelectAttribute"
            )
            .map((tx) => {
              return returnMultiSelectAttribute(
                tx?.id,
                tx?.name,
                tx?.values,
                tx?.attributeType
              );
            })}
          {attributeListData
            ?.filter(
              (tx) =>
                tx?.attributeType === "Normal" &&
                tx.__typename === "UserAttribute"
            )
            ?.map((tx) => {
              return returnUserAttribute(tx, tx?.attributeType);
            })}
          {attributeListData
            ?.filter(
              (tx) =>
                tx?.attributeType === "Normal" &&
                tx.__typename === "NormalAttribute"
            )
            ?.map((tx) => {
              return tx?.type === "Date"
                ? returnDateAttribute(tx?.id, tx?.name, "", tx)
                : returnTextAttribute(
                  tx?.id,
                  tx?.name,
                  tx?.values,
                  tx?.type,
                  tx?.attributeType
                );
            })}
        </ContactDrawerAccordion>
        <div className={styles.AddAttributeButtonContainer}>
          {/* <Popover content={content} title={null} trigger="click"> */}
          <Button
            className={styles.AddAttributeButton}
            type="dashed"
            onClick={() => {
              setAttributeDrawer(true);
            }}
          >
            <PlusOutlined /> New attribute
          </Button>
          {/* </Popover> */}
        </div>
      </div>
      <div className={styles.ButtonContainer}>
        <Button onClick={submitForm} type="primary" style={{backgroundColor: 'var(--primary-color)' }}>
          Save
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </div>
    </Drawer>
  );
};
CreateContactDrawer.propTypes = {};

CreateContactDrawer.defaultProps = {};

export default CreateContactDrawer;
