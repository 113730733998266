import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Drawer } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import styles from "./EditCannedResponseForm.module.scss";
import { Button, Form, Input, Radio, notification, message } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { updateCannedTemplate } from "src/api/MessageApi";
import { useQuery } from "@apollo/client";
import CannedTemplateVariables from "../CannedTemplateVariables/CannedTemplateVariables";
import CreateCannedUpload from "../CreateCannedUpload/CreateCannedUpload";
import PreviewGenerator from "../PreviewGenerator/PreviewGenerator";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Select from "react-select";
import { LOAD_MESSAGE_TEMPLATES_BY_ID } from "src/graphql/message";
import { getActiveOrgId } from "src/store/slices/userSlice";

const EditCannedResponseForm = ({ showDrawer, setShowDrawer }) => {
  const options = [{ value: "Whatsapp", label: "Whatsapp" }];
  const { TextArea } = Input;
  const [submit, setSubmit] = useState(false);
  const organizationId = useSelector(getActiveOrgId);
  const [loading, setLoading] = useState(false);
  const [messageContent, setMessageContent] = useState(null);
  const {
    data: messageTemplateData,
    refetch,
    isloading,
  } = useQuery(LOAD_MESSAGE_TEMPLATES_BY_ID, {
    variables: {
      templateId: showDrawer?.id,
    },
  });

  const [attachmentList, setAttachmentList] = useState([]);
  const addAttachment = (newAttachmentList) => {
    const new_list = [...attachmentList, ...newAttachmentList];
    setAttachmentList(new_list);
  };
  const [cursorPosition, setCursorPosition] = useState(0);
  const textareaRef = useRef(null);
  const reOrderAttachment = (oldIndex, newIndex) => {
    const prev_list = [...attachmentList];
    var element = prev_list[oldIndex];
    prev_list.splice(oldIndex, 1);
    prev_list.splice(newIndex, 0, element);
    setAttachmentList(prev_list);
  };
  const removeAttachmentByIndex = (value) => {
    const prev_list = [...attachmentList];
    const index = prev_list.indexOf(value);
    if (index === -1) return;
    prev_list.splice(index, 1);
    setAttachmentList(prev_list);
  };
  const insertTextAtPlace = (label) => {
    const new_text = values.message + label + " ";
    setFieldValue("message", new_text);
  };
  const textAreaChanged = (e) => {
    setCursorPosition(e.target.selectionStart);
  };
  useEffect(() => {
    if (messageTemplateData === null || messageTemplateData === undefined)
      return;
    setMessageContent(messageTemplateData?.getMessageTemplates);
    setAttachmentList(messageTemplateData?.getMessageTemplates?.attachments);
  }, [messageTemplateData]);
  const handleCreateTemplateSubmit = async (values, resetForm) => {
    try {
      setLoading(true);
      let formData = new FormData();
      formData.append("templateId", showDrawer?.id);
      formData.append("title", values.title);
      formData.append("content", values.message);
      formData.append("organizationId", organizationId);
      let attachmentUrls = [];
      let ordering = [];
      for (let i = 0; i < attachmentList.length; ++i) {
        if (attachmentList[i].url !== undefined) {
          attachmentUrls.push(attachmentList[i]);
          ordering.push({
            added: false,
            id: attachmentList[i]?.id,
            url: attachmentList[i].url,
          });
        } else {
          formData.append("attachment", attachmentList[i]);
          ordering.push({
            added: true,
            name: attachmentList[i].name,
          });
        }
      }
      formData.append("ordering", JSON.stringify({ order: ordering }));

      formData.append(
        "attachmentUrls",
        JSON.stringify({ urls: attachmentUrls })
      );
      let res = await updateCannedTemplate(formData);
      let data = res?.data;
      if (data?.success === false) {
        notification.error({
          message: "Notification",
          description: data?.message,
        });
      } else {
        message.success(`"${values.title}" canned response updated`);
        setShowDrawer({
          shown: false,
          id: "",
          title: "",
          content: "",
        });
        //resetForm();
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      notification.error({
        message: "Notification",
        description: "Something goes wrong!!!",
      });
      setLoading(false);
      return;
    }
  };

  const { values, handleSubmit, handleChange, errors, setFieldValue } =
    useFormik({
      initialValues: {
        title: showDrawer.title,
        message: showDrawer.content,
      },
      validationSchema: Yup.object().shape({
        title: Yup.string()
          .required("name cannot be empty")
          .test(
            "template_validation",
            "name should start with /",
            function (value) {
              if (value === null || value === undefined || value.length === 0)
                return true;
              if (value[0] !== "/") return false;
              return true;
            }
          ),
        message: Yup.string().required("Message cannot be empty"),
      }),
      onSubmit: (values, { resetForm }) => {
        handleCreateTemplateSubmit(values, resetForm);
      },
      enableReinitialze: true,
    });

  const LabelStyle = {
    fontSize: "16px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "24px",
    color: "#262626",
    paddingBottom: "0px",
    marginBottom: "0px",
  };

  return (
    <Drawer
      headerStyle={{ display: "none" }}
      title="Add Canned Response"
      width={1000}
      placement="right"
      open={showDrawer.shown}
      onClose={() => {
        setShowDrawer({
          shown: false,
          id: "",
          title: "",
          content: "",
        });
      }}
    >
      <div className={styles.EditCannedResponseForm}>
        <div className={styles.EditCannedResponseForm__Header}>
          <div className={styles.EditCannedResponseForm__Header__Title}>
            Edit Canned Response
          </div>
          <div
            className={styles.EditCannedResponseForm__Header__Close}
            onClick={() => {
              setShowDrawer({
                shown: false,
                id: "",
                title: "",
                content: "",
              });
            }}
          >
            <CloseOutlined className={styles.EditCannedResponseForm__cursor} />
          </div>
        </div>

        <div className={styles.EditCannedResponseForm__Body}>
          <div className={styles.EditCannedResponseForm__Body__Form}>
            <Form
              layout="vertical"
              onFinish={handleSubmit}
              initialValues={{
                title: messageContent === null ? "" : messageContent.title,
                message: messageContent === null ? "" : messageContent.content,
              }}
            >
              <Form.Item
                label={
                  <p style={LabelStyle}>
                    Response name <span style={{ color: "#CF1322" }}>*</span>
                  </p>
                }
              >
                <Input
                  disabled={messageContent === null ? true : false}
                  placeholder="Enter Reply name"
                  name="title"
                  onChange={handleChange}
                  value={values.title}
                  data-testid="titleInput"
                />
                {submit && errors.title ? (
                  <div className={styles.EditCannedResponseForm__Error__Title}>
                    <small>{errors.title}</small>
                  </div>
                ) : null}
              </Form.Item>
              <Form.Item label={<p style={LabelStyle}>Response content</p>}>
                <TextArea
                  onChange={(e) => {
                    textAreaChanged(e);
                    handleChange(e);
                  }}
                  ref={textareaRef}
                  disabled={messageContent === null ? true : false}
                  name="message"
                  value={values.message}
                  placeholder="Eg: Hi {{name}}! your order is placed successfully. Thank you! Warm regards, {{operator name}}"
                />
                {submit && errors.message ? (
                  <div
                    className={styles.EditCannedResponseForm__Error__Message}
                  >
                    <small>{errors.message}</small>
                  </div>
                ) : null}
              </Form.Item>

              <CannedTemplateVariables insertTextAtPlace={insertTextAtPlace} />
              <CreateCannedUpload
                attachmentList={attachmentList}
                addAttachment={addAttachment}
                removeAttachmentByIndex={removeAttachmentByIndex}
                reOrderAttachment={reOrderAttachment}
              />
              {/* <div className={styles.EditCannedResponseForm__MediaContainer}>
                <div className={styles.EditCannedResponseForm__MediaContainer__Label}>
                  Media
                </div>
                <div className={styles.EditCannedResponseForm__MediaContainer__FileField}>
                  <Button type="primary">Upload</Button>
                </div>
                <div className={styles.EditCannedResponseForm__MediaContainer__Info}>
                  Maximum file size should not exceed 16MB
                </div>
              </div> */}
              <div className={styles.EditCannedResponseForm__ButtonContainer}>
                <Button
                  type="secondary"
                  onClick={() => {
                    setShowDrawer(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  disabled={loading}
                  htmlType="submit"
                  onClick={() => setSubmit(true)}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
          <div className={styles.EditCannedResponseForm__Body__Preview}>
            <div
              className={styles.EditCannedResponseForm__Body__Preview__Title}
            >
              <div
                className={
                  styles.EditCannedResponseForm__Body__Preview__Title__Header
                }
              >
                Preview
              </div>
              <div
                style={{ width: "122px", height: "32px", marginBottom: "16px" }}
              >
                <Select
                  options={options}
                  defaultValue={{ value: "Whatsapp", label: "Whatsapp" }}
                  styles={{
                    indicatorSeparator: () => ({ display: "none" }),
                  }}
                  isDisabled={true}
                />
              </div>
            </div>
            <PreviewGenerator
              attachmentList={attachmentList}
              message={values.message}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

EditCannedResponseForm.propTypes = {};

EditCannedResponseForm.defaultProps = {};

export default EditCannedResponseForm;
