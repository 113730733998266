// All defined widget types
export const widgetTypes = [
  {
    rangePicker: ["timerConfig"],
  },
  {
    dropdown: ["dropdownConfig"],
  },
  {
    editButton: ["buttonConfig"],
  },
  {
    table: ["tableConfig", "dataSourceType", "dataSource", "loading", "error"],
  },
];

//  defualt widgets for timesheet
export const defaultWidgets = {
  timesheet: [
    { type: "rangePicker", fullWidth: false },
    { type: "dropdown", fullWidth: false },
    { type: "button", fullWidth: false },
    { type: "reportButton", fullWidth: false },
    { type: "table", fullWidth: true },
  ],
};

// timer configuration timesheet

const currentDate = new Date();

const startOfDay = new Date(currentDate);
startOfDay.setHours(0, 0, 0, 0);

const endOfDay = new Date(currentDate);
endOfDay.setHours(23, 59, 59, 999);

const formattedStartDate = startOfDay.toISOString();
const formattedEndDate = endOfDay.toISOString();

export const timeSheetTimerConfig = {
  startDate: formattedStartDate,
  endDate: formattedEndDate,
};
export const addManualTimeButtonConfig = {
  type: "primary",
};
export const addDownloadReportsButtonConfig = {
  type: "primary",
};

// Dropdown configuration for timesheet
export const userDropdownConfig = {
  dropdownConfig: {
    options: [
      { value: "1", label: "Option 1" },
      { value: "2", label: "Option 2" },
      { value: "3", label: "Option 3" },
    ],
    placeholder: "Choose other member",
  },
};
export const addManualTimeFormConfig = {
  fields: [
    { label: "Email", name: "email", type: "text" },
    { label: "Start Date", name: "startDate", type: "datetime-local" },
    { label: "End Date", name: "endDate", type: "datetime-local" },
    { label: "Project", name: "project", type: "select" },
  ],
};

// table configuration for timesheets
export const timesheetConfig = {
  columns: [
    {
      title: "Username",
      field: "username",
      dataIndex: "username",
      key: "username",
      type: "text",
    },
    {
      title: "Project",
      field: "project",
      dataIndex: "project",
      key: "project",
      type: "text",
    },
    {
      title: "Activity",
      field: "activity",
      dataIndex: "activity",
      key: "activity",
      type: "text",
    },
    {
      title: "Duration",
      field: "duration",
      dataIndex: "duration",
      key: "duration",
      type: "text",
    },
    {
      title: "Time",
      field: "time",
      dataIndex: "time",
      key: "time",
      type: "text",
    },
    {
      title: "Delete",
      field: "delete",
      dataIndex: "delete",
      key: "delete",
      type: "button",
    },
  ],
};

// API endpoint for collecting data for table
export const timeSheetTableDataSource = "http://example.com/api/v1/data";
