import { createSlice } from "@reduxjs/toolkit";
import { setSelectedNode } from "./nodeSlice";

const initialState = {
  unrepliedCount: {},
  newMessages: [],
  organizationId: null,
  organizationname: "",
  channelId: "",
  actionData: [],
  draftValue: "",
  selectedChat: null
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    resetChat: (state) => Object.assign(state, initialState),
    setChatData: (state, action) => {
      state = { ...state, ...action.payload };
    },
    setActionData: (state, action) => {
      state.actionData = action.payload;
    },
    storeNewMessages: (state, action) => {
      state.newMessages = action.payload;
    },
    setUnrepliedCount: (state, action) => {
      state.unrepliedCount = action.payload;
    },
    modifyCount: (state, action) => {
      state.unrepliedCount = {
        ...state.unrepliedCount,
        [action.payload.threadId]:
          action.payload.type === "sent"
            ? 0
            : state.unrepliedCount[action.payload.threadId] + 1,
      };
    },
    setSelectedChat: (state,action) => {
      state.selectedChat = action.payload
    },
    
  },
});

export const {
  resetChat,
  setChatData,
  setActionData,
  storeNewMessages,
  setUnrepliedCount,
  modifyCount,
  setSelectedChat,
} = chatSlice.actions;
export default chatSlice.reducer;
