import "antd-country-phone-input/dist/index.css";

import React, { useEffect, useState } from "react";

import { Badge, Button, Form, Input, message, Modal, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  CloudUploadOutlined,
  ExclamationCircleOutlined,
  UserAddOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";
import { useQuery, useSubscription } from "@apollo/client";
import { useTour } from "@reactour/tour";

import {
  addNewContact,
  deleteAttribute,
  deleteContact,
  updateContact,
} from "src/api/ContactApi";
import FilterIcon from "src/assets/Filter.svg";
import featureFlag from "../../helpers/featureFlag";
import ImportContact from "../ImportContact/ImportContact";
import Table from "../Table/Table";
import CreateContactDrawer from "./components/CreateContactDrawer/CreateContactDrawer";
import EditContactDrawer from "./components/EditContactDrawer/EditContactDrawer.lazy";
import FilterContactDrawer from "./components/FilterContactDrawer/FilterContactDrawer";
import SegmentationDrawer from "./components/SegmentationDrawer/SegmentationDrawer";
import ShowContactDrawer from "./components/ShowContactDrawer/ShowContactDrawer";
import styles from "./ContactsPage.module.scss";
import { setIsRedirectedFromStarted } from "src/store/slices/onboardingSlice";
import { setGlobalSelectedSegment } from "src/store/slices/contactSlice";
import { GET_ORGANIZATION_ATTRIBUTES } from "src/graphql/organization";
import { GET_BILLING_DETAILS } from "src/graphql/billing";
import { GET_LEAD_ATTR_DATA } from "src/graphql/lead";
import { getActiveOrgId } from "src/store/slices/userSlice";
import { CreateAttributeDrawer } from "../Conversation/Conversation";

const ContactsPage = () => {
  const organizationId = useSelector(getActiveOrgId);
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false);
  const { t, i18n } = useTranslation();
  const { Search } = Input;
  const lang = useSelector((store) => store?.language?.language);
  let others = ["Multiselect", "User"];
  const userDetails = useSelector((store) => store?.user?.data);
  const userDetailsObj = useSelector((store) => store?.user?.data);
  const dispatch = useDispatch();
  const { username } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openDetails, showOpenDetails] = useState(false);
  const [openEditDetails, showOpenEditDetails] = useState(false);
  const [fields, setFields] = useState([]);
  const [editFields, setEditFields] = useState([]);
  const [multiSelects, setMultiSelects] = useState([]);
  const [attributesList, setAttributesList] = useState([]);
  const [selectedContact, setSelectedContact] = useState("");
  const [staffData, setStaffData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [doRef, setDoref] = useState(false);
  const [leadsData, setLeadsData] = useState([]);
  const [showImportContact, setShowImportContact] = useState(false);
  const [selD, setSelD] = useState({});
  const [selectedSegmentData, setSelectedSegmentData] = useState({});
  const [filtersCount, setFiltersCount] = useState(0);
  const [selectDelete, setSelectDelete] = useState({
    id: 1,
    name: "",
    type: "",
    value: "",
  });
  const [deleteData, setDeleteData] = useState([]);
  // const [selectedSegment,setSelectedsegment] = useState("")
  const { setSteps, setIsOpen } = useTour();
  const selectedSegment = useSelector(
    (state) => state?.contactReducer?.globalSelectedSegment
  );

  const {
    loading,
    refetch: refetchAttributes,
    error,
    data,
    networkStatus,
  } = useQuery(GET_ORGANIZATION_ATTRIBUTES, {
    variables: {
      organizationId,
      notifyOnNetworkStatusChange: true,
    },
  });

  const { data: billingData } = useSubscription(GET_BILLING_DETAILS, {
    variables: { organizationId },
  });

  let subscriptionStatus = billingData?.getOrganization?.billing?.status;

  const onBoardingStatus = useSelector((state) => {
    return state?.onBoarding;
  });

  const {
    loading: selectedContactLoading,
    refetch: refetchSelectedContact,
    error: selectedContactError,
    data: selectedContactDataArr,
  } = useQuery(GET_LEAD_ATTR_DATA, {
    variables: {
      leadId: selectedContact,
    },
  });

  function convertData(data) {
    const convertedData = [];

    data?.normalAttribute.forEach((attr) => {
      convertedData.push({
        __typename: "NormalAttribute",
        id: attr.id,
        name: attr.name,
        type: attr.type,
        value: "",
        createdAt: attr.createdAt,
      });
    });

    data?.multiSelectAttribute.forEach((attr) => {
      // const options = attr.labels.map((label) => {
      //   return {
      //     id: label.id,
      //     name: label.name,
      //     color: label.color,
      //     label: label.name,
      //     value: label.id,
      //     isSelected: false,
      //   };
      // });
      // convertedData.push({
      //   type: "Multiselect",
      //   name: attr.name,
      //   createdAt: attr.createdAt,
      //   value: "",
      //   id: attr.id,
      //   options: options,
      // });
    });

    data?.userAttribute.forEach((attr) => {
      convertedData.push({
        type: "User",
        name: attr.name,
        value: "",
        id: attr.id,
        createdAt: attr.createdAt,
        options: [],
      });
    });

    return convertedData;
  }

  useEffect(() => {
    if (onBoardingStatus?.isRedirectedFromStarted) {
      showDrawer();
      setTimeout(() => {
        dispatch(setIsRedirectedFromStarted(false));
      }, 1000);
    }
  }, [onBoardingStatus?.isRedirectedFromStarted]);

  useEffect(() => {
    if (!open && !openEditDetails) return;

    if (!selD) {
      setFields([]);
      setEditFields([]);
      setSelectedContact("");
      return;
    }

    if (!data) return;

    if (!fields || fields.length === 0) {
      const d = convertData(data.getOrganization);
      if (Object.keys(selD).length !== 0) {
        const updatedData = updateData(selD, d);
        setEditFields(updatedData);
      }
      setFields(d);
      return;
    }

    const d = convertData(data.getOrganization);
    const result = d.filter((a) => !fields.some((b) => b.id === a.id));
    if (Object.keys(selD).length !== 0) {
      const updatedData = updateData(selD, [...fields, ...result]);
      setEditFields(updatedData);
    }
    setFields([...fields, ...result]);
  }, [open, data, openEditDetails, selD]);

  useEffect(() => {
    if (
      !selectedContactDataArr ||
      !selectedContactDataArr.queryHC_Lead ||
      !selectedContactDataArr.queryHC_Lead[0]
    ) {
      return;
    }
    const dataArr = selectedContactDataArr.queryHC_Lead[0];
    setSelD(dataArr || {});
  }, [selectedContactDataArr]);

  const [isAttributeDrawerOpen, setIsAttributeDrawerOpen] = useState(false);

  function transformEditData(data, username) {
    // Filter out empty values from NormalAttribute
    const normalAttribute = data
      .filter((item) => {
        return item.__typename === "NormalAttribute";
      })
      .map((item) => {
        return {
          name: item.name,
          value: item.value,
        };
      });

    // Transform UserAttribute
    const userAttribute = data
      .filter((item) => {
        return item.type === "User";
      })
      .map((item) => {
        return {
          name: item.name,
          value: item.options.map((option) => {
            return {
              id: option.agentId,
              // username: option.agentName,
            };
          }),
        };
      });

    // Transform MultiSelectAttribute
    const multiSelectAttribute = data
      .filter((item) => {
        return (
          item.type === "Multiselect" &&
          item.options.some((option) => option.isSelected)
        );
      })
      .map((item) => {
        return {
          multiSelectAttributeValue: item.options
            .filter((option) => option.isSelected)
            .map((option) => {
              return {
                color: option.color,
                name: option.name,
                multiSelectAttribute: {
                  id: item.id,
                },
              };
            }),
        };
      });

    // Extract username
    // const username = data.find((item) => item.__typename === "Profile").username;

    return {
      username,
      normalAttribute,
      userAttribute,
      multiSelectAttribute,
    };
  }

  function updateData(sourceData, targetData) {
    sourceData.normalAttributeValue.forEach((normalAttribute) => {
      const targetAttribute = targetData.find(
        (item) => item.id === normalAttribute.normalAttribute.id
      );
      if (targetAttribute) {
        targetAttribute.value = normalAttribute.value;
        targetAttribute.valId = normalAttribute.id;
      }
    });

    sourceData.multiSelectAttributeValue.forEach((multiSelectAttribute) => {
      const targetAttribute = targetData.find(
        (item) => item.id === multiSelectAttribute.multiSelectAttribute.id
      );
      if (targetAttribute) {
        targetAttribute.options.forEach((option) => {
          if (option.name === multiSelectAttribute.name) {
            option.isSelected = true;
          }
        });
      }
    });

    sourceData.userAttributeValue.forEach((userAttribute) => {
      const targetAttribute = targetData.find(
        (item) => item.id === userAttribute.userAttribute.id
      );
      if (targetAttribute) {
        userAttribute.value.forEach((user) => {
          targetAttribute.options.push({
            id: user.id,
            agentId: user.id,
            agentName: user.username,
          });
        });
        targetAttribute.valId = userAttribute?.id;
      }
    });

    return targetData;
  }

  const getSelectedContactData = () => {};

  const handleSetSelectedSegment = (val) => {
    dispatch(setGlobalSelectedSegment(val));
    message.success("Segment Applied!");
  };

  const fetchTableData = (val) => {
    setLeadsData(val);
  };

  const fetchStaffData = (v) => {
    setStaffData(v);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    form.resetFields();
    setSelectDelete({
      id: 1,
      name: "",
      type: "",
      value: "",
    });
    setFields([]);
    setEditFields([]);
    setSelD({});
    setSelectedContact("");
  };

  const showDetailsDrawer = () => {
    showOpenDetails(true);
  };

  const showEditDetailsDrawer = () => {
    showOpenEditDetails(true);
  };

  const onDetailsClose = () => {
    showOpenDetails(false);
    setSelectedContact("");
  };

  const onEditDetailsClose = () => {
    showOpenEditDetails(false);
    editForm.resetFields();
    setSelectDelete({
      id: 1,
      name: "",
      type: "",
      value: "",
    });
    setEditFields([]);
    setSelD({});
    setFields([]);
    setSelectedContact("");
  };

  const handleSelectedContact = (val) => {
    setSelectedContact(val);
  };

  const handleAddFields = (val) => {
    setFields([
      ...fields,
      {
        ...val,
        id: Math.random(),
        name: val?.name,
        type: val.type,
        value: val.value,
      },
    ]);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    let type = "";
    if (selectDelete?.type === "Multiselect") {
      type = "multiSelectAttribute";
    } else if (selectDelete?.type === "User") {
      type = "userAttribute";
    } else {
      type = "normalAttribute";
    }
    let body = {
      [type]: {
        id: selectDelete?.id,
      },
    };
    deleteAttribute(body)
      .then(() => {
        message?.success("Attribute deleted successfully!");
        setIsModalOpen(false);
        onClose();
        refetchAttributes();
      })
      .catch((e) => {
        message?.warning("Cannot delete attribute.Try again later");
        setIsModalOpen(false);
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleChange = (e, val) => {
    let res = fields?.map((item) =>
      item.id === val.id ? { ...item, value: e.target.value } : item
    );
    setFields(res);
  };

  const handleEditChange = (e, val) => {
    let res = editFields?.map((item) =>
      item.id === val.id ? { ...item, value: e.target.value } : item
    );
    setEditFields(res);
  };

  const handleDateChange = (v, val) => {
    let res = fields?.map((item) =>
      item.id === val.id ? { ...item, value: v } : item
    );
    setFields(res);
  };

  const handleEditDateChange = (v, val) => {
    let res = editFields?.map((item) =>
      item.id === val.id ? { ...item, value: v } : item
    );
    setEditFields(res);
  };

  const handleMultiSelectChange = (id, op) => {
    let res = fields?.map((item) =>
      item.id === id ? { ...item, options: op } : item
    );
    setFields(res);
  };

  const handleEditMultiSelectChange = (id, op) => {
    let res = editFields?.map((item) =>
      item.id === id ? { ...item, options: op } : item
    );
    setEditFields(res);
  };

  const handleUserChange = (id, op) => {
    let res = fields?.map((item) =>
      item.id === id ? { ...item, options: op } : item
    );
    setFields(res);
  };

  const handleEditUserChange = (id, op) => {
    let res = editFields?.map((item) =>
      item.id === id ? { ...item, options: op } : item
    );
    setEditFields(res);
  };

  const handleMultiselectFetch = (val) => {
    setMultiSelects(val);
  };

  function removeKeys(input) {
    input.map(function (item) {
      item.options.map(function (option) {
        delete option.id;
        delete option.label;
        delete option.value;
      });
    });
    return input;
  }

  function convertUserData(data) {
    let newData = data.map((item) => {
      return {
        name: item.name,
        value: item.options.map((option) => {
          return {
            agentId: option.id,
            agentName: option.agentName,
          };
        }),
      };
    });
    return newData;
  }

  function filterSelectedOptions(data) {
    data.forEach(function (item) {
      item.value = item.options.filter(function (option) {
        return option.isSelected === true;
      });
      item.value.forEach(function (value) {
        delete value.isSelected;
      });
      delete item.options;
    });
    return data;
  }

  const onFinish = (values) => {
    let { username } = values;
    // const finalFields = fields.map(i => {
    //   const { id, ...rest } = i;
    //   return rest;
    // });
    const updatedArray = fields.map((obj) => {
      if (Object.prototype.hasOwnProperty.call(obj, "__typename")) {
        delete obj.__typename;
        delete obj.id;
        delete obj.createdAt;
      }
      return obj;
    });
    let MS = updatedArray?.filter((item) => item.type === "Multiselect");
    let US = updatedArray?.filter((item) => item.type === "User");
    MS = removeKeys(
      MS.map(({ value, id, createdAt, ...keepAttrs }) => keepAttrs)
    );
    MS = MS.map(({ type, ...item }) => item);
    MS = filterSelectedOptions(MS);

    US = convertUserData(US);
    let obj = {
      username,
      organization: {
        id: organizationId,
      },
      contactAttributes: updatedArray?.filter(
        (item) => !others?.includes(item?.type)
      ),
      userAttribute: US,
      multiSelectAttribute: MS,
    };

    addNewContact(obj)
      .then((res) => {
        message.success("Contact created");
        setIsSuccess(true);
        setTimeout(() => {
          setIsSuccess(false);
        }, [3000]);
        refetchAttributes();
        onClose();
      })
      .catch((e) => {
        message.error("Something went wrong . Please try again.");
      });
  };
  const onEditFinish = (values) => {
    let { username } = values;
    let obj = transformEditData(editFields, username);
    updateContact(obj, selD?.id)
      .then((res) => {
        message.success("Contact updated");
        setIsSuccess(true);
        setTimeout(() => {
          setIsSuccess(false);
        }, [3000]);
        refetchAttributes();
        onEditDetailsClose();
      })
      .catch((e) => {
        message.error("Something went wrong . Please try again.");
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const onEditFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  const handleSubmit = (event) => {
    // event.preventDefault(); // 👈️ prevent page refresh
  };

  const handleRefetchAttributes = async () => {
    refetchAttributes();
  };

  const handleDelete = (item) => {
    showModal(true);
    setSelectDelete(item);
  };

  const refreshingData = () => {
    setIsSuccess(true);
    setTimeout(() => {
      setIsSuccess(false);
    }, [3000]);
    refetchAttributes();
  };

  const deleteModalAPIRequest = async () => {
    try {
      setIsDeleteButtonDisabled(true);
      let data = {
        contacts: deleteData,
        organizationId,
      };
      const res = await deleteContact(data);
      if (res?.data?.success === true) {
        notification.success({
          message: <b>Notification</b>,
          description: <b>Contact deleted successfully!</b>,
        });
      } else {
        if (res?.data?.message) {
          notification.error({
            message: <b>Notification</b>,
            description: <b>{res?.data?.message}</b>,
          });
        } else {
          notification.error({
            message: <b>Notification</b>,
            description: <b>Internal Server Error!</b>,
          });
        }
      }
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, [3000]);
      refetchAttributes();
      setIsDeleteModalOpen(false);
      setDeleteData([]);
      setIsDeleteButtonDisabled(false);
    } catch (err) {
      console.log(err);
      notification.error({
        message: <b>Notification</b>,
        description: <b>Internal Server Error!</b>,
      });
    }
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [isSegmentDrawerOpen, setIsSegmentDrawerOpen] = useState(false);
  const [dataForCountingSegmentation, setDataForCountingSegmentation] =
    useState({
      attrAndTypes: [],
      initialTableData: [],
      waChannel: [],
    });

  const handleDataForCountingSegmentation = (val) => {
    setDataForCountingSegmentation(val);
  };

  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleFetchFilters = (val) => {
    setSelectedFilters(val);
  };

  const handleFetchFiltersCount = (val) => {
    setFiltersCount(val);
  };

  const handleOpenFilterDrawer = () => {
    setIsFilterDrawerOpen(true);
  };

  const handleOpenSegmentDrawer = (val) => {
    setIsSegmentDrawerOpen(true);
  };

  const handleCloseSegmentDrawer = () => {
    setIsSegmentDrawerOpen(false);
  };

  const fetchSegmentData = (val) => {
    setSelectedSegmentData(val);
  };

  return (
    <>
      {showImportContact === true && (
        <ImportContact
          refreshingData={refreshingData}
          showContactDrawer={showImportContact}
          setShowContactDrawer={setShowImportContact}
        />
      )}
      <Modal
        className={styles.DeleteModal__Modal}
        open={isDeleteModalOpen}
        footer={null}
        closable={false}
        header={false}
      >
        <div className={styles.DeleteModal}>
          <div className={styles.DeleteModal__Body}>
            <div className={styles.DeleteModal__Body__IconContainer}>
              <ExclamationCircleOutlined
                style={{ color: "#FF4D4F", fontSize: "21px" }}
              />
            </div>
            <div className={styles.DeleteModal__Body__ContentContainer}>
              <h5>Delete contacts ({deleteData.length})</h5>
              <p>
                Selected contacts will be deleted permanently. Are you sure you
                want to delete?
              </p>
            </div>
          </div>
          <div className={styles.DeleteModal__Footer}>
            <Button
              disabled={isDeleteButtonDisabled}
              onClick={() => {
                deleteModalAPIRequest();
              }}
              type="primary"
              delete
              danger
            >
              Delete
            </Button>
            <Button
              onClick={() => {
                setIsDeleteModalOpen(false);
              }}
              type="ghost"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      <div className={styles.ContactsPage}>
        {isSegmentDrawerOpen && (
          <SegmentationDrawer
            handleCloseSegmentDrawer={handleCloseSegmentDrawer}
            organizationId={userDetailsObj?.activeOrg?.organization?.id}
            handleSetSelectedSegment={handleSetSelectedSegment}
            dataForCountingSegmentation={dataForCountingSegmentation}
            // selectedSegmentData={selectedSegmentData}
            // selectedSegment={selectedSegment}
          />
        )}
        <div
          className={styles.ContactsPage__container}
          style={{ width: isSegmentDrawerOpen ? "80%" : "100%" }}
        >
          {/* <div className={styles.ContactsPage__container__customers}>
            <div className={styles.ContactsPage__container__customers__head}>
              <h3>{t('Customers')}</h3>
            </div>
            <div
              className={styles.ContactsPage__container__customers__body}
            ></div>
          </div> */}

          <div className={styles.ContactsPage__container__contacts}>
            <div
              className={styles.ContactsPage__container__contacts__headermobile}
              style={{
                top:
                  billingData?.getOrganization?.billing !== null &&
                  billingData?.getOrganization?.billing !== undefined
                    ? subscriptionStatus === "trialing"
                      ? "38px"
                      : "0px"
                    : "38px",
              }}
            >
              <div
                className={
                  styles.ContactsPage__container__contacts__header__left
                }
              >
                <div
                  className={
                    styles.ContactsPage__container__contacts__header__left__container
                  }
                >
                  {!isSegmentDrawerOpen && (
                    <h4
                      onClick={handleOpenSegmentDrawer}
                      className={
                        styles.ContactsPage__container__contacts__header__left__container__icon
                      }
                      style={{ transform: "rotate(90deg)" }}
                    >
                      <VerticalAlignTopOutlined />
                    </h4>
                  )}
                  <h3>{t("Contacts")}</h3>
                </div>
                {deleteData.length === 0 ? (
                  <>
                    {/* <Button
                          id="newContact"
                          type="primary"
                          icon={<UserAddOutlined />}
                          onClick={() => {
                            if (onBoardingStatus?.isStarted === true) {
                              setIsOpen(false);
                            }
                            showDrawer();
                          }}
                        >
                          {t('NewContact')}
                        </Button> */}
                    {featureFlag.FilterButton && (
                      <Button
                        type="ghost"
                        icon={
                          <img
                            style={{ marginRight: "8px" }}
                            src={FilterIcon}
                          />
                        }
                        className={
                          styles.ContactsPage__container__contacts__header__right__filterbutton
                        }
                      >
                        {t("Filter")}
                      </Button>
                    )}
                    {featureFlag.ImportButton && (
                      <Button
                        onClick={() => {
                          setShowImportContact(true);
                        }}
                        className={styles.ImportContact}
                      >
                        <CloudUploadOutlined />
                        {t("Import")}
                      </Button>
                    )}

                    {featureFlag.CustomizeField && (
                      <Button type="ghost" style={{ marginLeft: "40px" }}>
                        {t("Customize field")}
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        setIsDeleteModalOpen(true);
                      }}
                      danger
                    >
                      {t("Delete")}
                    </Button>
                  </>
                )}
              </div>
              <div
                className={
                  styles.ContactsPage__container__contacts__header__right
                }
              >
                <div
                  className={
                    styles.ContactsPage__container__contacts__header__right__actionbar
                  }
                >
                  {/* <Button type="ghost" onClick={handleOpenFilterDrawer}>
                      Filter
                    </Button> */}
                  <Button
                    id="newContact"
                    type="primary"
                    size="small"
                    icon={<UserAddOutlined />}
                    onClick={() => {
                      if (onBoardingStatus?.isStarted === true) {
                        setIsOpen(false);
                      }
                      showDrawer();
                    }}
                  >
                    {t("NewContact")}
                  </Button>
                </div>

                {featureFlag.ContactSearch && (
                  <Search
                    placeholder="Search"
                    onSearch={() => {}}
                    style={{
                      width: 320,
                    }}
                  />
                )}
                <Button type="ghost" onClick={handleOpenFilterDrawer}>
                  Filter
                </Button>
                {/* <Button
                          id="newContact"
                          type="primary"
                          icon={<UserAddOutlined />}
                          onClick={() => {
                            if (onBoardingStatus?.isStarted === true) {
                              setIsOpen(false);
                            }
                            showDrawer();
                          }}
                        >
                          {t('NewContact')}
                        </Button> */}
                {/* {featureFlag.FilterButton &&
                    <Button
                      type="ghost"
                      icon={<img style={{ marginRight: "8px" }} src={FilterIcon} />}
                      className={
                        styles.ContactsPage__container__contacts__header__right__filterbutton
                      }>
                      {t('Filter')}
                    </Button>
                  } */}
              </div>
            </div>
            <div
              className={styles.ContactsPage__container__contacts__header}
              style={{
                top:
                  billingData?.getOrganization?.billing !== null &&
                  billingData?.getOrganization?.billing !== undefined
                    ? subscriptionStatus === "trialing"
                      ? "38px"
                      : "0px"
                    : "38px",
              }}
            >
              <div
                className={
                  styles.ContactsPage__container__contacts__header__left
                }
              >
                <div
                  className={
                    styles.ContactsPage__container__contacts__header__left__container
                  }
                >
                  {!isSegmentDrawerOpen && (
                    <h4
                      onClick={handleOpenSegmentDrawer}
                      className={
                        styles.ContactsPage__container__contacts__header__left__container__icon
                      }
                      style={{ transform: "rotate(90deg)" }}
                    >
                      <VerticalAlignTopOutlined />
                    </h4>
                  )}
                  <h3>{t("Contacts")}</h3>
                </div>
                {deleteData.length === 0 ? (
                  <>
                    <Button
                      id="newContact"
                      type="primary"
                      icon={<UserAddOutlined />}
                      onClick={() => {
                        if (onBoardingStatus?.isStarted === true) {
                          setIsOpen(false);
                        }
                        showDrawer();
                      }}
                      style={{ backgroundColor: 'var(--primary-color)' }}
                    >
                      {t("NewContact")}
                    </Button>
                    {featureFlag.ImportButton && (
                      <Button
                        onClick={() => {
                          setShowImportContact(true);
                        }}
                        className={styles.ImportContact}
                      >
                        <CloudUploadOutlined />
                        {t("Import")}
                      </Button>
                    )}

                    {featureFlag.CustomizeField && (
                      <Button type="ghost" style={{ marginLeft: "40px" }}>
                        {t("Customize field")}
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        setIsDeleteModalOpen(true);
                      }}
                      danger
                    >
                      {t("Delete")}
                    </Button>
                  </>
                )}
              </div>
              <div
                className={
                  styles.ContactsPage__container__contacts__header__right
                }
              >
                <div
                  className={
                    styles.ContactsPage__container__contacts__header__right__actionbar
                  }
                >
                  {filtersCount?.length !== 0 &&
                  filtersCount[0]?.category !== null ? (
                      <Badge count={filtersCount?.length} color="#40a9ff">
                        <Button type="ghost" onClick={handleOpenFilterDrawer}>
                        Filter
                        </Button>
                      </Badge>
                    ) : (
                      <Button type="ghost" onClick={handleOpenFilterDrawer}>
                      Filter
                      </Button>
                    )}
                  {/* <span>1</span> */}
                </div>

                {featureFlag.ContactSearch && (
                  <Search
                    placeholder="Search"
                    onSearch={() => {}}
                    style={{
                      width: 320,
                    }}
                  />
                )}
                {featureFlag.FilterButton && (
                  <Button
                    type="ghost"
                    icon={
                      <img style={{ marginRight: "8px" }} src={FilterIcon} />
                    }
                    className={
                      styles.ContactsPage__container__contacts__header__right__filterbutton
                    }
                  >
                    {t("Filter")}
                  </Button>
                )}
              </div>
            </div>
            <div className={styles.ContactsPage__container__contacts__table}>
              <Table
                selectedFilters={selectedFilters}
                fields={fields}
                selectedContacts={deleteData}
                setSelectedContacts={setDeleteData}
                fetchStaffData={fetchStaffData}
                fetchTableData={fetchTableData}
                isSuccess={isSuccess}
                showDetailsDrawer={showDetailsDrawer}
                handleSelectedContact={handleSelectedContact}
                t={t}
                fetchSegmentData={fetchSegmentData}
                // selectedSegment={selectedSegment}
                setIsAttributeDrawerOpen={setIsAttributeDrawerOpen}
                handleDataForCountingSegmentation={
                  handleDataForCountingSegmentation
                }
              />
            </div>
          </div>
          {open === true && (
            <CreateContactDrawer // Create contact drawer
              setIsSuccess={setIsSuccess}
              refetchAttributes={handleRefetchAttributes}
              handleMultiSelectChange={handleMultiSelectChange}
              handleUserChange={handleUserChange}
              handleDateChange={handleDateChange}
              open={open}
              onClose={onClose}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              form={form}
              fields={fields}
              handleChange={handleChange}
              handleDelete={handleDelete}
              handleAddFields={handleAddFields}
              attributesList={fields}
              setAttributeDrawer={setIsAttributeDrawerOpen}
              t={t}
            />
          )}
          {isAttributeDrawerOpen === true && (
            <CreateAttributeDrawer
              showDrawer={isAttributeDrawerOpen}
              setShowDrawer={setIsAttributeDrawerOpen}
              // refetchAttributes={refetchAttributes}
              showContactDrawer={showDrawer}
            />
          )}
          {/* <NewContactDrawer
              refetchAttributes={handleRefetchAttributes}
              handleMultiSelectChange={handleMultiSelectChange}
              handleUserChange={handleUserChange}
              handleDateChange={handleDateChange}
              open={open}
              onClose={onClose}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              form={form}
              fields={fields}
              handleChange={handleChange}
              handleDelete={handleDelete}
              handleAddFields={handleAddFields}
              attributesList={fields}
            /> */}
          <ShowContactDrawer
            staffData={staffData}
            leadsData={leadsData}
            editForm={editForm}
            onDetailsClose={onDetailsClose}
            showEditDetailsDrawer={showEditDetailsDrawer}
            selectedContact={selectedContact}
            open={openDetails}
            onClose={onDetailsClose}
            visible={openDetails}
          />
          {openEditDetails && (
            <EditContactDrawer
              handleMultiSelectChange={handleEditMultiSelectChange}
              handleUserChange={handleEditUserChange}
              handleDateChange={handleEditDateChange}
              open={openEditDetails}
              onClose={onEditDetailsClose}
              onFinish={onEditFinish}
              onFinishFailed={onEditFinishFailed}
              form={editForm}
              fields={editFields}
              handleChange={handleEditChange}
              handleDelete={handleDelete}
              selD={selD?.username}
              t={t}
            />
          )}
          <Modal
            zIndex={123123123}
            title={t("Delete")}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button key="back" onClick={handleCancel}>
                {t("Cancel")}
              </Button>,
              <Button key="submit" type="primary" onClick={handleOk}>
                {t("Delete")}
              </Button>,
            ]}
          >
            <span dangerouslySetInnerHTML={{ __html: `Are you sure you want to remove &quot;${selectDelete.name}&quot; attribute?` }} />

          </Modal>
        </div>
      </div>
      <FilterContactDrawer
        open={isFilterDrawerOpen}
        handleFetchFilters={handleFetchFilters}
        handleFetchFiltersCount={handleFetchFiltersCount}
        onClose={() => setIsFilterDrawerOpen(false)}
        handleOpenSegmentDrawer={handleOpenSegmentDrawer}
      />
    </>
  );
};

ContactsPage.propTypes = {};

ContactsPage.defaultProps = {};

export default ContactsPage;
