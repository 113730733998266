import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Drawer } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import styles from "./CreateCannedTemplateForm.module.scss";
import { Button, Form, Input, Radio, notification, message } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { createCannedTemplate } from "src/api/MessageApi";
import CannedTemplateVariables from "../CannedTemplateVariables/CannedTemplateVariables";
import CreateCannedUpload from "../CreateCannedUpload/CreateCannedUpload";
import PreviewGenerator from "../PreviewGenerator/PreviewGenerator";
import Select from "react-select";
import { getActiveOrgId } from "src/store/slices/userSlice";

const CreateCannedTemplateForm = ({ showDrawer, setShowDrawer }) => {
  const options = [{ value: "Whatsapp", label: "Whatsapp" }];
  const { TextArea } = Input;
  const [submit, setSubmit] = useState(false);

  const organizationId = useSelector(getActiveOrgId);

  const [loading, setLoading] = useState(false);
  const [attachmentList, setAttachmentList] = useState([]);
  const addAttachment = (newAttachmentList) => {
    const new_list = [...attachmentList, ...newAttachmentList];
    setAttachmentList(new_list);
  };
  const [cursorPosition, setCursorPosition] = useState(0);
  const textareaRef = useRef(null);
  const reOrderAttachment = (oldIndex, newIndex) => {
    const prev_list = [...attachmentList];
    var element = prev_list[oldIndex];
    prev_list.splice(oldIndex, 1);
    prev_list.splice(newIndex, 0, element);
    setAttachmentList(prev_list);
  };
  const removeAttachmentByIndex = (value) => {
    const prev_list = [...attachmentList];
    const index = prev_list.indexOf(value);
    if (index === -1) return;
    prev_list.splice(index, 1);
    setAttachmentList(prev_list);
  };
  const insertTextAtPlace = (label) => {
    const new_text = values.message + label + " ";
    setFieldValue("message", new_text);
  };
  const textAreaChanged = (e) => {
    setCursorPosition(e.target.selectionStart);
  };
  const handleCreateTemplateSubmit = async (values, resetForm) => {
    try {
      setLoading(true);
      let formData = new FormData();
      formData.append("title", values.title);
      formData.append("content", values.message);
      formData.append("organizationId", organizationId);
      for (let i = 0; i < attachmentList.length; ++i) {
        formData.append("attachment", attachmentList[i]);
      }
      let res = await createCannedTemplate(formData);
      let data = res?.data;
      if (data?.success === false) {
        notification.error({
          message: "Notification",
          description: data?.message,
        });
      } else {
        message.success(`"${values.title}" canned response created`);
        setShowDrawer(false);
        resetForm();
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      notification.error({
        message: "Notification",
        description: "Something goes wrong!!!",
      });
      setLoading(false);
      return;
    }
  };

  const { values, handleSubmit, handleChange, errors, setFieldValue } =
    useFormik({
      initialValues: {
        title: "",
        message: "",
      },
      validationSchema: Yup.object().shape({
        title: Yup.string()
          .required("name cannot be empty")
          .test(
            "template_validation",
            "name should start with /",
            function (value) {
              if (value === null || value === undefined || value.length === 0)
                return true;
              if (value[0] !== "/") return false;
              return true;
            }
          ),
        message: Yup.string().required("Message cannot be empty"),
      }),
      onSubmit: (values, { resetForm }) => {
        handleCreateTemplateSubmit(values, resetForm);
      },
    });

  const LabelStyle = {
    fontSize: "16px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "24px",
    color: "#262626",
    paddingBottom: "0px",
    marginBottom: "0px",
  };

  return (
    <Drawer
      className={styles.CreateCannedTemplateForm__drawer}
      headerStyle={{ display: "none" }}
      title="Add Canned Response"
      placement="right"
      open={showDrawer}
      onClose={() => setShowDrawer(false)}
    >
      <div className={styles.CreateCannedTemplateForm}>
        <div className={styles.CreateCannedTemplateForm__Header}>
          <div className={styles.CreateCannedTemplateForm__Header__Title}>
            Add Canned Response
          </div>
          <div className={styles.CreateCannedTemplateForm__Header__Close}>
            <CloseOutlined
              className={styles.CreateCannedTemplateForm__cursor}
              onClick={() => {
                setShowDrawer(false);
              }}
            />
          </div>
        </div>

        <div className={styles.CreateCannedTemplateForm__Body}>
          <div className={styles.CreateCannedTemplateForm__Body__Form}>
            <Form layout="vertical" onFinish={handleSubmit}>
              <Form.Item
                label={
                  <p style={LabelStyle}>
                    Response name <span style={{ color: "#CF1322" }}>*</span>
                  </p>
                }
              >
                <Input
                  placeholder="Enter Reply name"
                  name="title"
                  onChange={handleChange}
                  value={values.title}
                  data-testid="titleInput"
                />
                {submit && errors.title ? (
                  <div
                    className={styles.CreateCannedTemplateForm__Error__Title}
                  >
                    <small>{errors.title}</small>
                  </div>
                ) : null}
              </Form.Item>
              <Form.Item label={<p style={LabelStyle}>Response content</p>}>
                <TextArea
                  onChange={(e) => {
                    textAreaChanged(e);
                    handleChange(e);
                  }}
                  ref={textareaRef}
                  name="message"
                  value={values.message}
                  placeholder="Eg: Hi {{name}}! your order is placed successfully. Thank you! Warm regards, {{operator name}}"
                />
                {submit && errors.message ? (
                  <div
                    className={styles.CreateCannedTemplateForm__Error__Message}
                  >
                    <small>{errors.message}</small>
                  </div>
                ) : null}
              </Form.Item>
              <CannedTemplateVariables insertTextAtPlace={insertTextAtPlace} />
              <CreateCannedUpload
                attachmentList={attachmentList}
                addAttachment={addAttachment}
                removeAttachmentByIndex={removeAttachmentByIndex}
                reOrderAttachment={reOrderAttachment}
              />
              {/* 
              <div className={styles.CreateCannedTemplateForm__Info}>
                <div className={styles.CreateCannedTemplateForm__Info__Icon}>
                  <InfoCircleOutlined color="#262626" />
                </div>
                <p>Enter <span>{"{{name}}"}</span> to automatically fill in the recipient's user name &
                  <span>{" {{operator name}}"}</span>  to automatically fill in the agent name</p>
              </div> */}
              {/* <div className={styles.CreateCannedTemplateForm__MediaContainer}>
                <div className={styles.CreateCannedTemplateForm__MediaContainer__Label}>
                  Media
                </div>
                <div className={styles.CreateCannedTemplateForm__MediaContainer__FileField}>
                  <Button type="primary">Upload</Button>
                </div>
                <div className={styles.CreateCannedTemplateForm__MediaContainer__Info}>
                  Maximum file size should not exceed 16MB
                </div>
              </div> */}
              <div className={styles.CreateCannedTemplateForm__ButtonContainer}>
                <Button
                  type="secondary"
                  onClick={() => {
                    setShowDrawer(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  disabled={loading}
                  htmlType="submit"
                  onClick={() => setSubmit(true)}
                  style={{backgroundColor:'var(--primary-color)'}}
                >
                  Save
                </Button>
              </div>
            </Form>
          </div>
          <div className={styles.CreateCannedTemplateForm__Body__Preview}>
            <div
              className={styles.CreateCannedTemplateForm__Body__Preview__Title}
            >
              <div
                className={
                  styles.CreateCannedTemplateForm__Body__Preview__Title__Header
                }
              >
                Preview
              </div>
              <div
                style={{ width: "122px", height: "32px", marginBottom: "16px" }}
              >
                <Select
                  options={options}
                  defaultValue={{ value: "Whatsapp", label: "Whatsapp" }}
                  styles={{
                    indicatorSeparator: () => ({ display: "none" }),
                  }}
                  isDisabled={true}
                />
              </div>
            </div>
            <PreviewGenerator
              attachmentList={attachmentList}
              message={values.message}
            />
            {/* <div className={styles.CreateCannedTemplateForm__Body__Preview__Body}>
              {values.message}
            </div> */}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

CreateCannedTemplateForm.propTypes = {};

CreateCannedTemplateForm.defaultProps = {};

export default CreateCannedTemplateForm;
