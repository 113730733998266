import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./CreateMessageTemplate.module.scss";
import { Drawer, Divider, Form, Input, Select, Alert, Button, Space } from "antd";

const CreateMessageTemplate = ({ title, placement, onClose, open, form, nameValue, onFinish, onFinishFailed }) => {
  const { Option } = Select;
  const { TextArea } = Input;
  const [text, setText] = useState("");
  // const [form] = Form.useForm();

  // const onReset = () => {
  //   form.resetFields();
  // };
  const onInputChange = (e) => {
    setText(e.target.value)
  }
  return (
    <Drawer title={title} width={850} placement={placement} onClose={onClose} open={open}>
      <div className={styles.CreateMessageTemplate__container}>
        <div className={styles.CreateMessageTemplate__container__form}>
          <Form
            name="basic"
            form={form}
            layout={"vertical"}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Template Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your Template Name!",
                },
              ]}
            >
              <Input allowClear placeholder="Enter Template name" />
            </Form.Item>
            <Form.Item
              label="Template Category"
              name="category"
              rules={[
                {
                  required: true,
                  message: "Please select your category!",
                },
              ]}
            >
              <Select
                placeholder="Select a Category"
                allowClear>
                <Option value="auto_reply">Auto Reply</Option>
                <Option value="account_update">Account Update</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Template Language"
              name="language"
              rules={[
                {
                  required: true,
                  message: "Please select your language!",
                },
              ]}>
              <Select
                placeholder="Select a Language"
                allowClear
              >
                <Option value="English">English</Option>
                <Option value="Latin">Latin</Option>
                <Option value="Arabic">Arabic</Option>
                <Option value="Hindi">Hindi</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Template Content"
              name="body"
              rules={[
                {
                  required: true,
                  message: "Please write your template!",
                },
              ]}>
              <TextArea
                rows={4}
                placeholder="Eg: Hi {{name}}! your order is placed successfully. Thank you! Warm regards, {{operator name}}"
                allowClear
              />
            </Form.Item>
            <Alert
              message="Enter {{name}} to automatically fill in the recipient’s user name &
{{operator name}}  to automatically fill in the agent name"
              type="info"
              showIcon />
            <Form.Item
              label="Upload">
              <Button>Button</Button>
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 0,
                span: 24,
              }}
            >
              <Space type="small">
                <Button
                  type="default"
                  htmlType="button"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit">
                  Save and Submit
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
        <Divider type="vertical" style={{ height: "100%", margin: "25px" }} />
        <div className={styles.CreateMessageTemplate__container__preview}>
          <h4>Preview</h4>
          <div className={styles.CreateMessageTemplate__container__preview__content}>
            <pre>{nameValue}</pre>
          </div>
        </div>
      </div>
    </Drawer>
  )
};

CreateMessageTemplate.propTypes = {};

CreateMessageTemplate.defaultProps = {};

export default CreateMessageTemplate;
