import React from "react"

const ContactsIcon = ({isActive,...rest}) => {
  return (
    <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_10616_204016" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x={0} y={1} width={22} height={20}>
        <path fillRule="evenodd" clipRule="evenodd" d="M1.34243 14.1758C2.20197 13.3163 3.36776 12.8334 4.58333 12.8334H11.9167C13.1322 12.8334 14.298 13.3163 15.1576 14.1758C16.0171 15.0353 16.5 16.2011 16.5 17.4167V19.25C16.5 19.7563 16.0896 20.1667 15.5833 20.1667C15.0771 20.1667 14.6667 19.7563 14.6667 19.25V17.4167C14.6667 16.6874 14.3769 15.9879 13.8612 15.4722C13.3455 14.9564 12.646 14.6667 11.9167 14.6667H4.58333C3.85399 14.6667 3.15452 14.9564 2.63879 15.4722C2.12306 15.9879 1.83333 16.6874 1.83333 17.4167V19.25C1.83333 19.7563 1.42293 20.1667 0.916667 20.1667C0.410406 20.1667 0 19.7563 0 19.25V17.4167C0 16.2011 0.482886 15.0353 1.34243 14.1758Z" fill="#061938" />
        <path fillRule="evenodd" clipRule="evenodd" d="M8.24996 3.66671C6.73118 3.66671 5.49996 4.89792 5.49996 6.41671C5.49996 7.93549 6.73118 9.16671 8.24996 9.16671C9.76874 9.16671 11 7.93549 11 6.41671C11 4.89792 9.76874 3.66671 8.24996 3.66671ZM3.66663 6.41671C3.66663 3.8854 5.71865 1.83337 8.24996 1.83337C10.7813 1.83337 12.8333 3.8854 12.8333 6.41671C12.8333 8.94801 10.7813 11 8.24996 11C5.71865 11 3.66663 8.94801 3.66663 6.41671Z" fill="#061938" />
        <path fillRule="evenodd" clipRule="evenodd" d="M17.4458 13.64C17.5724 13.1498 18.0724 12.855 18.5626 12.9816C19.5458 13.2355 20.4169 13.8087 21.0391 14.6113C21.6613 15.4139 21.9993 16.4005 22.0001 17.416L22.0001 19.25C22.0001 19.7563 21.5897 20.1667 21.0834 20.1667C20.5771 20.1667 20.1667 19.7563 20.1667 19.25L20.1667 17.4173C20.1667 17.4173 20.1667 17.4174 20.1667 17.4173C20.1662 16.8081 19.9634 16.2161 19.5902 15.7346C19.2169 15.253 18.6942 14.909 18.1042 14.7567C17.614 14.6302 17.3193 14.1302 17.4458 13.64Z" fill="#061938" />
        <path fillRule="evenodd" clipRule="evenodd" d="M13.7786 2.64179C13.9042 2.15135 14.4036 1.85556 14.894 1.98114C15.8799 2.23357 16.7537 2.80694 17.3778 3.61087C18.0018 4.41479 18.3405 5.40355 18.3405 6.42124C18.3405 7.43894 18.0018 8.42769 17.3778 9.23161C16.7537 10.0355 15.8799 10.6089 14.894 10.8613C14.4036 10.9869 13.9042 10.6911 13.7786 10.2007C13.653 9.71025 13.9488 9.21088 14.4393 9.0853C15.0308 8.93385 15.5551 8.58982 15.9295 8.10746C16.3039 7.62511 16.5072 7.03186 16.5072 6.42124C16.5072 5.81062 16.3039 5.21737 15.9295 4.73502C15.5551 4.25266 15.0308 3.90864 14.4393 3.75718C13.9488 3.63161 13.653 3.13223 13.7786 2.64179Z" fill="#061938" />
      </mask>
      <g mask="url(#mask0_10616_204016)">
        <rect width={22} height={22} fill={isActive ? "white" : "#262626"} />
      </g>
    </svg>
  
  )
}

export default ContactsIcon