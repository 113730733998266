import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import fr_FR from "antd/es/locale/fr_FR";
import it_IT from "antd/es/locale/it_IT";
import hi_IN from "antd/es/locale/hi_IN";
import ar_EG from "antd/es/locale/ar_EG";
import en_US from "antd/es/locale/en_US";

import { useSelector } from "react-redux";
import { useEffect } from "react";

const Localization = () => {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(Backend)
    .init({
      fallbackLng: "en_US",

      interpolation: {
        escapeValue: false,
      },
    });

  const lang = useSelector((store) => store?.language?.language);

  const languages = {
    ar_EG: ar_EG,
    en_US: en_US,
    hi_IN: hi_IN,
    it_IT: it_IT,
    fr_FR: fr_FR,
  };

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  return languages[lang];
};

export default Localization;
