import React from "react";
import { useEffect } from "react";

const SuppressResizeObserver = () => {
  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message.toLocaleLowerCase().includes("resizeobserver loop")) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  return <></>;
};

export default SuppressResizeObserver;
