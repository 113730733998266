import React from 'react';
import styles from './WorkspaceWelcomeMessage.module.scss' // Assuming you have a styles module

const WorkspaceWelcomeMessage = () => {
    return (
        <div className={styles.defaultImagePosition}>
            <h1 style={{ display: 'flex', alignItems: 'center' }}>
                Head to the <mark style={{ color: '#055b7f', backgroundColor: '#ffffff' }}> workspace</mark> section to get started
            </h1>
        </div>
    );
};

export default React.memo(WorkspaceWelcomeMessage);