import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  data: {
    globalTimerRunning: false,
    totalElapsedTime: 0,
    nodeTimers: {},
    loadingTimers: true, 
    errorFetchingTimers: true, 
  },
  error: null,
};

const timerSlice = createSlice({
  name: "timer",
  initialState,
  reducers: {
    resetTimer: (state) => Object.assign(state, initialState),
    incrementElapsedTime: (state, action) => {
      state.data.totalElapsedTime = state.data.totalElapsedTime + 1;
    },
    setTotalElapsedTime: (state, action) => {
      state.data.totalElapsedTime = action.payload;
    },
    setNodeTimers: (state, action) => {
      state.data.nodeTimers = action.payload;
    },
    setGlobalTimerRunning(state, action) {
      state.data.globalTimerRunning = action.payload;
    },
    setLoadingTimers: (state, action) => { 
      state.data.loadingTimers = action.payload;
  },
    setErrorFetchingTimers: (state, action) => { 
      state.data.errorFetchingTimers = action.payload;
  },
  },
});

export const {
  resetTimer,
  incrementElapsedTime,
  setGloabalTimerRunning,
  setTotalElapsedTime,
  setNodeTimers,
  setGlobalTimerRunning,
  setLoadingTimers,
  setErrorFetchingTimers,
} = timerSlice.actions;
export default timerSlice.reducer;
