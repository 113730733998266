import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { InfoCircleOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import styles from "./NormalAttributeInlineEditingField.module.scss";
import { Input, Tooltip, message, Form } from "antd";
import { patterns } from "../../../../helpers/patterns";
const NormalAttributeInlineEditingField = ({
  attributeId,
  label,
  initialValue,
  onSubmit,
  onDeleteForm,
  attributeType,
  isDefault
}) => {
  const Actions = {
    Add_Field: "add_field",
    Update_Value: "update_value"
  };

  const [state, setState] = useState([{
    id: 0,
    value: "",
    name: label,
    type: attributeType,
    errorMessage: "",
    attributeId: attributeId
  }]);
  const [showAdd,setShowAdd] = useState(false)
  const [canUpdate,setCanUpdate] = useState(false)


  const UpdateState = (action) => {
    const { type, payload } = action;
    if (!type || !payload) {
      return;
    }
    const currentState = [...state];
    
    switch (type) {
    case Actions.Add_Field:
      currentState.push({
        id: Math.random(),
        value: "",
        name: label,
        type: attributeType,
        errorMessage: "",
        attributeId: attributeId,
      });
      break;
    case Actions.Update_Value:
      const index = currentState.findIndex((tx) => tx.id === payload.id);
      currentState[index].value = payload.value;
      currentState[index].errorMessage = payload.errorMessage;
      // onSubmit(currentState, label);
      break;
    default:
      break;
    }
    
    setState(currentState);
    setCanUpdate(true)
  };

  // useState(()=>{
  //   UpdateState({ type: Actions.Add_Field, payload: "text" })
  // },[])
  



  useEffect(() => {
    if(canUpdate){
      const timer = setTimeout(() => {
        // fakeApi()
        // console.log(state,label)
        onSubmit(state,label)
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [state])
  

    
  useEffect(() => {
    if (!initialValue) {
      // console.log("first")
      return
    }
    if(initialValue?.length > 0){
      let prefillState = [];
      initialValue.map((tx) => {
        prefillState.push({
          id: tx?.id,
          value: tx?.value,
          name: label,
          type: attributeType,
          errorMessage: "",
          attributeId: attributeId
        });
      });
      // console.log("second",prefillState)
      setState(prefillState);
    }
  }, [initialValue]);

  useEffect(()=>{
    // console.log(initialValue?.length ==0,label,attributeType,attributeId)
    if(initialValue?.length ==0 && label!=="" && attributeType !== "" && attributeId!==""){
      // console.log("eyfgdsauyfu ydsbfuyadsgb fuysb ")
      // return;
      let prefillState = [];
      // initialValue.map((tx) => {
      prefillState.push({
        id: Math.random(),
        value: "",
        name: label,
        type: attributeType,
        errorMessage: "",
        attributeId: attributeId,
      });
      // });
      // console.log("third",prefillState)
      setState(prefillState);
    }
  },[initialValue,label,attributeType,attributeId])

  const handleErrorMessage = (c) => {
    switch (c) {
    case "Text":
      return "The attribute must be a text";
    case "Numbers":
      return "The attribute must be a number";
    case "PhoneNumber":
      return "The attribute must be a Phone number";
    case "EmailAddress":
      return "Enter a valid email address";
    case "SocialMedia":
      return "Enter a valid username";
    case "URL":
      return "Enter a valid URL";
    }
  }

  // console.log(state)


  return (
    <div 
    // ref={containerRef} 
    // onMouseOver={handleMouseOver}
    //   onMouseLeave={handleMouseLeave}
      className={styles.NormalAttributeInlineEditingField}>
      <div className={styles.NormalAttributeInlineEditingField__AttributeContainer}>
        <div className={styles.NormalAttributeInlineEditingField__Label}>
          {label}
        </div>
       
        <div className={styles.NormalAttributeInlineEditingField__IconContainer}>
          <Tooltip title={`Source: Internal (${attributeType})`}>
            <InfoCircleOutlined className={styles.NormalAttributeInlineEditingField__InfoIcon} />
          </Tooltip>
        </div>
      </div>
      <div className={styles.NormalAttributeInlineEditingField__FieldContainer}>
        <div className={styles.NormalAttributeInlineEditingField__FieldContainer__box} >
          {state?.map((tx) =>
            <>
              <Input
                className={
                  tx?.errorMessage === "" ?
                    `${styles.NormalAttributeInlineEditingField__InputField}` :
                    `${styles.NormalAttributeInlineEditingField__InputField} ${styles.NormalAttributeInlineEditingField__DeleteUnderline}`
                }
                key={tx?.id}
                placeholder={`Enter ${attributeType}`}
                onChange={(e) => {
                  const pattern = patterns[attributeType];
                  if (!pattern) { return; }
                  UpdateState({
                    type: Actions.Update_Value,
                    payload: {
                      id: tx?.id,
                      value: e?.target?.value,
                      errorMessage: pattern.test(e?.target?.value) ? "" : handleErrorMessage(attributeType)
                    }
                  });
                  setShowAdd(true)
                }}
                defaultValue={tx?.value}
                // className={styles.NormalAttributeInlineEditingField__InputField}
                type="text" />
              <div className={styles.ErrorMessage}>{tx?.errorMessage}</div>
            </>
          )
          }
          {(showAdd || (state?.length > 0 && state[0]?.value !== "")) && <div onClick={() => UpdateState({ type: Actions.Add_Field, payload: "text" })} className={styles.NormalAttributeInlineEditingField__AddMore}>
            {/* <PlusOutlined /> */}

            <span>
              +
              Add
            </span>
          </div>}
        </div>
        {/* {
          <div className={styles.NormalAttributeInlineEditingField__FieldContainer__FieldTrigger}>
            {state?.map((tx) => <p className={styles.NormalAttributeInlineEditingField__PlaceholderText}>{tx?.value}</p>)}
          </div>
        }
 */}

      </div>

    </div>
  );
}
NormalAttributeInlineEditingField.propTypes = {};

NormalAttributeInlineEditingField.defaultProps = {};

export default NormalAttributeInlineEditingField;
