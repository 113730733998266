import React, { useState, useEffect, useRef } from "react";
// import { Tree } from "react-arborist";
import styles from "./NodeShell.module.scss";
import { Collapse, notification } from "antd";
import Chat from "../../../Chat/Chat";
import "antd/dist/reset.css";
import { v4 as uuidv4 } from "uuid";
import { useLazyQuery } from "@apollo/client";
import { createNode, getNestedNodes } from "src/api/NodeApi";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import featureFlag from "src/helpers/featureFlag";
import OfflineBadge from "../OfflineBadge/OfflineBadge";
import { useMutation, useQuery } from "@apollo/client";
import ModalBox from "../ModalBox/ModalBox";
import SearchBar from "../SearchBar/SearchBar";
import NodeTree from "../NodeTree/NodeTree";
import AddNodeModal from "../NodeModal/AddNodeModal";
import { setNodeId, setNodeList, setProjectWikiLink, setSelectedNode } from "src/store/slices/nodeSlice";
import {  setGloabalTimerRunning, setGlobalTimerRunning,} from "src/store/slices/timerSlice";
import ProfileDropdown from "./ProfileDropdown/ProfileDropdown";

import {
  CHECK_NODE_EXIST,
  PIN_NODE,
  QUERY_PINNED_NODES,
  UNPIN_NODE,
} from "src/graphql/node";
import { getActiveOrg, getActiveOrgId, getActiveStaffOrg } from "src/store/slices/userSlice";
import TimerDetails from "./TimerDetails/TimerDetails";
import WikiContent from "./WikiContent/WikiContent";
import WorkspaceWelcomeMessage from "./WorkspaceWelcomeImage/WorkspaceWelcomeMessage";
import { UPDATE_TIMER_STATUS } from "src/graphql/timer";

const NodeShell = () => {
  const [addPinnedNode] = useMutation(PIN_NODE);
  const [removePinnedNode] = useMutation(UNPIN_NODE);
  const [disabled, setDisabled] = useState(true);
  const [pinnedNodes, setPinnedNodes] = useState([]);
  const [data, setData] = useState([]);
  const [WorkspaceProjectClicked, setWorkspaceProjectClicked] = useState(true);
  const [FavoritesProjectClicked, setFavoritesProjectClicked] = useState(false);
  const [treeHeight, setTreeHeight] = useState(window.innerHeight * 0.8);
  const [splitPaneSize, setSplitPaneSize] = useState(window.innerWidth * 0.7);
  const [activeButton, setActiveButton] = useState("wiki");
  const [pinningNode, setPinningNode] = useState(false);
  const [unPinningNode, setUnPinningNode] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredFavorites, setFilteredFavorites] = useState([]);
  const [idleStatus, setIdleStatus] = useState(false);
  const [trackerStatus,setTrackerStatus] = useState()
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [keepIdleChecked, setKeepIdleChecked] = useState(false);
  const [idleStartTime, setIdleStartTime] = useState();
  const [idleDuration, setIdleDuration] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [prevWikiLink, setPrevWikiLink] = useState(null);
  const prevWikiLinkRef = useRef(null);
  const [pinnedPanelHeight, setPinnedPanelHeight] = useState("auto");
  const [sortedPinnedNodes, setSortedPinnedNodes] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [updateTimerStatus] = useMutation(UPDATE_TIMER_STATUS);



  const [nodesToDisplay, setNodesToDisplay] = useState(
    searchQuery.trim() === "" ? data : filteredData
  );
  const [favoritesToDisplay, setFavoritesToDisplay] = useState(
    searchQuery.trim() === "" ? pinnedNodes : filteredFavorites
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const organizationId = useSelector(getActiveOrgId);

  const getActiveStaffOrganization = useSelector(getActiveStaffOrg);
  const staffOrganizationId = getActiveStaffOrganization.id;
  const {selectedNode,nodeId:projectId,nodeList:storedNodeList,projectWikiLink} = useSelector((state) => state?.node?.data);

  const {data: { nodeView: nodeViewEnabled },} = useSelector((state) => state?.user);

  const {globalTimerRunning,errorFetchingTimers} = useSelector((state) => state?.timer?.data)


  const { data: { userDetails, activeOrg } = {} } = useSelector((state) => state.userDetails) || {};

  const userData = useSelector((state) => state.user.data);
  const staffId = userData.id;

  useEffect(() => {
    const handleResize = () => {
      setTreeHeight(window.innerHeight * 0.8);
      setSplitPaneSize(window.innerWidth * 0.7);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getNodeList();
  }, []);

  useEffect(() => {
    // Set initial height
    const contentHeight = calculateContentHeight();
    setPinnedPanelHeight(contentHeight);
  }, [pinnedNodes]);


  useEffect(() => {
    setNodesToDisplay(searchQuery.trim() === "" ? data : filteredData);
  }, [filteredData, data]);

  useEffect(() => {
    setFavoritesToDisplay(
      searchQuery.trim() === "" ? pinnedNodes : filteredFavorites
    );
  }, [filteredFavorites, pinnedNodes]);

  const setSelectedNodeID = async () => {
    if (window.pywebview && window.pywebview.api) {
      await window.pywebview.api.set_selected_node_id(projectId);
    }
  };
  useEffect(() => {
    setSelectedNodeID();
  }, [projectId]);

  

  useEffect(() => {
    const storedSelectedNode = selectedNode;
    if (storedSelectedNode) {
      dispatch(setSelectedNode(storedSelectedNode));
    }
  }, []);

  useEffect(() => {
    sortPinnedNodes();
  }, [pinnedNodes]);

  useEffect(() => {
    if (staffId) {
      fetchPinnedNodes(staffId);
    }
  }, [data]);

  useEffect(() => {
    if (!nodeViewEnabled) {
      navigate("/settings/interfaceOptions");
    }
  }, [nodeViewEnabled]);

  useEffect(() => {
    const interval = setInterval(stopTimerOnIdle, 10000);
    return () => clearInterval(interval);
  }, [idleStatus, idleStartTime]);

  const { Panel } = Collapse;
  const debounceSetWikiLink = useRef(
    debounce((url) => {
     dispatch(setProjectWikiLink(url));
    }, 500000)
  ).current;
  
  useEffect(() => {
    if (selectedNode) {
      const storedSelectedNode = selectedNode;
      const selectedNodeChanged =
        selectedNode.id !==
          (storedSelectedNode ? storedSelectedNode.id : null) ||
        selectedNode.url !==
          (storedSelectedNode ? storedSelectedNode.url : null);

      if (selectedNodeChanged && projectWikiLink !== selectedNode.url) {
        debounceSetWikiLink(selectedNode.url);
      }
    }
  }, [selectedNode, projectWikiLink]);

  useEffect(() => {
    prevWikiLinkRef.current = prevWikiLink;
    setPrevWikiLink(projectWikiLink);
  }, [projectWikiLink]);

  const {
    data: pinnedNodesData,
    Pinndloading,
    Pinnederror,
    refetch: getPinnedNodes,
  } = useQuery(QUERY_PINNED_NODES, {
    variables: { staffId: staffId || null },
  });

  const fetchPinnedNodes = async (staffId) => {
    try {
      const { data: updatedPinnedNodesData } = await getPinnedNodes({
        staffId,
      });

      if (!updatedPinnedNodesData || !updatedPinnedNodesData?.getHC_Staff) {
        return;
      }

      const pinnedNodesFromData =
        updatedPinnedNodesData?.getHC_Staff?.pinnedNodes;

      if (pinnedNodesFromData && Array.isArray(pinnedNodesFromData)) {
        const updatedPinnedNodes = [];
        const findPinnedNodesRecursively = (nodes) => {
          for (const node of nodes) {
            const matchedNode = pinnedNodesFromData.find(
              (item) => item.uniqueIdentifier === node.uniqueKey
            );

            if (matchedNode) {
              const { children, ...nodeWithoutChildren } = node;
              updatedPinnedNodes.push(nodeWithoutChildren);
            }

            if (node.children && node.children.length > 0) {
              findPinnedNodesRecursively(node.children);
            }
          }
        };

        findPinnedNodesRecursively(data);
        setPinnedNodes([...updatedPinnedNodes]);
      }
    } catch (error) {
      console.error("Error fetching pinned nodes:", error);
    }
  };

  // Function to handle adding a node to the pinned list
  const pinNode = async (staffId, uniqueId) => {
    try {
      // Adding pinned node
      const result = await addPinnedNode({
        variables: {
          staffId: staffId,
          nodeId: uniqueId,
        },
        update: (cache, { data }) => {},
      });
    } catch (error) {
      console.error("Error pinning node:", error);
    }
  };

  // Function to handle removing a node from the pinned list
  const unpinNode = async (staffId, uniqueId) => {
    try {
      // Adding pinned node
      const result = await removePinnedNode({
        variables: {
          staffId: staffId,
          nodeId: uniqueId,
        },
        update: (cache, { data }) => {},
      });
    } catch (error) {
      console.error("Error unpinning node:", error);
    }
  };

  const sortNodesRecursively = (nodes) => {
    nodes.forEach((node) => {
      if (node.children && node.children.length > 0) {
        const categories = node.children.filter(
          (child) => child.type === "Sub Category"
        );
        const articles = node.children.filter(
          (child) => child.type === "Article"
        );

        const sortedCategories = categories.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        const sortedArticles = articles.sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        const sortedChildren = [
          ...sortedCategories.map((category, index) => ({
            ...category,
            id: `${uuidv4()}_${category.id}_${index}`,
          })),
          ...sortedArticles.map((article, index) => ({
            ...article,
            id: `${uuidv4()}_${article.id}_${index}`,
          })),
        ];
        node.children = sortedChildren;

        sortNodesRecursively(node.children);
      }
    });
  };

  const setUniqueKeysRecursively = (nodes, parentKey) => {
    nodes.forEach((node, index) => {
      const uniqueKey = parentKey ? `${parentKey}/${node.name}` : node.name;
      node.uniqueKey = uniqueKey;
      if (node.children && node.children.length > 0) {
        setUniqueKeysRecursively(node.children, uniqueKey);
      }
    });
  };

  const getNodeList = async () => {
    try {
      if (storedNodeList.length > 0) {
        setData(storedNodeList);
        return;
      }

      let response = await getNestedNodes();
      if (response.data.message) {
        notification.error({
          message: <b>Notification</b>,
          description: <b>{response?.data?.message}</b>,
        });
        return;
      }
      if (Array.isArray(response?.data)) {
        const categories = response?.data?.filter(
          (node) => node.type === "Sub Category"
        );
        const articles = response?.data?.filter(
          (node) => node.type === "Article"
        );

        const sortedCategories = categories.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        const sortedArticles = articles.sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        const sortedCategoriesWithUniqueIds = sortedCategories.map(
          (category, index) => ({
            ...category,
            uniqueKey: `${category.name}`,
            id: `${uuidv4()}_${category.id}_${index}`,
          })
        );
        const sortedArticlesWithUniqueIds = sortedArticles.map(
          (article, index) => ({
            ...article,
            uniqueKey: `${article.name}`,
            id: `${uuidv4()}${article.id}_${index}`,
          })
        );
        const sortedNodes = [
          ...sortedCategoriesWithUniqueIds,
          ...sortedArticlesWithUniqueIds,
        ];
        sortNodesRecursively(sortedNodes);

        // Set unique keys recursively
        setUniqueKeysRecursively(sortedNodes, "");

        dispatch(setNodeList(sortedNodes))

        setData(sortedNodes);
      }
    } catch (error) {
      console.log("error from getNodeList ==>"),error;
    }
  };

  const createNodeHandler = async () => {
    try {
      let data = {
        title: selectedNode.name,
        uniqueIdentifier: selectedNode.uniqueKey,
        staffOrganizationId, //TODO
      };
      let response = await createNode(data);
      let createdNodeId = response?.data?.node?.id;
      if (!createdNodeId) {
        return false;
      }
      if (pinningNode) {
        pinNode(staffId, createdNodeId);
      } else if (unPinningNode) {
        unpinNode(staffId, createdNodeId);
      }
      dispatch(setNodeId(createdNodeId));
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const [checkNodeExits, { loading, error, newData }] = useLazyQuery(
    CHECK_NODE_EXIST,
    {
      onCompleted: (newData) => {
        if (!newData) {
          return;
        }
        const nodeExists = newData?.getOrganization?.nodes?.some((node) => {
          if (node.uniqueIdentifier === selectedNode.uniqueKey) {
            dispatch(setNodeId(node.id));
            if (pinningNode) {
              pinNode(staffId, node.id);
            } else if (unPinningNode) {
              unpinNode(staffId, node.id);
            }
            return true;
          }
          return false;
        });

        if (!nodeExists) {
          console.log("!nodeExists")
          return createNodeHandler();
        } else {
          if (!projectId) {
            return;
          }
        }
        // navigate(`/node/${newData?.getOrganization?.nodes[0]?.id}`);
      },
      fetchPolicy: "no-cache",
    }
  );

  const addNodeToPinned = async (addItem) => {
    let Item = { ...addItem };
    try {
      delete Item.children;
      if (pinnedNodes.findIndex((item) => item.id === Item.id) >= 0) {
        setUnPinningNode(true);
        const { data } = await checkNodeExits({
          variables: {
            organizationId: organizationId,
            uniqueId: Item.uniqueKey,
          },
        });
        setPinnedNodes(pinnedNodes.filter((item) => item.id !== Item.id));
        notification.open({
          message: "Success: Project has been removed from your favorites.",
        });
      } else {
        setPinningNode(true);
        const { data } = await checkNodeExits({
          variables: {
            organizationId: organizationId,
            uniqueId: Item.uniqueKey,
          },
        });
        setPinnedNodes([...pinnedNodes, Item]);
        notification.open({
          message: "Success: Project has been added to your favorites.",
        });
      }
    } catch (error) {
      console.error("Error checking node existence:", error);
      // Handle the error here
    } finally {
      setPinningNode(false);
      setUnPinningNode(false);
    }
  };

  const handleNodeClick = async (nodeId, header) => {
    dispatch(setGlobalTimerRunning(false))
    if (header === "Workspace") {
      setWorkspaceProjectClicked(true);
      setFavoritesProjectClicked(false);
    } else {
      setFavoritesProjectClicked(true);
      setWorkspaceProjectClicked(false);
    }
    setDisabled(false);
    try {
      await checkNodeExits({
        variables: {
          organizationId: organizationId,
          uniqueId: nodeId,
        },
      });
      setTimeout(() => {
        if(globalTimerRunning){
          dispatch(setGlobalTimerRunning(true))
        }
      },1000)
      
    } catch (error) {
      console.log("Error handling node click:", error);
    }
  };


  const calculateContentHeight = () => {
    if (pinnedNodes.length <= 0) {
      return 0;
    }
    const pinnedNodeHeight = 30;
    const totalHeight = pinnedNodes.length * pinnedNodeHeight;
    const extraSpace = 30;
    return totalHeight + extraSpace;
  };

  const sortPinnedNodes = () => {
    const categories = pinnedNodes.filter(
      (node) => node.type === "Sub Category"
    );
    const articles = pinnedNodes.filter((node) => node.type === "Article");

    const sortedCategories = categories.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    const sortedArticles = articles.sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    const sortedNodes = [...sortedCategories, ...sortedArticles];
    setSortedPinnedNodes(sortedNodes);
  };
  

  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    if (
      typeof window !== 'undefined' && window.pywebview && window.pywebview.api && keepIdleChecked) {
      let { set_keep_idle_time, set_idle_status } = window.pywebview.api;
      if (set_keep_idle_time && set_idle_status) {
        await set_keep_idle_time(idleStartTime, keepIdleChecked);
        setKeepIdleChecked(false);
        setIdleStatus(false);
        await set_idle_status(false);
      }
    }
    setConfirmLoading(false);
  };


  const stopTimerOnIdle = async () => {
    if (window.pywebview && window.pywebview.api) {
      const idleStatus = await window.pywebview.api.get_idle_status();
        if (idleStatus && idleStatus.idleStartTime) {
        const currentTime = new Date().getTime();
        const idleStart = new Date(idleStatus.idleStartTime).getTime();
        let idleDuration = (idleStart - currentTime) / 60000;
        idleDuration = Math.floor(Math.abs(idleDuration));
        setIdleDuration(idleDuration);

        if (idleDuration >= 1) {
          updateTimerStatus({ variables: { staffId, timerStatus: false } });
          dispatch(
            setGlobalTimerRunning(false)
          );
        }
      }
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };

  const saveProject = () => {
    setIsModalVisible(false);
    getNodeList();
  };

  return (
    <>

      <div className="demo-logo-vertical" />
      <div className={styles.NodeShell}>
        <OfflineBadge />
        <div className={styles.TreeContainer}>
          <ProfileDropdown />
          <SearchBar value={searchQuery} onChange={handleSearch} />
          <Collapse
            size="small"
            ghost={true}
            style={{ height: "100%" }}
            defaultActiveKey={["2"]}
          >
            <Panel header={"Favorites"} key="1">
              <div className={styles.TreeWrapper}>
                <NodeTree
                  height={pinnedPanelHeight}
                  handleNodeClick={(nodeId) =>
                    handleNodeClick(nodeId, "Favorites")
                  }
                  initialData={favoritesToDisplay}
                  pinnedNodes={favoritesToDisplay}
                  addNodeToPinned={addNodeToPinned}
                  selectedNodeId = {selectedNode?.id}
                  isClicked= {FavoritesProjectClicked}
                  searchQuery={searchQuery}
                  
                />
                </div>
            </Panel>
            <Panel header={"Workspace"} key="2" style={{ height: "100%" }}>
              <div className={styles.TreeWrapper}>
                <NodeTree
                  height={treeHeight}
                  handleNodeClick={(nodeId) =>
                    handleNodeClick(nodeId, "Workspace")
                  }
                  initialData={nodesToDisplay}
                  pinnedNodes={pinnedNodes}
                  addNodeToPinned={addNodeToPinned}
                  selectedNodeId={selectedNode?.id}
                  isClicked={WorkspaceProjectClicked}
                  searchQuery={searchQuery}
                  showModal={showModal}
                  
                />
              </div>
            </Panel>
          </Collapse>
        </div>

        <div className={styles.IframeContainer}>
          <TimerDetails disabled={disabled} errorFetchingTimers={errorFetchingTimers} />
          {activeButton === "wiki" &&
            ((projectWikiLink && (
              <div>
                {prevWikiLinkRef.current === projectWikiLink ? (
                  <WikiContent wikiLink={projectWikiLink} />
                ) : (
                  <iframe
                    src={projectWikiLink}
                    title="Wiki Link"
                    width="100%"
                    height="100vh"
                    style={{
                      overflowX: "hidden",
                      border: "none",
                      height: "100vh",
                    }}
                  />
                )}
              </div>
            )) || <WorkspaceWelcomeMessage />)}
        </div>

        {featureFlag.NodeChat && (
          <div className={styles.ConversationContainer}>
            <Chat showConversationList={false} isTreeView={true} />
          </div>
        )}
      </div>

      <AddNodeModal
        visible={isModalVisible}
        onCancel={hideModal}
        onSave={saveProject}
        parentProject={selectedNode}
      />
    </>
  );
};

NodeShell.propTypes = {};

NodeShell.defaultProps = {};

export default React.memo(NodeShell);

function debounce(func, delay) {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}
