import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Label, Tooltip } from 'recharts';

const PieChartStats = ({ data, COLORS, label,labelTitle }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={100}
            outerRadius={120}
            fill="#8884d8"
            paddingAngle={2}
            dataKey="value"
            stroke="#fff"
            strokeWidth={1}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
            <Label
            //   value={label}
              position="center"
              fill="#055b7f"
              content={({ viewBox }) => {
                const { cx, cy } = viewBox;
                return (
                    <text x={cx} y={cy} fill="#055b7f" textAnchor="middle" dominantBaseline="middle">
                        <tspan x={cx} dy="0">{label}</tspan>
                        <tspan x={cx} dy="20">{labelTitle}</tspan>
                    </text>
                );
            }}
              style={{ fontSize: '16px', fontWeight: 'semiBold',backgroundColor:'red' }}
            />
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PieChartStats;
