import React from "react"

const DeleteContactIcon = () => {
  return (
    <svg width={44} height={44} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={44} height={44} rx={4} fill="#FFF1F0" />
      <path d="M18.4375 14.3125H18.25C18.3531 14.3125 18.4375 14.2281 18.4375 14.125V14.3125H25.5625V14.125C25.5625 14.2281 25.6469 14.3125 25.75 14.3125H25.5625V16H27.25V14.125C27.25 13.2977 26.5773 12.625 25.75 12.625H18.25C17.4227 12.625 16.75 13.2977 16.75 14.125V16H18.4375V14.3125ZM30.25 16H13.75C13.3352 16 13 16.3352 13 16.75V17.5C13 17.6031 13.0844 17.6875 13.1875 17.6875H14.6031L15.182 29.9453C15.2195 30.7445 15.8805 31.375 16.6797 31.375H27.3203C28.1219 31.375 28.7805 30.7469 28.818 29.9453L29.3969 17.6875H30.8125C30.9156 17.6875 31 17.6031 31 17.5V16.75C31 16.3352 30.6648 16 30.25 16ZM27.1398 29.6875H16.8602L16.293 17.6875H27.707L27.1398 29.6875Z" fill="#F5222D" />
    </svg>
  )
}

export default DeleteContactIcon