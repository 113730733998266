import React from 'react';
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis, CartesianGrid} from 'recharts';

const CustomLegend = (props) => {
  const { payload } = props;
  return (
    <ul style={{ display:'flex',listStyleType: 'none', padding: 10 }}>
      {payload.map((entry, index) => (
        <li key={`item-${index}`} style={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}>
          <div
            style={{
              width: '20px',
              height: '20px',
              backgroundColor: entry.color,
              borderRadius: '20%',
              // marginleft: '8px',
            }}
          ></div>
          <span style={{marginRight:'5px'}}></span>
          <span>{entry.value}</span>
        </li>
      ))}
    </ul>
  );
};

const IntentionBarGraph = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={450}>
      <BarChart data={data} barSize={20}>
      <CartesianGrid strokeDasharray="3 3" />
         <XAxis tickLine={false} axisLine={false} dataKey="name" />
         <YAxis tickLine={false} axisLine={false} />
        <Tooltip />
        {/* <Legend align="left"  wrapperStyle={{ paddingTop: '20px' }} /> */}
        <Legend align="left" wrapperStyle={{ paddingTop: '20px' }} content={<CustomLegend />} />
        <Bar radius={[6, 6, 0, 0]} dataKey="Non-product Queries" fill="#033C4E" />
        <Bar radius={[6, 6, 0, 0]} dataKey="Product related Queries" fill="#79A84D" />
        <Bar radius={[6, 6, 0, 0]} dataKey="Contact a representative" fill="#2784AB" />

      </BarChart>
    </ResponsiveContainer>
  );
};

export default IntentionBarGraph;
