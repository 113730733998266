import React from "react";
import Channels from "../../Channels/Channels";
import styles from "./channels.module.scss";
import { useTranslation } from "react-i18next";

const AllChannels = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.AllChannelsPage}>
      <div className={styles.AllChannelsPage__header}>
        <h3>{t("Channels")}</h3>
        <p>
          {t(
            "Manage the different messaging channels used to communicate with your contacts."
          )}
        </p>
      </div>
      <div className={styles.AllChannelsPage__body}>
        <Channels />
      </div>
    </div>
  );
};

export default AllChannels;
