import React, { useState, useEffect, useCallback, useRef } from "react";
import styles from "./InviteContainer.module.scss";
import "antd/dist/reset.css";

import { useDispatch } from "react-redux";
import { createInviteLink } from "src/api/OrganizationApi";
import EditableTagGroup from "../EditableTagGroup/EditableTagGroup";
import { DownOutlined, RightCircleOutlined } from "@ant-design/icons";
import { Button, Divider, Radio, Select, Space, Tabs, message } from "antd";
import { useSelector } from "react-redux";
import { getActiveOrgId } from "src/store/slices/userSlice";

const InviteContainer = ({ changeVisibility }) => {
  const { TabPane } = Tabs;
  const { Option } = Select;

  const ref = useRef(null);
  const dispatch = useDispatch();

  const organizationId = useSelector(getActiveOrgId);

  const [domains, setDomains] = useState([]);
  const [linkAccess, setLinkAccess] = useState(1);
  const [accessAs, setAccessAs] = useState("admin");
  const [linkGenerate, setLinkGenerate] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [recievedLinkAdmin, setRecievedLinkAdmin] = useState("");
  const [recievedLinkMember, setRecievedLinkMember] = useState("");

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleDropdownVisibilityChange = (open) => {
    if (open !== isDropdownOpen) {
      setDropdownOpen(open);
    }
  };

  const handleSetDomains = (tags) => {
    setDomains(tags);
  };

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const handleLinkGenerateForRole = useCallback(
    async (role) => {
      const linkData =
        linkAccess === 1
          ? { role: role, organizationId }
          : { role: role, organizationId, allowedDomains: domains };

      const response = await createInviteLink(linkData);
      if (role === "admin") {
        setRecievedLinkAdmin(response?.data?.link);
      } else if (role === "member") {
        setRecievedLinkMember(response?.data?.link);
      }
    },
    [linkAccess, domains, dispatch]
  );

  useEffect(() => {
    handleLinkGenerateForRole("admin");
    handleLinkGenerateForRole("member");
  }, [handleLinkGenerateForRole]);

  const handleCopyLink = async (role) => {
    let linkToCopy;
    if (role === "admin") {
      linkToCopy = recievedLinkAdmin;
    } else if (role === "member") {
      linkToCopy = recievedLinkMember;
    }

    if (linkToCopy) {
      navigator.clipboard.writeText(linkToCopy);
      message.success("Link Copied");
      changeVisibility(false);

      setLinkGenerate(false);
      setLinkAccess(1);
    }
  };

  const handleLinkAccess = (e) => {
    setLinkAccess(e.target.value);
  };

  function handleAccessAs(value, option) {
    if (option.type && option.type === DownOutlined) {
      toggleDropdown();
      return;
    }
    setAccessAs(value);
  }

  return (
    <>
      <div className={styles.InviteContainer}>
        <Tabs
          className={styles.InviteContainer__tabsContainer}
          defaultActiveKey="1"
        >
          <TabPane
            className={styles.InviteContainer__tab}
            tab={dimensions.width > 768 ? "Invite by link" : "By Link"}
            key="1"
          >
            <Radio.Group onChange={handleLinkAccess} value={linkAccess}>
              <Space
                className={styles.InviteContainer__tab__radioContainer}
                direction="vertical"
              >
                <Radio value={1}>Anyone with this link can get access</Radio>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Radio value={2}>
                    Only allow people with email ending with
                  </Radio>
                  <EditableTagGroup getTags={handleSetDomains} />
                </div>
              </Space>
            </Radio.Group>

            <div className={styles.InviteContainer__footerContainer}>
              <Divider className={styles.InviteContainer__divider} />
              <div className={styles.InviteContainer__tab__tab1Footer}>
                <input
                  value={
                    accessAs === "admin"
                      ? recievedLinkAdmin
                      : recievedLinkMember
                  }
                  readOnly
                />

                <div
                  className={styles.InviteContainer__tab__tab1Footer__buttons}
                >
                  <Select
                    className={
                      styles.InviteContainer__tab__tab1Footer__memberSection
                    }
                    defaultValue="admin"
                    onChange={handleAccessAs}
                    open={isDropdownOpen}
                    onDropdownVisibleChange={handleDropdownVisibilityChange}
                    suffixIcon={<DownOutlined onClick={toggleDropdown} />}
                    ref={ref}
                  >
                    <Option value="admin">Admin</Option>
                    <Option value="member">Member</Option>
                  </Select>

                  <Button
                    className={
                      styles.InviteContainer__tab__tab1Footer__footerBtn2
                    }
                    type="primary"
                    onClick={() => handleCopyLink(accessAs)}
                    suffixIcon={<RightCircleOutlined />}
                  >
                    Copy link
                  </Button>
                </div>
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default InviteContainer;
