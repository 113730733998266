import React, { useEffect, useState } from "react";

import { Button, Dropdown, Input, message, Modal, notification } from "antd";

import { ExclamationCircleOutlined, MoreOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";

import {
  deleteSegmentation,
  updateSegmentation,
} from "src/api/SegmentationApi";
import { filterDataByColumn } from "../../../../Table/helpers/helpers";
import EditFilterDrawer from "../../EditFilterDrawer/EditFilterDrawer";
import styles from "./SegmentationItem.module.scss";
import { GET_ONE_SEGMENT } from "src/graphql/segment";
import { getActiveOrgId } from "src/store/slices/userSlice";
import { useSelector } from "react-redux";

const SegmentationItem = ({
  dataForCountingSegmentation,
  item,
  handleSegmentSelection,
  contactsData,
}) => {
  const organizationId = useSelector(getActiveOrgId);
  const {
    data: segmentData,
    loading: segmentDataLoading,
    error: segmentDataError,
    refetch,
  } = useQuery(GET_ONE_SEGMENT, {
    variables: {
      organizationId: organizationId,
      segmentId: item.id,
    },
  });

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isSegmentDrawerOpen, setIsSegmentDrawerOpen] = useState(false);

  const handleFetchFilters = (val) => {
    setSelectedFilters(val);
  };

  const handleOpenSegmentDrawer = (val) => {
    setIsSegmentDrawerOpen(true);
  };

  const [loading, setLoading] = useState(false);
  const handleOk = () => {
    setIsRenameModalOpen(false);
  };
  const handleCancel = () => {
    setIsRenameModalOpen(false);
  };

  const items = [
    {
      label: (
        <span
          onClick={(e) => {
            e.preventDefault();
            setIsFilterDrawerOpen(true);
          }}
        >
          Edit
        </span>
      ),
      key: "1",
    },
    {
      label: (
        <span
          onClick={() => {
            setIsRenameModalOpen(true);
          }}
        >
          Rename
        </span>
      ),
      key: "2",
      danger: true
    },
    {
      label: (
        <span
          onClick={() => {
            setIsDeleteModalOpen(true);
          }}
        >
          Delete
        </span>
      ),
      key: "3",
      danger: true,
    },
  ];

  const [data, setData] = useState([]);
  const [state, setState] = useState("");

  const handleChange = (e) => {
    setState(e.target.value);
  };

  useEffect(() => {
    setState(segmentData?.getSegments?.name);
  }, [segmentData]);

  useEffect(() => {
    if (
      !segmentDataLoading &&
      segmentData !== undefined &&
      dataForCountingSegmentation?.initialTableData?.length !== 0 &&
      segmentData?.getSegments?.segmentInformation?.length !== 0 &&
      dataForCountingSegmentation?.attrAndTypes?.length !== 0
    ) {
      setData(
        filterDataByColumn(
          dataForCountingSegmentation?.initialTableData,
          segmentData?.getSegments?.segmentInformation,
          dataForCountingSegmentation?.attrAndTypes,
          dataForCountingSegmentation?.waChannel
        )
      );
    }
  }, [dataForCountingSegmentation, segmentData]);

  const deleteModalAPIRequest = async () => {
    try {
      setIsDeleteButtonDisabled(true);
      let data = {
        id: item.id,
        organizationId: organizationId,
      };
      const res = await deleteSegmentation(data);
      if (res?.data?.success === true) {
        notification.success({
          message: <b>Success</b>,
          description: <b>Segment deleted successfully!</b>,
        });
      } else {
        if (res?.data?.message) {
          notification.error({
            message: <b>Notification</b>,
            description: <b>{res?.data?.message}</b>,
          });
        } else {
          notification.error({
            message: <b>Notification</b>,
            description: <b>Internal Server Error!</b>,
          });
        }
      }
      // setIsSuccess(true);
      // setTimeout(() => {
      //   setIsSuccess(false);
      // }, [3000]);
      // refetchAttributes();
      setIsDeleteModalOpen(false);
      // setDeleteData([]);
      setIsDeleteButtonDisabled(false);
    } catch (err) {
      console.log(err);
      setIsDeleteButtonDisabled(false);

      notification.error({
        message: <b>Notification</b>,
        description: <b>Internal Server Error!</b>,
      });
    }
  };

  const handleUpdateSegmentation = () => {
    setLoading(true);

    let obj = {
      id: item.id,
      organizationId: organizationId,
      segment: {
        name: state,
        organization: {
          id: organizationId,
        },
        segmentInformation: segmentData?.getSegments?.segmentInformation.map(
          (info) => {
            // Create a copy of the object without the 'id' property
            const { id, __typename, ...newInfo } = info;
            return newInfo;
          }
        ),
      },
    };

    // console.log(obj)
    updateSegmentation(obj)
      ?.then((res) => {
        message.success("Rename successful!");
        handleCancel();
        setLoading(false);
        refetch();
      })
      .catch((e) => {
        if (e?.response?.data?.message && e?.response?.data?.message !== "") {
          message.error(e?.response?.data?.message);
        } else {
          message.error("Something went wrong .Try again later");
        }
        setLoading(false);
      });
  };

  //   console.log(filterDataByColumn(initialTableData, selectedFilters, attrAndTypes,waChannel))

  return (
    <li
      key={item.id}
      style={{
        background:
          item.id == contactsData?.globalSelectedSegment
            ? "#BAE7FF"
            : "#FFFFFF",
      }}
      className={styles.SegmentationItem}
    >
      <span onClick={() => handleSegmentSelection(item.id)}>{item?.name}</span>
      <p className={styles.SegmentationItem__count}>{data?.length}</p>
      <Dropdown
        menu={{ items }}
        trigger={["click"]}
        className={styles.SegmentationItem__dropdown}
      >
        <a onClick={(e) => e.preventDefault()}>
          <MoreOutlined />
        </a>
      </Dropdown>
      <EditFilterDrawer
        open={isFilterDrawerOpen}
        handleFetchFilters={handleFetchFilters}
        onClose={() => setIsFilterDrawerOpen(false)}
        handleOpenSegmentDrawer={handleOpenSegmentDrawer}
        initialFilters={segmentData}
      />
      <Modal
        className={styles.DeleteModal__Modal}
        open={isDeleteModalOpen}
        footer={null}
        closable={false}
        header={false}
      >
        <div className={styles.DeleteModal}>
          <div className={styles.DeleteModal__Body}>
            <div className={styles.DeleteModal__Body__IconContainer}>
              <ExclamationCircleOutlined
                style={{ color: "#FF4D4F", fontSize: "21px" }}
              />
            </div>
            <div className={styles.DeleteModal__Body__ContentContainer}>
              <h5>Delete segment</h5>
              <p>
                Selected segment will be deleted permanently. Are you sure you
                want to delete?
              </p>
            </div>
          </div>
          <div className={styles.DeleteModal__Footer}>
            <Button
              disabled={isDeleteButtonDisabled}
              onClick={() => {
                deleteModalAPIRequest();
              }}
              type="primary"
              delete
              danger
            >
              Delete
            </Button>
            <Button
              onClick={() => {
                setIsDeleteModalOpen(false);
              }}
              type="ghost"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        open={isRenameModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        footer={null}
        className={styles.SegmentationModal}
      >
        <div className={styles.SegmentationModal__body}>
          <h3>Rename segment</h3>
          <p>Segment Name</p>
          <Input onChange={handleChange} value={state} />
        </div>
        <div className={styles.SegmentationModal__footer}>
          <Button onClick={handleCancel} type="ghost">
            Cancel
          </Button>
          <Button
            type="primary"
            disabled={state == "" || loading}
            onClick={handleUpdateSegmentation}
          >
            Save
          </Button>
        </div>
      </Modal>
    </li>
  );
};

export default SegmentationItem;
