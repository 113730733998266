import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Editor from "src/components/Editor/Editor";
import RichTextEditor from "react-rte";
import { notification } from "antd";
import {
  SendOutlined,
  CommentOutlined,
  PaperClipOutlined,
  EditOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import ConversationContainer_Styles from "src/components/Conversation/components/ConversationContainer/ConversationContainer.module.scss";
import styles from "./IndividualEditorInstance.module.scss";
import ListFile from "src/components/Conversation/components/ListFile/ListFile";

const IndividualEditorInstance = ({ onFileChange, onTextChange }) => {
  const [editorInstance, setEditorInstance] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [isToolBarVisible, setToolBarVisibility] = useState(false);
  const fileListContainer = useRef(null);
  const [files, setFiles] = useState([]);

  const onChangeRte = (value) => {
    setEditorInstance(value);
  };

  useEffect(() => {
    const editorText = editorInstance.toString("markdown");
    const trimmedEditorText = editorText
      .replace(/\n(?!\n)/g, "\n\n") // Convert single newlines to soft breaks
      .replace("\\s+", " ")
      .replace(/[\u200B]/g, "") // Remove zero-width spaces (if needed)
      .trim();
    if (trimmedEditorText !== "") {
      onTextChange(trimmedEditorText);
    }
  }, [editorInstance]);

  const handleEditorVisibility = () => {
    setToolBarVisibility(!isToolBarVisible);
  };

  const onFileUpload = (e) => {
    //onItemChange(item?.id, "file", e.target.files[0].name, "file")
  };

  const addFiles = (e) => {
    let resultingFiles = checkIfDuplicates(files, e.target.files);
    let prevFiles = files;
    prevFiles = [...prevFiles, ...resultingFiles];
    validateFiles(prevFiles);
  };

  const checkIfDuplicates = (prevFiles, newFiles) => {
    let resultingFiles = [];
    for (let i = 0; i < newFiles.length; ++i) {
      let currFile = newFiles[i];
      let isFound = false;
      for (let j = 0; j < prevFiles.length; ++j) {
        let currPrevFile = prevFiles[j];
        if (
          currPrevFile.name === currFile.name &&
          currPrevFile.lastModified === currFile.lastModified
        ) {
          notification.error({
            message: "Notification",
            description: `${currFile.name} is already added.`,
          });
          isFound = true;
          break;
        }
      }
      if (!isFound) {
        resultingFiles.push(currFile);
      }
    }
    return resultingFiles;
  };

  const removeFiles = (fileToBeRemoved) => {
    let prevFiles = files;
    let index = -1;
    prevFiles.map((tx, i) => {
      if (
        tx.name === fileToBeRemoved.name &&
        tx.lastModified === prevFiles[i].lastModified
      ) {
        index = i;
      }
    });
    if (index !== -1) {
      prevFiles.splice(index, 1);
    }
    prevFiles = [...prevFiles];
    setFiles(prevFiles);
    onFileChange(null);
  };

  const validateFiles = (file_array) => {
    file_array = file_array.filter((file) => {
      const allowed_extensions = ["jpg", "jpeg", "png"];
      const meta_name = file.name.split(".");
      const extension = meta_name[meta_name.length - 1];
      if (allowed_extensions.includes(extension) === false) {
        notification.error({
          message: <b>Notification</b>,
          description: (
            <b>
              {file.name} is not allowed.
              <br />
              Only files with following extensions are allowed:-
              <ul>
                <li>.jpg</li>
                <li>.jpeg</li>
                <li>.png</li>
              </ul>
            </b>
          ),
        });
        return false;
      } else if (file.size > 16 * 1024 * 1024) {
        notification.error({
          message: <b>Notification</b>,
          description: <b>{file.name} should be under 16mb</b>,
        });
        return false;
      }

      return true;
    });
    setFiles(file_array);
    if (Array.isArray(file_array) && file_array.length > 0) {
      onFileChange(file_array[0]);
    }
    return file_array;
  };

  return (
    <>
      <Editor
        editorInstance={editorInstance}
        setEditorInstance={setEditorInstance}
        isToolBarVisible={isToolBarVisible}
        onChange={onChangeRte}
        onSubmitConfig={{}}
        placeholder={"Type a message."}
      />
      <div
        style={{ zIndex: 1 }}
        ref={fileListContainer}
        className={`${ConversationContainer_Styles.ConversationContainer__FileListContainer} 
               ${ConversationContainer_Styles.defaultBackground}`}
      >
        {files.map((tx, i) => (
          <ListFile customkey={i} key={i} file={tx} removeFile={removeFiles} />
        ))}
      </div>
      <div
        style={{ zIndex: 1 }}
        className={
          ConversationContainer_Styles.ConversationContainer__inputcontainer__optionsbar
        }
      >
        <div
          className={
            ConversationContainer_Styles.ConversationContainer__inputcontainer__optionsbar__leftsection
          }
        >
          {/* <EditOutlined
            className={
              !isToolBarVisible
                ? ConversationContainer_Styles.ConversationContainer__iconsleft
                : `${ConversationContainer_Styles.ConversationContainer__iconsleft} ${ConversationContainer_Styles.ConversationContainer__active_icon}`
            }
            onClick={handleEditorVisibility}
          /> */}
          {files.length <= 0 && (
            <label htmlFor="uploadFile">
              <input
                placeholder="selectFile"
                type="file"
                id="uploadFile"
                hidden
                onChange={addFiles}
                onClick={(event) => (event.target.value = "")}
              />
              <PaperClipOutlined
                data-testid="attachment-icon"
                className={
                  ConversationContainer_Styles.ConversationContainer__iconsleft
                }
              />
            </label>
          )}
        </div>
      </div>
    </>
  );
};

IndividualEditorInstance.propTypes = {};

IndividualEditorInstance.defaultProps = {};

export default IndividualEditorInstance;
