import React from "react";
import { ConfigProvider } from "antd";
import { ApolloProvider } from "@apollo/client";

import styles from "./App.module.css";
import "./App.css";

import AppContainer from "./components/AppContainer/AppContainer";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import ApolloClient from "./components/ApolloClient/ApolloClient";
import Localization from "./components/Localization/Localization";
import PyWebview from "./components/PyWebview/PyWebview";
import OnboardingTour from "./components/OnboardingTour/OnboardingTour";
import SuppressResizeObserver from "./helpers/suppress";

function App() {
  SuppressResizeObserver();
  PyWebview();

  const locale = Localization();
  const apolloClient = ApolloClient();

  return (
    <div className={styles.App}>
      <ErrorPage>
        <OnboardingTour>
          <ConfigProvider locale={locale}>
            <ApolloProvider client={apolloClient}>
              <AppContainer />
            </ApolloProvider>
          </ConfigProvider>
        </OnboardingTour>
      </ErrorPage>
    </div>
  );
}

export default App;
