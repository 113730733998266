import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./Login.module.scss";
import AuthLayout from "../AuthLayout/AuthLayout";

import { useFormik } from "formik";
import { getTokenByLogin } from "src/api/AuthApi";
import { setAuthdata } from "src/store/slices/authSlice";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Alert, Button, Form, Input, Checkbox } from "antd";
import { faArrowAltCircleRight } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as Yup from "yup";
import "antd/dist/reset.css";
import organizationFeatures from "src/helpers/organizationFeatures";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ success: null, error: null });

  const handleLogin = async ({ email, password }) => {
    setLoading(true);
    let payload = { username: email, password };
    let res = await getTokenByLogin(payload);
    let { access_token, refresh_token } = res.data;
    if (access_token) {
      dispatch(setAuthdata({ access_token, refresh_token }));
      navigate("/");
    } else {
      let { success, message } = res.data;
      setMessage({
        success,
        error: { title: "Login Failed", description: message, type: "error" },
      });
    }

    setLoading(false);
  };

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: { email: "", password: "" },
      validationSchema: Yup.object().shape({
        email: Yup.string()
          .email("Invalid email address format")
          .required("Email required"),
        password: Yup.string()
          .matches(
            /^(?=.*[A-Za-z])(?=.*[@$!%*#?&])[A-Za-z0-9@$!%*#?&]{6,}$/,
            "Password should be atleast 6 characters long and should contain a special symbol!"
          )
          .required("Password required"),
      }),
      onSubmit: handleLogin,
    });

  const handleValidationStatus = (key) => {
    return !touched[key] && !errors[key]
      ? ""
      : touched[key] && !errors[key]
      ? "success"
      : touched[key] && errors[key]
      ? "error"
      : "success";
  };

  const printValidationMsg = (key) => {
    if (touched[key] && errors[key]) {
      return (
        <div
          role="alert"
          className={styles.Login__signInContainer__formItem__error}
        >
          {errors[key]}
        </div>
      );
    } else return null;
  };
  function shouldShowCreateAccountButton() {
    const url = window.location.href;
    const hostname = new URL(url).hostname;
    return organizationFeatures.createAccountFeature.includes(hostname);
  }

  return (
    <AuthLayout>
      <div className={styles.Login} data-testid="sign-in-form">
        <div className={styles.Login__signInContainer}>
          <>
            <h3>Login</h3>
            {!shouldShowCreateAccountButton() && (
            <div className={styles.Login__signInContainer__helpMsg}>
              New user?&nbsp;
              <a onClick={() => navigate("/signup")}>Create account</a>
            </div>
            )}
          </>
          <form onSubmit={handleSubmit}>
            <Form.Item
              className={`${styles.Login__signInContainer__formItem} signIn__form`}
              label="Enter email address"
              name="email"
              validateStatus={handleValidationStatus("email")}
              help={printValidationMsg("email")}
            >
              <Input
                id="email"
                name="email"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                placeholder="example@gmail.com"
              />
            </Form.Item>
            <Form.Item
              className={`${styles.Login__signInContainer__formItem} signIn__form`}
              label={"Enter the Password"}
              name="password"
              validateStatus={handleValidationStatus("password")}
              help={printValidationMsg("password")}
            >
              <Input.Password
                id="password"
                name="password"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item
              className={`${styles.Login__signInContainer__formItem} signIn__form`}
            >
              <Checkbox
                className={
                  styles.Login__signInContainer__formItem__check_remember
                }
              >
                Remember me
              </Checkbox>

              <Link
                to="/forgot-password"
                className={styles.Login__signInContainer__formItem__forget_link}
              >
                Forgot password
              </Link>
            </Form.Item>
            <div className={styles.Login__signInContainer__btnContainer}>
              <Button
                style={{
                  width: "100%",
                  background: "rgba(9, 109, 217, 1)",
                  color: "white",
                }}
                loading={loading}
                htmlType="submit"
              >
                <span>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} />
                </span>
                Login
              </Button>
            </div>

            <div className={styles.Login__message_container}>
              {message.error !== null ? (
                <Alert
                  message={message.error.title}
                  description={message.error.description}
                  type={message.error.type}
                  showIcon={message.error.showIcon}
                />
              ) : null}
            </div>
          </form>
          <footer className={styles.Login__footer} data-testid="footer">
            Need help? <span>Feel free to contact us</span>&nbsp;
            <a
              href="https://calendly.com/hybrid-chat/15min"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact Us!
            </a>
          </footer>
        </div>
      </div>
    </AuthLayout>
  );
};

Login.propTypes = {};

Login.defaultProps = {};

export default Login;
