import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { formatTimer } from "src/helpers/timerConfig";
import styles from './TimerDetails.module.scss';
import { ClockCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import StopIcon from "src/assets/svgs/StopIcon";
import StartIcon from "src/assets/svgs/StartIcon";
import { Button, Typography, Spin, message, Tooltip } from "antd";
const { Text, Title } = Typography;
import { GET_TIMER_STATUS, UPDATE_TIMER_STATUS } from "src/graphql/timer";
import { useQuery, useMutation } from "@apollo/client";
import { setGlobalTimerRunning } from "src/store/slices/timerSlice";

const TimerDetails = () => {
  const selectedNode = useSelector((state) => state?.node?.data?.selectedNode);
  const {globalTimerRunning,loadingTimers} = useSelector((state) => state?.timer?.data);
  const nodeTimers = useSelector((state) => state?.timer?.data?.nodeTimers);
  const totalElapsedTime = useSelector((state) => state?.timer?.data?.totalElapsedTime);
  const userData = useSelector((state) => state.user.data);
  const staffId = userData.id;
  const dispatch = useDispatch();

  const { loading, error, data,refetch } = useQuery(GET_TIMER_STATUS, {
    variables: { staffId },
  });
  
  const timerStatus = data?.getHC_Staff?.timerStatus;

  const [updateTimerStatus] = useMutation(UPDATE_TIMER_STATUS);

  const toggleGlobalTimerRunning = (newValue) => {
    if (newValue !== globalTimerRunning) {
      if (typeof window !== 'undefined' && window.pywebview && window.pywebview.api) {
        let { set_tracker_status } = window.pywebview.api;
        set_tracker_status(newValue);
      }
    }
    return newValue;
  };
  useEffect(() => {
    const interval = setInterval(() => {

      console.log("refetch",timerStatus)
      refetch(); 
    }, 10000);
    return () => clearInterval(interval);
  }, [refetch]);

  const handleButtonClick = () => {
    const newTimerStatus = !globalTimerRunning;
    updateTimerStatus({ variables: { staffId, timerStatus: newTimerStatus } });
    dispatch(
      setGlobalTimerRunning(toggleGlobalTimerRunning(newTimerStatus))
    );
    
  };

  const isButtonDisabled = (!globalTimerRunning && timerStatus);

  return (
    <div className={styles.Navigation}>
      {selectedNode && (
        <>
          <div style={{ display: 'flex' }}>
            <div className={styles.timerContainer}>
              <div className={styles.timerHeader}>
                <h2 
                  onClick={handleButtonClick}
                  data-cy="node-timer"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: "#055b7f",
                    paddingRight: "10px",
                    paddingLeft: "10px",
                    paddingTop: '15px'
                  }}
                >
                  {loadingTimers ? (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{ fontSize: 24 }}
                          spin
                        />
                      }
                    />
                  ) : (
                    <>
                      <ClockCircleOutlined style={{ marginRight: '13px' }} />
                      {formatTimer(nodeTimers[selectedNode.uniqueKey] || 0)}
                    </>
                  )}
                </h2>
              </div>
            </div>
            <Tooltip color="#055b7f" title={isButtonDisabled ? `Timer is already running` : globalTimerRunning ? `Stop timer for ${selectedNode.name}` : `Start timer for ${selectedNode.name}`}>
            <Button
              data-cy="start-timer-button"
              style={{ marginLeft: '14px' }}
              onClick={handleButtonClick}
              type="primary"
              shape="circle"
              icon={globalTimerRunning ? <StopIcon /> : <StartIcon />}
              size="large"
              disabled={isButtonDisabled}
            />
            </Tooltip>

            <Title
              data-cy="project-name"
              level={4}
              style={{
                backgroundColor: '',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#939393',
                margin: '10px'
              }}
            >
             {selectedNode.name}
            </Title>
          </div>
          <div>
            <p style={{ display: 'flex', font: 'bold', fontSize: 'medium', color: '#055b7f' }}>
              Today: <span data-cy="daily-timer">{loadingTimers ? '--:--:--' : formatTimer(totalElapsedTime)}</span>
            </p>
          </div>
        </>
      )}
      {!selectedNode &&(
        <>
          <div style={{ display: 'flex' }}>
            <Button
              data-cy="disabled-start-timer-button"
              style={{ marginLeft: '14px', backgroundColor: 'gray' }}
              onClick={handleButtonClick}
              type="primary"
              shape="circle"
              icon={globalTimerRunning ? <StopIcon /> : <StartIcon />}
              size="large"
              disabled
            />
            <Title
              data-cy="disabled-button-title"
              level={4}
              style={{
                backgroundColor: '',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#939393',
                margin: '10px'
              }}
            >
              Choose a project to begin tracking!
            </Title>
          </div>
          <div>
            
            <p style={{ display: 'flex', font: 'bold', fontSize: 'medium', color: '#055b7f' }}>
              Today: <span data-cy="daily-timer">{loadingTimers ? '--:--:--' : formatTimer(totalElapsedTime)}</span>
            </p>
          </div>
        </>
      )}
      {error && <p style={{ color: 'red' }}>Error fetching timer status</p>}
    </div>
  );
};

export default TimerDetails;
