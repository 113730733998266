import React, { useState } from "react";
import { Drawer, Form, Input, Button, Select, notification } from "antd";
import styles from "./CreateEmailChannel.module.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { createEmailChannel } from "src/api/ChannelApi";
import { getActiveOrgId } from "src/store/slices/userSlice";

const CreateEmailChannel = ({ isVisible, setShowDrawer, fetch }) => {
  const organizationId = useSelector(getActiveOrgId);

  const submitForm = async (formValues) => {
    let data = {
      organizationId: organizationId,
      name: formValues?.channelName,
      active: "true",
      email: formValues?.email,
    };
    try {
      const res = await createEmailChannel(data);
      if (res.data.success === true) {
        notification.success({
          message: <b>Notification</b>,
          description: res.data.message,
        });
        fetch();
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        notification.error({
          message: <b>Notification</b>,
          description: err?.response?.data?.message,
        });
      }
      console.log(err);
      return;
    }
  };

  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    isValid,
    // resetForm,
  } = useFormik({
    initialValues: {
      channelName: "",
      userName: "",
      email: "",
    },
    validationSchema: Yup.object().shape({
      channelName: Yup.string().required("channel name is required"),
      email: Yup.string().required("email is required"),
    }),
    onSubmit: (formValues) => {
      submitForm(formValues);
      setShowDrawer(false);
      // resetForm();
    },
  });

  const handleValidationStatus = (key) => {
    return !touched[key] && !errors[key]
      ? ""
      : touched[key] && !errors[key]
      ? "success"
      : touched[key] && errors[key]
      ? "error"
      : "";
  };

  return (
    <>
      <Drawer
        className={styles.CreateEmailChannel__drawer}
        headerStyle={{ display: "none" }}
        placement="right"
        open={isVisible}
        onClose={() => setShowDrawer(false)}
      >
        <div className={styles.CreateEmailChannel}>
          <div className={styles.CreateEmailChannel__Header}>
            Create Email Channel Inbox
          </div>
          <div className={styles.CreateEmailChannel__SubHeader}>
            Setting up a Custom Email Channel Inbox requires a username and
            email in which you are willing to create a Email channel.
          </div>

          <Form
            layout="vertical"
            className={styles.ChannelForm}
            onFinish={handleSubmit}
          >
            <Form.Item
              name="channelName"
              label="Channel Name"
              validateStatus={handleValidationStatus("channelName")}
            >
              <Input
                name="channelName"
                placeholder="Enter the Channel Name"
                onChange={handleChange}
                value={values.channelName}
              />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email"
              validateStatus={handleValidationStatus("email")}
            >
              <Input
                name="email"
                placeholder="email@email.com"
                onChange={handleChange}
                value={values.email}
              />
            </Form.Item>

            <div className={styles.ChannelForm__ButtonContainer}>
              <Button type="secondary" onClick={() => setShowDrawer(false)}>
                {" "}
                Cancel{" "}
              </Button>
              <Button type="primary" disabled={!isValid} htmlType="submit" style={{backgroundColor:'var(--primary-color)'}}>
                {" "}
                Verify{" "}
              </Button>
            </div>
          </Form>
        </div>
      </Drawer>
    </>
  );
};

CreateEmailChannel.propTypes = {};

CreateEmailChannel.defaultProps = {};

export default CreateEmailChannel;
