import { gql } from "@apollo/client";

export const GET_CONTACT_DETAILS_BY_ID = gql`
  subscription ($organizationId: ID!, $contacts: [ID!]) {
    getOrganization(id: $organizationId) {
      id
      leads(filter: { id: $contacts }) {
        id
        createdDate
        username
        threads {
          id
        }
        multiSelectAttributeValue {
          id
          label {
            id
            name
            color
          }
          multiSelectAttribute {
            id
            name
            attributeType
          }
        }
        userAttributeValue {
          id
          value {
            id
            username
          }
          userAttribute {
            id
            name
            attributeType
          }
        }
        normalAttributeValue {
          id
          normalAttribute {
            id
            name
            type
            createdAt
            attributeType
          }
          value
        }
      }
    }
  }
`;

export const GET_CONTACT_DETAILS = gql`
  subscription ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      id
      leads {
        id
        createdDate
        username
        threads {
          id
        }
        multiSelectAttributeValue {
          id
          label {
            id
            name
            color
          }
          multiSelectAttribute {
            id
            name
            attributeType
          }
        }
        userAttributeValue {
          id
          value {
            id
            username
          }
          userAttribute {
            id
            name
            attributeType
          }
        }
        normalAttributeValue {
          id
          normalAttribute {
            id
            name
            type
            createdAt
            attributeType
          }
          value
        }
      }
    }
  }
`;

export const GET_ALL_LABELS = gql`
  subscription ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      id
      timezone
      labels {
        id
        color
        name
        category {
          id
        }
      }
    }
  }
`;

export const SUBSCRIBE_NORMAL_ATTRIBUTE_VALUE = gql`
  subscription ($leadId: ID!) {
    getHC_Lead(id: $leadId) {
      id
      username
      phone
      vdiIdentifier
      normalAttributeValue {
        value
        normalAttribute {
          name
        }
      }
    }
  }
`;

export const UPDATE_CONTACT_MUTATION = gql`
  mutation (
    $filter: ID!
    $email: String
    $username: String
    $phone: String
    $bio: String
    $whatsappNumber: PhoneNumberRef
    $phoneNumber: PhoneNumberRef
  ) {
    updateHC_Lead(
      input: {
        filter: { id: [$filter] }
        set: {
          email: $email
          username: $username
          bio: $bio
          phone: $phone
          whatsappNumber: $whatsappNumber
          phoneNumber: $phoneNumber
        }
      }
    ) {
      hC_Lead {
        id
      }
      numUids
    }
  }
`;

export const GET_ORGANIZATION_LABELS = gql`
  query ($organizationId: ID!, $multiselectId: ID!) {
    getOrganization(id: $organizationId) {
      multiSelectAttribute(filter: { id: [$multiselectId] }) {
        labels {
          id
          name
          color
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_CONTACTS = gql`
  query ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      leads {
        username
        id
        phone
        normalAttributeValue {
          id
          value
        }
        multiSelectAttributeValue {
          id
          label {
            id
            name
            color
          }
        }
        userAttributeValue {
          id
          value {
            id
            username
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_CONTACTS_IDS = gql`
  query ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      leads {
        id
        username
      }
    }
  }
`;

export const GET_ORGANIZATION_DATA = gql`
  query ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      normalAttribute {
        id
        name
        type
        createdAt
        normalAttributeValue {
          id
          value
          lead {
            id
            username
            email
          }
        }
      }
      multiSelectAttribute {
        id
        name
        createdAt
        attributeType
        multiSelectAttributeValue {
          id
          label {
            id
            name
            color
          }
          lead {
            id
            username
          }
        }
      }
      userAttribute {
        id
        name
        createdAt
        userAttributeValue {
          id
          value {
            id
            username
          }
          lead {
            id
            username
          }
        }
      }
    }
  }
`;

export const GET_MULTISELECTS = gql`
  query ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      multiSelectAttribute {
        id
        name
      }
    }
  }
`;

export const GET_ALL_ASSIGNEES = gql`
  query ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      userAttribute {
        id
        name
      }
    }
  }
`;

export const GET_DEFAULT_ATTRIBUTE = gql`
  query ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      id
      userAttribute(filter: { attributeType: { eq: DefaultOutsideDrawer } }) {
        id
        name
        attributeType
        createdAt
      }
      multiSelectAttribute(
        filter: { attributeType: { eq: DefaultOutsideDrawer } }
      ) {
        id
        name
        attributeType
      }
      normalAttribute(filter: { attributeType: { eq: DefaultOutsideDrawer } }) {
        id
        name
        attributeType
        type
      }
    }
  }
`;

export const GET_ORGANIZATION_ALL_LABELS = gql`
  query ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      id
      multiSelectAttribute {
        id
        name
        labels {
          id
          name
          color
        }
      }
    }
  }
`;

export const UPDATE_ASSIGNEES = gql`
  mutation UpdateHC_Lead($id: [ID!], $assignees: [HC_StaffRef!]) {
    updateHC_Lead(input: { filter: { id: $id }, set: { assignee: $assignees } }) {
      hC_Lead {
        id
        username
        assignee {
          id
          firstName
          lastName
        }
      }
    }
  }
`


export const GET_ASSIGNEES = gql`
  query QueryHC_Lead($id: [ID!]) {
    queryHC_Lead(filter:{id:$id}) {
        id
        username
        assignee{
            id
            firstName
            lastName
            email
        }
    }
  }
`

export const DELETE_ASIGNEE = gql`
  mutation UpdateHC_Lead($id: [ID!], $assignees: [HC_StaffRef!]) {
  updateHC_Lead(input: { filter: { id: $id }, remove: { assignee: $assignees } }) {
    hC_Lead {
      id
      username
      assignee {
        id
        firstName
        lastName
      }
    }
  }
}
`


