export const categories = [
  {
    value: "Contact field",
    label: "Contact field",
  },
  {
    value: "Channel",
    label: "Channel",
  },
  {
    value: "Tags",
    label: "Tags",
  },
  {
    value: "Assignee",
    label: "Assignee",
  },
];

export const basicOptions = [
  {
    label: "exists",
    value: "exists",
  },
  {
    label: "does not exist",
    value: "does not exist",
  },
  {
    label: "has any of",
    value: "has any of",
  },
  {
    label: "has all of",
    value: "has all of",
  },
  {
    label: "has none of",
    value: "has none of",
  },
];

export const contactOptions = [
  {
    label: "lab",
    value: "lab",
  },
];

export const numericOptions = [
  {
    label: "Equals",
    value: "equals",
  },
  {
    label: "Does not equal",
    value: "doesNotEqual",
  },
  {
    label: "Greater than",
    value: "greaterThan",
  },
  {
    label: "Less than",
    value: "lessThan",
  },
  {
    label: "Greater than or equal to",
    value: "greaterThanOrEqual",
  },
  {
    label: "Less than or equal to",
    value: "lessThanOrEqual",
  },
  // {
  //   label: "Between",
  //   value: "between",
  // },
  {
    label: "Is empty",
    value: "does not exist",
  },
  {
    label: "Is not empty",
    value: "exists",
  },
];

export const textOptions = [
  {
    label: "Is",
    value: "is",
  },
  {
    label: "Is not",
    value: "isNot",
  },
  {
    label: "Contains",
    value: "contains",
  },
  {
    label: "Does not contain",
    value: "doesNotContain",
  },
  {
    label: "Starts with",
    value: "startsWith",
  },
  {
    label: "Ends with",
    value: "endsWith",
  },
  {
    label: "Is empty",
    value: "does not exist",
  },
  {
    label: "Is not empty",
    value: "exists",
  },
];

export const combinationOptions = [{
  label: "AND",
  value:"and",
},
{

  label: "OR",
  value:"or",
}
];
