import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./AutomationActionBlock.module.scss";
import { PlusOutlined } from "@ant-design/icons";
import { Select } from "antd";
import AutomationTagsContainer from "../AutomationTagsContainer/AutomationTagsContainer";
import AutomationIndividualAction from "../AutomationIndividualAction/AutomationIndividualAction";

const AutomationActionBlock = ({ handleAutomationNewItem, organizationId, handleAutomationChange, automationState }) => {
  return (
    <div className={styles.AutomationActionBlock}>
      <div className={styles.AutomationActionBlock__Body}>
        {
          automationState.map((tx) => <AutomationIndividualAction organizationId={organizationId} item={tx} onItemChange={handleAutomationChange} />)
        }

      </div>
      <div className={styles.AutomationActionBlock__Footer}>
        <div className={styles.AutomationActionBlock__AddCondition} onClick={handleAutomationNewItem}>
          <PlusOutlined style={{ paddingRight: "10px", color:'var(--primary-color)' }} />Add new action
        </div>
      </div>
    </div>
  );
}

AutomationActionBlock.propTypes = {};

AutomationActionBlock.defaultProps = {};

export default AutomationActionBlock;
