import React from "react";
import styles from "./people.module.scss";
import PeopleBody from "../../PeopleBody/PeopleBody";
import { useTranslation } from "react-i18next";

const AllPeople = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.AllPeoplePage}>
      <div className={styles.AllPeoplePage__header}>
        <h3>{t("People")}</h3>
        <p>{t("Control member access")}</p>
      </div>
      <div className={styles.AllPeoplePage__body}>
        <div className={styles.AllPeoplePage__body__container}>
          <PeopleBody />
        </div>
      </div>
    </div>
  );
};

export default AllPeople;
