import React, { useState } from "react";
import PropTypes from "prop-types";
import { UsergroupAddOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import "antd/dist/reset.css";
import InviteContainer from "../InviteContainer/InviteContainer";
import styles from "./InvitePeopleModal.module.scss";

const InvitePeopleModal = ({ triggerElement }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleInviteShow = () => {
    setIsModalVisible(true);
  };

  return (
    <>
      <span data-testid="triggerElement" onClick={() => handleInviteShow()}>
        {triggerElement}
      </span>
      <Modal
        data-testid="Invitemodal"
        title={
          <>
            <div
              className={styles.InvitePeople__desktopHeader}
              data-testid="invitemodal-heading-desktop"
            >
              Invite your people
              <UsergroupAddOutlined />
            </div>
            {/* <div
              className={styles.InvitePeople__mobileHeader}
              data-testid="invitemodal-heading-mobile"
            >
              {mobileHeader}
            </div> */}
          </>
        }
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer=""
        className={styles.InvitePeople}
      >
        <InviteContainer changeVisibility={setIsModalVisible} />
      </Modal>
    </>
  );
};

InvitePeopleModal.propTypes = {};

InvitePeopleModal.defaultProps = {};

export default InvitePeopleModal;
