import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import styles from "./AuthLayout.module.scss";
import ProtectRoute from "src/components/ProtectRoute/ProtectRoute";
import FeatureCarousel from "../FeatureCarousel/FeatureCarousel";
import "antd/dist/reset.css";

const AuthLayout = ({ children }) => {
  return (
    <ProtectRoute invert={true}>
      <div className={styles.AuthLayout}>
        <div className={styles.__formContainer}>
          <Row>
            <Col span={24}>
              <Row className={styles.__fullsignup}>
                <Col span={12}>{children}</Col>
                <Col className={styles.__carousel} span={12}>
                  <FeatureCarousel />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </ProtectRoute>
  );
};

AuthLayout.propTypes = {};

AuthLayout.defaultProps = {};

export default AuthLayout;
