import React from "react";
import styles from "./Chat.module.scss";
import { ChatShell } from "../Conversation/Conversation";

const Chat = () => {
  return (
    <div className={styles.container}>
      <ChatShell />
    </div>
  );
};

Chat.propTypes = {};

Chat.defaultProps = {};

export default Chat;
