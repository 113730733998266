import "./Table.scss";

import React, { useEffect, useRef, useState } from "react";

import { Checkbox, Spin } from "antd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import {
  useColumnOrder,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useSticky } from "react-table-sticky";

// import { getTableData } from "../../api"
import {
  CaretDownOutlined,
  CaretUpOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useQuery } from "@apollo/client";

import { executeSegmentation } from "src/api/SegmentationApi";

import featureFlag from "../../helpers/featureFlag";
import ContactTableSelect from "../ContactTableSelect/ContactTableSelect";
import Cell from "./Cell/Cell";
import ChannelCell from "./ChannelCell/ChannelCell";
import DateCell from "./DateCell/DateCell";
import { filterDataByColumn } from "./helpers/helpers";
import MultiSelectInlineFieldsCell from "./MultiSelectInlineFieldsCell/MultiSelectInlineFieldsCell";
import NameCell from "./NameCell/NameCell";
import SortingPopup from "./SortingPopup/SortingPopup";
import UserCell from "./UserCell/UserCell";
import {
  GET_ORGANIZATION_CONTACTS,
  GET_ORGANIZATION_CONTACTS_IDS,
  GET_ORGANIZATION_DATA,
} from "src/graphql/contact";
import { GET_ORGANIZATION_STAFF } from "src/graphql/staff";
import { LOAD_CHANNELS_LEAD_DATA } from "src/graphql/lead";
import { GET_ORGANIZATION_ATTRIBUTES } from "src/graphql/organization";
import { GET_ONE_SEGMENT } from "src/graphql/segment";
import { LOAD_CHANNELS } from "src/graphql/channel";
import { getActiveOrgId } from "src/store/slices/userSlice";

const getItemStyle = ({ isDragging, isDropAnimating }, draggableStyle) => ({
  ...draggableStyle,
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "grey",

  ...(!isDragging && { transform: "translate(0,0)" }),
  ...(isDropAnimating && { transitionDuration: "0.001s" }),

  // styles we need to apply on draggables
});

const Table = ({
  showDetailsDrawer,
  handleSelectedContact,
  isSuccess,
  fetchTableData,
  fetchStaffData,
  fields,
  t,
  selectedContacts,
  setSelectedContacts,
  setIsAttributeDrawerOpen,
  handleDataForCountingSegmentation,
  selectedFilters,
  fetchSegmentData,
  // selectedSegment
}) => {
  const organizationId = useSelector(getActiveOrgId);
  function removeBot(tabledata, contactsData) {
    if (!tabledata || !contactsData) {
      return [];
    }
    const contact_data = contactsData?.getOrganization?.leads;
    const dataWithoutBots = tabledata.filter((tx) => {
      let username = "";
      for (let i = 0; i < contact_data.length; ++i) {
        if (contact_data[i]?.id === tx?.leadId) {
          username = contact_data[i]?.username;
          break;
        }
      }
      return username !== "Hybrid Chat Bot";
    });
    return dataWithoutBots;
  }

  const userDetailsObj = useSelector((store) => store?.user?.data);
  const {
    data: channelsData,
    loading: channelsDataLoading,
    error: channelsDataError,
  } = useQuery(LOAD_CHANNELS, {
    variables: {
      organizationId: organizationId,
    },
  });

  const [waChannel, setWaChannel] = useState([]);
  const [waDesktopChannel, setWaDesktopChannel] = useState([]);
  const [seperateLeads, setSeperateLeads] = useState(null);
  const [attrAndTypes, setAttrAndTypes] = useState([]);

  const [isSelectedAll, setIsSelectedAll] = useState(false);

  const updateContacts = (leadId) => {
    let copiedContacts = selectedContacts;
    let index = copiedContacts.indexOf(leadId);

    if (index === -1) {
      copiedContacts.push(leadId);
      setSelectedContacts([...copiedContacts]);
    } else {
      copiedContacts.splice(index, 1);
      setSelectedContacts([...copiedContacts]);
    }
  };
  useEffect(() => {
    if (channelsData === null || channelsData === undefined) return;
    setWaChannel(channelsData?.getOrganization?.channelData?.waChannel);
    setWaDesktopChannel(
      channelsData?.getOrganization?.channelData?.waDesktopChannel
    );
  }, [channelsData]);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {},
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  const [tableData, setTableData] = useState([]);
  const [initialTableData, setInitialTableData] = useState([]);
  const [usersData, setUsersData] = useState([]);

  const [finalLoading, setFinalLoading] = useState(true);

  const [defaultSort, setDefaultSort] = useState({
    id: "title",
    desc: true,
  });

  const { loading, error, data, refetch } = useQuery(GET_ORGANIZATION_DATA, {
    variables: {
      organizationId: organizationId,
    },
  });

  const {
    loading: contactsLoading,
    refetch: contactsRefetch,
    error: contactsError,
    data: contactsData,
  } = useQuery(GET_ORGANIZATION_CONTACTS_IDS, {
    variables: {
      organizationId: organizationId,
    },
  });

  const {
    loading: staffLoading,
    refetch: staffRefetch,
    error: staffError,
    data: staffData,
  } = useQuery(GET_ORGANIZATION_STAFF, {
    variables: {
      organizationId: organizationId,
    },
  });

  const {
    data: channelLeadData,
    error: leadError,
    loading: leadLoading,
  } = useQuery(LOAD_CHANNELS_LEAD_DATA, {
    // variables: {
    //   leadId: leadId
    // }
  });

  // console.log(channelLeadData?.queryHC_Lead)

  const {
    loading: allLeadsLoading,
    refetch: allLeadsRefetch,
    error: allLeadsError,
    data: allLeadsData,
  } = useQuery(GET_ORGANIZATION_CONTACTS, {
    variables: {
      organizationId: organizationId,
    },
  });

  const {
    data: attributeData,
    loading: attributeLoading,
    error: attributeError,
  } = useQuery(GET_ORGANIZATION_ATTRIBUTES, {
    variables: {
      organizationId: organizationId,
    },
  });

  const selectedSegment = useSelector(
    (state) => state?.contactReducer?.globalSelectedSegment
  );

  const {
    data: segmentData,
    loading: segmentDataLoading,
    error: segmentDataError,
  } = useQuery(GET_ONE_SEGMENT, {
    variables: {
      organizationId: organizationId,
      segmentId: selectedSegment,
    },
  });

  useEffect(() => {
    if (tableData?.length !== 0) {
      fetchTableData(tableData);
    }
  }, [tableData]);

  useEffect(() => {
    if (staffData) {
      setUsersData(transform(staffData?.getOrganization?.organizationStaff));
      fetchStaffData(transform(staffData?.getOrganization?.organizationStaff));
    }
  }, [staffData]);

  const currentColOrder = useRef();

  useEffect(() => {
    if (allLeadsData) {
      let leads = allLeadsData?.getOrganization?.leads;
      const filteredLeads = leads?.filter(
        (lead) =>
          lead?.normalAttributeValue?.length === 0 &&
          lead?.multiSelectAttributeValue?.length === 0 &&
          lead?.userAttributeValue?.length === 0
      );

      const leadIds = filteredLeads?.map((lead) => lead.id);

      setSeperateLeads(leadIds);
    }
  }, [allLeadsData]);

  function transform(data) {
    return data?.map(
      ({ staff: { id, firstName, lastName, email, ...rest } }) => ({
        id: id,
        firstName: firstName,
        lastName: lastName,
        email: email,
      })
    );
  }

  // console.log(MultiSelectInlineFields)

  function fixMissingFields(tableData, attrTypesAndData) {
    let updatedData = [];

    // Create a dictionary of attribute types for easier lookup
    let attrTypes = {};
    for (let attr of attrTypesAndData) {
      attrTypes[attr.id] = attr.type;
    }

    // Copy the first object and update its missing fields
    let firstObject = { ...tableData[0] };

    for (let attr of attrTypesAndData) {
      let attrId = attr.id;
      let attrName = attr.name;
      let attrType = attr.type;

      if (!(attrName in firstObject)) {
        firstObject[attrName] = [];
      }

      let lowercaseMainType =
        attrType.charAt(0).toLowerCase() + attrType.slice(1);
      if (
        !firstObject[attrName].some(
          (entry) => entry.mainType === lowercaseMainType
        )
      ) {
        firstObject[attrName].push({ mainType: lowercaseMainType });
      }
    }

    updatedData.push(firstObject);

    // Copy the remaining objects without making any changes
    updatedData.push(...tableData.slice(1));

    return updatedData;
  }

  useEffect(() => {
    // console.log(data)
    if (!data || loading || !contactsData || !channelLeadData) {
      // console.log(channelLeadData)
      setTableData([]);
      // setFinalLoading(false)
      setInitialTableData([]);
      return;
    }
    // console.log(data?.getOrganization,"one")
    let res = flattenAttributes(data?.getOrganization);
    // console.log(res,"two")

    res = getDataByLeadId(res);
    // console.log(res,"three")

    res = fillData(res);
    // console.log(res,"four")

    let allAttributes = [];

    allAttributes = allAttributes.concat(
      data.getOrganization.normalAttribute,
      data.getOrganization.multiSelectAttribute,
      data.getOrganization.userAttribute
    );

    // console.log(allAttributes)
    let types = allAttributes?.map((item) => {
      return {
        name: item.name,
        id: item.id,
        type: item.__typename,
        attrType: item?.type ? item?.type : "",
      };
    });

    if (seperateLeads === null) {
      setTableData(removeBot(res, contactsData));
      // setFinalLoading(false)
      setInitialTableData(removeBot(res, contactsData));
    } else {
      // console.log(res)
      let item = res[0];

      if (item) {
        item = simplifyObject(item);
        // console.log(item)
        let leadsArr = seperateLeads.map((it) => ({ ...item, leadId: it }));
        // console.log(leadsArr)
        // console.log(removeBot([...res, ...leadsArr], contactsData))
        // console.log(attrAndTypes,types)
        let d = fixMissingFields(
          removeBot([...res, ...leadsArr], contactsData),
          types
        );
        // console.log(d)
        // setTableData()
        setTableData(d);
        setInitialTableData(d);
        // setFinalLoading(false)
      } else {
        let leadsData = seperateLeads?.map((item) => ({
          leadId: item,
        }));
        setTableData(removeBot(leadsData, contactsData));
        setInitialTableData(removeBot(leadsData, contactsData));
      }
    }
  }, [data, loading, seperateLeads, contactsData, channelLeadData]);

  useEffect(() => {}, [tableData, contactsData]);

  const allRefetch = () => {
    refetch();
    contactsRefetch();
    staffRefetch();
    allLeadsRefetch();
  };

  useEffect(() => {
    if (isSuccess) {
      allRefetch();
    }
  }, [isSuccess]);

  function flattenAttributes(data) {
    let result = {};
    let allAttributes = [];

    allAttributes = allAttributes.concat(
      data.normalAttribute,
      data.multiSelectAttribute,
      data.userAttribute
    );

    // console.log(allAttributes)
    let types = allAttributes?.map((item) => {
      return {
        name: item.name,
        id: item.id,
        type: item.__typename,
        attrType: item?.type ? item?.type : "",
      };
    });

    setAttrAndTypes(types);

    allAttributes.forEach(function (attribute) {
      result[attribute.name] = [];

      if (attribute.normalAttributeValue) {
        attribute.normalAttributeValue.forEach(function (value) {
          const lead = value.lead
            ? {
              id: value.lead.id || "",
              username: value.lead.username || "",
              email: value.lead.email || "",
            }
            : {
              id: "",
              username: "",
              email: "",
            };

          result[attribute.name].push({
            id: value.id,
            value: value.value,
            mainType: "normalAttribute",
            attributeType: attribute.type,
            attrId: attribute.id,
            lead: lead,
          });
        });
      }

      if (attribute.multiSelectAttributeValue) {
        attribute.multiSelectAttributeValue.forEach(function (value) {
          const lead = value.lead
            ? {
              id: value.lead.id || "",
              username: value.lead.username || "",
            }
            : {
              id: "",
              username: "",
            };

          result[attribute.name].push({
            id: value.id,
            name: value.label !== null ? value.label.name : value.name,
            color: value.label !== null ? value.label.color : value.color,
            labelId: value.label !== null ? value.label.id : "",
            mainType: "multiSelectAttribute",
            attributeType: attribute?.attributeType,
            attributeId: attribute?.id,
            lead: lead,
          });
        });
      }

      if (attribute.userAttributeValue) {
        attribute.userAttributeValue.forEach(function (value) {
          const lead = value.lead
            ? {
              id: value.lead.id || "",
              username: value.lead.username || "",
            }
            : {
              id: "",
              username: "",
            };

          value.value.forEach(function (user) {
            result[attribute.name].push({
              mainType: "userAttribute",
              id: user.id,
              username: user.username,
              lead: lead,
              attributeId: attribute.id,
              attributeName: attribute.name,
            });
          });
        });
      }
    });

    return modifyData(result);
  }

  function modifyData(data) {
    let modifiedData = { ...data };

    for (let key in modifiedData) {
      if (!Array.isArray(modifiedData[key])) continue;

      modifiedData[key] = modifiedData[key].map((item) => {
        let leadId = item?.lead?.id;
        let leadUsername = item?.lead?.username;
        delete item?.lead;
        return { ...item, leadId, leadUsername };
      });
    }

    return modifiedData;
  }

  const [allAttrData, setAllAttrData] = useState({});

  function getDataByLeadId(data) {
    setAllAttrData(data);
    const leadIds = new Set();

    for (const key in data) {
      data[key].forEach((item) => {
        leadIds.add(item.leadId);
      });
    }

    const result = [];
    for (const leadId of leadIds) {
      const obj = {};
      for (const key in data) {
        obj[key] = data[key].filter((item) => item.leadId === leadId);
      }

      const channel = getSelectedChannelIdById(leadId, channelLeadData);
      obj.Channel = channel;

      result.push({ ...obj, leadId });
    }

    // console.log(result)
    return result;
  }

  function transormEmptyData(inputData) {
    // Create a list with the missing objects
    const missingObjects = [
      {
        accessor: "selectAll",
        Header: () => <Checkbox disabled />,
        id: "selectAll",
        sticky: "left",
        width: 50,
        disableSortBy: true,
      },
      {
        sticky: "left",
        accessor: "leadId",
        Header: "Title",
        id: "leadId",
        width: 250,
      },
      {
        id: "Channel",
        Header: "Channel",
        accessor: "Channel",
        width: 250,
      },
    ];

    // Create the transformed data with the missing objects and headers
    const transformedData = inputData.map((item) => ({
      id: item.name,
      Header: item.name,
      accessor: item.name,
      width: 250,
    }));

    // Concatenate the missing objects and the transformed data
    const result = missingObjects.concat(transformedData);

    return result;
  }

  function getSelectedChannelIdById(id, data) {
    // console.log(id, data)
    const lead = data?.queryHC_Lead?.find((item) => item.id === id);
    let val;
    if (lead?.selectedChannelType == "whatsappOfficial") {
      val = waChannel?.filter((item) => item.id == lead?.selectedChannelId)[0]
        ?.name;
    }
    return lead?.selectedChannelId !== null
      ? [
        {
          type: lead?.selectedChannelType,
          val: val,
        },
      ]
      : [
        {
          type: "",
          val: "",
        },
      ];
  }

  // console.log(allAttrData,attrAndTypes)

  function fillData(data) {
    const keys = new Set();
    data.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (key !== "leadId") keys.add(key);
      });
    });

    data.forEach((item) => {
      // console.log(data)
      keys.forEach((key) => {
        if (!item[key]) {
          item[key] = [];
        }
        if (item[key].length === 0) {
          data.forEach((entry) => {
            if (entry[key].length > 0) {
              const mainType = entry[key][0].mainType;
              const attributeType = entry[key][0].attributeType;
              const attrId = entry[key][0].attrId;

              item[key].push({ mainType, attributeType, attrId });
            }
          });
        }
      });
    });
    return data;
  }

  function compare(key) {
    // console.log(key,attrAndTypes)
    const attr = attrAndTypes.find((item) => item.name === key);
    return attr ? attr.type : null;
  }

  function simplifyObject(obj) {
    // console.log(obj,"asdasd")
    // console.log(attrAndTypes)
    const newObj = {};

    Object.entries(obj).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        const mainType = value[0]?.mainType || compare(key);
        newObj[key] = [
          {
            mainType: mainType
              ? mainType.charAt(0).toLowerCase() + mainType.slice(1)
              : null,
          },
        ];
      } else if (key !== "leadId") {
        newObj[key] = value;
      }
    });
    // console.log(newObj)

    return newObj;
  }

  const [columns, setColumns] = useState([]);

  // console.log(attrAndTypes)

  useEffect(() => {
    let columns = [];
    if (tableData?.length == 0) {
      /*
        This is the line causing the error where contact page is causing OOPS! page.
        Apart from it, the condition for tableDate?.length === 0 is not correct.
        It's supposed to run only when there is no contact in table but it's also 
        running when there are contacts in the table. Fetching the table data usually
        takes time during which tableData will be empty causing this condition to run.
      */
      //setColumns(transormEmptyData(attrAndTypes))
      setFinalLoading(false);
      return;
    }
    tableData.map((tx) => {
      tx.selectAll = tx?.leadId;
    });
    let indexSelectAll = tableData.findIndex(
      (tx) => tx?.accessor === "selectAll"
    );
    for (const key in tableData[0]) {
      if (Object.prototype.hasOwnProperty.call(tableData[0], key)) {
        if (key === "leadId") {
          columns.unshift({
            Header: () =>
              featureFlag.ContactTableHeader ? <>{t("Title")}</> : t("Title"),
            sticky: "left",
            accessor: key,
            id: key,
            width: 250,
            Cell: ({ value, row }) => {
              return (
                <NameCell
                  index={row?.index}
                  selectedContacts={selectedContacts}
                  updateContacts={updateContacts}
                  contactsData={contactsData?.getOrganization?.leads}
                  value={value}
                  showDetailsDrawer={showDetailsDrawer}
                  handleSelectedContact={handleSelectedContact}
                />
              );
            },
          });
        } else if (key === "Channel") {
          columns.unshift({
            id: key,
            Header: key,
            accessor: key,
            width: 250,
            Cell: ({ value, row }) => {
              return (
                <ChannelCell
                  value={value}
                  waChannel={waChannel}
                  waDesktopChannel={waDesktopChannel}
                />
              );
            },
          });
        } else {
          let obj = tableData[0];
          let ty = obj[key][0]?.mainType;
          if (ty === "multiSelectAttribute") {
            columns.push({
              id: key,
              Header: key,
              accessor: key,
              width: 250,
              Cell: ({ value, row }) => {
                return (
                  <MultiSelectInlineFieldsCell
                    allRefetch={allRefetch}
                    attrId={
                      attrAndTypes?.filter((item) => item.name == key)[0]?.id
                    }
                    allAttrData={allAttrData}
                    value={value}
                    row={row}
                    accessor={key}
                  />
                );
              },
            });
          } else if (ty === "userAttribute") {
            columns.push({
              id: key,
              Header: key,
              accessor: key,
              width: 250,
              Cell: ({ value, row }) => {
                return (
                  <UserCell
                    allAttrData={allAttrData}
                    allRefetch={allRefetch}
                    attrId={attrAndTypes?.filter((item) => item.name == key)[0]}
                    value={value}
                    row={row}
                    accessor={key}
                    usersData={usersData}
                  />
                );
              },
            });
          } else {
            let isDate =
              attrAndTypes?.filter((item) => item.name == key)[0]?.attrType ==
              "Date";
            if (isDate) {
              columns.push({
                id: key,
                Header: key,
                accessor: key,
                width: 250,
                Cell: ({ value, row }) => {
                  return (
                    <DateCell
                      allAttrData={attributeData}
                      attrId={
                        attrAndTypes?.filter((item) => item.name == key)[0]
                      }
                      value={value}
                      row={row}
                      accessor={key}
                      allRefetch={allRefetch}
                    />
                  );
                },
              });
            } else {
              columns.push({
                id: key,
                Header: key,
                accessor: key,
                width: 250,
                Cell: ({ value, row }) => {
                  return (
                    <Cell
                      allAttrData={attributeData}
                      value={value}
                      row={row}
                      accessor={key}
                      allRefetch={allRefetch}
                    />
                  );
                },
              });
            }
          }
        }
      }
    }

    //shifting checkbox column to first column
    const selectAllIndex = columns.findIndex((tx) => tx.Header === "selectAll");
    const element = columns.splice(selectAllIndex, 1);
    if (element.length === 1) {
      let newColumn = {
        Header: () => (
          <Checkbox checked={isSelectedAll} onChange={selectAll}></Checkbox>
        ),
        accessor: "selectAll",
        id: "selectAll",
        sticky: "left",
        width: 50,
        disableSortBy: true,
        Cell: ({ value }) => {
          return (
            <ContactTableSelect
              selectedContacts={selectedContacts}
              updateContacts={updateContacts}
              value={value}
            />
          );
        },
      };
      columns.unshift(newColumn);
    }
    currentColOrder.current = columns.map((o) => o.id);
    setColumns(columns);
    setFinalLoading(false);
  }, [
    tableData,
    usersData,
    isSelectedAll,
    contactsData,
    allLeadsData,
    selectedContacts,
    data,
  ]);

  // const columns = useMemo(() => {
  //   const columns = [];
  //   tableData.map((tx) => {
  //     tx.selectAll = tx?.leadId;
  //   });
  //   let indexSelectAll = tableData.findIndex(
  //     (tx) => tx?.accessor === "selectAll"
  //   );
  //   // console.log(tableData)
  //   for (const key in tableData[0]) {
  //     if (tableData[0].hasOwnProperty(key)) {
  //       // console.log(tableData[0])
  //       console.log(key,"keyyyyyyyy")
  //       if (key === "leadId") {
  //         columns.unshift({
  //           Header: () =>
  //             featureFlag.ContactTableHeader ? <>{t("Title")}</> : t("Title"),
  //           accessor: key,
  //           id: key,
  //           width: 250,
  //           Cell: ({ value, row }) => {
  //             return (
  //               <NameCell
  //                 index={row?.index}
  //                 selectedContacts={selectedContacts}
  //                 updateContacts={updateContacts}
  //                 contactsData={contactsData?.getOrganization?.leads}
  //                 value={value}
  //                 showDetailsDrawer={showDetailsDrawer}
  //                 handleSelectedContact={handleSelectedContact}
  //               />
  //             );
  //           },
  //         });
  //       }

  //       else if (key === "Channel") {
  //         columns.unshift({
  //           id: key,
  //           Header: key,
  //           accessor: key,
  //           width: 250,
  //           Cell: ({ value, row }) => {
  //             return (
  //               <ChannelCell
  //               value={value}
  //               waChannel={waChannel}
  //               waDesktopChannel={waDesktopChannel}                />
  //             );
  //           },
  //         });
  //       }

  //       else {
  //         let obj = tableData[0];
  //         console.log(obj)
  //         let ty = obj[key][0]?.mainType;
  //         console.log(ty)
  //         if (ty === "multiSelectAttribute") {
  //           columns.push({
  //             id: key,
  //             Header: key,
  //             accessor: key,
  //             width: 250,
  //             Cell: ({ value, row }) => {
  //               return <MultiSelectInlineFieldsCell allRefetch={allRefetch} attrId={attrAndTypes?.filter((item) => item.name == key)[0]?.id} allAttrData={allAttrData} value={value} row={row} accessor={key} />
  //             },
  //           });
  //         } else if (ty === "userAttribute") {
  //           columns.push({
  //             id: key,
  //             Header: key,
  //             accessor: key,
  //             width: 250,
  //             Cell: ({ value, row }) => {
  //               return <UserCell allAttrData={allAttrData} allRefetch={allRefetch} attrId={attrAndTypes?.filter((item) => item.name == key)[0]} value={value} row={row} accessor={key} usersData={usersData} />;
  //             },
  //           });
  //         } else {
  //           let isDate = attrAndTypes?.filter((item)=>item.name == key)[0]?.attrType=="Date";
  //           if(isDate){
  //             columns.push({
  //               id: key,
  //               Header: key,
  //               accessor: key,
  //               width: 250,
  //               Cell: ({ value, row }) => {
  //                 return <DateCell allAttrData={attributeData} attrId={attrAndTypes?.filter((item) => item.name == key)[0]}  value={value} row={row} accessor={key} allRefetch={allRefetch} />;
  //               },
  //             });
  //           }else{
  //             columns.push({
  //               id: key,
  //               Header: key,
  //               accessor: key,
  //               width: 250,
  //               Cell: ({ value, row }) => {
  //                 return <Cell allAttrData={attributeData} value={value} row={row} accessor={key} allRefetch={allRefetch} />;
  //               },
  //             });
  //           }
  //         }
  //       }
  //     }
  //   }

  //   //shifting checkbox column to first column
  //   const selectAllIndex = columns.findIndex((tx) => tx.Header === "selectAll");
  //   const element = columns.splice(selectAllIndex, 1);
  //   if (element.length === 1) {
  //     let newColumn = {
  //       Header: () => (
  //         <Checkbox checked={isSelectedAll} onChange={selectAll}></Checkbox>
  //       ),
  //       accessor: "selectAll",
  //       id: "selectAll",
  //       width: 50,
  //       disableSortBy: true,
  //       Cell: ({ value }) => {
  //         return (
  //           <ContactTableSelect
  //             selectedContacts={selectedContacts}
  //             updateContacts={updateContacts}
  //             value={value}
  //           />
  //         );
  //       },
  //     };
  //     columns.unshift(newColumn);
  //   }
  //   currentColOrder.current = columns.map((o) => o.id);
  //   return columns;
  // }, [
  //   tableData,
  //   usersData,
  //   isSelectedAll,
  //   contactsData,
  //   allLeadsData,
  //   selectedContacts,
  //   data
  // ]);

  const onDragUpdate = (dragUpdateObj) => {
    const colOrder = [...currentColOrder.current];
    const sIndex = dragUpdateObj.source.index;
    const dIndex = dragUpdateObj.destination && dragUpdateObj.destination.index;

    if (
      typeof sIndex === "number" &&
      typeof dIndex === "number" &&
      dIndex !== 0
    ) {
      colOrder.splice(sIndex, 1);
      colOrder.splice(dIndex, 0, dragUpdateObj.draggableId);
      currentColOrder.current = colOrder;
      setColumnOrder(colOrder);
    }
  };

  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      initialState: {
        sortBy: [defaultSort],
        pageSize: 1000000, // a very large value
      },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useSticky
    // useAbsoluteLayout
  );

  const {
    getTableProps,
    getTableBodyProps,
    setColumnOrder,
    headerGroups,
    rows,
    page,
    nextPage,
    flatColumns,
    previousPage,
    pageOptions,
    state,
    canNextPage,
    canPreviousPage,
    prepareRow,
    gotoPage,
    pageCount,
    setPageSize,
    selectedFlatRows,
    setSortBy,
  } = tableInstance;

  const handleDefaultSort = (columnName, isDesc) => {
    const newSortBy = [
      {
        id: columnName,
        desc: isDesc, // Toggle the sort direction
      },
    ];

    setSortBy(newSortBy);
  };

  const selectAll = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!tableData) return;
    if (e.target.checked === true) {
      let allData = tableData.map((tx) => tx?.leadId);
      setSelectedContacts([...allData]);
    } else {
      setSelectedContacts([]);
    }
  };

  useEffect(() => {
    let isSel =
      tableData.length > 0 && selectedContacts.length === tableData.length;
    setIsSelectedAll(isSel);
  }, [selectedContacts, tableData]);

  // filterDataByColumn(tableData,selectedFilters)

  // console.log(segmentData?.getSegments?.segmentInformation)

  useEffect(() => {
    if (selectedSegment == "all") {
      setTableData(initialTableData);
    } else {
      if (segmentData !== undefined && selectedSegment !== "") {
        let data = filterDataByColumn(
          initialTableData,
          segmentData?.getSegments?.segmentInformation,
          attrAndTypes,
          waChannel
        );
        let executeSegmentBody = {
          segmentId: selectedSegment,
          organizationId: organizationId,
          leads: data?.map((item) => ({ id: item.leadId })),
        };
        executeSegmentation(executeSegmentBody)
          ?.then((res) => {})
          .catch((e) => {
            console.log(e, "res");
          });
        setTableData(
          filterDataByColumn(
            initialTableData,
            segmentData?.getSegments?.segmentInformation,
            attrAndTypes,
            waChannel
          )
        );
      }
    }
  }, [segmentData, selectedSegment, waChannel]);

  useEffect(() => {
    if (selectedFilters[0]?.category !== undefined) {
      setTableData(
        filterDataByColumn(
          initialTableData,
          selectedFilters,
          attrAndTypes,
          waChannel
        )
      );
    }
  }, [selectedFilters, waChannel]);

  useEffect(() => {
    handleDataForCountingSegmentation({
      initialTableData: initialTableData,
      waChannel: waChannel,
      attrAndTypes: attrAndTypes,
    });
  }, [initialTableData, attrAndTypes, waChannel]);

  // console.log()

  // console.log(attrAndTypes)

  useEffect(() => {
    if (segmentData) {
      fetchSegmentData(segmentData);
    }
  }, [segmentData]);

  const [currentPage, setCurrentPage] = useState(0);
  const perPage = 10; // Number of rows per page

  // Calculate the start and end indices for the current page
  const startIndex = currentPage * perPage;
  const endIndex = startIndex + perPage;

  // Slice the rows to display only the ones for the current page
  const displayedRows = rows.slice(startIndex, endIndex);

  // Define a function to handle page change
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <>
      {!finalLoading &&
      !loading &&
      !segmentDataLoading &&
      !contactsLoading &&
      !staffLoading &&
      !leadLoading ? (
          <>
            <div className="Table">
              <div className="Table__table">
                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <DragDropContext
                        onDragUpdate={onDragUpdate}
                        key={headerGroup.id}
                      >
                        <Droppable droppableId="droppable" direction="horizontal">
                          {(droppableProvided, snapshot) => (
                            <tr
                              {...headerGroup.getHeaderGroupProps()}
                              ref={droppableProvided.innerRef}
                              className="row header-group"
                            >
                              {headerGroup.headers.map((column, index) => (
                                <React.Fragment key={column.id}>
                                  {index === 0 ? (
                                    <Draggable
                                      key={column.id}
                                      draggableId={column.id}
                                      index={index}
                                      isDragDisabled={true}
                                    >
                                      {(provided, snapshot) => (
                                        <th {...column.getHeaderProps()}>
                                          <div
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                            style={{
                                              ...getItemStyle(
                                                snapshot,
                                                provided.draggableProps.style
                                              ),
                                            // ...style
                                            }}
                                          >
                                            {column.render("Header")}
                                          </div>
                                        </th>
                                      )}
                                    </Draggable>
                                  ) : (
                                    <Draggable
                                      key={column.id}
                                      draggableId={column.id}
                                      index={index}
                                      isDragDisabled={!column.accessor}
                                    >
                                      {(provided, snapshot) => (
                                        <SortingPopup
                                          allRefetch={allRefetch}
                                          organizationId={
                                            userDetailsObj?.activeOrg
                                              ?.organization?.id
                                          }
                                          column={column}
                                          attributeData={attributeData}
                                          handleDefaultSort={handleDefaultSort}
                                        >
                                          <th
                                            {...column.getHeaderProps()}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                              ...getItemStyle(
                                                snapshot,
                                                provided.draggableProps.style
                                              ),
                                            // ...style
                                            }}
                                          >
                                            {column.render("Header")}
                                            <span>
                                              {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                  <CaretUpOutlined />
                                                ) : (
                                                  <CaretDownOutlined />
                                                )
                                              ) : (
                                                ""
                                              )}
                                            </span>
                                          </th>
                                        </SortingPopup>
                                      )}
                                    </Draggable>
                                  )}
                                </React.Fragment>
                              ))}
                              {/* {droppableProvided.placeholder} */}
                            </tr>
                          )}
                        </Droppable>
                      </DragDropContext>
                    ))}
                  </thead>

                  <tbody {...getTableBodyProps()}>
                    {" "}
                    {displayedRows.map(
                      (row, i) =>
                        prepareRow(row) || (
                          <tr
                            {...row.getRowProps()}
                            className={`Table__disableborder ${
                              i % 2 !== 0 ? "Table__oddrow" : "Table__evenrow"
                            }`}
                            style={{ border: "0px" }}
                          >
                            {row.cells.map((cell, cellIndex) => {
                              return cell?.column?.Header === "Channels" ? (
                                <></>
                              ) : (
                              // <ContactChannels
                              //   leadId={cell?.row?.values?.Channels}
                              //   waChannel={waChannel}
                              //   waDesktopChannel={waDesktopChannel}
                              // />
                                <td
                                  {...cell.getCellProps()}
                                  className={`${
                                    i % 2 !== 0 ? "Table__oddcell" : "Table__evencell"
                                  } ${cellIndex === 1 ? "second-column" : ""}`}
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
                {/* <ReactPaginate
        pageCount={Math.ceil(rows.length / perPage)}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        onPageChange={handlePageChange}
        containerClassName={'pagination'}
        activeClassName={'active'}
      /> */}
              </div>
              <div className="Table__NewColumn">
                <div
                  className="Table__NewColumn__Header"
                  onClick={() => {
                    setIsAttributeDrawerOpen(true);
                  }}
                >
                  <PlusOutlined />
                </div>
              </div>
            </div>
            {tableData?.length == 0 && (
              <div>
                <p style={{ textAlign: "center", margin: "12px 0px" }}>
                No Contacts Found
                </p>
              </div>
            )}
          </>
        ) : (
          <div className={"Table__loader"}>
            <Spin size="large" indicator={<LoadingOutlined spin />} />
          </div>
        )}
      <div className="pagination_container">
        {tableData?.length !== 0 &&
          !finalLoading &&
          !loading &&
          !segmentDataLoading &&
          !contactsLoading &&
          !staffLoading &&
          !leadLoading && (
          <ReactPaginate
            pageCount={Math.ceil(rows.length / perPage)}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
            nextLabel=">"
            previousLabel="<"
          />
        )}
      </div>
    </>
  );
};
export default Table;
