import React from 'react';
import style from './Dropdown.module.scss';
import Avatar from 'antd/es/avatar/avatar';
import { useSelector } from 'react-redux';
import { useState, useEffect } from "react";
import { NavLink as Link, useNavigate } from "react-router-dom";
import ChatIcon from "src/assets/svgs/ChatIcon";
import { Tooltip } from "antd";
import { useSubscription } from "@apollo/client";
import { getActiveOrg, getActiveOrgId } from "src/store/slices/userSlice";
import { LOAD_UNREPLIEDCOUNTS } from 'src/graphql/thread';
import { ContactsOutlined } from '@ant-design/icons';
import { QuestionCircleOutlined } from "@ant-design/icons";
import SettingsIcon from 'src/assets/svgs/SettingsIcon';
import NodeIcon from 'src/assets/svgs/NodeIcon';

const DropdownMenu = ({ isOpen,toggleMenu }) => {

  const organizationId = useSelector(getActiveOrgId);
  const isActive = ({ isActive }) => {
    if (isActive) {
      return true;
    } else {
      return false;
    }
  };
  const {
    data: { nodeView: nodeViewEnabled },
  } = useSelector((state) => state?.user);

  const [profileImageSrc, setProfileImageSrc] = useState(
    "https://picsum.photos/50"
  );
  const [statusColor, setStatusColor] = useState("#00E676");
  const activeOrg = useSelector(getActiveOrg);

  function getUnrepliedCount(leads) {
    let sum = 0;

    if (leads !== undefined) {
      for (let lead of leads) {
        const unRepliedCount = lead.unRepliedCount;
        if (unRepliedCount !== null && typeof unRepliedCount !== "undefined") {
          sum += unRepliedCount;
        }
      }
    }

    return sum;
  }

  // console.log(activeOrg)

  const {
    data: leadData,
    error: leadError,
    loading: leadLoading,
  } = useSubscription(LOAD_UNREPLIEDCOUNTS, {
    variables: {
      organizationId: organizationId,
    },
  });

  // console.log(leadData)
    const { firstName, username } = useSelector((state) => state?.user?.data);
  return (
    <div className={`${style['dropdown-container']} ${isOpen ? style.show : ''}`}>
      <div className={style['dropdown-header']}>
        {/* <img src="path/to/profile-image.jpg" alt="Profile" width="40" height="40" /> */}
        <Avatar style={{backgroundColor: '#055b7f', marginRight:'4px' }}>PG</Avatar> 
        <div className={style.userName}>{username}</div>
      </div>
      <ul className={style['dropdown-menu']}>
          <Link to="/chat">
            {({ isActive }) => (
              <>
                <div 
                  className={
                    isActive
                      ? style.SideNavMobile__active
                      : style.SideNavMobile__icon
                  }
                  onClick={toggleMenu}
                >
                <li>
                  <span className={style.listItem} role="img" aria-label="conversations"><ChatIcon isActive={isActive} /></span>
                  <span style={{textDecoration:'none'}}>Conversations</span>
                </li>
                  {/* {getUnrepliedCount(leadData?.getOrganization?.leads) !==
                    0 && (
                    <span className={style.SideNavMobile__count}>
                      {getUnrepliedCount(leadData?.getOrganization?.leads)}
                    </span>
                  )} */}
                  
                </div>
              </>
            )}
          </Link>
          <Link to="/contacts">
            {({ isActive }) => (
              <>
                <div
                  className={
                    isActive
                      ? style.SideNavMobile__active
                      : style.SideNavMobile__icon
                  }
                  onClick={toggleMenu}
                >
                    <li>
                    <ContactsOutlined />
                  <span className={style.listItem}>Contacts</span>
                </li>
                 
                </div>
              </>
            )}
          </Link>
          <Link to="/settings">
            {({ isActive }) => (
              <>
                <div
                  className={
                    isActive
                      ? style.SideNavMobile__active
                      : style.SideNavMobile__icon
                  }
                  onClick={toggleMenu}
                >
                    <li>
                    <SettingsIcon isActive={isActive} />
                  <span className={style.listItem}>Settings</span>
                </li>
                 
                </div>
              </>
            )}
          </Link>
          <Link to="/settings">
            {({ isActive }) => (
              <>
                <div
                  className={
                    isActive
                      ? style.SideNavMobile__active
                      : style.SideNavMobile__icon
                  }
                  onClick={toggleMenu}
                >
                    <li>
                    <QuestionCircleOutlined  style={{ color: "#262626", fontSize: "21px" }} isActive={isActive} />
                  <span className={style.listItem}>Help</span>
                </li>
                 
                </div>
              </>
            )}
          </Link>
        {nodeViewEnabled && (
          <Link to="/node">
            {({ isActive }) => (
              <>
                <div
                  className={
                    isActive
                      ? style.SideNavMobile__active
                      : style.SideNavMobile__icon
                  }
                  onClick={toggleMenu}
                >
                    <li>
                    <NodeIcon isActive={isActive} />
                  <span className={style.listItem}>Time Tracker</span>
                </li>
                 
                </div>
              </>
            )}
          </Link>
        )}
      </ul>
    </div>
  );
};

export default DropdownMenu;
