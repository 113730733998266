import React, { useEffect, useState } from "react";
import IntentionChart from "./components/IntentionChart";
import VisitorStatistics from "./components/VisitorStatistics";
import style from './Analytics.module.scss';
import TopQueries from "./components/QueriesList";
import ConversationStatus from "./components/ConversationStatus";
import Quotations from "./components/Quotations";
import Header1 from "./components/Header";
import { getAnalyticsData, getConversationStats } from "src/api/AnalyticsApi";
import { timeSheetTimerConfig } from "../Dashboard/configs/dashboardConfig";
import moment from "moment";
import { getActiveOrgId } from 'src/store/slices/userSlice';
import {  useSelector } from "react-redux";
import { getStaffPeople } from "src/api/OrganizationApi";

const Analytics = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRange, setSelectedRange] = useState(timeSheetTimerConfig);
  const [selectedSalesRepEmail,setSelectedSalesRepEmail] = useState("")
  const [analyticsData, setAnalyticsData] = useState({
    visitors_statistics: {},
    intention_statistics: {},
    conversation_statistics: {},
    quotations_statistics: {},
    datewise_query_statistics: [],
    top_queries_raised: [],
    names_of_leads: []
  });
  const [conversationStatistics,setConversationStatistics] = useState({})
  const [staffList, setStaffList] = useState([]);
  const organizationId = useSelector(getActiveOrgId);

 
  useEffect(() => {
    fetchAnalyticsData();
    fetchConversationStats();
    const intervalId = setInterval(() => {
      fetchAnalyticsData();
      fetchConversationStats();
    }, 10000);

    return () => clearInterval(intervalId); 
  }, [selectedRange, selectedSalesRepEmail]);

  const handleRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      const startDate = dates[0].startOf("day").toISOString();
      const endDate = dates[1].endOf("day").toISOString();
      setSelectedRange({ startDate, endDate });
    }
  };

  const fetchAnalyticsData = async () => {
    try {
      setIsLoading(true);
      let params = {
        start_date: moment(selectedRange.startDate).format('YYYY-MM-DD'),
        end_date: moment(selectedRange.endDate).format('YYYY-MM-DD')
      }
      const response = await getAnalyticsData(params);
      if (response && response.data && response.data.data) {
        setAnalyticsData(response.data.data);
      } else {
        setAnalyticsData({
          visitors_statistics: {},
          intention_statistics: {},
          conversation_statistics: {},
          quotations_statistics: {},
          datewise_query_statistics: [],
          top_queries_raised: []
        });
      }
    } catch (e) { 
      console.log(e);
      setAnalyticsData({
        visitors_statistics: {},
        intention_statistics: {},
        conversation_statistics: {},
        quotations_statistics: {},
        datewise_query_statistics: [],
        top_queries_raised: []
      });
    } finally {
      setIsLoading(false);
    }
  };
  const fetchConversationStats = async () => {
    try {
      setIsLoading(true);
      let params = {
        start_date: moment(selectedRange.startDate).format('YYYY-MM-DD'),
        end_date: moment(selectedRange.endDate).format('YYYY-MM-DD'),
        sales_rep_email: selectedSalesRepEmail
      }
      const response = await getConversationStats(params);
      if (response && response.data && response.data.data) {
        setConversationStatistics(response.data.data);
      } 
    }
    catch(e) {
      console.log(e)
    }

  }


  useEffect(() => {
    let payload = { organizationId };
      getStaffPeople(payload)
        .then(({ data }) => {
          if (data.success) {
            setStaffList(data.data.staff);
          }
        })
  }, []);

  return (
    <div className={style.dashboard}>
      <Header1 handleRangeChange={handleRangeChange} selectedRange={selectedRange} setSelectedRange={setSelectedRange} />
      <div className={style.topSection}>
        <IntentionChart data={analyticsData.datewise_query_statistics} />
        <TopQueries data={analyticsData.top_queries_raised} />
        <VisitorStatistics data={analyticsData.visitors_statistics} leadData={analyticsData.names_of_leads} selectedRange={selectedRange}  />
        <ConversationStatus data={conversationStatistics} staffList={staffList} setSelectedSalesRepEmail={setSelectedSalesRepEmail} />
        <Quotations data={analyticsData.quotations_statistics} />
      </div>
    </div>
  );
};

export default Analytics;
