import React, { useEffect, useState } from "react";

import { Button, Input, message, Modal, Popover, Typography } from "antd";

import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  BorderlessTableOutlined,
  CalendarOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  FontSizeOutlined,
  GlobalOutlined,
  LinkOutlined,
  MailOutlined,
  PhoneOutlined,
  ReloadOutlined,
  TagsOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { deleteAttributeOrg, renameAttribute } from "src/api/ContactApi";
import styles from "./SortingPopup.module.scss";
import { getActiveOrgId } from "src/store/slices/userSlice";
import { useSelector } from "react-redux";

const { Text } = Typography;

const defaultAttributes = [
  "Title",
  "Phone number",
  "Email",
  "Twilio Whatsapp Number",
  "Channels",
  "Assignee",
  "Tags",
];

const sortData = [
  { key: "asc", icon: "up", text: "Sort Ascending" },
  { key: "desc", icon: "down", text: "Sort Descending" },
];

const SortingPopup = ({
  column,
  attributeData,
  handleDefaultSort,
  allRefetch,
  children,
}) => {
  const organizationId = useSelector(getActiveOrgId);
  let type = getTypeByName(attributeData, column?.Header);
  let dataObject = formatData(attributeData, column?.Header);
  const possibleAttributes = [
    "userAttribute",
    "multiSelectAttribute",
    "normalAttribute",
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [openSortingPopup, setOpenSortingPopup] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [name, setName] = useState("");

  const onChange = (e) => {
    setName(e?.target?.value);
  };

  useEffect(() => {
    setName(column?.Header);
  }, [column]);

  const hideSortingPopup = () => {
    setOpenSortingPopup(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpenSortingPopup(newOpen);
  };

  const handleDelete = () => {
    setIsModalVisible(true);
    hideSortingPopup();
  };

  const handleConfirmDelete = () => {
    // Handle delete logic here
    setIsDeleting(true);
    deleteAttributeOrg(dataObject)
      .then(() => {
        setIsDeleting(false);
        allRefetch();
        setTimeout(() => {
          message.success("Attribute Deleted Successfully");
        }, 1000);
      })
      .catch((e) => {
        message.error("Something went wrong. Try again later.");
        setIsDeleting(false);
      });
    setIsModalVisible(false);
  };

  const handleCancelDelete = () => {
    setIsModalVisible(false);
  };

  const handleSort = (id, val) => {
    handleDefaultSort(id, val);
    hideSortingPopup();
  };
  function formatData(data, name) {
    const result = {};

    if (!data?.getOrganization || typeof data?.getOrganization !== "object") {
      return {};
    }

    for (const attributeType in data?.getOrganization) {
      if (attributeType.endsWith("Attribute")) {
        const attributes = data?.getOrganization[attributeType];
        for (const attribute of attributes) {
          if (attribute.name === name) {
            result[attributeType] = { id: attribute.id };
          }
        }
      }
    }

    result.organizationId = organizationId;
    return result;
  }

  function getTypeByName(data, name) {
    try {
      const normalAttributes = data?.getOrganization.normalAttribute;
      const multiSelectAttributes = data?.getOrganization.multiSelectAttribute;
      const userAttributes = data?.getOrganization.userAttribute;

      // Check in normalAttribute
      if (normalAttributes) {
        for (const normalAttribute of normalAttributes) {
          if (normalAttribute.name === name) {
            return normalAttribute.type;
          }
        }
      }

      // Check in multiSelectAttribute
      if (multiSelectAttributes) {
        for (const multiSelectAttribute of multiSelectAttributes) {
          if (multiSelectAttribute.name === name) {
            return multiSelectAttribute.__typename;
          }
        }
      }

      // Check in userAttribute
      if (userAttributes) {
        for (const userAttribute of userAttributes) {
          if (userAttribute.name === name) {
            return userAttribute.__typename;
          }
        }
      }

      return null;
    } catch (error) {
      // Handle any errors that occurred during the data processing
      console.error("Error occurred while processing data:", error);
      return null;
    }
  }

  const returnIcon = (type) => {
    switch (type) {
    case "Text":
      return (
        <FontSizeOutlined className={styles.SortingPopup__header__icons} />
      );
    case "Numbers":
      return (
        <BorderlessTableOutlined
          className={styles.SortingPopup__header__icons}
        />
      );
    case "MultiSelectAttribute":
      return <TagsOutlined className={styles.SortingPopup__header__icons} />;
    case "Status":
      return (
        <ReloadOutlined className={styles.SortingPopup__header__icons} />
      );
    case "Date":
      return (
        <CalendarOutlined className={styles.SortingPopup__header__icons} />
      );
    case "UserAttribute":
      return <UserOutlined className={styles.SortingPopup__header__icons} />;
    case "PhoneNumber":
      return <PhoneOutlined className={styles.SortingPopup__header__icons} />;
    case "EmailAddress":
      return <MailOutlined className={styles.SortingPopup__header__icons} />;
    case "SocialMedia":
      return (
        <GlobalOutlined className={styles.SortingPopup__header__icons} />
      );
    case "URL":
      return <LinkOutlined className={styles.SortingPopup__header__icons} />;
    default:
      return null;
    }
  };
  function isValidAttribute() {
    const attributeType = Object.keys(dataObject)?.[0];
    return attributeType && possibleAttributes.includes(attributeType);
  }

  const handleBlur = (name) => {
    let renameData = transformObject(dataObject);
    renameData = { ...renameData, updatedName: name };
    renameAttribute(renameData)
      ?.then(() => {
        message.success("Name updated successfully.");
        allRefetch();
      })
      .catch(() => {
        message.error("Cannot update name . Please try again later.");
      });
    // console.log(renameData);
  };

  function capitalizeFirstLetter(str) {
    if (typeof str !== "string" || str.length === 0) {
      return str; // Return the input as it is if it's not a string or empty
    }

    const firstLetter = str.charAt(0).toUpperCase();
    const restOfString = str.slice(1);

    return firstLetter + restOfString;
  }

  function transformObject(obj) {
    const key = Object.keys(obj)[0]; // Assuming only one key exists in the object
    return {
      attributeType: capitalizeFirstLetter(key),
      attributeId: obj[key].id,
      organizationId: obj.organizationId,
    };
  }

  // console.log(column?.Header)

  return (
    <Popover
      content={
        <div className={styles.SortingPopup} data-testid="SortingPopup">
          {type !== null &&
            isValidAttribute() &&
            !defaultAttributes.includes(column?.Header) && (
            <div className={styles.SortingPopup__header}>
              <input
                value={name}
                className={styles.SortingPopup__header__Input}
                type="text"
                onChange={onChange}
                onBlur={() => handleBlur(name)}
              />
              {type !== null && (
                <Input
                  value={type}
                  className={styles.SortingPopup__header__input}
                  disabled
                  prefix={returnIcon(type)}
                />
              )}
            </div>
          )}
          <div className={styles.SortingPopup__body}>
            <div>
              {sortData?.map((item) => (
                <div
                  key={item.key}
                  className={styles.SortingPopup__body__sortrow}
                  onClick={() =>
                    handleSort(column?.id, item.icon === "up" ? true : false)
                  }
                >
                  {item.icon === "up" ? (
                    <ArrowUpOutlined
                      className={styles.SortingPopup__body__sortrow__icon}
                    />
                  ) : (
                    <ArrowDownOutlined
                      className={styles.SortingPopup__body__sortrow__icon}
                    />
                  )}
                  <Text className={styles.SortingPopup__body__sortrow__text}>
                    {item.text}
                  </Text>
                </div>
              ))}
            </div>
          </div>
          {type !== null &&
            isValidAttribute() &&
            !defaultAttributes.includes(column?.Header) && (
            <div className={styles.SortingPopup__footer}>
              <Button
                type="text"
                danger
                icon={<DeleteOutlined />}
                className={styles.SortingPopup__footer__delete}
                onClick={handleDelete}
              >
                  Delete
              </Button>
            </div>
          )}
          {/* <Modal
            closable={false}
            className={styles.modal}
            title={null}
            visible={isModalVisible}
            onOk={handleConfirmDelete}
            onCancel={handleCancelDelete}
            Header
            footer={[
              <Button key="cancel" onClick={handleCancelDelete}>
                Cancel
              </Button>,
              <Button
                key="delete"
                type="primary"
                danger
                onClick={handleConfirmDelete}
                loading={isDeleting}
              >
                Delete
              </Button>,
            ]}
          >
            <div className={styles.modalContent}>
              <div className={styles.modalContent__left}>
                <WarningOutlined />
              </div>
              <div className={styles.modalContent__right}>
                <h4>Delete</h4>
                <span>
                  The attribute will be deleted for everyone. <br></br>Are you
                  sure you want to delete the <b>{column?.Header}</b> attribute?
                </span>
              </div>
            </div>
          </Modal> */}

          <Modal
            className={styles.DeleteModal__Modal}
            // open={isDeleteModalOpen}
            footer={null}
            closable={false}
            header={false}
            title={null}
            visible={isModalVisible}
            onOk={handleConfirmDelete}
            onCancel={handleCancelDelete}
          >
            <div className={styles.DeleteModal}>
              <div className={styles.DeleteModal__Body}>
                <div className={styles.DeleteModal__Body__IconContainer}>
                  <ExclamationCircleOutlined
                    style={{ color: "#FF4D4F", fontSize: "21px" }}
                  />
                </div>
                <div className={styles.DeleteModal__Body__ContentContainer}>
                  <h5>Delete</h5>
                  <p>
                    The attribute will be deleted for everyone. <br></br>Are you
                    sure you want to delete the <b>{column?.Header}</b>{" "}
                    attribute?
                  </p>
                </div>
              </div>
              <div className={styles.DeleteModal__Footer}>
                <Button
                  onClick={() => {
                    handleConfirmDelete();
                  }}
                  type="primary"
                  delete
                  danger
                  loading={isDeleting}
                >
                  Delete
                </Button>
                <Button
                  onClick={() => {
                    handleCancelDelete();
                  }}
                  type="ghost"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      }
      title={null}
      trigger="click"
      open={openSortingPopup}
      onOpenChange={handleOpenChange}
    >
      {children}
    </Popover>
  );
};

export default SortingPopup;
