import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Input, message, Select } from "antd";
import { addManualTimeFormConfig } from "src/components/Dashboard/configs/dashboardConfig"; 
import { addManualTimesheetData } from "src/api/TimesheetApi";
import { useSelector } from "react-redux";

const { Option } = Select;

const AddManualTimeButton = ({ selectedUserId, fetchTableData, staffList }) => {
  const [visible, setVisible] = useState(false);
  const profileDetails = useSelector(
    (state) => state?.profileDetails?.data?.profileDetails
  );
  const { email, role: designation } = profileDetails || {};

  const [formData, setFormData] = useState({
    email: email, // Set default value for email field
    startDate: "",
    endDate: "",
    project: "",
  });

  useEffect(() => {
    if (designation === "admin") {
      setFormData({
        ...formData,
        email: "", // Clear email if admin to display staff dropdown
      });
    }
  }, [profileDetails]);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = async () => {
    try {
      if (
        !formData.email ||
        !formData.startDate ||
        !formData.endDate ||
        !formData.project
      ) {
        message.error("All fields are required");
        throw new Error("All fields are required");
      }
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(formData.email)) {
        message.error("Please enter a valid email address");
        throw new Error("Please enter a valid email address");
      }
      const formattedData = {
        ...formData,
        startDate: formatDate(formData.startDate),
        endDate: formatDate(formData.endDate),
      };

      const res = await addManualTimesheetData(formattedData, selectedUserId);
      message.success(res.data.message);
      fetchTableData();
      setFormData({
        email: email, // Reset email field to default value
        username: "",
        startDate: "",
        endDate: "",
        project: "",
      });
      setVisible(false);
    } catch (error) {
      console.error("Error adding manual time:", error);
    }
  };

  const handleInputChange = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString();
  };

  return (
    <div>
      <Button
        style={{
          marginLeft: "10px",
          background: "#055b7f",
          borderRadius: "10px",
        }}
        type="primary"
        onClick={showModal}
      >
        Add Manual Time
      </Button>
      <Modal
        title="Add Manual Time"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form layout="vertical">
          {addManualTimeFormConfig.fields.map((field) => {
            if (field.name === "email") {
              return (
                <Form.Item
                  label={field.label}
                  name={field.name}
                  key={field.name}
                >
                  <Select
                    placeholder={field.label}
                    value={formData[field.name]}
                    onChange={(value) => handleInputChange(field.name, value)}
                  >
                    {staffList.map((staff) => (
                      <Option key={staff.staff.email} value={staff.staff.email}>
                        {staff.staff.email}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            } else {
              return (
                <Form.Item
                  label={field.label}
                  name={field.name}
                  key={field.name}
                >
                  <Input
                    type={field.type}
                    placeholder={field.label}
                    value={formData[field.name]}
                    onChange={(e) =>
                      handleInputChange(field.name, e.target.value)
                    }
                  />
                </Form.Item>
              );
            }
          })}
        </Form>
      </Modal>
    </div>
  );
};

export default AddManualTimeButton;