let featureFlag = {
  ImportButton: true,
  CustomizeField: false,
  FilterButton: false,
  ContactSearch: false,
  ContactTableHeader: true,
  DocsTab: false,
  AddTab: false,
  NodeChat: false,
  ContactBar: false,
  ChatBar: true,
  NotificationBar: false,
};
export default featureFlag;
