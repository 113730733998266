export const allowedOptions = [
  //   {
  //   value: "Assign to agent",
  //   label: "Assign to agent",
  // },
  {
    value: "SENDAMESSAGE",
    label: "Send a message",
  },
  // {
  //   value: "Schedule a message",
  //   label: "Schedule a message",
  // },
  // {
  //   value: "Notify",
  //   label: "Notify",
  // },
  // {
  //   value: "Assigned to",
  //   label: "Assigned to",
  // },
  // {
  //   value: "Add tag",
  //   label: "Add tag",
  // },
];

export const sendOptions = [
  {
    value: "SENDNOW",
    label: "Send now",
  },
  {
    value: "CUSTOM",
    label: "Custom",
  },
  {
    value: "ONDATE",
    label: "on Date",
  },
];

export const periodOptions = [
  {
    value: "days from now",
    label: "Days from now",
  }
];

export const attributeOptions = {
  "Add label": [
    {
      value: "Products",
      label: "Products",
    },
    {
      value: "Stock",
      label: "Stock",
    },
    {
      value: "Order Status",
      label: "Order Status",
    },
  ],
  // "Assign to agent": [],
  // "Notify agent": []
};

export const allActionAttributeOptions = {
  Products: {
    placeholder: "Choose Label",
    options: [
      {
        value: "Wallpaper",
        label: "Wallpaper",
      },
      {
        value: "Poster",
        label: "Poster",
      },
      {
        value: "Furniture",
        label: "Furniture",
      },
    ],
  },
};
