import React from 'react';
import { Spin } from 'antd';
import styles from './SuspenseSpinner.module.scss'
import { LoadingOutlined } from '@ant-design/icons';

const SuspenseSpinner = () => (
  <div className={styles.SuspenseSpinner}>
    <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
  </div>
);

export default SuspenseSpinner;
