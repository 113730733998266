import { useEffect } from "react";
import { useSelector } from "react-redux";

const PyWebview = () => {
  const { access_token, refresh_token } = useSelector(({ auth }) => auth?.data);
  const {globalTimerRunning} = useSelector((state) => state?.timer?.data);
  const user_data = useSelector(({ user }) => user?.data);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault()
      if (window.pywebview) {
        const message = "Are you sure you want to leave?";
        event.returnValue = message;
        return message;
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (window && window.pywebview && window.pywebview.api) {
      let { set_user_info, set_user_token } = window.pywebview.api;
      set_user_token({ access_token, refresh_token });
      set_user_info({ user_data });
    }
  }, [globalTimerRunning,access_token, refresh_token, user_data]);

  return;
};

export default PyWebview;
