import LocationApiClient from "./clients/LocationApiClient";

export const getAcessToken = async (header) => {
  return LocationApiClient({
    method: "GET",
    url: "/getaccesstoken",
    headers: header
  });
};

export const getContries = async () => {
  return LocationApiClient({
    method: "GET",
    url: "/countries",
  });
};


export const getStates = async (country) => {
    return LocationApiClient({
      method: "GET",
      url: `/states/${country}`,
    });
  };
  