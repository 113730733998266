import React from "react";
import PropTypes from "prop-types";
import { FileTextOutlined, CloseOutlined } from "@ant-design/icons";

import styles from "./ListFile.module.scss";

const ListFile = ({ file, removeFile, customkey }) => (
  <div className={styles.ListFile} key={customkey}>
    <div className={styles.ListFile__ImageContainer}>
      <FileTextOutlined className={styles.ListFile__Icons} />
    </div>
    <div className={styles.ListFile__NameContainer}>
      {file.name}
    </div>
    <div className={styles.ListFile__CloseContainer}>
      <CloseOutlined className={styles.ListFile__Icons} onClick={() => removeFile(file)} />
    </div>
  </div>
);

ListFile.propTypes = {};

ListFile.defaultProps = {};

export default ListFile;
