import React, { useEffect, useState } from "react";
import { Input, Drawer, Button, notification } from "antd";
import { useSelector } from "react-redux";
import TriggerIcon from "src/assets/AddRule.svg";
import styles from "./CreateAutomationRuleModal.module.scss";
import AutomationRuleBlock from "../AutomationRuleBlock/AutomationRuleBlock";
import AutomationActionBlock from "../AutomationActionBlock/AutomationActionBlock";
import { createAutomationRule } from "src/api/AutomationApi";
import { uploadAttachment } from "src/api/MessageApi";
import FormData from "form-data";
import { getActiveOrgId } from "src/store/slices/userSlice";
import { useTranslation } from "react-i18next";
import { generateRandomString } from "src/components/ContactsPage/components/FilterContactDrawer/helpers/helpers";

const CreateAutomationRuleModal = ({ refetch }) => {
  const { t } = useTranslation();
  const organizationId = useSelector(getActiveOrgId);
  const [automationName, setAutomationName] = useState("");
  const [conditionRelation, setConditionRelation] = useState("");
  const [showDrawer, setShowDrawer] = useState(false);
  const [condition, setCondition] = useState([]);
  const [action, setAction] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState([
    {
      id: 1,
      automationRuleCondition: "AND",
      blockCondition: "ANY",
      conditions: [
        {
          id: 1,
          scope: "Segment",
          segment: "",
          trigger: "",
          action: null,
        },
      ],
    },
  ]);
  const [automationState, setAutomationState] = useState([
    {
      id: generateRandomString(),
      action: null,
      source: "",
      whatsappRule: {
        channels: [],
        content: "",
        file: null,
      },
      apiChannelRule: {
        channels: [],
        content: "",
        file: null,
      },
      schedule: {
        type: "SENDNOW",
        timestamp: "",
      },
    },
  ]);

  const handleAutomationChange = (id, key, value, subKey) => {
    setAutomationState((prevState) => {
      const updatedState = prevState.map((item) => {
        if (item.id === id) {
          // Clone the item and update the specified key or subKey with the new value
          const updatedItem = { ...item };

          if (subKey) {
            // Update a property within the nested object
            updatedItem[key] = {
              ...updatedItem[key],
              [subKey]: value,
            };
          } else {
            // Update a property in the main object
            updatedItem[key] = value;
          }

          return updatedItem;
        }
        return item;
      });

      return updatedState;
    });
  };

  const handleAutomationNewItem = () => {
    setAutomationState([
      ...automationState,
      {
        id: generateRandomString(),
        action: null,
        source: "",
        whatsappRule: {
          channels: [],
          content: "",
          file: null,
        },
        apiChannelRule: {
          channels: [],
          content: "",
          file: null,
        },
        schedule: {
          type: "SENDNOW",
          timestamp: "",
        },
      },
    ]);
  };

  const isKeyEmpty = (object_array) => {
    for (let i = 0; i < object_array.length; ++i) {
      for (let key in object_array[i]) {
        if (object_array[i][key].trim() === "") {
          return false;
        }
      }
    }
    return true;
  };
  const isFormValid = () => {
    if (automationName.trim() === "") {
      return false;
    }
    if (conditionRelation !== "ANY" && conditionRelation !== "ALL") {
      return false;
    }
    if (!isKeyEmpty(condition)) {
      return false;
    }
    if (!isKeyEmpty(action)) {
      return false;
    }
    return true;
  };
  function transformData(inputData1, inputData2) {
    // Initialize the result object
    const result = {
      name: automationName,
      organization: {
        id: organizationId,
      },
      automationConditionsBlock: [],
      automationActions: [],
    };

    // Transform the first input data
    for (const item of inputData1) {
      const conditionBlock = {
        automationRuleCondition: item.automationRuleCondition,
        blockCondition: item.blockCondition,
        conditions: [],
      };

      for (const condition of item.conditions) {
        conditionBlock.conditions.push({
          scope: condition.scope,
          segment: {
            id: condition.segment,
          },
          trigger: condition.trigger,
          event: condition.action,
        });
      }

      result.automationConditionsBlock.push(conditionBlock);
    }

    // Transform the second input data
    for (const item of inputData2) {
      if (item.source === "Whatsapp") {
        const action = {
          action: item.action,
          source: item.source,
          whatsappRule: {
            channels: [
              {
                id: item.whatsappRule.channels,
              },
            ],
            content: item.whatsappRule.content,
          },
          schedule: {
            type: item.schedule.type,
          },
        };

        if (item.schedule.type !== "SENDNOW") {
          action.schedule.timestamp = String(item.schedule.timestamp);
        }

        if (item?.whatsappRule?.file) {
          action.whatsappRule.file = item?.whatsappRule?.file;
        }

        result.automationActions.push(action);
      } else if (item.source === "Apichannel") {
        const action = {
          action: item.action,
          source: item.source,
          apiChannelRule: {
            channels: [
              {
                id: item.apiChannelRule.channels,
              },
            ],
            content: item.apiChannelRule.content,
          },
          schedule: {
            type: item.schedule.type,
          },
        };

        if (item.schedule.type !== "SENDNOW") {
          action.schedule.timestamp = String(item.schedule.timestamp);
        }

        if (item?.apiChannelRule?.file) {
          action.apiChannelRule.file = item?.apiChannelRule?.file;
        }

        result.automationActions.push(action);
      }
    }

    return result;
  }

  function isDataValid(data) {
    // Check if any of the specified fields is empty or undefined
    if (
      !data.name ||
      !data.organization ||
      !data.organization.id ||
      !data.automationConditionsBlock ||
      !Array.isArray(data.automationConditionsBlock) ||
      data.automationConditionsBlock.some((block) => {
        return (
          !block.automationRuleCondition ||
          !block.blockCondition ||
          !block.conditions ||
          !Array.isArray(block.conditions) ||
          block.conditions.some((condition) => {
            return (
              !condition.scope ||
              !condition.segment ||
              !condition.segment.id ||
              !condition.trigger ||
              !condition.event
            );
          })
        );
      }) ||
      !data.automationActions ||
      !Array.isArray(data.automationActions) ||
      data.automationActions.length === 0 ||
      data.automationActions.some((action) => {
        return (
          !action.action ||
          !action.source ||
          (action.source === "Whatsapp" &&
            (!action.whatsappRule ||
              !action.whatsappRule.channels ||
              !Array.isArray(action.whatsappRule.channels) ||
              action.whatsappRule.channels.some((channel) => {
                return !channel.id;
              }) ||
              !action.whatsappRule.content)) ||
          (action.source === "Apichannel" &&
            (!action.apiChannelRule ||
              !action.apiChannelRule.channels ||
              !Array.isArray(action.apiChannelRule.channels) ||
              action.apiChannelRule.channels.some((channel) => {
                return !channel.id;
              }) ||
              !action.apiChannelRule.content)) ||
          !action.schedule ||
          !action.schedule.type
        );
      })
    ) {
      return false;
    }

    return true;
  }

  useEffect(() => {
    // const res=uploadAttachment(automationState.file)
    let obj = transformData(state, automationState);
    if (isDataValid(obj)) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [state, automationState]);

  const uploadSingleAttachment = async (file) => {
    try {
      let formData = new FormData();
      formData.append("file", file);
      let response = await uploadAttachment(formData);
      if (response?.data?.success === true) {
        return response?.data?.url;
      }
      return undefined;
    } catch (err) {
      console.log(err);
      return undefined;
    }
  };

  const onSubmitForm = async () => {
    let obj = transformData(state, automationState);
    setLoading(true);
    try {
      const { automationActions } = obj;
      for (let tx of automationActions) {
        if (tx?.apiChannelRule) {
          const file = tx?.apiChannelRule?.file;
          if (file) {
            const attachmentUrl = await uploadSingleAttachment(file);
            if (attachmentUrl) {
              tx.apiChannelRule.url = attachmentUrl;
              delete tx?.apiChannelRule?.file;
            }
          }
        } else if (tx?.whatsappRule) {
          const file = tx?.whatsappRule?.file;
          if (file) {
            const attachmentUrl = await uploadSingleAttachment(file);
            if (attachmentUrl) {
              tx.whatsappRule.url = attachmentUrl;
              delete tx?.whatsappRule?.file;
            }
          }
        }
      }
      obj.automationActions = automationActions;
      const res = await createAutomationRule(obj);
      if (res?.data?.success === true) {
        notification.success({
          message: <b>Notification</b>,
          description: <b>Automation Rule is created</b>,
        });
        setLoading(false);
        refetch();
        setShowDrawer(false);
      } else {
        setLoading(false);
        notification.error({
          message: <b>Notification</b>,
          description: <b>{res?.data?.message}</b>,
        });
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      notification.error({
        message: <b>Notification</b>,
        description: <b>Internal Server Error</b>,
      });
    }
  };
  const handleInputChange = (id, key, value, subKey, subId) => {
    setState((prevState) => {
      // Find the index of the item with the matching id
      const itemIndex = prevState.findIndex((item) => item.id === id);

      if (itemIndex === -1) {
        // If the item with the given id is not found, return the previous state
        return prevState;
      }

      const updatedItem = { ...prevState[itemIndex] };

      if (key === "conditions" && subKey && subId) {
        // If the key is "condition" and subKey is provided, update the subKey within the conditions object
        updatedItem.conditions = updatedItem.conditions.map((condition) => {
          if (condition.id === subId) {
            return {
              ...condition,
              [subKey]: value,
            };
          }
          return condition;
        });
      } else if (key !== "conditions") {
        // If the key is not "condition", update the key in the main object
        updatedItem[key] = value;
      }

      // Create a new state array with the updated item
      const newState = [...prevState];
      newState[itemIndex] = updatedItem;

      return newState;
    });
  };

  const handleNewItem = () => {
    setState([
      ...state,
      {
        id: generateRandomString(),
        automationRuleCondition: "AND",
        blockCondition: "ALL",
        conditions: [
          {
            id: 1,
            scope: "Segment",
            segment: "",
            trigger: "",
            action: null,
          },
        ],
      },
    ]);
  };

  const addConditionItem = (id, newConditionItem) => {
    setState((prevState) => {
      const updatedState = prevState.map((item) => {
        if (item.id === id) {
          // Clone the item and add the new condition to its conditions array
          return {
            ...item,
            conditions: [...item.conditions, newConditionItem],
          };
        }
        return item;
      });

      return updatedState;
    });
  };

  useEffect(() => {
    let isValid = isFormValid();
    setIsDisabled(!isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [automationName, conditionRelation, condition, action]);

  const handleOnClose = () => {
    setState([
      {
        id: generateRandomString(),
        automationRuleCondition: "AND",
        blockCondition: "ALL",
        conditions: [
          {
            id: 1,
            scope: "Segment",
            segment: "",
            trigger: "",
            action: null,
          },
        ],
      },
    ]);
    setAutomationState([
      {
        id: generateRandomString(),
        action: null,
        source: "",
        whatsappRule: {
          channels: [],
          content: "",
          file: null,
        },
        apiChannelRule: {
          channels: [],
          content: "",
          file: null,
        },
        schedule: {
          type: "SENDNOW",
          timestamp: "",
        },
      },
    ]);
    setShowDrawer(true);
  };
  return (
    <>
      <div
        className={styles.CreateAutomationRuleModal__TriggerButton}
        onClick={handleOnClose}
      >
        <img src={TriggerIcon} alt="trigger" />
        <div>{t("Create custom rule")}</div>
      </div>
      <Drawer
        headerStyle={{ display: "none" }}
        className={styles.CreateAutomationRuleModal__drawer}
        title="Create a Rule"
        placement="right"
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        destroyOnClose
      >
        <div className={styles.CreateAutomationRuleModal__Header}>
          <div className={styles.CreateAutomationRuleModal__Header__Title}>
            {t("Create a Rule")}
          </div>
          <span className={styles.CreateAutomationRuleModal__Header__text}>
            Create rules that automatically respond to actions, schedules and
            etc.
          </span>
        </div>
        <div className={styles.CreateAutomationRuleModal__FormContainer}>
          <div className={styles.CreateAutomationRuleModal__InputContainer}>
            <div
              className={
                styles.CreateAutomationRuleModal__InputContainer__Label
              }
            >
              Rule Name
            </div>
            <div
              className={
                styles.CreateAutomationRuleModal__InputContainer__Field
              }
            >
              <Input
                className={styles.CreateAutomationRuleModal__Input}
                placeholder={t("Enter a name")}
                onChange={(e) => {
                  setAutomationName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className={styles.CreateAutomationRuleModal__Holder}>
            <div className={styles.CreateAutomationRuleModal__Header}>
              <div className={styles.CreateAutomationRuleModal__Header__Title}>
                {t("Run these condition")}
              </div>
            </div>
            {state?.map((item) => {
              return (
                <AutomationRuleBlock
                  setCondition={setCondition}
                  setConditionRelation={setConditionRelation}
                  organizationId={organizationId}
                  item={item}
                  handleInputChange={handleInputChange}
                  handleNewItem={handleNewItem}
                  addConditionItem={addConditionItem}
                />
              );
            })}
          </div>
          <div className={styles.CreateAutomationRuleModal__Holder}>
            <div className={styles.CreateAutomationRuleModal__Header}>
              <div className={styles.CreateAutomationRuleModal__Header__Title}>
                {t("Perform these condition")}
              </div>
            </div>
            <AutomationActionBlock
              organizationId={organizationId}
              handleAutomationNewItem={handleAutomationNewItem}
              handleAutomationChange={handleAutomationChange}
              automationState={automationState}
            />
          </div>
        </div>
        <div className={styles.CreateAutomationRuleModal__Footer}>
          <Button onClick={() => setShowDrawer(false)}>Cancel</Button>
          <Button
            onClick={onSubmitForm}
            disabled={isDisabled || loading}
            type="primary"
            loading={loading}
            style={!isDisabled && {backgroundColor:'var(--primary-color)', color:'white'}}
          >
            {t("Create rule")}
          </Button>
        </div>
      </Drawer>
    </>
  );
};

CreateAutomationRuleModal.propTypes = {};

CreateAutomationRuleModal.defaultProps = {};

export default CreateAutomationRuleModal;
