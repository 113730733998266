import React from "react"

const PresentationIcon = ({ isActive, ...rest }) => {
  return (
    <svg {...rest} width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11 0C11.5523 0 12 0.447715 12 1V2H21C21.5523 2 22 2.44772 22 3C22 3.55228 21.5523 4 21 4V16C21 16.5523 20.5523 17 20 17H15.477L16.9285 20.6286C17.1336 21.1414 16.8842 21.7234 16.3714 21.9285C15.8586 22.1336 15.2766 21.8842 15.0715 21.3714L13.323 17H8.67703L6.92848 21.3714C6.72336 21.8842 6.14139 22.1336 5.62861 21.9285C5.11583 21.7234 4.86641 21.1414 5.07152 20.6286L6.52297 17H2C1.44772 17 1 16.5523 1 16V4C0.447715 4 0 3.55228 0 3C0 2.44772 0.447715 2 1 2H10V1C10 0.447715 10.4477 0 11 0ZM3 4V15H19V4H3ZM16.7071 6.29289C17.0976 6.68342 17.0976 7.31658 16.7071 7.70711L12.7071 11.7071C12.3166 12.0976 11.6834 12.0976 11.2929 11.7071L9 9.41421L6.70711 11.7071C6.31658 12.0976 5.68342 12.0976 5.29289 11.7071C4.90237 11.3166 4.90237 10.6834 5.29289 10.2929L8.29289 7.29289C8.68342 6.90237 9.31658 6.90237 9.70711 7.29289L12 9.58579L15.2929 6.29289C15.6834 5.90237 16.3166 5.90237 16.7071 6.29289Z" fill={isActive ? "white" : "#262626"} />
    </svg>

  )
}

export default PresentationIcon