import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  data: {
    auth_token: null,
  },
  error: null,
};

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    resetAuth: (state) => Object.assign(state, initialState),
    setAuthdata: (state, action) => {
      state.data = { ...action.payload };
    },
  },
});

export const { resetAuth, setAuthdata } = locationSlice.actions;
export default locationSlice.reducer;
