import React, { useEffect, useState } from "react";
import {
  Modal,
  Drawer,
  Form,
  Input,
  Button,
  Tooltip,
  notification,
} from "antd";
import styles from "./CreateWhatsappDesktopChannel.module.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  ExclamationCircleOutlined,
  CopyOutlined,
  LoadingOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { createWhatsAppDesktopChannel } from "src/api/WhatsappApi";
import successIcon from "src/assets/desktop_success.png";
import CountrySelect from "src/components/CountrySelect/CountrySelect";
import { getActiveOrg } from "src/store/slices/userSlice";

const CreateWhatsappDesktopChannel = ({ isVisible, setShowDrawer }) => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [successData, setSuccessData] = useState({
    generated: false,
    url: "",
    path: "",
    password: "",
  });
  const activeOrg = useSelector(getActiveOrg);
  const submitForm = async () => {
    setSubmitting(true);
    const [dialCode, isoCountryCode] = values.dialCodeText.split(",");
    let data = {
      organizationId: activeOrg?.id,
      isoCountryCode: isoCountryCode,
      dialCode: dialCode,
      phone: values.phoneNumber,
      name: values.channelName,
    };
    try {
      const res = await createWhatsAppDesktopChannel(data);
      if (res.data.success === true) {
        notification.success({
          message: <b>Notification</b>,
          description: "Channel Created Successfully",
        });
        setSuccessData({
          generated: true,
          url: res.data?.url,
          path: res.data?.path,
          password: res.data?.password,
        });
        setSubmitting(false);
      } else {
        if (res.data.error) {
          notification.error({
            message: <b>Notification</b>,
            description: res.data.error,
          });
        } else {
          notification.error({
            message: <b>Notification</b>,
            description:
              "We're encountering some problem processing your request",
          });
        }
        setSubmitting(false);
        resetForm();
      }
    } catch (err) {
      notification.error({
        message: <b>Notification</b>,
        description: "We're encountering some problem processing your request",
      });
      setLoading(false);
    }
  };
  const {
    values,
    touched,
    errors,
    setFieldValue,
    // setFormikState,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    resetForm,
  } = useFormik({
    initialValues: {
      dialCodeText: "+91,IN",
      phoneNumber: "",
      channelName: "",
    },
    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string()
        .matches(/^[0-9]*$/, "Only numbers are allowed")
        .min(10, "Invalid Phone Number")
        .required("Required field"),
      channelName: Yup.string()
        .min(2, "Too Short!")
        .max(70, "Too Long!")
        .required("Required field"),
    }),
    onSubmit: () =>
      // formValues
      {
        // const [dialCode, isoCountryCode] = formValues.dialCodeText.split(',');
        submitForm();
      },
  });

  const handleValidationStatus = (key) => {
    return !touched[key] && !errors[key]
      ? ""
      : touched[key] && !errors[key]
      ? "success"
      : touched[key] && errors[key]
      ? "error"
      : "";
  };

  useEffect(() => {
    if (
      values.phoneNumber.trim() === "" ||
      values.channelName.trim() === "" ||
      isValid === false
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [values, isValid]);

  const LabelStyle = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "22px",
    paddingBottom: "0px",
    marginBottom: "0px",
    color: "#595959",
  };

  const copyToClipBoard = (data) => {
    navigator.clipboard
      .writeText(data)
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const redirectToVDI = () => {
    window.open(`https://${successData.url}`, "_blank");
    setShowDrawer(false);
    setSuccessModal(true);
  };

  const closeModal = () => {
    setSuccessModal(false);
  };

  return (
    <>
      <Modal
        visible={successModal}
        footer={null}
        width="405px"
        closable={false}
      >
        <div className={styles.WhatsappModal__ImageContainer}>
          <img
            className={styles.WhatsappModal__ImageIcon}
            src={successIcon}
            alt="whatsapp"
          />
        </div>
        <h3 className={styles.WhatsappModal__Heading}>
          Your channel is connected
        </h3>
        <p className={styles.WhatsappModal__Paragraph}>
          You can now engage with your customers through your new channel.
        </p>
        <div className={styles.ResultInfo}>
          <ExclamationCircleFilled className={styles.WhatsappModal__Icon} />
          <p>
            Only new incoming messages will be received. No chat history will be
            imported.
          </p>
        </div>
        <div className={styles.WhatsappModal__ButtonContainer}>
          <Button type="primary" onClick={closeModal}>
            Done
          </Button>
        </div>
      </Modal>
      <Drawer
        width={534}
        headerStyle={{ display: "none" }}
        placement="right"
        open={isVisible}
        onClose={() => setShowDrawer(false)}
      >
        <div className={styles.CreateWhatsappDesktopChannel}>
          <div className={styles.CreateWhatsappDesktopChannel__Header}>
            Create WhatsApp Desktop Inbox
          </div>
          <div className={styles.CreateWhatsappDesktopChannel__SubHeader}>
            Setting up a WhatsApp desktop inbox requires a phone number in which
            you are willing to create a WhatsApp channel.
          </div>
          <Form
            layout="vertical"
            onFinish={handleSubmit}
            className={styles.ChannelForm}
          >
            <Form.Item
              name="channelName"
              label={
                <p style={LabelStyle}>
                  Channel Name
                  <span
                    style={{ color: "#CF1322" }}
                    className={styles.ChannelForm__formContainer}
                  >
                    *
                  </span>
                </p>
              }
              validateStatus={handleValidationStatus("channelName")}
              hasFeedback
              style={{ flexDirection: "column" }}
            >
              <Input
                name="channelName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.channelName}
                placeholder="Enter inbox name"
              />
              {touched.channelName && errors.channelName ? (
                <div className={styles.ChannelForm__formContainer__error}>
                  {errors.channelName}
                </div>
              ) : null}
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label={
                <p style={LabelStyle}>
                  Phone Number
                  <span
                    style={{ color: "#CF1322" }}
                    className={styles.ChannelForm__formContainer}
                  >
                    *
                  </span>
                </p>
              }
              className={styles.ChannelForm__formContainer}
              validateStatus={handleValidationStatus("phoneNumber")}
              hasFeedback
            >
              <Input
                id="phoneNumber"
                addonBefore={
                  <CountrySelect
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                }
                name="phoneNumber"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phoneNumber}
                disabled={false}
                placeholder="9048084872"
              />
              {touched.phoneNumber && errors.phoneNumber ? (
                <div className={styles.ChannelForm__formContainer__error}>
                  {errors.phoneNumber}
                </div>
              ) : null}
            </Form.Item>
            <p className={styles.ChannelForm__PhoneNumberHelper}>
              Enter phone number without any space. eg: +19036003866
            </p>
            {!successData.generated && (
              <div className={styles.ChannelForm__ButtonContainer}>
                <Button
                  type="secondary"
                  onClick={() => {
                    setShowDrawer(false);
                  }}
                >
                  Cancel
                </Button>
                {submitting === true ? (
                  <Button type="primary">
                    <LoadingOutlined />
                  </Button>
                ) : (
                  <Button type="primary" disabled={loading} htmlType="submit">
                    Step 1/2: Initiate virtual machine
                  </Button>
                )}
              </div>
            )}
          </Form>
          {successData.generated && (
            <>
              <div className={styles.ResultInfo}>
                <ExclamationCircleOutlined style={{ color: "#FAAD14" }} />
                <p>
                  In the next step, you will be asked for a username and
                  password. Use these credentials to set up your channel.
                </p>
              </div>

              <div className={styles.ResultDetails}>
                <div className={styles.ResultDetails__Placeholder}>
                  Username
                </div>
                <Input.Group compact>
                  <Input
                    style={{
                      width: "calc(100% - 31px)",
                    }}
                    disabled={true}
                    defaultValue={successData?.path}
                  />
                  <Tooltip title="Copy Username">
                    <Button
                      onClick={() => copyToClipBoard(successData.path)}
                      icon={<CopyOutlined />}
                    />
                  </Tooltip>
                </Input.Group>
                <div className={styles.ResultDetails__Placeholder}>
                  Password
                </div>
                <Input.Group compact>
                  <Input
                    style={{
                      width: "calc(100% - 31px)",
                    }}
                    disabled={true}
                    defaultValue={successData?.password}
                  />
                  <Tooltip title="Copy Password">
                    <Button
                      onClick={() => copyToClipBoard(successData.password)}
                      icon={<CopyOutlined />}
                    />
                  </Tooltip>
                </Input.Group>
              </div>

              <div className={styles.ChannelForm__ButtonContainer}>
                <Button type="secondary" onClick={setShowDrawer(false)}>
                  Cancel
                </Button>
                <Button type="primary" onClick={() => redirectToVDI()}>
                  Step 2/2: Setup virtual machine
                </Button>
              </div>
            </>
          )}
        </div>
      </Drawer>
    </>
  );
};

CreateWhatsappDesktopChannel.propTypes = {};

CreateWhatsappDesktopChannel.defaultProps = {};

export default CreateWhatsappDesktopChannel;
