import React from "react"

const StartIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 16 22" fill="none">
      <path d="M14.7336 10.0798C15.2927 10.4786 15.2927 11.3093 14.7336 11.7081L1.8005 20.9329C1.13856 21.4051 0.219806 20.9319 0.219806 20.1188L0.219806 1.66905C0.219806 0.855987 1.13856 0.382788 1.8005 0.854931L14.7336 10.0798Z" fill="white"/>
    </svg>
  )
}

export default StartIcon