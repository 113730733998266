import React, { useEffect, useState } from "react";
import { Switch,Button } from "antd";
import "antd/dist/reset.css";
import { useDispatch } from "react-redux";
import styles from "./InterfaceOptionBody.module.scss";
import { manageNodeView, syncWikiCategoryData } from "src/api/OrganizationApi";
import { message } from "antd";
import { getActiveOrgId, getActiveStaffOrg, updateOrganizationActionCardStatus, updateOrganizationCueCardStatus } from "src/store/slices/userSlice";
import { useSelector } from "react-redux";
import { SyncOutlined, BgColorsOutlined, EditOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom";
import ColorSchemePickerModal from "../ColorSchemePicker/ColorSchemePickerModal.lazy";
import DomainSetupModal from "../DomainSetupModal/DomainSetupModal";
import { resetNode } from "src/store/slices/nodeSlice";
import { updateOrganizationActionCard } from "src/api/ActionCardApi";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ACTIONCARD_STATUS, UPDATE_CUE_CARD_STATUS } from "src/graphql/organization";

const InterfaceOptionBody = (props) => {
  const organizationId = useSelector(getActiveOrgId);
  const activeStaffOrg = useSelector(getActiveStaffOrg);
  const [isColorPickerModalOpen, setIsColorPickerModalOpen] = useState(false);
  const [isDomainSetupModalOpen, setIsDomainSetupModalOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    data: { nodeView },
  } = useSelector((state) => state?.user);

  const {actionCardFeature,cueCardFeature} = useSelector((state) => state?.user?.data?.staffOrganization?.[0]?.organization || { // Default to an object if the path doesn't exist
    actionCardFeature: false,
    cueCardFeature: false,
  });

  const [updateCueCardStatus, { loading, error, data }] = useMutation(UPDATE_CUE_CARD_STATUS);


  const handleNodeViewChange = async (checked) => {
    const data = {
      isEnabled: checked,
      url: "http://remove.this",
      organizationId: organizationId,
    };

    await manageNodeView(data).then(({ data: { success } }) => {
      if (success) {
        if (checked) {
          message.success(
            "Project view enabled.Navigating to the workspace..."
          );
        }
      }
    });
  };

  const handleActionCardViewChange = async (checked) => {
    const data = {
      disabled: !checked,
      organizationId: organizationId
    }
    await updateOrganizationActionCard(data).then(({ data: { success } }) => {
      if (success) {
        console.log("checked",checked)
        dispatch(updateOrganizationActionCardStatus({index: 0,actionCardStatus: checked} ))
        message.success(`Action card ${checked ? 'enabled' : 'disabled'} successfully`);
      }
    });
  };

  const handleCueCardViewChange = async(checked) =>{
    try {
      const result = await updateCueCardStatus({
        variables: { organizationId, cueCardStatus:checked },
      });
      dispatch(updateOrganizationCueCardStatus({index: 0,cueCardStatus: checked} ))
      message.success(`Cue card ${checked ? 'enabled' : 'disabled'} successfully`);
    } catch (error) {
      console.error('Error updating cue card status:', error);
      throw error;
    }
  }
  
  const handleSyncDocsData = async () => {
    try {
      message.loading('Syncing data...',6);
      dispatch(resetNode());
      const res = await syncWikiCategoryData()
      console.log(res)
      message.destroy();
      message.success('Project synced succesfully. Navigating to the workspace...')
      setTimeout(() => {
        navigate('/node')
      }, 1000)
    }
     catch(e) {
      console.log('error populating data');
      message.error("Error syncing data. Please try again later.");
     }
  };

  const handleOpenModal = () => {
    setIsColorPickerModalOpen(true);
  };

  const handleOpenDomainModal = () =>{
    if (activeStaffOrg?.roleName === "admin") {
      setIsDomainSetupModalOpen(true)
    } else {
      message.error(`You are not allowed to do this action`);
    }
  }

  const handleCloseModal = () => {
    setIsColorPickerModalOpen(false);
  };

  const handleCloseDomainModal = () =>{
    setIsDomainSetupModalOpen(false)
  }

  return (
    <div className={styles.InterfaceOptionBody}>
        <div className={styles.option}>
          <span className={styles.span}>Sync Projects from docs</span>
            <Button onClick={handleSyncDocsData} type="primary"  style={{backgroundColor:'var(--primary-color)'}}>
            <SyncOutlined style={{height:'16px', width:'16px', color:'#ffffff'}} />Sync
        </Button>
        </div>
      <div className={styles.option}>
        <span className={styles.span}>Time Tracker</span>
        <Switch
          checked={nodeView}
          style={{backgroundColor:'var(--primary-color)'}}
          onChange={handleNodeViewChange}
          disabled={activeStaffOrg?.roleName !== "admin"}
        />
      </div>
      <div className={styles.option}>
        <span className={styles.span}>Enable Action Card</span>
        <Switch
         style={{backgroundColor:'var(--primary-color)'}}
          checked={actionCardFeature}
          onChange={handleActionCardViewChange}
          disabled={activeStaffOrg?.roleName !== "admin"}
        />
      </div>
      <div className={styles.option}>
        <span className={styles.span}>Enable Cue Card</span>
        <Switch
         style={{backgroundColor:'var(--primary-color)'}}
          checked={cueCardFeature}
          onChange={handleCueCardViewChange}
          disabled={activeStaffOrg?.roleName !== "admin"}
        />
      </div>
      <div className={styles.option}>
        <span className={styles.span}>Select Color Scheme</span>
        <Button onClick={handleOpenModal} type="primary"  style={{backgroundColor:'var(--primary-color)'}}>
          <BgColorsOutlined />Choose
        </Button>
        <ColorSchemePickerModal visible={isColorPickerModalOpen} onClose={handleCloseModal} />
      </div>
      <div className={styles.option}>
        <span className={styles.span}>Setup Domain Name</span>
        <Button disabled={activeStaffOrg?.roleName !== "admin"} onClick={handleOpenDomainModal} type="primary"  style={{backgroundColor:'var(--primary-color)',color:activeStaffOrg?.roleName != "admin" && '#e3e3e3'}}>
          <EditOutlined />Setup
        </Button>
        <DomainSetupModal visible={isDomainSetupModalOpen} onClose={handleCloseDomainModal} />
      </div>
    </div>
  );
};

export default InterfaceOptionBody;
