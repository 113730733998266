import CoreApiClient from "./clients/CoreApiClient";

export const createLabel = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/organization/label",
    data: body,
  });
};

export const updateLabel = async (body, id) => {
  return CoreApiClient({
    method: "PUT",
    url: "/label",
    data: body,
  });
};

export const deleteLabel = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/label/delete",
    data: body,
  });
};
