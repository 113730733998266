import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./CreateAttributeDrawer.module.scss";
import { Drawer, Input, Button, Divider, message } from "antd";
import ContactDrawerAccordion from "../ContactDrawerAccordion/ContactDrawerAccordion";
import InternalIcon from "src/assets/Icon/Rocket.png";
import {
  FontSizeOutlined,
  NumberOutlined,
  TagsOutlined,
  CalendarOutlined,
  UserOutlined,
  PhoneOutlined,
  MailOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { addNewAttribute } from "src/api/ContactApi";
import { useLocation, useNavigate } from "react-router-dom";
import { getActiveOrgId } from "src/store/slices/userSlice";
const Actions = {
  UPDATE_ATTRIBUTE: "update_attribute",
  UPDATE_ATTRIBUTE_TYPE: "update_attribute_type",
};
const CreateAttributeDrawer = ({
  showDrawer,
  setShowDrawer,
  refetchAttributes,
  showContactDrawer,
}) => {
  const organizationId = useSelector(getActiveOrgId);

  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setIsLoading] = useState(false);

  const [state, setState] = useState({
    attributeName: "Edit attribute name here",
    attributeType: "",
    attributeIcon: <></>,
  });
  const location = useLocation();
  const navigate = useNavigate();

  // Access the current path

  const [filterText, setFilterText] = useState("");

  const updateState = (action) => {
    const { type, payload } = action;
    if (!type || !payload) {
      return;
    }
    let currentState = state;
    switch (type) {
    case Actions.UPDATE_ATTRIBUTE:
      const { text } = payload;
      if (text === null || text === undefined) {
        return;
      }
      currentState.attributeName = text;
      break;
    case Actions.UPDATE_ATTRIBUTE_TYPE:
      const { attributeType, attributeIcon } = payload;
      if (attributeType === null || attributeType === undefined) {
        return;
      }
      currentState.attributeType = attributeType;
      currentState.attributeIcon = attributeIcon;
      break;
    default:
      break;
    }
    setState({ ...currentState });
  };

  useEffect(() => {
    if (state.attributeName === "" || state.attributeType === "") {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [state]);

  const InternalAttributes = [
    {
      text: "Text",
      icon: <FontSizeOutlined className={styles.CreateAttributeDrawer__Icon} />,
      attributeType: "Text",
    },
    {
      text: "Numbers",
      icon: <NumberOutlined className={styles.CreateAttributeDrawer__Icon} />,
      attributeType: "Numbers",
    },
    {
      text: "Multiselect",
      icon: <TagsOutlined className={styles.CreateAttributeDrawer__Icon} />,
      attributeType: "MultiSelect",
    },
    {
      text: "Date",
      icon: <CalendarOutlined className={styles.CreateAttributeDrawer__Icon} />,
      attributeType: "Date",
    },
    {
      text: "User",
      icon: <UserOutlined className={styles.CreateAttributeDrawer__Icon} />,
      attributeType: "UserSelect",
    },
    {
      text: "Phone Number",
      icon: <PhoneOutlined className={styles.CreateAttributeDrawer__Icon} />,
      attributeType: "PhoneNumber",
    },
    {
      text: "Email",
      icon: <MailOutlined className={styles.CreateAttributeDrawer__Icon} />,
      attributeType: "EmailAddress",
    },
    {
      text: "Social media",
      icon: <GlobalOutlined className={styles.CreateAttributeDrawer__Icon} />,
      attributeType: "SocialMedia",
    },
  ];
  const updateAttributes = (attribute_type, attributeIcon) => {
    if (
      attribute_type === null ||
      attribute_type === undefined ||
      !attributeIcon
    ) {
      return;
    }
    updateState({
      type: Actions.UPDATE_ATTRIBUTE_TYPE,
      payload: {
        attributeType: attribute_type,
        attributeIcon: attributeIcon,
      },
    });
  };
  useEffect(() => {
    if (state.attributeType !== "") {
      setFilterText("");
    }
  }, [state]);
  const returnAttributeRow = (tx, i) => {
    return (
      <div
        className={styles.CreateAttributeDrawer__Row}
        key={i}
        onClick={(e) => {
          updateAttributes(tx.attributeType, tx.icon);
        }}
      >
        <div className={styles.CreateAttributeDrawer__RowIcon}>{tx.icon}</div>
        <div className={styles.CreateAttributeDrawer__RowText}>{tx.text}</div>
      </div>
    );
  };
  const createAttribute = async () => {
    try {
      if (
        state.attributeName === "" ||
        state.attributeType === "" ||
        !organizationId
      ) {
        return;
      }
      let body = {};
      if (state.attributeType === "MultiSelect") {
        body = {
          multiSelectAttribute: {
            name: state.attributeName,
            value: [],
          },
          organization: {
            id: organizationId,
          },
        };
      } else if (state.attributeType === "UserSelect") {
        body = {
          userAttribute: {
            name: state.attributeName,
          },
          organization: {
            id: organizationId,
          },
        };
      } else {
        body = {
          contactAttribute: {
            name: state.attributeName,
            type: state.attributeType,
          },
          organization: {
            id: organizationId,
          },
        };
      }
      setIsLoading(true);
      let res = await addNewAttribute(body);
      if (res?.data?.success) {
        message.success("Attribute created successfully!!!");
        if (typeof refetchAttributes === "function") {
          refetchAttributes()?.then(() => {
            setShowDrawer(false);
            // showContactDrawer(true)
            setIsLoading(false);
          });
        }
        const currentPath = location.pathname;
        const splitUrl = currentPath.split("/");
        if (splitUrl.length >= 2 && splitUrl[1] === "chat") {
          navigate(0);
        }
      } else {
        message.error(res?.data?.message);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      return;
    }
  };
  return (
    <Drawer
      headerStyle={{ display: "none" }}
      title="Add Canned Response"
      width={368}
      placement="right"
      open={showDrawer}
      onClose={() => setShowDrawer(false)}
    >
      <div className={styles.CreateAttributeDrawer}>
        <Input
          placeholder="Edit attribute name here"
          className={styles.CreateAttributeDrawer__Header}
          value={state.attributeName}
          onChange={(e) => {
            updateState({
              type: Actions.UPDATE_ATTRIBUTE,
              payload: {
                text: e.target.value,
              },
            });
          }}
        />
        {state.attributeType === "" ? (
          <Input
            placeholder="Find an attribute property"
            value={filterText}
            onChange={(e) => {
              setFilterText(e?.target?.value);
            }}
          />
        ) : (
          <div
            className={styles.CreateAttributeDrawer__DisplaySelectedAttribute}
          >
            {state.attributeIcon} {state.attributeType}
          </div>
        )}
        <Divider className={styles.CreateAttributeDrawer__Separator} />
        <div className={styles.CreateAttributeDrawer__AccordionContainer}>
          {InternalAttributes?.filter((tx) =>
            tx.text.toLowerCase().includes(filterText.toLowerCase())
          ).map((tx, i) => returnAttributeRow(tx, i))?.length === 0 ? (
              <span style={{ textAlign: "center", fontWeight: "bold" }}>
              No attributes available
              </span>
            ) : (
              <ContactDrawerAccordion
                AccordionIcon={() => <img src={InternalIcon} />}
                title="Internal"
                openByDefault={true}
              >
                <div className={styles.CreateAttributeDrawer__RowContainer}>
                  {filterText?.trim() === ""
                    ? InternalAttributes?.map((tx, i) => returnAttributeRow(tx, i))
                    : InternalAttributes?.filter((tx) =>
                        tx.text.toLowerCase().includes(filterText.toLowerCase())
                      ).map((tx, i) => returnAttributeRow(tx, i))}
                </div>
              </ContactDrawerAccordion>
            )}
        </div>
        <div className={styles.CreateAttributeDrawer__Footer}>
          <Button
            onClick={createAttribute}
            type="primary"
            disabled={isDisabled}
            loading={loading}
          >
            Create
          </Button>
          <Button onClick={() => setShowDrawer(false)}>Cancel</Button>
        </div>
      </div>
    </Drawer>
  );
};
CreateAttributeDrawer.propTypes = {};

CreateAttributeDrawer.defaultProps = {};

export default CreateAttributeDrawer;
