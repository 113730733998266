import React, { useEffect, useState } from "react";
import { PushpinOutlined, PushpinFilled, PlusOutlined } from "@ant-design/icons";
import styles from "./Node.module.scss";
import { setProjectWikiLink, setSelectedNode } from "src/store/slices/nodeSlice";
import { useDispatch, useSelector } from "react-redux";

const Node = ({ node, style, dragHandle, pinnedNodes, handleNodeClick, addNodeToPinned, isClicked, showModal }) => {
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const selectedNode = useSelector((state) => state?.node.data.selectedNode);

  if (node?.data?.id !== selectedNode?.id && isClicked && node.isSelected) {
    dispatch(setSelectedNode(node.data));
    dispatch(setProjectWikiLink(node.data.url));
  }

  
  const isPinned =
    pinnedNodes.findIndex((item) => item.id === node.data.id) >= 0;

  const handleAddClick = (e) => {
    e.stopPropagation();
    showModal();
  };

  return (
    <div
      key={node.id}
      style={{
        ...{
          display: "flex",
          gap: "2.5vw",
          alignItems: "center",
          cursor: "pointer",
        },
        ...style,
      }}
      className={node.isSelected && isClicked ? styles.selectedNode : ""}
      ref={dragHandle}
      onClick={() => {
        node.toggle();
        handleNodeClick(node.data.uniqueKey);
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={styles.nodeWrapper}>
        <div
          style={{ paddingLeft: "20px", paddingRight: "40px" }}
          data-cy="node-name"
        >
          {node.isLeaf && node.data.type==="Article" ? "📄" : "📁"} {node.data.name}
        </div>
        <div className={styles.iconContainer}>
          {isHovered && (
            <>
              <PlusOutlined className={styles.addIcon} onClick={handleAddClick} />
              <div
                className={styles.pinIcon}
                onClick={() => addNodeToPinned(node.data)}
              >
                {isPinned ? (
                  <PushpinFilled data-cy="is-pinned-icon" />
                ) : (
                  <PushpinOutlined data-cy="not-pinned-icon" />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Node;
