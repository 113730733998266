import React from 'react';
import { Button } from 'antd';
import { CSVLink } from 'react-csv';
import { DownloadOutlined } from '@ant-design/icons';

const ExportCSVButton = ({ data, filename, label }) => {
  return (
    <Button type="primary" icon={<DownloadOutlined />} style={{ marginRight: '10px', backgroundColor: '#055b7f' }}>
      <CSVLink
        data={data}
        filename={filename}
        style={{ color: 'white', textDecoration: 'none' }}
      >
        {label}
      </CSVLink>
    </Button>
  );
};

export default ExportCSVButton;
