import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  data: {
    selectedNode: null,
    nodeList: [],
    nodeId: null,
    projectWikiLink: null
  },
  error: null,
};

const nodeSlice = createSlice({
  name: "node",
  initialState,
  reducers: {
    resetNode: (state) => Object.assign(state, initialState),
    setSelectedNode(state, action) {
      state.data.selectedNode = action.payload;
    },
    setNodeList(state, action) {
      state.data.nodeList = action.payload;
    },
    setNodeId(state,action) {
      state.data.nodeId = action.payload
    },
    setProjectWikiLink(state,action){
      state.data.projectWikiLink = action.payload
    }
  },
});

export const { setSelectedNode,setNodeList,resetNode,setNodeId,setProjectWikiLink } = nodeSlice.actions;
export default nodeSlice.reducer;
