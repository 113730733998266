import { loadStripe } from "@stripe/stripe-js";

const getStripe = () => {
  const stripe = loadStripe(
    "pk_test_51Mp2nfSABxabPiRmJ2YvU3pPxcAddJfVW40TLKyOMVTtwGwHJcJr5y1RKWsSMnJAvU18mBfrSfkC7vtLlXzZVkhP00ynx3WYto"
  );
  return stripe;
};

export default getStripe;
