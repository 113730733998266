import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Select, Checkbox } from "antd";
import AutomationTagsContainer from "../AutomationTagsContainer/AutomationTagsContainer";
import styles from "./AutomationIndividualCondition.module.scss";
import { generateRandomString } from "src/components/ContactsPage/components/FilterContactDrawer/helpers/helpers";
const { Option } = Select;

const AutomationIndividualCondition = ({
  index,
  onConditionChange,
  segmentOptions,
  item,
  parentItem,
}) => {
  const [state, setState] = useState({
    id: generateRandomString(),
    scope: "Segment",
    segment: "",
    trigger: "",
    action: "",
  });
  const [segmentOptionsList, setSegmentOptionsList] = useState([]);

  const onTargetChange = (e) => {
    onConditionChange(parentItem?.id, "conditions", e, "trigger", item.id);
  };
  const containerOption = [
    // {
    //   "label": "Chat",
    //   "value": "Chat"
    // },
    //   {
    //     "label": "CRM",
    //     "value": "CRM"
    //   },
    //   {
    //     "label": "Contact",
    //     "value": "Contact"
    //   }
    {
      label: "In Segment",
      value: "Segment",
    },
  ];

  const conditionOptions = [
    {
      label: "If Contact",
      value: "Contact",
    },
  ];

  const relationsOptions = [
    {
      label: "is Exists",
      value: "Exists",
    },

    // , {
    //   "label": "Is not",
    //   "value": "Is not"
    // }, {
    //   "label": "Contains",
    //   "value": "Contains"
    // }, {
    //   "label": "Does not contain",
    //   "value": "Does not contain"
    // }, {
    //   "label": "Has any of these words",
    //   "value": "Has any of these words"
    // }
  ];
  const onSegmentOption = (e) => {
    onConditionChange(parentItem?.id, "conditions", e, "segment", item.id);
  };

  const onContainerOption = (e) => {
    onConditionChange(parentItem?.id, "conditions", e, "scope", item.id);
  };
  const onRelationshipChange = (e) => {
    onConditionChange(parentItem?.id, "conditions", e, "action", item.id);
  };
  const onTagsChange = (tags) => {
    setState((prevState) => ({
      ...prevState,
      text: tags.join(","),
    }));
  };

  function convertDataToSegmentsArray(data) {
    const segmentsArray = [];
    data.forEach((segment) => {
      const { id, name } = segment;
      segmentsArray.push({ id, name, label: name, value: id });
    });

    return segmentsArray;
  }

  useEffect(() => {
    if (segmentOptions !== undefined) {
      setSegmentOptionsList(convertDataToSegmentsArray(segmentOptions));
    }
  }, [segmentOptions]);

  return (
    <>
      <div className={styles.AutomationIndividualCondition__Body__FirstRow}>
        <Select
          className="Automation_Rule_Segment_Select"
          style={{ width: "104px" }}
          value={item?.scope}
          options={containerOption}
          onChange={onContainerOption}
        />
        {segmentOptions !== undefined && (
          <Select
            className="Automation_Rule_Segment_Name_Select"
            placeholder="Choose segment"
            style={{ width: "104px" }}
            value={item.segment}
            options={segmentOptionsList}
            onChange={onSegmentOption}
          />
        )}
        <Select
          className="Automation_Rule_Condition_Select"
          style={{ width: "157px" }}
          value={item?.trigger}
          placeholder="Choose condition"
          options={conditionOptions}
          onChange={onTargetChange}
        />

        {state.condition !== "" ? (
          <>
            <Select
              className="Automation_Rule_Event_Select"
              style={{ width: "104px" }}
              onChange={onRelationshipChange}
              placeholder="choose action"
              value={item?.action}
              options={relationsOptions}
            />
            {/* <AutomationTagsContainer onTagsChange={onTagsChange} /> */}
          </>
        ) : null}
      </div>
    </>
  );
};

AutomationIndividualCondition.propTypes = {};

AutomationIndividualCondition.defaultProps = {};

export default AutomationIndividualCondition;
