import axios from "axios";
import { useNavigate } from "react-router-dom";

// import axiosRetry from "axios-retry";
import store from "src/store";
import getConfig from "src/helpers/config";

let { REACT_APP_ANALYTICS_URL } = getConfig();

const AnalyticsApiClient = axios.create({
  baseURL: REACT_APP_ANALYTICS_URL,
  timeout: 100000,
});

//TODO: Add check if auth header and store token are same

// axiosRetry(AuthApiClient, {
//   retries: 5,
//   retryDelay: () => {
//     return 1000;
//   },
//   retryCondition: (error) => {
//     return [401, 403].includes(error?.response?.status);
//   },
// });

AnalyticsApiClient.interceptors.response.use(
  function (response) {
    return Promise.resolve(response);
  },
  function (error) {
    return Promise.reject(error);
  }
);

store.subscribe(listener);

function listener() {
  let token = select(store.getState());

  AnalyticsApiClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  AnalyticsApiClient.defaults.headers.common.organizationName = "hcinbox";

  function select(state) {
    return state?.auth?.data?.access_token;
  }
}

export default AnalyticsApiClient;
