import PropTypes from "prop-types";
import React from "react";
import styles from "./SettingsSideNavbar.module.scss";
import UserSvg from "src/assets/User.svg";
import CannedTemplateIcon from "src/assets/Fire.svg";
import TimezoneSvg from "src/assets/Timezone.svg";
import ChannelSvg from "src/assets/Channel.svg";
import AutomationSvg from "src/assets/Bulb.svg";
import BillingSvg from "src/assets/billing.svg";
import InterfaceSvg from "src/assets/interfaceOptions.svg";

import { Link, useLocation } from "react-router-dom";
import { ArrowLeftOutlined, LeftOutlined } from "@ant-design/icons";
import { Badge } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { checkFeatureAvailability } from "src/helpers/checkFeatureAvailability";

const SettingsSideNavbar = ({ setActiveSetting, activeSetting }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const orgUrl = useSelector((state) => state?.user?.data?.staffOrganization?.[0]?.organization?.domain || '');


  const {
    data: { nodeView },
  } = useSelector((state) => state?.user);

  return (
    <div data-testid="setting_side_navbar" className={styles.Settings_sidebar}>
      <div className={styles.Settings_sidebar__head}>
        <Link to={nodeView ? "/node" : "/chat"} key="node" style={{textDecoration: "none"}}>
          <h3
            data-cy="back-title"
            style={{ color: "rgba(0, 0, 0, 0.45)", marginLeft: "6px" }}
          >
            <LeftOutlined
              data-cy="settings-back-icon"
              style={{
                height: "45px",
                width: "auto",
                fontSize: "20px",
                marginRight: "7px",
                color: 'var(--secondary-color)'
              }}
            />
            {t("Workspace")}
          </h3>
        </Link>
      </div>
      <div className={styles.Settings_sidebar__body}>
        <Link
          to="/settings/people"
          className={
            location.pathname === "/settings/people" ||
            location.pathname === "/settings" ||
            location.pathname === "/settings/"
              ? styles.active + " " + styles.Settings_sidebar__body__tab
              : styles.Settings_sidebar__body__tab
          }
          onClick={() => setActiveSetting("people")}
        >
          <img src={UserSvg} alt="user" />
          <p>{t("People")}</p>
        </Link>
        <Link
          to="/settings/cannedResponse"
          className={
            location.pathname === "/settings/cannedResponse"
              ? styles.active + " " + styles.Settings_sidebar__body__tab
              : styles.Settings_sidebar__body__tab
          }
          onClick={() => setActiveSetting("cannedResponse")}
        >
          <img src={CannedTemplateIcon} alt="cannedTemplate" />
          <p>{t("Canned response")}</p>&nbsp;
          {checkFeatureAvailability("upgradeCannedResponse",orgUrl) && <Badge
            className="site-badge-count-109"
            count={t("upgrade")}
            style={{ backgroundColor: 'var(--primary-color)', transform: "translateY(3px)" }}
          />}
        </Link>
        <Link
          to="/settings/timezoneAndLanguage"
          className={
            location.pathname === "/settings/timezoneAndLanguage"
              ? styles.active + " " + styles.Settings_sidebar__body__tab
              : styles.Settings_sidebar__body__tab
          }
          onClick={() => setActiveSetting("timezoneAndLanguage")}
        >
          <img src={TimezoneSvg} alt="timezone" />
          <p>{t("Timezone & Language")}</p>
        </Link>
        <Link
          to="/settings/automation"
          className={
            location.pathname === "/settings/automation"
              ? styles.active + " " + styles.Settings_sidebar__body__tab
              : styles.Settings_sidebar__body__tab
          }
          onClick={() => setActiveSetting("automation")}
        >
          <img src={AutomationSvg} alt="automation" />
          <p>{t("Automation")}</p>&nbsp;
          {checkFeatureAvailability("upgradeAutomationRule",orgUrl) && <Badge
            className="site-badge-count-109"
            count={t("upgrade")}
            style={{ backgroundColor: 'var(--primary-color)', transform: "translateY(3px)" }}
          />}
        </Link>
        <Link
          to="/settings/channels"
          className={
            location.pathname === "/settings/channels"
              ? styles.active + " " + styles.Settings_sidebar__body__tab
              : styles.Settings_sidebar__body__tab
          }
          onClick={() => setActiveSetting("channels")}
        >
          <img src={ChannelSvg} alt="channel" />
          <p>{t("Channels")}</p>
        </Link>
        {!checkFeatureAvailability("upgradeBilling",orgUrl) &&
          <Link
          to="/settings/billing"
          className={
            location.pathname === "/settings/billing"
              ? styles.active + " " + styles.Settings_sidebar__body__tab
              : styles.Settings_sidebar__body__tab
          }
          onClick={() => setActiveSetting("billing")}
        >
          <img src={BillingSvg} alt="billing" />
          <p>{t("Billing")}</p>
          {checkFeatureAvailability("upgradeBilling",orgUrl) && <Badge
            className="site-badge-count-109"
            count={t("upgrade")}
            style={{ backgroundColor: 'var(--primary-color)', transform: "translateY(3px)" }}
          />
          }
        </Link>
        }
        <Link
          to="/settings/interfaceOptions"
          className={
            location.pathname === "/settings/interfaceOptions"
              ? styles.active + " " + styles.Settings_sidebar__body__tab
              : styles.Settings_sidebar__body__tab
          }
          onClick={() => setActiveSetting("interfaceOptions")}
        >
          <img
            src={InterfaceSvg}
            alt="interfaceOptions"
            width={25}
            height={25}
          />
          <p>{t("Interface options")}</p> &nbsp;
          {checkFeatureAvailability("upgradeInterfaceOption",orgUrl) && <Badge
            className="site-badge-count-109"
            count={t("upgrade")}
            style={{ backgroundColor: 'var(--primary-color)', transform: "translateY(3px)" }}
          />}
        </Link>
      </div>
    </div>
  );
};

SettingsSideNavbar.propTypes = {
  navigate: PropTypes.func,
};

SettingsSideNavbar.defaultProps = {
  navigate: () => {},
};

export default React.memo(SettingsSideNavbar);
