import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./FilterContactDrawer.module.scss";
import { useQuery, useSubscription } from "@apollo/client";
import { Drawer, Button, message } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import QuerySelector from "../QuerySelector/QuerySelector";
import { useDispatch, useSelector } from "react-redux";
import {
  convertDataFormat,
  convertDataFormatChannels,
  extractLabels,
  flattenAttributes,
  getIconByType,
  getStaffArray,
  getTagsId,
  formHasError,
  generateRandomNumber,
} from "./helpers/helpers";
import SegmentationModal from "../SegmentationModal/SegmentationModal";
import { setGlobalSelectedSegment } from "src/store/slices/contactSlice";
import { LOAD_CHANNELS } from "src/graphql/channel";
import {
  GET_MULTISELECTS,
  GET_ORGANIZATION_DATA,
  GET_ORGANIZATION_LABELS,
} from "src/graphql/contact";
import { GET_ORGANIZATION_STAFF } from "src/graphql/staff";
import { GET_ONE_SEGMENT } from "src/graphql/segment";
import { getActiveOrgId } from "src/store/slices/userSlice";

const randomColor = "#" + Math.random().toString(16).substr(-6);

const FilterContactDrawer = ({
  handleOpenSegmentDrawer,
  onClose,
  open,
  handleFetchFilters,
  handleFetchFiltersCount,
}) => {
  const organizationId = useSelector(getActiveOrgId);
  const {
    loading,
    error,
    data: multiSelectsData,
  } = useQuery(GET_MULTISELECTS, {
    variables: {
      organizationId: organizationId,
    },
  });
  const {
    loadingAssignees,
    errorAssignees,
    data: assigneesData,
  } = useQuery(GET_ORGANIZATION_STAFF, {
    variables: {
      organizationId: organizationId,
    },
  });

  const {
    data: channelsData,
    loading: loadingChannels,
    refetch: refetchChannels,
  } = useQuery(LOAD_CHANNELS, {
    variables: {
      organizationId: organizationId,
    },
  });

  const {
    loading: orgDataLoading,
    error: orgError,
    data: orgData,
    refetch: refetchOrg,
  } = useQuery(GET_ORGANIZATION_DATA, {
    variables: {
      organizationId: organizationId,
    },
  });

  const dispatch = useDispatch();

  const [tagsId, setTagsId] = useState("");
  const [allLabels, setAllLabels] = useState([]);
  const [allAssignees, setAllAssignees] = useState([]);
  const [allChannels, setAllChannels] = useState([]);
  const [attrAndTypes, setAttrAndTypes] = useState([]);
  const [isSegmentModalOpen, setIsSegmentModalOpen] = useState(false);
  const showModal = () => {
    const errorMessages = formHasError(selectedFilters, attrAndTypes);
    // console.log(selectedFilters)
    if (errorMessages.length > 0) {
      errorMessages.forEach((errorMessage) => {
        // console.log(errorMessage); // Print each error message
        message.warning(errorMessage);
      });
    } else {
      setIsSegmentModalOpen(true);
    }
  };
  const handleOk = () => {
    setIsSegmentModalOpen(false);
  };
  const handleCancel = () => {
    setIsSegmentModalOpen(false);
  };

  useEffect(() => {
    if (
      multiSelectsData !== undefined &&
      Object.keys(multiSelectsData)?.length !== 0
    ) {
      setTagsId(getTagsId(multiSelectsData));
    }
  }, [multiSelectsData]);

  const {
    data: tagsData,
    loading: attributeLoading,
    error: attributeError,
    refetch,
  } = useQuery(GET_ORGANIZATION_LABELS, {
    variables: {
      organizationId: organizationId,
      multiselectId: tagsId,
    },
    skip: !organizationId || !tagsId,
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (tagsData !== undefined && Object.keys(tagsData)?.length !== 0) {
      setAllLabels(extractLabels(tagsData));
    }
  }, [tagsData]);

  useEffect(() => {
    if (assigneesData && Object.keys(assigneesData).length !== 0) {
      setAllAssignees(getStaffArray(assigneesData));
    }
  }, [assigneesData]);

  useEffect(() => {
    if (channelsData && Object.keys(channelsData).length !== 0) {
      setAllChannels(convertDataFormatChannels(channelsData));
    }
  }, [channelsData]);

  useEffect(() => {
    if (orgData && Object.keys(orgData).length !== 0) {
      setAttrAndTypes(flattenAttributes(orgData.getOrganization));
    }
  }, [orgData]);

  const [selectedFilters, setSelectedFilters] = useState([
    {
      id: generateRandomNumber(),
      category: null,
      subCategory: null,
      operator: null,
      values: [],
      combination: "and",
      contactFilterValue: "",
    },
  ]);

  const handleAddFilter = () => {
    const newId = generateRandomNumber();
    setSelectedFilters([
      ...selectedFilters,
      {
        id: newId,
        category: null,
        subCategory: null,
        operator: null,
        values: [],
        combination: "and",
        contactFilterValue: "",
      },
    ]);
  };

  const handleChangeFilter = (val, key) => {
    let res = selectedFilters.map((item) => {
      if (item.id == key) {
        return {
          ...item,
          category: val.category,
          subCategory: val.subCategory,
          operator: val.operator,
          values: val.values,
          combination: val.combination,
          contactFilterValue: val.contactFilterValue,
        };
      }
      return item;
    });
    setSelectedFilters(res);
  };

  const handleChangeFilterSpecific = (val, key, it) => {
    let res = selectedFilters.map((item) => {
      if (item.id == key) {
        return {
          ...item,
          [it]: val,
        };
      }
      return item;
    });
    setSelectedFilters(res);
  };

  const handleRemoveFilter = (filterId) => {
    // console.log(filterId)
    // console.log(selectedFilters)
    const updatedFilters = selectedFilters.filter(
      (filter) => filter.id !== filterId
    );
    // console.log(updatedFilters)

    // handleChangeFilter(updatedFilters, filterId);
    setSelectedFilters(updatedFilters);
  };

  const handleClearAll = () => {
    dispatch(setGlobalSelectedSegment("all"));
    setSelectedFilters([
      {
        id: 1,
        category: null,
        subCategory: null,
        operator: null,
        values: [],
        combination: "and",
        contactFilterValue: "",
      },
    ]);
    onClose();
  };

  const handleApply = () => {
    // handleFetchFilters(selectedFilters)
    // onClose()

    const errorMessages = formHasError(selectedFilters, attrAndTypes);
    // console.log(selectedFilters)
    if (errorMessages.length > 0) {
      errorMessages.forEach((errorMessage) => {
        // console.log(errorMessage); // Print each error message
        message.warning(errorMessage);
      });
    } else {
      message.success("Filters applied");
      handleFetchFilters(selectedFilters);
      dispatch(setGlobalSelectedSegment(""));
      onClose();
      // onClose();
      // console.log("No errors."); // No error conditions met
    }
  };

  useEffect(() => {
    handleFetchFiltersCount(selectedFilters);
  }, [selectedFilters]);

  const selectedSegment = useSelector(
    (state) => state?.contactReducer?.globalSelectedSegment
  );

  const {
    data: segmentData,
    loading: segmentDataLoading,
    error: segmentDataError,
  } = useQuery(GET_ONE_SEGMENT, {
    variables: {
      organizationId: organizationId,
      segmentId: selectedSegment,
    },
  });

  useEffect(() => {
    if (
      selectedSegment !== "all" &&
      segmentData?.getSegments?.segmentInformation !== undefined
    ) {
      setSelectedFilters(segmentData?.getSegments?.segmentInformation);
    }
  }, [selectedSegment]);

  return (
    <Drawer
      title={null}
      placement="right"
      onClose={onClose}
      visible={open}
      // width={500}
      closable={false}
      className={styles.FilterContactDrawer}
      data-testid="FilterContactDrawer"
    >
      <div className={styles.FilterContactDrawer__header}>
        <div className={styles.FilterContactDrawer__header__left}>
          <ArrowLeftOutlined
            className={styles.FilterContactDrawer__header__left__arrow}
            onClick={onClose}
          />
          <span style={{ fontWeight: "bold" }}>All Contacts</span>
        </div>
        <Button type="primary" onClick={showModal}>
          Save as Segment
        </Button>
      </div>

      <div className={styles.FilterContactDrawer__body}>
        {selectedFilters.map((filter) => (
          <>
            <QuerySelector
              key={filter.id}
              items={selectedFilters}
              addFilter={handleAddFilter}
              filterId={filter.id}
              onRemoveFilter={handleRemoveFilter}
              handleChangeFilterSpecific={handleChangeFilterSpecific}
              labels={allLabels}
              allAssignees={convertDataFormat(allAssignees)}
              handleChangeFilter={handleChangeFilter}
              multiSelectsData={multiSelectsData}
              allChannels={allChannels}
              attrAndTypes={attrAndTypes?.map((item) => ({
                ...item,
                label: item.name,
                value: item.id,
              }))}
              filter={filter}
              segmentData={segmentData}
            />
          </>
        ))}
      </div>

      <div className={styles.FilterContactDrawer__buttons}>
        <Button onClick={handleClearAll}>Clear All</Button>
        <Button type="primary" onClick={handleApply}>
          Apply
        </Button>
      </div>
      <SegmentationModal
        isModalOpen={isSegmentModalOpen}
        handleCancel={handleCancel}
        handleOk={handleOk}
        selectedFilters={selectedFilters}
        onClose={onClose}
        handleOpenSegmentDrawer={handleOpenSegmentDrawer}
      />
    </Drawer>
  );
};

FilterContactDrawer.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

FilterContactDrawer.defaultProps = {
  onClose: () => {},
  open: false,
};

export default FilterContactDrawer;
