import React, { useState } from "react";
import styles from "../../Channels/Channels.module.scss";
import {
  Button,
  Switch,
  Dropdown,
  Menu,
  Drawer,
  Modal,
  Space,
  Input,
  Checkbox,
} from "antd";
import WhatsappDesktop from "src/assets/desktop.png";
import SettingsIcon from "src/assets/svgs/SettingsIcon";
import Tick from "src/assets/Tick.svg";
import ErrorSvg from "src/assets/error.svg";
import UsersSvg from "src/assets/users.svg";
import { DownOutlined } from "@ant-design/icons";
import WhatsappIcon from "src/assets/svgs/WhatsappIcon";
import EditWhatsappDesktopChannel from "../../EditWhatsappDesktopChannel/EditWhatsappDesktopChannel";
import EditCustomAPIChannel from "../../EditCustomAPIChannel/EditCustomAPIChannel";
import { useMutation } from "@apollo/client";
import { message } from "antd";
import { CopyOutlined, MailOutlined } from "@ant-design/icons";
import {
  SET_CUSTOMAPI_CHANNEL_ACTIVE,
  // SET_EMAIL_CHANNEL_ACTIVE,
  SET_WHATSAPP_CHANNEL_ACTIVE,
} from "src/graphql/channel";
import ChannelForm from "src/components/ChannelForm/ChannelForm";
// import DropdownWithSearchAndCheckboxes from "./DropdownWithSearchAndCheckboxes";

const AllChannelsComponent = ({
  waChannel,
  searchTerm,
  customAPIChannel,
  emailChannel,
  loading,
  handleEditSubmitWhatsappOfficial,
  handleDeleteWhatsappOfficial,
}) => {
  const [showEditWhatsapp, setShowEditWhatsapp] = useState({
    detail: {},
    shown: false,
  });

  const [showEditCustomAPI, setShowEditCustomAPI] = useState({
    detail: {},
    shown: false,
  });

  // const [showEditEmailChannel, setEditEmailChannel] = useState({
  //   detail: {},
  //   shown: false,
  // });

  const [isEditOfficial, setIsEditOfficial] = useState(false);
  const [setWhatsappChannelActiveMutation] = useMutation(
    SET_WHATSAPP_CHANNEL_ACTIVE
  );
  const [setCustomAPIChannelActiveMutation] = useMutation(
    SET_CUSTOMAPI_CHANNEL_ACTIVE
  );
  // const [setEmailChannelActiveMutation] = useMutation(SET_EMAIL_CHANNEL_ACTIVE);

  const [initValues, setInitValues] = useState("");

  const [changeField, setChangeField] = useState(false);

  const info = (token, postLeadSaveWebhookURL, sendMessageWebhookURL) => {
    const handleCopyText = (text) => {
      navigator.clipboard.writeText(text);
      message.success("Text copied to clipboard");
    };

    const renderParagraphWithCopy = (text) => {
      return (
        <Input
          value={text}
          disabled
          addonAfter={<CopyOutlined onClick={() => handleCopyText(text)} />}
        />
      );
    };

    Modal.info({
      content: (
        <Space direction="vertical" style={{ textAlign: "left" }}>
          {renderParagraphWithCopy(token)}
          {renderParagraphWithCopy(postLeadSaveWebhookURL)}
          {renderParagraphWithCopy(sendMessageWebhookURL)}
        </Space>
      ),
      onOk() {},
    });
  };

  const handleEditOfficial = (_id, waChannel) => {
    let ch = waChannel?.filter((idx) => idx?.id === _id);

    setInitValues(ch);
    setIsEditOfficial(true);
  };

  const handleCancel = () => {
    setIsEditOfficial(false);
  };

  const Items = [
    {
      label: "all members",
      key: "0",
      peopleBadge: "",
    },
    {
      label: "test test",
      key: "1",
      peopleBadge: "",
    },
  ];

  const menu = <Menu items={Items} />;

  const handleWhatsappChannelStatus = async (id, isActive) => {
    try {
      const patch = {
        filter: {
          id: [`${id}`],
        },
        set: {
          active: isActive === "true" ? "false" : "true",
        },
      };

      const { data } = await setWhatsappChannelActiveMutation({
        variables: {
          patch,
        },
      });

      // Handle the response data after the mutation if needed
    } catch (error) {
      // Handle the error if the mutation fails
      console.error(error);
    }
  };

  const handleCustomAPIChannelStatus = async (id, isActive) => {
    try {
      const patch = {
        filter: {
          id: [`${id}`],
        },
        set: {
          active: isActive === "true" ? "false" : "true",
        },
      };

      const { data } = await setCustomAPIChannelActiveMutation({
        variables: {
          patch,
        },
      });

      // Handle the response data after the mutation if needed
    } catch (error) {
      // Handle the error if the mutation fails
      console.error(error);
    }
  };

  // const handleEmailChannelStatus = async (id, isActive) => {
  //   try {
  //     const patch = {
  //       filter: {
  //         id: [`${id}`],
  //       },
  //       set: {
  //         active: isActive === "true" ? "false" : "true",
  //       },
  //     };

  //     const { data } = await setEmailChannelActiveMutation({
  //       variables: {
  //         patch,
  //       },
  //     });

  //     // Handle the response data after the mutation if needed
  //   } catch (error) {
  //     // Handle the error if the mutation fails
  //     console.error(error);
  //   }
  // };

  function searchData(waChannel, customAPIChannel, emailChannel, searchTerm) {
    // console.log(
    //   "🚀 ~ file: AllChannelsComponent.js:204 ~ searchData ~ waChannel, customAPIChannel, emailChannel, searchTerm:",
    //   waChannel,
    //   customAPIChannel,
    //   emailChannel,
    //   searchTerm
    // );

    const lowercasedSearchTerm = searchTerm.toLowerCase();
    const filteredWaChannel = waChannel?.filter((channel) =>
      channel?.name?.toLowerCase().includes(lowercasedSearchTerm)
    );
    const filteredCustomAPIChannel = customAPIChannel?.filter((channel) =>
      channel?.name?.toLowerCase().includes(lowercasedSearchTerm)
    );
    const filteredEmailChannel = emailChannel?.filter((channel) =>
      channel?.name?.toLowerCase().includes(lowercasedSearchTerm)
    );

    return {
      filteredWaChannel,
      filteredCustomAPIChannel,
      filteredEmailChannel,
    };
  }

  return (
    <div className={styles.ChannelCards}>
      {showEditWhatsapp.shown && (
        <EditWhatsappDesktopChannel
          detail={showEditWhatsapp.detail}
          isVisible={showEditWhatsapp.shown}
          setShowDrawer={setShowEditWhatsapp}
        />
      )}
      {showEditCustomAPI.shown && (
        <EditCustomAPIChannel
          detail={showEditCustomAPI.detail}
          isVisible={showEditCustomAPI.shown}
          setShowDrawer={setShowEditCustomAPI}
        />
      )}
      {searchData(
        waChannel,
        customAPIChannel,
        emailChannel,
        searchTerm
      )?.filteredWaChannel?.map((tx) => (
        <div className={styles.ChannelCard}>
          <div className={styles.ChannelCard__header}>
            <div className={styles.ChannelCard__header__name}>
              <div>
                <WhatsappIcon />
              </div>
              <p>Whatsapp Official</p>
            </div>
            <div className={styles.ChannelCard__header__status}>
              <div>
                <img src={tx?.active === "true" ? Tick : ErrorSvg} />
                <p
                  style={
                    tx?.active === "true"
                      ? { color: "#237804" }
                      : { color: "#CF1322" }
                  }
                >
                  {tx?.active === "true" ? "Connected" : "Session expired"}
                </p>
              </div>

              <Switch
                loading={loading}
                checked={tx?.active === "true"}
                onClick={() => handleWhatsappChannelStatus(tx?.id, tx?.active)}
              />
            </div>
          </div>
          <div className={styles.ChannelCard__body}>
            <p>{tx.name}</p>
          </div>
          <div className={styles.ChannelCard__footer}>
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              data-testid="UserDropdownBtn"
            >
              <Button>
                <div className={styles.ChannelCard__footer__dropdown}>
                  <img src={UsersSvg} />
                  <p>14</p>
                  <DownOutlined />
                </div>
              </Button>
            </Dropdown>

            <Button
              data-testid="channel_edit_btn"
              onClick={() => handleEditOfficial(tx?.id, waChannel)}
            >
              <p>Edit</p>
            </Button>
          </div>
        </div>
      ))}

      {searchData(
        waChannel,
        customAPIChannel,
        emailChannel,
        searchTerm
      )?.filteredEmailChannel?.map((tx) => (
        <div className={styles.ChannelCard}>
          <div className={styles.ChannelCard__header}>
            <div className={styles.ChannelCard__header__name}>
              <div>
                <MailOutlined />
              </div>
              <p>Email Official</p>
            </div>
            <div className={styles.ChannelCard__header__status}>
              <div>
                <img src={tx?.active === "true" ? Tick : ErrorSvg} />
                <p
                  style={
                    tx?.active === "true"
                      ? { color: "#237804" }
                      : { color: "#CF1322" }
                  }
                >
                  {tx?.active === "true" ? "Connected" : "Session expired"}
                </p>
              </div>

              <Switch
                loading={loading}
                checked={tx?.active === "true"}
                // onClick={() => handleWhatsappChannelStatus(tx?.id, tx?.active)}
              />
            </div>
          </div>
          <div className={styles.ChannelCard__body}>
            <p>{tx.name}</p>
          </div>
          <div className={styles.ChannelCard__footer}>
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              data-testid="UserDropdownBtn"
            >
              <Button>
                <div className={styles.ChannelCard__footer__dropdown}>
                  <img src={UsersSvg} />
                  <p>14</p>
                  <DownOutlined />
                </div>
              </Button>
            </Dropdown>

            <Button
              data-testid="channel_edit_btn"
              disabled={true}
              // onClick={() => handleEditOfficial(tx?.id, waChannel)}
            >
              <p>Edit</p>
            </Button>
          </div>
        </div>
      ))}

      {/* {waDesktopChannel.map((tx) => (
        <div className={styles.ChannelCard}>
          <div className={styles.ChannelCard__header}>
            <div className={styles.ChannelCard__header__name}>
              <div>
                <img src={WhatsappDesktop} />
              </div>
              <p>Whatsapp Desktop</p>
            </div>
            <div className={styles.ChannelCard__header__status}>
              <div>
                <img src={tx?.active ? Tick : ErrorSvg} />
                <p
                  style={
                    tx?.active ? { color: "#237804" } : { color: "#CF1322" }
                  }
                >
                  {tx?.active ? "Connected" : "Session expired"}
                </p>
              </div>
              <Switch
                loading={loading}
                checked={tx?.active}
                onChange={handleWhatsappChannelStatus}
              />
            </div>
          </div>
          <div className={styles.ChannelCard__body}>
            <p>{tx.name}</p>
          </div>
          <div className={styles.ChannelCard__footer}>
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              data-testid="UserDropdownBtn"
            >
              <Button>
                <div className={styles.ChannelCard__footer__dropdown}>
                  <img src={UsersSvg} />
                  <p>14</p>
                  <DownOutlined />
                </div>
              </Button>
            </Dropdown>

            <Button
              onClick={() => {
                setShowEditWhatsapp({ detail: tx, shown: true });
              }}
            >
              <p>Edit</p>
            </Button>
          </div>
        </div>
      ))} */}

      {searchData(
        waChannel,
        customAPIChannel,
        emailChannel,
        searchTerm
      ).filteredCustomAPIChannel?.map((tx) => (
        <div className={styles.ChannelCard}>
          <div className={styles.ChannelCard__header}>
            <div className={styles.ChannelCard__header__name}>
              <div>
                <SettingsIcon />
              </div>
              <p>Custom API</p>
            </div>
            <div className={styles.ChannelCard__header__status}>
              <div>
                <img src={tx?.active === "true" ? Tick : ErrorSvg} />
                <p
                  style={
                    tx?.active === "true"
                      ? { color: "#237804" }
                      : { color: "#CF1322" }
                  }
                >
                  {tx?.active === "true" ? "Connected" : "Session expired"}
                </p>
              </div>
              <Switch
                loading={loading}
                checked={tx?.active === "true"}
                onChange={() =>
                  handleCustomAPIChannelStatus(tx?.id, tx?.active)
                }
              />
            </div>
          </div>
          <div className={styles.ChannelCard__body}>
            <p>{tx.name}</p>
          </div>

          <div className={styles.ChannelCard__footer}>
            {!changeField ? (
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                data-testid="UserDropdownBtn"
              >
                <Button
                // onClick={() => setChangeField(true)}
                >
                  <div className={styles.ChannelCard__footer__dropdown}>
                    <img src={UsersSvg} />
                    <p>14</p>
                    <DownOutlined />
                  </div>
                </Button>
              </Dropdown>
            ) : null}
            <Button
              // onClick={() => info(tx.token, tx.postLeadSaveWebhookURL, tx.sendMessageWebhookURL)}
              onClick={() => {
                setShowEditCustomAPI({ detail: tx, shown: true });
              }}
            >
              <p>Edit</p>
            </Button>
          </div>
        </div>
      ))}
      <Drawer
        headerStyle={{ display: "none" }}
        // width={534}
        className={styles.ChannelForm__drawer}
        placement="right"
        footer={null}
        open={isEditOfficial}
        onOk={() => {}}
        onCancel={handleCancel}
        onClose={() => setIsEditOfficial(false)}
      >
        <ChannelForm
          initialValues={initValues?.[0]}
          // loadingFlag={isloading}
          action={"EDIT"}
          onSubmit={handleEditSubmitWhatsappOfficial}
          onCancel={handleCancel}
          handleDeleteWhatsappOfficial={handleDeleteWhatsappOfficial}
        />
      </Drawer>
    </div>
  );
};

export default AllChannelsComponent;
