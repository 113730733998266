import { gql } from "@apollo/client";

export const GET_BILLING_DETAILS = gql`
  subscription ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      id
      billing {
        id
        endDate
        status
        subscriptionId
        customerId
      }
    }
  }
`;

export const GET_ORG_BILLING_DETAILS = gql`
  subscription ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      id
      billing {
        id
        subscriptionId
        customerId
        status
        current_price_id
      }
    }
  }
`;

export const CURSOR_LOAD_MESSAGES = gql`
  query ($leadId: ID!, $uniqueId: Int64, $pageSize: Int) {
    getHC_Lead(id: $leadId) {
      id
      username
      phone
      apiChannelIdentifier {
        apiChannel {
          id
        }
        identifierValue
      }
      threads {
        id
        messages(
          order: { desc: timestamp }
          first: $pageSize
          filter: { uniqueId: { lt: $uniqueId } }
        ) {
          from
          to
          timestamp
          body
          type
          id
          username
          uniqueId
          attachments {
            id
            url
            type
          }
        }
        participants {
          ... on HC_Lead {
            username
          }
          ... on HC_Staff {
            username
          }
        }
      }
    }
  }
`;
