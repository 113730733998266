import { gql } from "@apollo/client";

export const STAFF_SUBSCRIPTION = gql`
  subscription ($staffId: ID!) {
    getHC_Staff(id: $staffId) {
      staffOrganization {
        id
        roleName
      }
    }
  }
`;

export const QUERY_STAFF = gql`
  subscription ($staffId: ID!) {
    getHC_Staff(id: $staffId) {
      id
      timezone
    }
  }
`;

export const GET_STAFF_DATA = gql`
  query ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      id
      name
      leads {
        id
        email
        phone
        createdDate
        bio
        organization {
          id
        }
        whatsappNumber {
          code
          country
          number
          fullNumber
        }
        phoneNumber {
          code
          country
          number
          fullNumber
        }
        username
      }
    }
  }
`;

export const GET_ORGANIZATION_STAFF = gql`
  query ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      id
      organizationStaff {
        staff {
          id
          username
          email
          firstName
          lastName
          keycloakUserId
        }
      }
    }
  }
`;

export const WEBSOCKET_PING = gql`
  query ($staffId: ID!) {
    getHC_Staff(id: $staffId) {
      id
      staffOrganization {
        id
        roleName
      }
    }
  }
`;
