import React, { lazy, Suspense } from "react";
import Loader from "../Conversation/components/Loader/Loader";

const LazyLayout = lazy(() => import("./Layout"));

const Layout = props => (
  <Suspense fallback={Loader}>
    <LazyLayout {...props} />
  </Suspense>
);

export default Layout;
