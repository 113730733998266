import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./AutomationIndividualAction.module.scss";
import {
  Select,
  Checkbox,
  Input,
  DatePicker,
  TimePicker,
  notification,
} from "antd";
import { useQuery, useSubscription } from "@apollo/client";
import {
  allActionAttributeOptions,
  allowedOptions,
  attributeOptions,
  periodOptions,
  sendOptions,
} from "./constants";
import IndividualEditorInstance from "../IndividualEditorInstance/IndividualEditorInstance";
import { LOAD_CHANNELS } from "src/graphql/channel";
import { getActiveOrgId } from "src/store/slices/userSlice";
import { useSelector } from "react-redux";

const { TextArea } = Input;

const { Option } = Select;

function dateToTimestamp(dateString) {
  const timestamp = new Date(dateString).getTime();
  return timestamp;
}

const AutomationIndividualAction = ({ item, onItemChange }) => {
  const organizationId = useSelector(getActiveOrgId);
  const {
    data: channelsData,
    loading: channelsDataLoading,
    error: channelsDataError,
  } = useQuery(LOAD_CHANNELS, {
    variables: {
      organizationId: organizationId,
    },
  });

  // console.log(channelsData?.getOrganization?.channelData?.waChannel)

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [checkboxOptions, setCheckboxOptions] = useState([]);
  const [emailOptions, setEmailOptions] = useState([]);
  const [channelOptions, setChannelOptions] = useState([]);
  const [apiChannelOptions, setApiChannelOptions] = useState([]);
  const [val, setVal] = useState(null);
  const [period, setPeriod] = useState(null);
  const [time, setTime] = useState(null);
  const [timeForDate, setTimeForDate] = useState(null);
  const [date, setDate] = useState(null);
  const fileListContainer = useRef(null);

  const onValChange = (e) => {
    setVal(e.target.value);
  };

  const handleOptionClick = (value) => {
    setCheckboxOptions([]);
    if (checkboxOptions.includes(value)) {
      return;
    }

    if (!selectedOptions.includes(value)) {
      setSelectedOptions([value]);
    } else {
      setSelectedOptions([]);
    }
  };

  const handleCheckboxChange = (value) => {
    if (!checkboxOptions.includes(value)) {
      setCheckboxOptions([value]);
    } else {
      setCheckboxOptions([]);
    }
  };

  const handleEmailCheckboxChange = (value) => {
    if (!emailOptions.includes(value)) {
      setEmailOptions([...emailOptions, value]);
    } else {
      setEmailOptions(emailOptions.filter((option) => option !== value));
    }
  };

  const onPeriodChange = (e) => {
    setPeriod(e);
  };

  const onActionChange = (e) => {
    onItemChange(item?.id, "action", e);
  };

  const onSourceChange = (e) => {
    onItemChange(item?.id, "source", e);
  };

  const handleContent = (e) => {
    onItemChange(item?.id, "whatsappRule", e, "content");
  };

  const handleApiChannelContent = (e) => {
    onItemChange(item?.id, "apiChannelRule", e, "content");
  };

  const onSendChange = (e) => {
    onItemChange(item?.id, "schedule", e, "type");
  };

  const onChannelChange = (e) => {
    onItemChange(item?.id, "whatsappRule", e, "channels");
  };

  const onApiChannelChange = (e) => {
    onItemChange(item?.id, "apiChannelRule", e, "channels");
  };

  const onApiFileChange = (e) => {
    onItemChange(item?.id, "apiChannelRule", e, "file");
  };

  const onWhatsappFileChange = (e) => {
    onItemChange(item?.id, "whatsappRule", e, "file");
  };

  const onFileUpload = (e) => {
    //onItemChange(item?.id, "file", e.target.files[0].name, "file")
  };

  function createTimestamp(dateStr, timeStr) {
    const [year, month, day] = dateStr.split("-").map(Number);
    const [hours, minutes, seconds] = timeStr.split(":").map(Number);

    // Create a new Date object with the provided date and time components
    const timestamp = new Date(
      year,
      month - 1,
      day,
      hours,
      minutes,
      seconds
    ).getTime();

    return timestamp;
  }

  const dateChange = (date, dateString) => {
    // console.log(dateString,timeForDate)
    // let val = dateToTimestamp(dateString);
    // onItemChange(item?.id, "schedule", val, "timestamp");
    setDate(dateString);
  };
  const timeChange = (date, dateString) => {
    setTime(dateString);
    // let val = dateToTimestamp(dateString);
    // onItemChange(item?.id, "schedule", val, "timestamp");
  };

  const timeChangeForDate = (date, dateString) => {
    setTimeForDate(dateString);
    // let val = dateToTimestamp(dateString);
    // onItemChange(item?.id, "schedule", val, "timestamp");
  };

  // console.log(timeForDate,)

  function calculateTimestamp(daysFromNow, timeString) {
    const currentDate = new Date();

    // Add daysFromNow to the current date
    currentDate.setDate(currentDate.getDate() + daysFromNow);

    // Parse the timeString to extract hours, minutes, and seconds
    const [hours, minutes, seconds] = timeString.split(":").map(Number);

    // Set the time to the specified timeString
    currentDate.setHours(hours, minutes, seconds, 0);

    // Calculate and return the Unix timestamp in milliseconds
    return currentDate.getTime();
  }

  useEffect(() => {
    if (val !== null && time !== null) {
      let res = calculateTimestamp(val, time);
      onItemChange(item?.id, "schedule", res, "timestamp");
    }
  }, [val, time]);

  useEffect(() => {
    if (timeForDate !== null && date !== null) {
      let res = createTimestamp(date, timeForDate);
      onItemChange(item?.id, "schedule", res, "timestamp");
    }
  }, [date, timeForDate]);

  useEffect(() => {
    if (channelsData?.getOrganization?.channelData?.waChannel) {
      let item = channelsData?.getOrganization?.channelData?.waChannel?.map(
        (item) => ({
          value: item?.id,
          label: item?.name,
        })
      );
      setChannelOptions(item);
    }
    if (channelsData?.getOrganization?.channelData?.apiChannel) {
      let item = channelsData?.getOrganization?.channelData?.apiChannel?.map(
        (item) => ({
          value: item?.id,
          label: item?.name,
        })
      );
      setApiChannelOptions(item);
    }
  }, [channelsData]);

  return (
    <div className={styles.AutomationIndividualAction}>
      <div className={styles.AutomationIndividualAction__Body__FirstRow}>
        <Select
          style={{ width: "150px" }}
          className="Automation_Rule_Action_Action_Type_Select"
          placeholder="Choose action"
          value={item?.action}
          onChange={onActionChange}
          options={allowedOptions}
        />
        {item.action !== null && (
          <Select
            className="Automation_Rule_Action_Channel_Type_Select"
            mode={checkboxOptions.length > 0 ? "default" : "default"}
            style={{ width: "100%" }}
            placeholder="Select options"
            value={item?.source}
            onChange={onSourceChange}
          >
            {/* <Option value="all" onClick={() => handleOptionClick("all")}>
            via All available channels
          </Option> */}
            {/* <Option value="last" onClick={() => handleOptionClick("last")}>
            via Last interacted
          </Option> */}
            <Option value="Whatsapp">
              <Checkbox
                onChange={() => handleCheckboxChange("Whatsapp")}
                checked={checkboxOptions.includes("Whatsapp")}
              >
                via Whatsapp
              </Checkbox>
            </Option>
            <Option value="Apichannel">
              <Checkbox
                onChange={() => handleCheckboxChange("Apichannel")}
                checked={checkboxOptions.includes("Apichannel")}
              >
                via Apichannel
              </Checkbox>
            </Option>
            {/* <Option value="via SMS">
            <Checkbox
              onChange={() => handleCheckboxChange("via SMS")}
              checked={checkboxOptions.includes("via SMS")}
            >
              via SMS
            </Checkbox>
          </Option> */}
            {/* <Option value="via Email">
            <Checkbox
              onChange={() => handleCheckboxChange("via Email")}
              checked={checkboxOptions.includes("via Email")}
            >
              via Email
            </Checkbox>
          </Option> */}
          </Select>
        )}
        {checkboxOptions.includes("via Email") && (
          <Select
            mode={"default"}
            style={{ width: "100%" }}
            placeholder="Select Email"
            value={emailOptions}
            onChange={setEmailOptions}
          >
            <Option value="sales@booking.com">
              <Checkbox
                onChange={() => handleEmailCheckboxChange("sales@booking.com")}
                checked={emailOptions.includes("sales@booking.com")}
              >
                sales@booking.com
              </Checkbox>
            </Option>
            <Option value="promotion@sales.com">
              <Checkbox
                onChange={() =>
                  handleEmailCheckboxChange("promotion@sales.com")
                }
                checked={emailOptions.includes("promotion@sales.com")}
              >
                promotion@sales.com
              </Checkbox>
            </Option>
            <Option value="hello@booking.com">
              <Checkbox
                onChange={() => handleEmailCheckboxChange("hello@booking.com")}
                checked={emailOptions.includes("hello@booking.com")}
              >
                hello@booking.com
              </Checkbox>
            </Option>
          </Select>
        )}
        {item.source == "Whatsapp" && (
          <Select
            style={{ width: "150px" }}
            placeholder="Choose channel"
            className="Automation_Rule_Action_Whatsapp_Select"
            // value={item?.whatsappRule?}
            onChange={onChannelChange}
            options={channelOptions}
          />
        )}
        {item.source === "Apichannel" && (
          <Select
            className="Automation_Rule_Action_Apichannel_Select"
            placeholder="Choose channel"
            style={{ width: "150px" }}
            onChange={onApiChannelChange}
            options={apiChannelOptions}
          />
        )}
      </div>
      {(checkboxOptions.includes("Whatsapp") ||
        checkboxOptions.includes("Apichannel")) && (
        <div className={styles.AutomationIndividualAction__message}>
          <span className={styles.AutomationIndividualAction__message__head}>
            Message content
          </span>
          {/* <div className={styles.AutomationIndividualAction__message__container}>
          <span
            className={
              styles.AutomationIndividualAction__message__container__label
            }
          >
            Subject
          </span>
          <Input />
        </div> */}
          <div
            className={styles.AutomationIndividualAction__message__container}
          >
            <span
              className={
                styles.AutomationIndividualAction__message__container__label
              }
            >
              Body
            </span>
            {
              item.source === "Whatsapp" && (
                <IndividualEditorInstance
                  onFileChange={onWhatsappFileChange}
                  onTextChange={handleContent}
                />
              )
              // <TextArea
              //   value={item?.whatsappRule?.content}
              //   onChange={handleContent}
              //   rows={4}
              // />
            }

            {item.source === "Apichannel" && (
              <IndividualEditorInstance
                onFileChange={onApiFileChange}
                onTextChange={handleApiChannelContent}
              />
            )}
          </div>
        </div>
      )}
      <div className={styles.AutomationIndividualAction__gap}>
        <span>When to send?</span>
        <Select
          style={{ width: "150px" }}
          placeholder="Select type"
          value={item?.schedule.type}
          onChange={onSendChange}
          options={sendOptions}
        />
        {item?.schedule?.type == "ONDATE" && (
          <div>
            <DatePicker onChange={dateChange} style={{ width: "120px" }} />
            <TimePicker
              onChange={timeChangeForDate}
              style={{ width: "120px" }}
            />
          </div>
        )}
        {item?.schedule?.type == "CUSTOM" && (
          <>
            <Input
              value={val}
              placeholder="Choose value"
              onChange={onValChange}
              style={{ width: "120px" }}
            />
            <Select
              style={{ width: "120px" }}
              placeholder="Select period"
              value={period}
              onChange={onPeriodChange}
              options={periodOptions}
            />
            <TimePicker onChange={timeChange} style={{ width: "120px" }} />
          </>
        )}
      </div>
    </div>
  );
};

AutomationIndividualAction.propTypes = {};

AutomationIndividualAction.defaultProps = {};

export default AutomationIndividualAction;
