import { getAcessToken, getContries, getStates } from "src/api/LocationApi"
import getConfig from "src/helpers/config";
import { setAuthdata } from "src/store/slices/locationSlice";

let { REACT_APP_LOCATION_TOKEN, REACT_APP_LOCATION_EMAIL } = getConfig();


export const getAuthToken = async (dispatch) => {
    try {
      const header = {
        "api-token": REACT_APP_LOCATION_TOKEN,
        "user-email": REACT_APP_LOCATION_EMAIL,
      };
      const { data } = await getAcessToken(header);
      if (data?.auth_token) {
        dispatch(setAuthdata({ auth_token: data.auth_token }));
        return data.auth_token;
      } else {
        throw new Error('No auth token found in the response');
      }
    } catch (error) {
      console.error('Error fetching auth token:', error);
      return null;
    }
  };

  export const getCountryList = async () => {
    try {
      const { data } = await getContries();
      if (data && data.length > 0) {
        const filteredCountryList = getFilteredCountryData(data)
        return filteredCountryList;
      } else {
        throw new Error('No countries found in the response');
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
      return null;
    }
  };

  export const getStateList = async (country) => {
    try {
      const { data } = await getStates(country);
      if (data && data.length > 0) {
        const filteredStateList = getFilteredStateData(data)
        return filteredStateList;
      } else {
        throw new Error(`No states found for country: ${country}`);
      }
    } catch (error) {
      console.error(`Error fetching states for ${country}:`, error);
      return null;
    }
  };


const getFilteredCountryData = (countryData)=>{
    return countryData.map(country => ({
        value: country.country_name,
        label: country.country_name
    }));
}

const getFilteredStateData = (stateData)=>{
    return stateData.map(country => ({
        value: country.state_name,
        label: country.state_name
    }));
}