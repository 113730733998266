import CoreApiClient from "./clients/CoreApiClient";

export const createNewOrganization = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/organization",
    data: body,
  });
};

export const getIntialData = async () => {
  return CoreApiClient({
    method: "GET",
    url: "/staff/initial-data",
  });
};

export const getStaffList = async () => {
  return CoreApiClient({
    method: "GET",
    url: `/staff/list`,
  });
};

export const getPeopleListing = async (url, headers) => {
  return CoreApiClient({
    method: "GET",
    url: `${url}/staff`,
    headers: headers,
  });
};

export const peopleUpdate = async (body) => {
  return CoreApiClient({
    method: "PUT",
    url: "/staff/role",
    data: body,
  });
};

export const peopleDelete = async (body) => {
  return CoreApiClient({
    method: "DELETE",
    url: "/staff",
    data: body,
  });
};

export const updateOrganisationTimezone = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/organization/timezone",
    data: body,
  });
};

export const updateAgentTimezone = async (body) => {
  return CoreApiClient({
    method: "PUT",
    url: "/staff/timezone",
    data: body,
  });
};

export const updateOrganisationLanguage = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/organization/language",
    data: body,
  });
};

export const getOrganisationPlans = async () => {
  return CoreApiClient({
    method: "GET",
    url: "/organization/plans",
  });
};

export const getActiveOrganisationPlan = async (priceId) => {
  return CoreApiClient({
    method: "GET",
    url: `/organization/plan/${priceId}`,
  });
};

export const getStripeCustomerById = async (customerId) => {
  return CoreApiClient({
    method: "GET",
    url: `/organization/customer/${customerId}`,
  });
};

export const createCheckoutSession = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/organization/create-checkout-session",
    data: body,
  });
};

export const manageNodeView = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/organization/treeView",
    data: body,
  });
};

export const manageActionCardView = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/organization/actionCard",
    data: body,
  });
};

export const createInviteLink = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/staff/invitation",
    data: body,
  });
};

export const fetchStaffProfile = async () => {
  return CoreApiClient({
    method: "GET",
    url: "/staff/self",
  });
};

export const getStaffPeople = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/staff/get-staff",
    data: body,
  });
};
export const syncWikiCategoryData = () => {
  return CoreApiClient({
    method: "POST",
    url: "/data/sync-wiki-data"
  })
}

