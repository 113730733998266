import axios from "axios";
import getConfig from "src/helpers/config";

let { REACT_APP_DOCS_API_URL, REACT_APP_DOCS_API_KEY } = getConfig();

const DocsApiClient = axios.create({
  baseURL: REACT_APP_DOCS_API_URL,
  timeout: 100000,
});

DocsApiClient.interceptors.request.use(
  config => {
    if (REACT_APP_DOCS_API_KEY) {
      config.headers['API_KEY'] = REACT_APP_DOCS_API_KEY;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default DocsApiClient;
