import { gql } from "@apollo/client";

export const CHECK_RESOLVED_STATUS = gql`
  subscription ($threadId: ID!) {
    getThread(id: $threadId) {
      currentStatus
    }
  }
`;

export const MARK_AS_READ = gql`
  mutation updateThread($patch: UpdateThreadInput!) {
    updateThread(input: $patch) {
      thread {
        id
        readBy
      }
    }
  }
`;

export const MARK_AS_UNREAD = gql`
  mutation updateThread($patch: UpdateThreadInput!) {
    updateThread(input: $patch) {
      thread {
        id
        readBy
      }
    }
  }
`;

export const CHECK_READ_BY = gql`
  subscription ($leadId: ID!) {
    getHC_Lead(id: $leadId) {
      unRepliedCount
      threads {
        readBy
      }
    }
  }
`;

export const LOAD_MESSAGE_TEMPLATES = gql`
  subscription ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      messageTemplates {
        id
        title
        content
        attachments {
          id
          url
          type
        }
      }
    }
  }
`;

export const REMOVE_USER_STARRED_MESSAGE = gql`
  mutation updateMessage($patch: UpdateMessageInput!) {
    updateMessage(input: $patch) {
      message {
        id
        starredBy {
          id
        }
      }
    }
  }
`;

export const LOAD_PARTICIPANTS = gql`
  query ($leadId: ID!) {
    getHC_Lead(id: $leadId) {
      username
      threads {
        id
        participants {
          ... on HC_Lead {
            username
          }
          ... on HC_Staff {
            username
          }
        }
      }
    }
  }
`;

export const CHANGE_STARRED_MESSAGE = gql`
  mutation updateMessage($patch: UpdateMessageInput!) {
    updateMessage(input: $patch) {
      message {
        id
        isStarred
      }
    }
  }
`;

export const ADD_MESSAGE_TEMPLATE = gql`
  mutation ($template: [AddMessageTemplatesInput!]!) {
    addMessageTemplates(input: $template) {
      messageTemplates {
        id
        organization {
          id
        }
        title
        content
      }
    }
  }
`;

export const LOAD_SEARCH_LIST = gql`
  query message($filter: MessageFilter, $organizationId: ID!) {
    getOrganization(id: $organizationId) {
      messages(filter: $filter) {
        id
        body
        from
        timestamp
        username
        organization {
          id
        }
        thread {
          id
          sender {
            id
            username
          }
        }
      }
    }
  }
`;

export const LOAD_NEWMESSAGES = gql`
  subscription ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      messages(order: { desc: timestamp }, offset: 0, first: 1) {
        id
        type
        thread {
          id
          classifier
        }
        chatbotMessageId
        isLiveResponse
        isBadge
        feedback
        isBotResponse
        sender
        senderId
        body
        timestamp
        from
        to
        attachments {
          id
          url
          type
        }
      }
    }
  }
`;

export const LOAD_MESSAGES = gql`
  query ($leadId: ID!, $pageSize: Int) {
    getHC_Lead(id: $leadId) {
      id
      username
      phone
      vdiIdentifier
      apiChannelIdentifier {
        apiChannel {
          id
        }
        identifierValue
      }
      normalAttributeValue {
        value
        normalAttribute {
          name
        }
      }
      threads {
        id
        messages(order: { desc: timestamp }, first: $pageSize) {
          from
          to
          timestamp
          body
          type
          id
          username
          uniqueId
          chatbotMessageId
          isLiveResponse
          isBadge
          feedback
          isBotResponse
          sender
          senderId
          pipelineProcessing
          actionItems {
            id
            query_type
            service_url
            snippet_ids
          }
          productType {
            id
            element
            surface_text
            index_offset
            uri
            productAttributes {
              id
              element
              surface_text
              index_offset
              uri
            }
          }
          attachments {
            id
            url
            type
          }
        }
        participants {
          ... on HC_Lead {
            username
          }
          ... on HC_Staff {
            username
          }
        }
      }
    }
  }
`;

export const UPDATE_MESSAGE_TEMPLATE = gql`
  mutation updateMessageTemplates($patch: UpdateMessageTemplatesInput!) {
    updateMessageTemplates(input: $patch) {
      messageTemplates {
        id
        organization {
          id
        }
        title
        content
      }
    }
  }
`;

export const DELETE_MESSAGE_TEMPLATE = gql`
  mutation deleteMessageTemplates($filter: MessageTemplatesFilter!) {
    deleteMessageTemplates(filter: $filter) {
      msg
    }
  }
`;

export const SEARCH_QUERY = gql`
  query ($organizationId: ID!, $filter: MessageFilter) {
    getOrganization(id: $organizationId) {
      leads {
        id
        username
        phone
        uniqueId
        unRepliedCount
        lastMessageTimestamp
        threads {
          id
          readBy
          messages(filter: $filter, order: { desc: timestamp }) {
            id
            from
            to
            timestamp
            body
            username
            attachments {
              id
              url
            }
          }
        }
      }
    }
  }
`;

export const SEARCH_QUERY_WITH_ATTACHMENTS = gql`
  query ($organizationId: ID!, $filter: MessageFilter) {
    getOrganization(id: $organizationId) {
      leads {
        id
        username
        phone
        uniqueId
        unRepliedCount
        lastMessageTimestamp
        threads {
          id
          readBy
          messages(filter: $filter, order: { desc: timestamp }) {
            id
            from
            to
            timestamp
            body
            username
            attachments {
              id
              url
            }
          }
        }
      }
    }
  }
`;

export const LOAD_MESSAGE_TEMPLATES_BY_ID = gql`
  query ($templateId: ID!) {
    getMessageTemplates(id: $templateId) {
      id
      title
      content
      attachments {
        id
        url
        type
      }
    }
  }
`;

export const CURSOR_LOAD_MESSAGE_TEMPLATES = gql`
  subscription ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      messageTemplates {
        id
        title
        content
        attachments {
          id
          url
          type
        }
      }
    }
  }
`;

export const LIST_STARRED_MESSAGES = gql`
  subscription ($leadId: ID!) {
    getHC_Lead(id: $leadId) {
      username
      phone
      threads {
        id
        messages(filter: { isStarred: true }) {
          from
          to
          timestamp
          body
          type
          id
          username
          uniqueId
          isStarred
          attachments {
            id
            url
            type
          }
        }
      }
    }
  }
`;

export const LIST_ACTION_CARDS = gql`
  subscription ($leadId: ID!) {
    getHC_Lead(id: $leadId) {
      threads {
        messages {
          id
          body
          timestamp
          thread {
            id
          }
          actionItems {
            id
            query_type
            service_url
            snippet_ids
          }
        }
      }
    }
  }
`;

export const GET_MESSAGE = gql`
  subscription ($messageId: ID!) {
    getMessage(id: $messageId) {
      from
      to
      timestamp
      body
      type
      id
      source
      username
      uniqueId
      isStarred
      channelId
      chatbotMessageId
      isLiveResponse
      isBadge
      feedback
      isBotResponse
      sender
      senderId
      feedback
      feedbackText
      status
      waErrorMsg
      pipelineProcessing
      thread {
        id
      }
      actionItems {
        id
        query_type
        service_url
        snippet_ids
      }
      productType {
        id
        element
        surface_text
        index_offset
        uri
        productAttributes {
          id
          element
          surface_text
          index_offset
          uri
        }
      }
    }
  }
`;

export const UPDATE_FEEDBACK = gql`
  mutation UpdateMessage($messageId: ID!, $feedback: String!, $feedbackText: String) {
    updateMessage(
        input: { filter: { id: [$messageId] }, set: { feedback: $feedback, feedbackText: $feedbackText } }
    ) {
        numUids
    }
  }
`

export const ADD_USER_STARRED_MESSAGE = gql`
  mutation updateHC_Staff($patch: UpdateHC_StaffInput!) {
    updateHC_Staff(input: $patch) {
      hC_Staff {
        id
      }
    }
  }
`;
