// Timer Worker
export default () => {
  let timer;

  self.addEventListener('message', e => {
    if (!e) return;
    let type = e.data;

    if (type === "start") {
      timer = setInterval(() => {
        // console.log('tick');
        postMessage('tick');
      }, 1000);
    } else if (type === 'stop') { 
      clearInterval(timer);
      postMessage('timer-stopped');
    }
  });
}
