import React from 'react';

const TickIcon = () => {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            {/* eslint-disable-next-line react/no-unknown-property */}
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="0" width="16" height="16">
                <path fillRule="evenodd" clipRule="evenodd" d="M8.8763 0.666016C4.82621 0.666016 1.54297 3.94926 1.54297 7.99935C1.54297 12.0494 4.82621 15.3327 8.8763 15.3327C12.9264 15.3327 16.2096 12.0494 16.2096 7.99935C16.2096 3.94926 12.9264 0.666016 8.8763 0.666016ZM12.4167 6.30414C12.6771 6.04379 12.6771 5.62168 12.4167 5.36133C12.1564 5.10098 11.7343 5.10098 11.4739 5.36133L7.80727 9.22326L6.27868 7.69466C6.01833 7.43431 5.59622 7.43431 5.33587 7.69466C5.07552 7.95501 5.07552 8.37712 5.33587 8.63747L7.33587 10.6375C7.59622 10.8978 8.01833 10.8978 8.27868 10.6375L12.4167 6.30414Z" fill="#061938" />
            </mask>
            <g mask="url(#mask0)">
                <rect x="0.875977" width="16" height="16" fill='var(--secondary-color)' />
            </g>
        </svg>
    );
};

export default TickIcon;
