import React from "react";
import PropTypes from "prop-types";
import styles from "./OnboardingPopover.module.scss";
import { CloseOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "antd";
import { changeOnBoardingStepNumber } from "src/store/slices/onboardingSlice";

const OnboardingPopover = ({
  steps,
  currentStep,
  disabledActions,
  onClickClose,
  meta,
  setMeta,
  setSteps,
  setIsOpen,
  showCloseButton = true,
}) => {
  const dispatch = useDispatch();
  const step = steps[currentStep];
  const onBoardingStatus = useSelector((state) => {
    return state?.onBoarding;
  });
  function closeClickHandler() {
    if (!disabledActions) {
      if (onClickClose && typeof onClickClose === "function") {
        onClickClose({
          setIsOpen,
          currentStep,
          steps,
          meta,
          setMeta,
          setSteps,
        });
      } else {
        setIsOpen(false);
      }
    }
  }

  return (
    <div className={styles.OnboardingPopover} data-testid="OnboardingPopover">
      <div className={styles.OnboardingPopover__Container}>
        <div className={styles.OnboardingPopover__Content}>{step?.content}</div>
        {showCloseButton === true && (
          <CloseOutlined
            disabled={disabledActions}
            onClick={closeClickHandler}
          />
        )}
      </div>
      {/* {
        onBoardingStatus?.data?.current === "communicateContact" && (currentStep === 1 || currentStep === 2) ?
          <div className={styles.OnboardingPopover__Footer}>
            <div className={styles.OnboardingPopover__Progress}>1<span>/2</span></div>
            <div className={styles.OnboardingPopover__Button}>
              <Button type="primary" onClick={() => {
                if (currentStep === 1) {
                  dispatch(changeOnBoardingStepNumber(2));
                } else if (currentStep === 2) {
                  dispatch(changeOnBoardingStepNumber(3));
                }
              }}>Next</Button>
            </div>
          </div> : null
      } */}
    </div>
  );
};
OnboardingPopover.propTypes = {};

OnboardingPopover.defaultProps = {};

export default OnboardingPopover;
