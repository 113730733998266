import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  data: {
    people: [],
    profileIds: {},
  },
  error: null,
};

const peopleSlice = createSlice({
  name: "people",
  initialState,
  reducers: {
    resetPeople: () => initialState,
    setProfileIds: (state, action) => {
      state.data.profileIds = { ...state.data.profileIds, ...action.payload };
    },
  },
});

export const { resetPeople, setProfileIds } = peopleSlice.actions;
export default peopleSlice.reducer;
