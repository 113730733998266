// import {
//   ArrowLeftOutlined,
//   CaretRightOutlined,
//   EditOutlined,
// } from "@ant-design/icons";
// import { Collapse } from "antd";
import React, { useEffect, useState } from "react";
// import { useMediaQuery } from "react-responsive";
import PropTypes from "prop-types";
import styles from "./Settings.module.scss";
import SettingsSideNavbar from "../SettingsSideNavbar/SettingsSideNavbar";
// import SettingSidebarContent from "../SettingSidebarContent/SettingSidebarContent";
import { useLocation } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AllPeople from "../SettingSidebarContent/AllPeople/people";
import AllChannels from "../SettingSidebarContent/AllChannels/channels";
import Billing from "../SettingSidebarContent/Billing/billing";
import TimezoneAndLanguages from "../SettingSidebarContent/TimezoneAndLanguages/timezoneAndLanguage";
import ListAutomationRule from "../ListAutomationRule/ListAutomationRule";
import ListCannedTemplate from "../ListCannedTemplate/ListCannedTemplate";
import InterfaceOptions from "../SettingSidebarContent/InterfaceOptions/InterfaceOptions";
// props need to be called from API for user info

// const { Panel } = Collapse;

// const profilePictureStyle = {
//   width: "80px",
//   height: "80px",
//   fontSize: "2em",
// };

const Settings = (props) => {
  // const profileChannels = useSelector(
  //   (state) => state?.channels?.data?.channels
  // );
  // const profileDetails = useSelector(
  //   (state) => state?.profileDetails?.data?.profileDetails
  // );
  // const [isProfile, setIsProfile] = useState(false);
  // const [showErrorMsg, setErrMsg] = useState("");
  // const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 980px)" });
  const [activeSetting, setActiveSetting] = useState("people");
  const { active } = useLocation().state || { active: null };

  useEffect(() => {
    if (active) {
      setActiveSetting(active);
    } else {
      setActiveSetting((activeSetting) => activeSetting);
    }
  }, [active]);

  return (
    <div data-testid="settings" className={styles.SettingsPage}>
      <div className={styles.SettingsPage__left_container}>
        <SettingsSideNavbar
          activeSetting={activeSetting}
          setActiveSetting={setActiveSetting}
        />
      </div>
      <div className={styles.SettingsPage__right_container}>
        <Routes>
          <Route path="/people" element={<AllPeople />} />
          <Route exact path="/" element={<AllPeople />} />
          <Route path="/channels" element={<AllChannels />} />
          <Route path="/billing" element={<Billing />} />
          <Route path="/automation" element={<ListAutomationRule />} />
          <Route
            path="/timezoneAndLanguage"
            element={<TimezoneAndLanguages />}
          />
          <Route path="/cannedResponse" element={<ListCannedTemplate />} />
          <Route path="/interfaceOptions" element={<InterfaceOptions />} />
        </Routes>
      </div>
    </div>
  );
};

Settings.propTypes = {
  navigate: PropTypes.func,
};

Settings.defaultProps = {
  navigate: () => {},
};

export default Settings;
