import React, { lazy, Suspense } from "react";

const LazyColorSchemePickerModal = lazy(() => import("./ColorSchemePickerModal"));

const ColorSchemePickerModal = props => (
  <Suspense fallback={null}>
    <LazyColorSchemePickerModal {...props} />
  </Suspense>
);

export default ColorSchemePickerModal;
