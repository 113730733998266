import React from "react";
import PropTypes from "prop-types";

import styles from "./AutomationRow.module.scss";
import AutomationCard from "../AutomationCard/AutomationCard";
import CreateAutomationRuleModal from "../CreateAutomationRuleModal/CreateAutomationRuleModal";
import UserAutomationCard from "../UserAutomationCard/UserAutomationCard";

const AutomationRow = ({ t, data, refetch }) => {
  return (
    <div className={styles.AutomationRow}>
      <div className={styles.AutomationRow__Header}>
        {data?.title}
      </div>
      <div className={styles.AutomationRow__Content}>
        {data?.title === t("Automation List") && <CreateAutomationRuleModal t={t} refetch={refetch} />}
        {
          data?.title === t("Automation List") ?
            data?.cards?.map((tx) => <UserAutomationCard data={tx} />)
            :
            data?.cards?.map((tx) => <AutomationCard data={tx} />)
        }
      </div>
    </div>
  );
}

AutomationRow.propTypes = {};

AutomationRow.defaultProps = {};

export default AutomationRow;
