import React, { useEffect, useState } from "react";
import { Select, Typography } from "antd";
import { ArrowDownOutlined } from "@ant-design/icons";
const { Text } = Typography;

const DropdownBox = ({
  config,
  staffList,
  setSelectedUserId,
  selectedUserId,
  setSelectedSalesRepEmail
}) => {
  const [placeholder, setPlaceholder] = useState("Select Member");
  
  const options = staffList.map((staff) => ({
    value: staff.staff.keycloakUserId,
    label: `${staff.staff.firstName} ${staff.staff.lastName}`,
    email: staff.staff.email,
  }));

  const onChange = (value) => {
    if(setSelectedUserId){
      setSelectedUserId(value);
    }
    const selectedOption = options.find(option => option.value === value);
    if (selectedOption) {
      if(setSelectedSalesRepEmail){
        setSelectedSalesRepEmail(selectedOption.email);
      }
    }
  };
  const onSearch = (value) => {};

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    const selectedUser = staffList.find(
      (user) => user.staff.keycloakUserId === selectedUserId
    );
    if (selectedUser) {
      setPlaceholder(`${selectedUser.staff.firstName} ${selectedUser.staff.lastName}`);
    } else {
      if(setSelectedSalesRepEmail){
        setPlaceholder("For all sales reps");

      }
      else{
        setPlaceholder("Select Member");
      }
    }
  }, [selectedUserId, staffList]);

  return (
    <>
      <Select
        showSearch
        style={{
          width: 200,
          marginRight: "3px",
          borderRadius: "30px",
        }}
        placeholder={placeholder}
        optionFilterProp="children"
        onChange={onChange}
        onSearch={onSearch}
        filterOption={filterOption}
        options={options}
        notFoundContent={setSelectedSalesRepEmail ? <Text type="secondary">No sales reps</Text> : <Text type="secondary">No members found</Text> }
      />
    </>
  );
};

export default DropdownBox;
