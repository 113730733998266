const passwordvalid = new RegExp(
  "^(?=.*[A-Za-z])(?=.*[@$!%*#?&])[A-Za-z0-9@$!%*#?&]{6,}$"
);
const namevalid = new RegExp("^[a-zA-Z]+$");
const emailValid = new RegExp(
  /^([\w]+[_\.\-]?[\w]+[@]{1}[a-z]+[\-]?[a-z]+[\.]{1}[a-z]{2,}?)$/gm
);
const signinApiHeaders = {
  Accept: "*/*",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers": "content-type",
  "Access-Control-Allow-Methods": "POST",
};

const emailField = "email";
const passwordField = "password";
const signinemailField = "signinemail";
const signinpasswordField = "signinpassword";
const fields = {
  [emailField]: {
    regex: emailValid,
    errorMessage: "Please enter a valid Email",
  },
  [passwordField]: {
    regex: passwordvalid,
    errorMessage:
      "Password should be atleast 6 characters long and should contain a special symbol!",
  },
  [signinemailField]: {
    regex: emailValid,
    errorMessage: "Invalid Email",
  },

  [signinpasswordField]: {
    regex: passwordvalid,
    errorMessage: "Invalid Password!",
  },
};

const returnValidatorFor =
  (fieldName, setValidationStatus, validationStatus) =>
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (value && getFieldValue(fieldName).match(fields[fieldName].regex)) {
          setValidationStatus({
            ...validationStatus,
            [fieldName]: "success",
          });
          return Promise.resolve();
        } else if (
          getFieldValue(fieldName) === undefined ||
        getFieldValue(fieldName)?.length === 0
        ) {
          setValidationStatus({
            ...validationStatus,
            [fieldName]: "",
          });
          return Promise.resolve();
        }
        setValidationStatus({
          ...validationStatus,
          [fieldName]: "error",
        });
        return Promise.reject(new Error(fields[fieldName].errorMessage));
      },
    });

const returnValidatorForName =
  (fieldName, setValidationStatus, validationStatus) =>
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (
          getFieldValue(fieldName) === undefined ||
        getFieldValue(fieldName)?.length === 0
        ) {
          setValidationStatus({
            ...validationStatus,
            [fieldName]: "",
          });
          return Promise.resolve();
        } else if (getFieldValue(fieldName).match(namevalid) === null) {
          setValidationStatus({
            ...validationStatus,
            [fieldName]: "error",
          });
          return Promise.reject(new Error("only aphabets are allowed"));
        } else if (
          value?.length !== 0 &&
        getFieldValue(fieldName)?.length !== 0
        ) {
          setValidationStatus({
            ...validationStatus,
            [fieldName]: "success",
          });
          return Promise.resolve();
        }
      },
    });

export {
  signinApiHeaders,
  emailField,
  emailValid,
  passwordvalid,
  namevalid,
  passwordField,
  signinemailField,
  signinpasswordField,
  returnValidatorFor,
  returnValidatorForName,
};
