import React from "react"

const EditContactIcon = () => {
  return (
    <svg width={44} height={44} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={44} height={44} rx={4} fill="#E6F7FF" />
      <path d="M16.0398 27.625C16.0867 27.625 16.1336 27.6203 16.1805 27.6133L20.1227 26.9219C20.1695 26.9125 20.2141 26.8914 20.2469 26.8563L30.182 16.9211C30.2038 16.8994 30.221 16.8737 30.2328 16.8453C30.2445 16.8169 30.2506 16.7866 30.2506 16.7559C30.2506 16.7252 30.2445 16.6948 30.2328 16.6664C30.221 16.6381 30.2038 16.6123 30.182 16.5906L26.2867 12.693C26.2422 12.6484 26.1836 12.625 26.1203 12.625C26.057 12.625 25.9984 12.6484 25.9539 12.693L16.0187 22.6281C15.9836 22.6633 15.9625 22.7055 15.9531 22.7523L15.2617 26.6945C15.2389 26.8201 15.2471 26.9493 15.2855 27.071C15.3238 27.1927 15.3913 27.3032 15.482 27.393C15.6367 27.543 15.8313 27.625 16.0398 27.625ZM17.6195 23.5375L26.1203 15.0391L27.8383 16.757L19.3375 25.2555L17.2539 25.6234L17.6195 23.5375ZM30.625 29.5938H13.375C12.9602 29.5938 12.625 29.9289 12.625 30.3438V31.1875C12.625 31.2906 12.7094 31.375 12.8125 31.375H31.1875C31.2906 31.375 31.375 31.2906 31.375 31.1875V30.3438C31.375 29.9289 31.0398 29.5938 30.625 29.5938Z" fill='var(--primary-color)' />
    </svg>
  )
}

export default EditContactIcon