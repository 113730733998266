import React, { lazy, Suspense } from "react";

const LazyLayout = lazy(() => import("./Layout"));

const TrainingInterfaceLayout = props => (
  <Suspense fallback={null}>
    <LazyLayout {...props} />
  </Suspense>
);

export default TrainingInterfaceLayout;
