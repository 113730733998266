import axios from "axios";

// import axiosRetry from "axios-retry";
import store from "src/store";
import getConfig from "src/helpers/config";

let { REACT_APP_LOCATION_BASE_URL } = getConfig();

const LocationApiClient = axios.create({
  baseURL: REACT_APP_LOCATION_BASE_URL,
  timeout: 100000,
  headers: {
    "Content-Type": "application/json",
  },
});


store.subscribe(listener);

function listener() {
  let token = select(store.getState());

  LocationApiClient.defaults.headers.common.Authorization = `Bearer ${token}`;

  function select(state) {
    return state?.location?.data?.auth_token;
  }
}

export default LocationApiClient;
