import React, { useEffect, useState } from "react";
import { Divider, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import styles from "./ListAutomationRule.module.scss";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import AutomationRow from "../AutomationRow/AutomationRow";
import { useTranslation } from "react-i18next";
import { AUTOMATION_RULE_BY_ORGANISATION } from "src/graphql/organization";
import { getActiveOrgId } from "src/store/slices/userSlice";
import { checkFeatureAvailability } from "src/helpers/checkFeatureAvailability";

const ListAutomationRule = () => {
  const { t } = useTranslation();
  const organizationId = useSelector(getActiveOrgId);
  const orgUrl = useSelector((state) => state?.user?.data?.staffOrganization?.[0]?.organization?.domain || '');

  const options = [
    [t("Automation List")],
    [
      t("Internal"), //"Recommended",
    ],
    // ["Slack", "Woo commerce", "Gmail", "Shopify", "Instagram", "Microsoft Teams"]
  ];
  // const data = [
  //   {
  //     title: "Recommended",
  //     cards: [{
  //       "title": "Shopify",
  //       "content": "New order received - Create card in certain section"
  //     }, {
  //       "title": "Woo commerce",
  //       "content": "Seek for some words - Add label to the contact"
  //     }]
  //   },
  //   {
  //     title: "Internal",
  //     cards: [{
  //       "title": "Internal",
  //       "content": "Seek for some words - Add label to the contact"
  //     }, {
  //       "title": "Internal",
  //       "content": "When comment added - Notify particular agent"
  //     }, {
  //       "title": "Internal",
  //       "content": "Card moved to a section - Add agents"
  //     }]
  //   }];
  // const IntegrationData = [{
  //   title: "Slack",
  //   cards: [{
  //     "title": "Slack",
  //     "content": "Task marked completed - Send channel message"
  //   }, {
  //     "title": "Slack",
  //     "content": "Task moved to a section - Send channel message"
  //   }, {
  //     "title": "Slack",
  //     "content": "Seek for some words - Add label to the contact"
  //   }]
  // }];
  const {
    data: automationData,
    loading,
    error,
    refetch,
  } = useQuery(AUTOMATION_RULE_BY_ORGANISATION, {
    variables: {
      organizationId: organizationId,
    },
  });
  const [items, setItems] = useState({
    title: t("Automation List"),
    cards: [],
  });
  useEffect(() => {
    if (loading === true || error === true) {
      setItems({
        title: t("Automation List"),
        cards: [],
      });
    }
    if (automationData?.getOrganization?.automationRule) {
      setItems({
        title: t("Automation List"),
        cards: automationData?.getOrganization?.automationRule,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [automationData]);
  return (
    <div className={styles.ListAutomationRule}>
      <div className={styles.ListAutomationRule__Header}>
        <h4>{t("Automation")}</h4>
        <p>{t("AutomationPlaceholder")}</p>
      </div>

      <div className={styles.ListAutomationRule__Container}>
      {checkFeatureAvailability("upgradeAutomationRule",orgUrl) && <div className={styles.ListAutomationRule__Container__DisabledOverlay} />}
        <div className={styles.ListAutomationRule__Container__Sidebar}>
          {options.map((tx, index) => {
            return (
              <>
                {tx.map((title) => (
                  <div className={styles.AutomationRow}>{title}</div>
                ))}
                {index + 1 !== options.length && (
                  <Divider style={{ margin: "0px" }} />
                )}
              </>
            );
          })}
        </div>
        <div className={styles.ListAutomationRule__Container__Content}>
          <div className={styles.Content__Header}>
            <p>{t("Available rules")}</p>
            <Input
              className={styles.Content__Header__Input}
              size="small"
              placeholder="Search rules"
              prefix={<SearchOutlined />}
            />
          </div>
          <AutomationRow refetch={refetch} t={t} data={items} />
          {/* {data.map((tx) => <AutomationRow data={tx} />)} */}
          {/* <div className={styles.Content__Header}>
            <p>Integration rules</p>
          </div>
          {IntegrationData.map((tx) => <AutomationRow data={tx} />)} */}
        </div>
      </div>
    </div>
  );
};

ListAutomationRule.propTypes = {};

ListAutomationRule.defaultProps = {};

export default ListAutomationRule;
