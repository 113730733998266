import React, { useState } from "react";
import PropTypes from "prop-types";
import desc1 from "src/assets/d1.png";
import desc2 from "src/assets/d2.png";
import desc3 from "src/assets/d3.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./FeatureCarousel.css";
import { Button, Row, Col } from "antd";
import "antd/dist/reset.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-regular-svg-icons";
import SignupForm from "../SignupForm/SignupForm";
import Socialmediasignup from "../Socialmediasignup/Socialmediasignup";
import { useMediaQuery } from "react-responsive";
import styles from "./FeatureCarousel.module.scss";
import getConfig from "src/helpers/config";

const FeatureCarousel = (props) => {
  const [display, setDisplay] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 980px)" });
  let hideCarousel = display && !isDesktopOrLaptop ? "none" : "block";

  /*function for creating custom pagination*/
  const settings = {
    customPaging: function (i) {
      return <div style={{ color: carouselBg }}>{""}</div>;
    },
    autoplay: true,
    arrows: false,
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 2,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    adaptiveHeight: false,
  };
  const {
    carouselBg,
    headingColor,
    headingLabel,
    headingTextSize,
    paraTextSize,
    paraColor,
    paraLabel,
  } = props;
  const textHeight = { height: "100px" };

  return (
    <>
      <div
        className="carousel-center-container"
        style={{ background: carouselBg, display: hideCarousel }}
      >
        {/*Intentionally left the above inline css for storybook variables */}
        <div className="carousel-content">
          {/*Intentionally left this inline css for storybook variables */}
          {/* <h3 className={headingTextSize} style={{ color: headingColor }}>
            {headingLabel}
          </h3>
          <p className={paraTextSize} style={{ color: paraColor }}>
            {paraLabel}
          </p> */}
        </div>
        <Slider {...settings}>
          <div className={styles.FeatureCarousel__SlideImage}>
            <div className={styles.FeatureCarousel__TextContainer}>
              Automate your repetitive workflows
            </div>
            <div className={styles.FeatureCarousel__ImageContainer}>
              <img src={desc1} alt="description" />
            </div>
          </div>
          <div className={styles.FeatureCarousel__SlideImage}>
            <div className={styles.FeatureCarousel__TextContainer}>
              Connect with all your customers across different channels from one
              place
            </div>
            <div className={styles.FeatureCarousel__ImageContainer}>
              <img src={desc2} alt="description" />
            </div>
          </div>
          <div className={styles.FeatureCarousel__SlideImage}>
            <div className={styles.FeatureCarousel__TextContainer}>
              Build AI enabled virtual assistants
            </div>
            <div className={styles.FeatureCarousel__ImageContainer}>
              <img src={desc3} alt="description" />
            </div>
          </div>
        </Slider>
        <div className="carousel-btn">
          <Button onClick={() => setDisplay(true)}>
            Get Started
            <span>
              <FontAwesomeIcon icon={faArrowAltCircleRight} />
            </span>
          </Button>
        </div>
      </div>
      {display && !isDesktopOrLaptop && (
        <Row>
          <Col span={24}>
            <SignupForm {...props} />
          </Col>
          {/* <Col className="signin-options" span={24}>
            <Socialmediasignup />
          </Col> */}
        </Row>
      )}
    </>
  );
};

FeatureCarousel.propTypes = {
  carouselBg: PropTypes.string,
  headingColor: PropTypes.string,
  paraColor: PropTypes.string,
  headingTextSize: PropTypes.string,
  paraTextSize: PropTypes.string,
};

FeatureCarousel.defaultProps = {
  carouselBg: "rgba(89, 126, 247, 1)",
  headingColor: "white",
  paraColor: "white",
  headingLabel: "Hybrid.chat",
  paraLabel: "Intelligence in your Omnichannel conversations",
  headingTextSize: "medium",
  paraTextSize: "medium",
};

export default FeatureCarousel;
