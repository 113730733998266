import React from "react";
// import PropTypes from "prop-types";
import styles from "../PeopleBody/PeopleBody.module.scss";
import { Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { getActiveStaffOrg } from "src/store/slices/userSlice";

const { Option } = Select;

const ListUser = ({
  item,
  handleStaffChange,
  showDeleteConfirm,
  index,
  loading,
}) => {
  let currentUserEmail = "remove@this";
  const activeStaffOrg = useSelector(getActiveStaffOrg);
  const {  email } = useSelector((state) => state?.user?.data);
  return (
    <>
    <div className={styles.PeopleBody__staff__body} key={index}>
      <div className={styles.PeopleBody__staff__body__name}>
        {index === 0 ?  <span data-testid="PeopleBody_ListUsers_name">
          {item.staff.firstName} {item.staff.lastName}(you)
        </span> :  <span data-testid="PeopleBody_ListUsers_name">
          {item.staff.firstName} {item.staff.lastName}
        </span> }
       
        <p>{item.staff.email}</p>
      </div>

      <div className={styles.PeopleBody__staff__body__actionContainer}>
        <Select
          data-testid="select-role-field"
          value={item.roleName}
          onChange={(value) => handleStaffChange(value, item.staff.id)}
          className={styles.PeopleBody__staff__body__actionContainer__select}
          loading={loading}
          disabled={activeStaffOrg?.roleName != "admin"}
        >
          <Option value="admin">Admin</Option>
          <Option value="member">Member</Option>
        </Select>
        {activeStaffOrg?.roleName === "admin" ? (
          <div
            className={styles.PeopleBody__staff__body__actionContainer__delete}
          >
            <DeleteOutlined
              onClick={() =>
                showDeleteConfirm(
                  `${item.staff.firstName} ${item.staff.lastName}`,
                  item.staff.id
                )
              }
              className={
                styles.PeopleBody__staff__body__actionContainer__delete__dltIcon
              }
            />
          </div>
        ) : (
          <div
            className={styles.PeopleBody__staff__body__actionContainer__delete_disabled}
          >
            <DeleteOutlined
              
              className={
                styles.PeopleBody__staff__body__actionContainer__delete__dltIcon
              }
            />
          </div>
        )}
      </div>
    </div>
    </>
  );
};

ListUser.propTypes = {};

ListUser.defaultProps = {};

export default ListUser;
