let organizationFeatures = {
    onboardingExperience: ['casterconcepts.hybrid.chat'],
    contactTags:['casterconcepts.hybrid.chat'],
    organizationLogo: ['casterconcepts.hybrid.chat'],
    upgradeCannedResponse: ['casterconcepts.hybrid.chat'],
    upgradeAutomationRule: ['casterconcepts.hybrid.chat'],
    upgradeInterfaceOption: ['casterconcepts.hybrid.chat'],
    locationFeature: ['casterconcepts.hybrid.chat'],
    analyticsTab: ['inbox.hci-dev.smarter.codes',"casterconcepts.hybrid.chat"],
    upgradeBilling: ['casterconcepts.hybrid.chat'],
    createAccountFeature: ['casterconcepts.hybrid.chat'],
    AIResponseFeedback: ['inbox.hci-dev.smarter.codes','casterconcepts.hybrid.chat', 'app.localhost'],
    drawerFiltering: ['inbox.hci-dev.smarter.codes','casterconcepts.hybrid.chat', 'app.localhost'],
    trainingInterface: ['inbox.hci-dev.smarter.codes','casterconcepts.hybrid.chat', 'app.localhost'],
  };
  export default organizationFeatures;
  

  export let DrawerSorting = {
    'app.localhost' : ['State', 'Country','Email'],
    'casterconcepts.hybrid.chat' : ['State', 'Country','Email'],
    'inbox.hci-dev.smarter.codes' : ['State', 'Country','Email'],
  }