import CoreApiClient from "./clients/CoreApiClient";

export const createSegmentation = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/filter/segmentation",
    data: body,
  });
};

export const executeSegmentation = async (body, id) => {
  return CoreApiClient({
    method: "POST",
    url: "/filter/segmentation/execute",
    data: body,
  });
};

export const deleteSegmentation = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/filter/segmentation/delete",
    data: body,
  });
};

export const updateSegmentation = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/filter/segmentation/update",
    data: body,
  });
};
