import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./ForgotPassword.module.scss";

import AuthLayout from "../AuthLayout/AuthLayout";

import { useFormik } from "formik";
import { resetPasswordEmail } from "src/api/AuthApi";
import { Link } from "react-router-dom";
import { Alert, Button, Form, Input } from "antd";
import { faArrowAltCircleRight } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as Yup from "yup";
import "antd/dist/reset.css";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const handleForgotPassword = async ({ email }) => {
    setLoading(true);
    let payload = { email };
    let res = await resetPasswordEmail(payload);
    setLoading(false);

    if (res.data.success) {
      setMessage({
        title: "Success",
        description:
          "An email for reset password is sent to your email address. Please check your email for password reset instructions.",
        type: "success",
        showIcon: true,
      });
    } else {
      const { error } = res.data;
      setMessage({
        title: "Error",
        description: error.replace(error[0], error[0].toUpperCase()),
        type: "error",
        showIcon: true,
      });
    }
  };

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: { email: "" },
      validationSchema: Yup.object().shape({
        email: Yup.string()
          .email("Invalid email address format")
          .required("Email required"),
      }),
      onSubmit: handleForgotPassword,
    });

  const handleValidationStatus = (key) => {
    return !touched[key] && !errors[key]
      ? ""
      : touched[key] && !errors[key]
      ? "success"
      : touched[key] && errors[key]
      ? "error"
      : "";
  };

  const printValidationMsg = (key) => {
    if (touched[key] && errors[key]) {
      return (
        <div
          role="alert"
          className={styles.ForgotPassword__signInContainer__formItem__error}
        >
          {errors[key]}
        </div>
      );
    } else return null;
  };

  return (
    <AuthLayout>
      <div className={styles.ForgotPassword} data-testid="sign-in-form">
        <div className={styles.ForgotPassword__signInContainer}>
          <h3 data-testid="SigninForm_heading">Send reset password link</h3>
          <form onSubmit={handleSubmit}>
            <Form.Item
              className={`${styles.ForgotPassword__signInContainer__formItem} signIn__form`}
              label="Enter email address"
              name="email"
              validateStatus={handleValidationStatus("email")}
              help={printValidationMsg("email")}
            >
              <Input
                id="email"
                name="email"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                placeholder="example@gmail.com"
              />
            </Form.Item>

            <div
              className={styles.ForgotPassword__signInContainer__btn_pwd_link}
            >
              <Button
                style={{
                  width: "100%",
                  background: "rgba(9, 109, 217, 1)",
                  color: "white",
                }}
                loading={loading}
                htmlType="submit"
              >
                <span>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} />
                </span>
                Reset Password
              </Button>
            </div>
            <div className={styles.ForgotPassword__message_container}>
              {message !== null ? (
                <Alert
                  message={message.title}
                  description={message.description}
                  type={message.type}
                  showIcon={message.showIcon}
                />
              ) : null}
            </div>
          </form>
          <footer
            className={styles.ForgotPassword__footer}
            data-testid="footer"
          >
            Need help? <span>Feel free to contact us</span>&nbsp;
            <a
              href="https://calendly.com/hybrid-chat/15min"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact Us!
            </a>
          </footer>
          <div className={styles.ForgotPassword__btn_back_container}>
            <Link to="/">Back To Login</Link>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

ForgotPassword.propTypes = {};

ForgotPassword.defaultProps = {};

export default ForgotPassword;
