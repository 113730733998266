import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./SideNavMobile.module.scss";
import { NavLink as Link, useNavigate } from "react-router-dom";
import ChatIcon from "src/assets/svgs/ChatIcon";
import ContactsIcon from "src/assets/svgs/ContactsIcon";
import UserSvg from "src/assets/User.svg";
import CannedTemplateIcon from "src/assets/Fire.svg";
import TimezoneSvg from "src/assets/Timezone.svg";
import ChannelSvg from "src/assets/Channel.svg";
import AutomationSvg from "src/assets/Bulb.svg";
import BillingSvg from "src/assets/billing.svg";
import PresentationIcon from "src/assets/svgs/PresentationIcon";
import MenuIcon from "src/assets/svgs/MenuIcon";
import SettingsIcon from "src/assets/svgs/SettingsIcon";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import OrganizationTitle from "src/assets/title.svg";
import { useSelector } from "react-redux";
import { useSubscription } from "@apollo/client";
import { getActiveOrg, getActiveOrgId } from "src/store/slices/userSlice";
import { LOAD_UNREPLIEDCOUNTS } from "src/graphql/thread";

const SideNavMobile = ({ t, toggleMenu }) => {
  const organizationId = useSelector(getActiveOrgId);
  const isActive = ({ isActive }) => {
    if (isActive) {
      return true;
    } else {
      return false;
    }
  };

  const [profileImageSrc, setProfileImageSrc] = useState(
    "https://picsum.photos/50"
  );
  const [statusColor, setStatusColor] = useState("#00E676");
  const activeOrg = useSelector(getActiveOrg);

  function getUnrepliedCount(leads) {
    let sum = 0;

    if (leads !== undefined) {
      for (let lead of leads) {
        const unRepliedCount = lead.unRepliedCount;
        if (unRepliedCount !== null && typeof unRepliedCount !== "undefined") {
          sum += unRepliedCount;
        }
      }
    }

    return sum;
  }

  // console.log(activeOrg)

  const {
    data: leadData,
    error: leadError,
    loading: leadLoading,
  } = useSubscription(LOAD_UNREPLIEDCOUNTS, {
    variables: {
      organizationId: organizationId,
    },
  });

  // console.log(leadData)

  return (
    <div className={styles.SideNavMobile}>
      <nav className={styles.SideNavMobile__bottom}>
        <div className={styles.SideNavMobile__ProfileImage}>
          <span className={styles.SideNavMobile__ProfileInitial}>
            {activeOrg?.length > 0 && activeOrg?.name[0]}
          </span>
        </div>{" "}
        <Tooltip placement="right" title="Inbox">
          <Link to="/chat">
            {({ isActive }) => (
              <>
                <div
                  className={
                    isActive
                      ? styles.SideNavMobile__active
                      : styles.SideNavMobile__icon
                  }
                  onClick={toggleMenu}
                >
                  <ChatIcon isActive={isActive} />
                  {getUnrepliedCount(leadData?.getOrganization?.leads) !==
                    0 && (
                    <span className={styles.SideNavMobile__count}>
                      {getUnrepliedCount(leadData?.getOrganization?.leads)}
                    </span>
                  )}
                  <span style={{ marginLeft: "10px" }}>Chats</span>
                </div>
              </>
            )}
          </Link>
        </Tooltip>
        <Tooltip placement="right" title="Contacts">
          <Link to="/contacts">
            {({ isActive }) => (
              <>
                <div
                  className={
                    isActive
                      ? styles.SideNavMobile__active
                      : styles.SideNavMobile__icon
                  }
                  onClick={toggleMenu}
                >
                  <ContactsIcon isActive={isActive} />
                  <span>Contacts</span>
                </div>
              </>
            )}
          </Link>
        </Tooltip>
        {/* <Link className={isActive} to="/dashboard">
          {({ isActive }) => (
            <div
              className={
                isActive ? styles.SideNavMobile__active : styles.SideNavMobile__icon
              }
            >
              <PresentationIcon isActive={isActive} />
            </div>
          )}
        </Link> */}
      </nav>
      <nav className={styles.SideNavMobile__bottom}>
        <Tooltip placement="right" title="People">
          <Link to="/settings/people">
            {({ isActive }) => (
              <>
                <div
                  className={
                    isActive
                      ? styles.SideNavMobile__active
                      : styles.SideNavMobile__icon
                  }
                  onClick={toggleMenu}
                >
                  <img src={UserSvg} alt="user" />
                  <span>People</span>
                </div>
              </>
            )}
          </Link>
        </Tooltip>
        <Tooltip placement="right" title="Canned Response">
          <Link to="/settings/cannedResponse">
            {({ isActive }) => (
              <>
                <div
                  className={
                    isActive
                      ? styles.SideNavMobile__active
                      : styles.SideNavMobile__icon
                  }
                  onClick={toggleMenu}
                >
                  <img src={CannedTemplateIcon} alt="cannedTemplate" />
                  <span>Canned Response</span>
                </div>
              </>
            )}
          </Link>
        </Tooltip>
        <Tooltip placement="right" title="Timezone and Language">
          <Link to="/settings/timezoneAndLanguage">
            {({ isActive }) => (
              <>
                <div
                  className={
                    isActive
                      ? styles.SideNavMobile__active
                      : styles.SideNavMobile__icon
                  }
                  onClick={toggleMenu}
                >
                  <img src={TimezoneSvg} alt="timezone" />
                  <span>Timezone and Language</span>
                </div>
              </>
            )}
          </Link>
        </Tooltip>
        <Tooltip placement="right" title="Automation">
          <Link to="/settings/automation">
            {({ isActive }) => (
              <>
                <div
                  className={
                    isActive
                      ? styles.SideNavMobile__active
                      : styles.SideNavMobile__icon
                  }
                  onClick={toggleMenu}
                >
                  <img src={AutomationSvg} alt="automation" />
                  <span>Automation</span>
                </div>
              </>
            )}
          </Link>
        </Tooltip>
        <Tooltip placement="right" title="Channels">
          <Link to="/settings/channels">
            {({ isActive }) => (
              <>
                <div
                  className={
                    isActive
                      ? styles.SideNavMobile__active
                      : styles.SideNavMobile__icon
                  }
                  onClick={toggleMenu}
                >
                  <img src={ChannelSvg} alt="channel" />
                  <span>Channels</span>
                </div>
              </>
            )}
          </Link>
        </Tooltip>
        <Tooltip placement="right" title="Billing">
          <Link to="/settings/billing">
            {({ isActive }) => (
              <>
                <div
                  className={
                    isActive
                      ? styles.SideNavMobile__active
                      : styles.SideNavMobile__icon
                  }
                  onClick={toggleMenu}
                >
                  <img src={BillingSvg} alt="billing" />
                  <span>Billing</span>
                </div>
              </>
            )}
          </Link>
        </Tooltip>
        <Tooltip placement="right" title="Help">
          <a target="_blank" href="https://docs.hybrid.chat" rel="noreferrer">
            <>
              <div className={styles.SideNavMobile__icon} onClick={toggleMenu}>
                <QuestionCircleOutlined
                  style={{
                    color: "#262626",
                    fontSize: "21px",
                    marginLeft: "-1px",
                  }}
                />
                <span>Help</span>
              </div>
            </>
          </a>
        </Tooltip>
        <div
          className={styles.SideNavMobile__profile_image_block}
          onClick={toggleMenu}
        >
          <Link to="/profile" style={{ display: "flex", alignItems: "center" }}>
            <img
              src={profileImageSrc}
              alt="profile"
              className={styles.SideNavMobile__profile_image}
            />
            <span
              className={styles.SideNavMobile__online_status_circle}
              style={{ backgroundColor: statusColor }}
            ></span>
            <span style={{ marginLeft: "8px", color: 'var(--primary-color)' }}>Profile</span>
          </Link>
        </div>
      </nav>
    </div>
  );
};

SideNavMobile.propTypes = {};

SideNavMobile.defaultProps = {};

export default SideNavMobile;
