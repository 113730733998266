import React, { useEffect, useState } from "react";
import styles from "./ListCannedTemplate.module.scss";
import {
  Button,
  Input,
  // Divider,
  Table,
  Space,
  // Tag,
  // Tooltip,
  // Form,
  message,
  Modal,
  notification,
} from "antd";
import {
  DeleteOutlined,
  // EyeOutlined,
  EditOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useSubscription } from "@apollo/client";
import { deleteCannedTemplateAPI } from "src/api/MessageApi";
import {
  CreateCannedTemplateForm,
  EditCannedResponseForm,
} from "src/components/Conversation/Conversation";
import { useTranslation } from "react-i18next";
import { CURSOR_LOAD_MESSAGE_TEMPLATES } from "src/graphql/message";
import { getActiveOrgId } from "src/store/slices/userSlice";
import { checkFeatureAvailability } from "src/helpers/checkFeatureAvailability";

const { Search } = Input;
const ListCannedTemplate = () => {
  const { t } = useTranslation();
  const orgUrl = useSelector((state) => state?.user?.data?.staffOrganization?.[0]?.organization?.domain || '');

  const organizationId = useSelector(getActiveOrgId);

  const { data } = useSubscription(CURSOR_LOAD_MESSAGE_TEMPLATES, {
    variables: {
      organizationId: organizationId,
    },
  });
  const [showform, setShowForm] = useState(false);
  const [list, setList] = useState([]);
  useEffect(() => {
    if (data === null || data === undefined) return;
    setList(data?.getOrganization?.messageTemplates);
  }, [data]);
  const [value, setValue] = useState("");
  // const [selectedRow, setSelectedRow] = useState(null);
  const [
    shown,
    // setShown
  ] = useState({
    shown: false,
    name: "",
    content: "",
  });
  const [showDeleteModal, setShowDeleteModal] = useState({
    shown: false,
    name: "",
    content: "",
    id: "",
  });
  // const setContent = (content) => {
  //   setShown({
  //     shown: true,
  //     name: content.templateName,
  //     content: content.content,
  //   });
  // };
  const openDeleteModal = (record) => {
    setShowDeleteModal({
      shown: true,
      name: record.title,
      content: record.content,
      id: record?.id,
    });
  };
  const [editTemplateId, setEditTemplateId] = useState({
    shown: false,
    id: "",
    title: "",
    content: "",
  });

  const columns = [
    {
      title: t("Reply Name"),
      dataIndex: "templateName",
      key: "template name",
      width: "45%",
      render: (_, record) => <p>{record.title}</p>,
    },
    {
      title: t("Action"),
      key: "action",
      width: "20%",
      render: (_, record) => (
        <Space size="small">
          {/* <EyeOutlined
            onClick={() => setContent(record)}
            className={
              selectedRow === record
                ? `${styles.ListCannedTemplate__eye} ${styles.ListCannedTemplate__eye__Color}`
                : styles.ListCannedTemplate__eye
            }
          /> */}

          <button
            onClick={async () => {
              setEditTemplateId({
                shown: true,
                id: record?.id,
                title: record?.title,
                content: record?.content,
              });
            }}
            className={styles.ListCannedTemplate__EditIcon}
          >
            <EditOutlined />
          </button>

          <button
            onClick={() => openDeleteModal(record)}
            className={styles.ListCannedTemplate__delete}
          >
            <DeleteOutlined />
          </button>
        </Space>
      ),
    },
  ];
  let locale = {
    emptyText: (
      <div className={styles.MessageTemplates__empty_state}>
        <div>{t("There is no message Template")}</div>
      </div>
    ),
  };

  const onSearch = (e) => {
    const currValue = e.target.value;
    setValue(currValue);
  };

  const closePanel = (e) => {
    setShowDeleteModal({
      shown: false,
      name: "",
      content: "",
    });
  };

  const deleteCannedTemplate = async () => {
    try {
      if (
        showDeleteModal.id === null ||
        showDeleteModal === undefined ||
        showDeleteModal.id === ""
      )
        return;
      const res = await deleteCannedTemplateAPI({
        templateId: showDeleteModal.id,
      });
      const data = res?.data;
      if (data?.success === true) {
        message.success(`"${showDeleteModal.name}" canned response deleted`);
      } else {
        notification.error({
          message: "Notification",
          description: data?.message,
        });
      }
      setShowDeleteModal({
        shown: false,
        name: "",
        content: "",
        id: "",
      });
    } catch (err) {
      console.log(err);
      return;
    }
  };

  const cancelCannedTemplate = () => {
    setShowDeleteModal({
      shown: false,
      name: "",
      content: "",
    });
  };

  const filteredTemplates = (list, value) => {
    if (!value) {
      return list;
    }

    const lowerCaseSearchTerm = value.toLowerCase();
    const filtered = list?.filter((item) => {
      return item?.title?.toLowerCase().includes(lowerCaseSearchTerm);
    });
    return filtered;
  };

  const filtered = filteredTemplates(list, value);

  return (
    <>
      {editTemplateId.shown === true ? (
        <EditCannedResponseForm
          showDrawer={editTemplateId}
          setShowDrawer={setEditTemplateId}
        />
      ) : null}
      <CreateCannedTemplateForm
        showDrawer={showform}
        setShowDrawer={setShowForm}
      />
      <div className={styles.ListCannedTemplate}>
        {}
        <div className={styles.ListCannedTemplate__header}>
          <h3>{t("Canned response")}</h3>
          <p>{t("Manage canned response here")}</p>
        </div>
        <div
          className={
            shown.shown === false
              ? `${styles.ListCannedTemplate__Body} ${styles.ListCannedTemplate__Body__PaddingRight}`
              : `${styles.ListCannedTemplate__Body}`
          }
        >{checkFeatureAvailability("upgradeCannedResponse",orgUrl) && <div className={styles.ListCannedTemplate__Body__DisabledOverlay} />}
          <div className={styles.ListCannedTemplate__Body__Table}>
            <div className={styles.ListCannedTemplate__activity_buttons}>
              <Search
                className={
                  styles.ListCannedTemplate__activity_buttons__searchbar
                }
                placeholder={t("Search Template")}
                allowClear
                onChange={(e) => onSearch(e)}
              />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                className={styles.ListCannedTemplate__activity_buttons__button}
                onClick={() => {
                  setShowForm(true);
                }}
                style={{backgroundColor:'var(--primary-color)'}}
              >
                {t("Add New Template")}
              </Button>
            </div>
            <Table
              // onRow={(record, rowIndex) => {
              //   return {
              //     onClick: () => {
              //       // setSelectedRow(record);
              //     },
              //   };
              // }}
              locale={locale}
              pagination={{ pageSize: 10, position: ["bottomCenter"] }}
              dataSource={filtered}
              columns={columns}
            />
          </div>
          {shown.shown === true ? (
            <div className={styles.ListCannedTemplate__Body__Preview}>
              <div
                className={styles.ListCannedTemplate__PreviewContent__Header}
              >
                <p>{t("Preview")}</p>
                <CloseOutlined onClick={closePanel} />
              </div>
              <div className={styles.ListCannedTemplate__PreviewContent__Title}>
                {shown.name}
              </div>
              <div
                className={styles.ListCannedTemplate__PreviewContent__Preview}
              >
                {shown.content}
              </div>
            </div>
          ) : null}
        </div>
        <Modal
          title="Confirm Deletion"
          open={showDeleteModal.shown}
          onOk={deleteCannedTemplate}
          onCancel={cancelCannedTemplate}
          maskClosable={false}
        >
          <p>
            Are you sure to delete <b>{showDeleteModal.name}</b>?
          </p>
        </Modal>
      </div>
    </>
  );
};

ListCannedTemplate.propTypes = {};

ListCannedTemplate.defaultProps = {};

export default ListCannedTemplate;
