import React, { useEffect, useState } from "react";
import styles from "./User.module.scss";
import { Avatar, Tag, Popover, Tooltip } from "antd";

const User = ({ value, usersData }) => {
  function showData(value) {
    return value
      .map((item) => `${item.firstName} ${item.lastName} (${item.email})`)
      .join(", ");
  }

  if (Object.keys(value[0]).length > 2) {
    return (
      <span className={styles.User}>
        {value.map((item) => {
          let randomColor = "#" + Math.random().toString(16).substr(-6);
          let ele = usersData?.filter((e) => e.id === item.id)?.[0];
          return (
            <Tag
              closable={false}
              style={{
                marginRight: 3,
              }}
            >
              <Avatar
                size="small"
                className={styles.User__user}
                style={{
                  backgroundColor: randomColor,
                  verticalAlign: "middle",
                }}
              >
                {ele?.firstName + ele?.lastName}
              </Avatar>
              {ele?.firstName} {ele?.lastName}
            </Tag>
          );
        })}
      </span>
    );
  } else {
    return <span>---</span>;
  }
};

export default User;
