import React, { useEffect } from "react";
import { getDefaultKeyBinding } from "draft-js";
import RichTextEditor from "react-rte";
import classNames from 'classnames';
import styles from "./Editor.module.scss";
import "./Editor.css";

const Editor = ({ 
  editorInstance, 
  setEditorInstance, 
  isToolBarVisible, 
  onSubmitConfig, 
  placeholder, 
  onChange, 
  chatChoice,
  readOnly 
}) => {
  const onKeyPress = (e) => {
    const { onSubmit, activateKeyEvent } = onSubmitConfig;
    if (activateKeyEvent) {
      if (e.key === "Enter" && !isToolBarVisible) {
        onSubmit();
      } else {
        return getDefaultKeyBinding(e);
      }
    }
  }

  const editorClass = chatChoice === "2"
    ? styles.Editor__rich_text_editor_simple
    : styles.Editor__rich_text_editor_advanced;

  useEffect(() => {
    const placeholderElement = document.querySelector('.public-DraftEditorPlaceholder-root');
    if (placeholderElement) {
      if (readOnly) {
        placeholderElement.classList.add('public-DraftEditorPlaceholder-readonly');
      } else {
        placeholderElement.classList.remove('public-DraftEditorPlaceholder-readonly');
      }
    }
  }, [readOnly]);

  return (
    <div className={styles.Editor}>
      <RichTextEditor
        className={editorClass}
        toolbarClassName={
          isToolBarVisible
            ? styles.Editor__input_toolbar_visible
            : styles.Editor__input_toolbar_hide
        }
        toolbarConfig={{
          display: [
            "INLINE_STYLE_BUTTONS",
            "BLOCK_TYPE_BUTTONS",
            "HISTORY_BUTTONS",
          ],
          INLINE_STYLE_BUTTONS: [
            { label: "Bold", style: "BOLD", className: "custom-css-class" },
            { label: "Italic", style: "ITALIC" },
            { label: "Strikethrough", style: "STRIKETHROUGH" },
          ],
          BLOCK_TYPE_BUTTONS: [
            { label: "UL", style: "unordered-list-item" },
            { label: "OL", style: "ordered-list-item" },
          ],
        }}
        placeholder={placeholder}
        value={editorInstance}
        onChange={onChange}
        keyBindingFn={onKeyPress}
        readOnly={readOnly}
      />
    </div>
  );
};

RichTextEditor.defaultProps = {
  placeholder: "Type a message",
  onSubmitConfig: {
    onSubmit: () => {},
    activateKeyEvent: false
  },
  readOnly: false
}

export default Editor;
