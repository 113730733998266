import React, { useEffect, useState } from "react";
import styles from "./Channels.module.scss";
import { Button, Modal, Form, Input, notification, Drawer } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createWhatsAppDesktopChannel } from "src/api/WhatsappApi";
import WhatsAppDesktopSuccess from "src/assets/desktop_success.png";
import { CloseOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import CreateWhatsappDesktopChannel from "../CreateWhatsappDesktopChannel/CreateWhatsappDesktopChannel";
import AllChannelsComponent from "./AllChannelsComponent/AllChannelsComponent";
import ChannelsSectionComponent from "./ChannelsSectionComponent/ChannelsSectionComponent";
import successIcon from "src/assets/desktop_success.png";
import CreateCustomAPIChannel from "../CreateCustomAPIChannel/CreateCustomAPIChannel";
import CreateEmailChannel from "../CreateEmailChannel/CreateEmailChannel";
import CreateEmailExtraction from "../CreateEmailExtraction/CreateEmailExtraction";
import { LOAD_CHANNELS } from "src/graphql/channel";
import { getActiveOrgId } from "src/store/slices/userSlice";
import { useTranslation } from "react-i18next";
import ChannelForm from "src/components/ChannelForm/ChannelForm";
import CountrySelect from "src/components/CountrySelect/CountrySelect";
import {
  createChannelWhatsapp,
  deleteChannelWhatsapp,
  updateChannelWhatsapp,
} from "src/api/ChannelApi";
import { useDispatch } from "react-redux";
import { setChannels } from "src/store/slices/channelSlice";

const Channels = () => {
  const { Search } = Input;
  const { t } = useTranslation();
  const organizationId = useSelector(getActiveOrgId);

  const [action, setAction] = useState("");
  const [channelError, setError] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const [waChannel, setWaChannel] = useState([]);
  const [emailChannel, setEmailChannel] = useState([]);
  const [waDesktopChannel, setWaDesktopChannel] = useState([]);
  const [customAPIChannel, setCustomAPIChannel] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [modalScreen, setModalScreen] = useState(1);
  const [isWhatsappPhone, setIsWhatsappPhone] = useState("");
  const [isEmailVisible, setIsEmailVisible] = useState(false);
  const [isChannelCreated, setIsChannelCreated] = useState(false);
  const [isCustomAPIVisible, setIsCustomAPIVisible] = useState(false);
  const [isWADesktopVisible, setIsWADesktopVisible] = useState(false);
  const [isExtractionVisible, setIsExtractionVisible] = useState(false);
  const [selectedProfileChannel, setSelectedProfileChannel] = useState(null);

  const closeModal = () => {
    setIsChannelCreated(false);
  };

  const showWhatsappDesktop = () => {
    setIsWADesktopVisible(true);
  };

  const showCustomAPI = () => {
    setIsCustomAPIVisible(true);
  };

  const showEmail = () => {
    setIsEmailVisible(true);
  };

  const showExtractionForm = () => {
    setIsExtractionVisible(true);
  };

  const setLoading = (param) => {
    setIsLoading(param);
  };

  useEffect(() => {
    if (action !== "create") return;
    if (isloading === false && channelError === true) {
      notification.error({
        message: <b>Notification</b>,
        description: (
          <b>
            Uh-oh! This shouldn&apos;t have happened! Review your details and try
            again
          </b>
        ),
      });
      setError(false);
    } else if (isloading === false && channelError === undefined) {
      setIsChannelCreated(true);
      setError(false);
    }
  }, [channelError, isloading, action]);

  const { data, loading, refetch } = useQuery(LOAD_CHANNELS, {
    variables: {
      organizationId: organizationId,
    },
  });

  useEffect(() => {
    if (action === "") return;
    if (action !== "edit") {
      const emptyFiels = {
        phoneNumber: "",
        channelName: "",
        twilioSID: "",
        twilioAuthToken: "",
        dialCode: "+91",
        isoCountryCode: "IN",
      };
      setSelectedProfileChannel(emptyFiels);
    }
  }, [action]);

  const handleSubmitWhatsappOfficial = async (values) => {
    let payload = {
      isoCountryCode: values.isoCountryCode,
      dialCode: values.dialCode,
      phone: values.phoneNumber,
      name: values.channelName,
      sid: values.twilioSID,
      authToken: values.twilioAuthToken,
      organizationId: organizationId,
    };
    const success = await createChannelWhatsapp(payload);

    setIsWhatsappPhone(`${values.dialCode}${values.phoneNumber}`);

    if (success) {
      refetch();
      setModalScreen(false);
    }
    return success;
  };

  const handleEditSubmitWhatsappOfficial = async (values) => {
    let payload = {
      isoCountryCode: values.isoCountryCode,
      dialCode: values.dialCode,
      phone: values.phoneNumber,
      name: values.channelName,
      sid: values.twilioSID,
      authToken: values.twilioAuthToken,
      id: values.id,
    };

    const success = await updateChannelWhatsapp(payload);

    setIsWhatsappPhone(`${values.dialCode}${values.phoneNumber}`);

    return success;
  };

  const handleDeleteWhatsappOfficial = async (values) => {
    let payload = { id: values.id };

    const success = await deleteChannelWhatsapp(payload);

    setIsWhatsappPhone(`${values.dialCode}${values.phoneNumber}`);

    return success;
  };

  useEffect(() => {
    if (data === null || data === undefined) return;

    setWaChannel(data?.getOrganization?.channelData?.waChannel);
    setWaDesktopChannel(data?.getOrganization?.channelData?.waDesktopChannel);
    setCustomAPIChannel(data?.getOrganization?.channelData?.apiChannel);
    setEmailChannel(data?.getOrganization?.channelData?.emailChannel);
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: {
      dialCodeText: "+91,IN",
      phoneNumber: "",
      channelName: "",
    },
    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string()
        .matches(/^[0-9]*$/, "Only numbers are allowed")
        .min(10, "Invalid Phone Number")
        .required("Required field"),
      channelName: Yup.string()
        .min(2, "Too Short!")
        .max(70, "Too Long!")
        .required("Required field"),
    }),
    onSubmit: () => {},
  });

  const handleValidationStatus = (key) => {
    return !touched[key] && !errors[key]
      ? ""
      : touched[key] && !errors[key]
      ? "success"
      : touched[key] && errors[key]
      ? "error"
      : "";
  };
  const handleChannelStatus = () => {};

  const submitForm = async () => {
    setIsLoading(true);
    const [dialCode, isoCountryCode] = values.dialCodeText.split(",");
    let data = {
      organizationId: organizationId,
      isoCountryCode: isoCountryCode,
      dialCode: dialCode,
      phone: values.phoneNumber,
      name: values.channelName,
    };
    try {
      const res = await createWhatsAppDesktopChannel(data);
      if (res.data.success === true) {
        notification.success({
          message: <b>Notification</b>,
          description: "Channel Created Successfully",
        });
        setModalScreen(3);
        setIsLoading(false);
      } else {
        if (res.data.error) {
          notification.error({
            message: <b>Notification</b>,
            description: res.data.error,
          });
        } else {
          notification.error({
            message: <b>Notification</b>,
            description:
              "We're encountering some problem processing your request",
          });
        }
        setIsLoading(false);
      }
    } catch (err) {
      notification.error({
        message: <b>Notification</b>,
        description: "We're encountering some problem processing your request",
      });
      setIsLoading(false);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getModalScreen = () => {
    switch (modalScreen) {
      case 1:
        return (
          <>
            <p className={styles.Modal__heading}>Enter your phone number</p>
            <p className={styles.Modal__para}>
              WhatsApp will send an SMS message to verify your phone number.
            </p>

            <Form.Item
              name="channelName"
              label="Channel Name"
              className={styles.ChannelForm__formContainer}
              validateStatus={handleValidationStatus("channelName")}
              hasFeedback
            >
              <Input
                name="channelName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.channelName}
              />
              {touched.channelName && errors.channelName ? (
                <div className={styles.ChannelForm__formContainer__error}>
                  {errors.channelName}
                </div>
              ) : null}
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label="Phone Number"
              className={styles.ChannelForm__formContainer}
              validateStatus={handleValidationStatus("phoneNumber")}
              hasFeedback
            >
              <Input
                id="phoneNumber"
                addonBefore={
                  <CountrySelect
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                }
                name="phoneNumber"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phoneNumber}
                disabled={false}
              />
              {touched.phoneNumber && errors.phoneNumber ? (
                <div className={styles.ChannelForm__formContainer__error}>
                  {errors.phoneNumber}
                </div>
              ) : null}
            </Form.Item>
            <div className={styles.Modal__ButtonRow}>
              <Button
                onClick={() => {
                  setIsVisible(false);
                }}
              >
                Back
              </Button>
              <Button
                onClick={() => {
                  if (
                    values.phoneNumber === "" ||
                    errors.phoneNumber !== undefined ||
                    errors.channelName !== undefined
                  ) {
                    return;
                  }
                  setModalScreen(2);
                }}
                type="primary"
              >
                Next
              </Button>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <p className={styles.Modal__para}>
              We will be verifying the phone number:
            </p>
            <p className={styles.Modal__parabold}>
              {values.dialCodeText.split(",")[0]} {values.phoneNumber}
            </p>
            <p className={styles.Modal__para}>
              Is this OK, or would you like to edit the number?
            </p>
            <div className={styles.Modal__ButtonRow}>
              <Button
                onClick={() => {
                  setModalScreen(1);
                }}
              >
                Edit
              </Button>
              <Button onClick={submitForm} disabled={isloading} type="primary">
                OK
              </Button>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className={styles.Success__HeaderContainer}>
              <img
                className={styles.Success__HeaderContainer__Image}
                src={WhatsAppDesktopSuccess}
                alt="whatsapp"
              />
              <CloseOutlined />
            </div>
            <p className={styles.Modal__heading}>Your inbox is connected</p>
            <p>
              You can now engage with your customers through your new channel.
            </p>
            <div className={styles.Modal__ButtonRow}>
              <Button
                className={styles.Success__Button}
                onClick={() => {
                  setModalScreen(1);
                  setIsVisible(false);
                }}
                type="primary"
              >
                Go to Inbox
              </Button>
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  const openWhatsappWeb = () => {
    window.open(
      `https://wa.me/${isWhatsappPhone.substring(1)}?text=Hi`,
      "_blank"
    );
  };
  const onSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <>
      <Modal
        visible={isChannelCreated}
        footer={null}
        width="405px"
        onCancel={closeModal}
      >
        <div className={styles.WhatsappModal__ImageContainer}>
          <img
            className={styles.WhatsappModal__ImageIcon}
            src={successIcon}
            alt="whatsapp"
          />
        </div>
        <h3 className={styles.WhatsappModal__Heading}>
          Your channel is connected
        </h3>
        <p className={styles.WhatsappModal__Paragraph}>
          You can now engage with your customers through your new channel.
        </p>
        <div className={styles.ResultInfo}>
          <ExclamationCircleFilled className={styles.WhatsappModal__Icon} />
          <p>
            All set now. To receive your first conversation in hybrid.chat
            inbox, Send a text from your WhatsApp to {isWhatsappPhone}
          </p>
        </div>
        <div className={styles.WhatsappModal__ButtonContainer}>
          <Button onClick={closeModal}>Skip</Button>
          <Button type="primary" onClick={openWhatsappWeb}>
            Open whatsapp web
          </Button>
        </div>
      </Modal>
      {
        <CreateWhatsappDesktopChannel
          isVisible={isWADesktopVisible}
          setShowDrawer={setIsWADesktopVisible}
        />
      }
      {
        <CreateCustomAPIChannel
          isVisible={isCustomAPIVisible}
          setShowDrawer={setIsCustomAPIVisible}
          fetch={refetch}
        />
      }
      {
        <CreateEmailChannel
          isVisible={isEmailVisible}
          setShowDrawer={setIsEmailVisible}
          fetch={refetch}
        />
      }
      {
        <CreateEmailExtraction
          isVisible={isExtractionVisible}
          setShowDrawer={setIsExtractionVisible}
          fetch={refetch}
        />
      }
      <div className={styles.ChannelsBody}>
        <div className={styles.ChannelsBody__Header}>
          <h3>{t("Channels")}</h3>
          <Search
            placeholder={t("Search channels")}
            onChange={onSearch}
            className={styles.ChannelsBody__search}
            allowClear
            style={{
              width: 281,
            }}
          />
        </div>

        <AllChannelsComponent
          waChannel={waChannel}
          waDesktopChannel={waDesktopChannel}
          customAPIChannel={customAPIChannel}
          emailChannel={emailChannel}
          loading={loading}
          handleChannelStatus={handleChannelStatus}
          handleEditSubmitWhatsappOfficial={handleEditSubmitWhatsappOfficial}
          handleDeleteWhatsappOfficial={handleDeleteWhatsappOfficial}
          searchTerm={searchTerm}
        />
        <h3>{t("Connect new channels")}</h3>
        <ChannelsSectionComponent
          t={t}
          showModal={showModal}
          showCustomAPI={showCustomAPI}
          showEmailChannel={showEmail}
          setAction={setAction}
          showWhatsappDesktop={showWhatsappDesktop}
          showExtractionForm={showExtractionForm}
          searchTerm={searchTerm}
        />

        <Drawer
          className={styles.ChannelForm__drawer}
          headerStyle={{ display: "none" }}
          placement="right"
          footer={null}
          open={isModalOpen}
          onOk={() => {}}
          onCancel={handleCancel}
          onClose={() => setIsModalOpen(false)}
        >
          <ChannelForm
            initialValues={selectedProfileChannel}
            loadingFlag={isloading}
            action={action.toUpperCase()}
            onSubmit={handleSubmitWhatsappOfficial}
            onCancel={handleCancel}
          />
        </Drawer>
        <Modal
          footer={null}
          open={isVisible}
          onOk={() => {}}
          onCancel={() => {}}
          closable={false}
        >
          <form onSubmit={handleSubmit} className={styles.ChannelForm}>
            {getModalScreen()}
          </form>
        </Modal>
      </div>
    </>
  );
};
Channels.propTypes = {};

Channels.defaultProps = {};

export default Channels;
