import React from "react";
import styles from "../../Channels/Channels.module.scss";
import WhatsappIcon from "src/assets/svgs/WhatsappIcon";
import SettingsIcon from "src/assets/svgs/SettingsIcon";
import {InfoOutlined, MailOutlined} from "@ant-design/icons"; 

// import EditWhatsappDesktopChannel from "../../EditWhatsappDesktopChannel/EditWhatsappDesktopChannel";
import { Button, Modal } from "antd";
import { useTour } from "@reactour/tour";
// import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux";

const ChannelsSectionComponent = ({
  showModal,
  setAction,
  searchTerm,
  showWhatsappDesktop,
  showCustomAPI,
  showEmailChannel,
  showExtractionForm,
  t
}) => {
  const info = () => {
    Modal.info({
      content: (
        <div style={{ textAlign: "left", maxHeight: "400px", overflowY: "scroll"  }}>
          <h3>While creating apichannel, two webhook must be provided:-</h3>
          <ol>
            <li>send message webhook:- HC Inbox will call this endpoint whenever a message is being sent from HC Inbox to API Channel.</li>
            <li>post-lead-save webhook:- HC inbox will call this endpoint whenever a lead is updated to broadcast the changes done.</li>
          </ol>
  
          <p>Demo example message body when a message is being received:</p>
          <pre>
            {`
              {
                  "body":"test messages 124",
                  "from":"+1234567890",
                  "to":"+2345678902",
                  "mediaUrl": "https://api.twilio.com/2010-04-01/Accounts/AC0bcd35c5a521a82d39d09f5add2deb89/Messages/MMa286d9b7250fe182f6a924424e7e6b56/Media/ME00fe50a6d654079425239929b17cf02e",
                  "mediaType": "image/jpeg",
                  "leadInfo":{
                      "username":"test 123",
                      "idName":"dialogNumber123a",
                      "idValue":"12345",
                      "isType":"Numbers",
                      "attrArray":[{
                          "name":"dilog1233456",
                          "value":"4556",
                          "type":"Numbers"
                      }]
                  }
              }
            `}
          </pre>
  
          <p>Demo example message body when a message is being sent from HC Inbox to API channel:</p>
          <pre>
            {`
              {
                body: 'hello',
                leadId: '12345',
                "mediaUrl":"https://storage.googleapis.com/hcinbox/1685354283697annotely_image(4).png",
                "mediaType":"png"
              }
            `}
          </pre>
        </div>
      ),
      onOk() {},
      style: { width: "1000px", height: "800px", top: "20px" },
    });
  };

  const { setIsOpen } = useTour();
  const onBoardingStatus = useSelector(
    (state) => {
      return state?.onBoarding;
    }
  );
  const closeOnboarding = () => {
    if (onBoardingStatus?.isStarted === true) {
      setIsOpen(false);
    }
  }
  const channelSections = [
    {
      name: "Whatsapp",
      channels: [
        {
          channelType: t("Whatsapp Official"),
          icon: <WhatsappIcon />,
          text: "Manage WhatsApp official conversations with your team",
          onClickEvent: function () {
            showModal();
            setAction("create");
            closeOnboarding();
          },
        },
        // {
        //   channelType: "Whatsapp Desktop",
        //   icon: <img src={WhatsappDesktop} alt="whatsapp"/>,
        //   text: "Manage WhatsApp desktop conversations with your team",
        //   onClickEvent: function () {
        //     showWhatsappDesktop();
        //     closeOnboarding();
        //   },
        // },
      ],
    },
    {
      name: "Custom API channel",
      channels: [
        {
          channelType: t("Custom API channel"),
          icon: <SettingsIcon />,
          text: "Manage Custom API channel conversations with your team",
          onClickEvent: function () {
            showCustomAPI();
            closeOnboarding();
          },
        },
      ]
    },

    {
      name: "Email channel",
      channels: [
        {
          channelType: t("Email channel"),
          icon: <MailOutlined />,
          text: "Manage Email channel conversations with your team",
          onClickEvent: function () {
            showEmailChannel();
            closeOnboarding();
          },
        },
      ]
    },
    {
      name: "Data channels",
      channels: [
        {
          channelType: t("Email Data channel"),
          icon: <MailOutlined />,
          text: "Enrich your customer data using incoming emails and automate conversations.",
          onClickEvent: function () {
            showExtractionForm();
            // closeOnboarding();
          },
        },
      ]
    }
  ];


  // console.log(channelSections)

  return (
    <div className={styles.ChannelSections}>
      {channelSections.map((section) => {
        return (
          <div key={section.name} className={styles.ChannelSections__section}>
            <p className={styles.ChannelSections__section__header}>
              {section.name}
            </p>
            <div className={styles.ChannelSections__section__body}>
              {section.channels.map((channel) => {
                return (
                  <div
                    key={channel.channelType}
                    className={styles.ChannelSections__section__body__card}
                  >
                    {
                      channel.channelType === t("Custom API channel") ? 
                        <Button
                          size="small"
                          onClick={info}
                          style={{"left": "87px"}}
                          icon={<InfoOutlined />} 
                        /> : ""
                    }

                    {channel.icon}
                    <h4>{channel.channelType}</h4>
                    <p>{channel.text}</p>
                    <Button onClick={() => channel.onClickEvent()}>
                      Connect
                    </Button>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ChannelsSectionComponent;
