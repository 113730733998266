const isArrayFilledWith = (arr, value) => {
  return arr.every((element) => element === value);
};
export const filterDataByColumn = (data, filters, attrtypes,waChannel) => {
  // Helper function to check if an object's column matches the filter values
  // console.log(data)
  const hasMatchingValues = (object, filter, column, type) => {
    const { operator, values, combination, subCategory } = filter;
    let col =
      column == "Contact field"
        ? attrtypes.filter((item) => item.id == subCategory)[0].name
        : column;
    let objectValues = [];

    if (type == "UserAttribute") {
      objectValues = object[col]?.map((item) => item.id);
    }

    if (type == "MultiSelectAttribute" && column == "Channel") {
      // console.log(object[col])
      objectValues = object[col]?.map((item) => item.val);
      // console.log(objectValues)
    }

    if (type == "MultiSelectAttribute" && column !== "Channel") {
      objectValues = object[col]?.map((item) => item.labelId);
    }
    // console.log(objectValues,col,object,filter,type)
    if (operator === "exists") {
      if (isArrayFilledWith(objectValues, undefined)) {
        return false;
      }
      return true;
    }

    if (operator === "does not exist") {
      if (isArrayFilledWith(objectValues, undefined)) {
        return true;
      }
      return false;
    }

    if (operator === "has all of") {
      return values.every((value) => objectValues.includes(value));
    }

    if (operator === "has none of") {
      return values.every((value) => !objectValues.includes(value));
    }

    if (operator === "has any of") {
      return values.some((value) => objectValues.includes(value));
    }

    return true;
  };

  const hasMatchingNumericValues = (object, filter, column, type) => {
    const { operator, values, combination, subCategory, contactFilterValue } = filter;
    let col =
      column == "Contact field"
        ? attrtypes.filter((item) => item.id == subCategory)[0].name
        : column;
    let objectValues = [];
    objectValues = object[col]?.map((item) => item.value);
    const numericValue = Number(contactFilterValue);
    // console.log(objectValues,col,contactFilterValue,operator)
    if (operator === "exists") {
      if (isArrayFilledWith(objectValues, undefined)) {
        return false;
      }
      return true;
    }

    if (operator === "does not exist") {
      if (isArrayFilledWith(objectValues, undefined)) {
        return true;
      }
      return false;
    }
    if (operator === "equals") {
      return objectValues.every((objectValue) => Number(objectValue) === numericValue);
    }

    if (operator === "doesNotEqual") {
      return objectValues.every((objectValue) => Number(objectValue) !== numericValue);
    }

    if (operator === "greaterThan") {
      return objectValues.every((objectValue) => Number(objectValue) > numericValue);
    }

    if (operator === "lessThan") {
      return objectValues.every((objectValue) => Number(objectValue) < numericValue);
    }

    if (operator === "greaterThanOrEqual") {
      return objectValues.every((objectValue) => Number(objectValue) >= numericValue);
    }

    if (operator === "lessThanOrEqual") {
      return objectValues.every((objectValue) => Number(objectValue) <= numericValue);
    }
    return true;
  }

  const hasMatchingTextValues = (object, filter, column, type) => {
    const { operator, values, combination, subCategory, contactFilterValue } = filter;
    let col =
      column == "Contact field"
        ? attrtypes.filter((item) => item.id == subCategory)[0].name
        : column;
    let objectValues = [];
    // console.log(object)
    objectValues = object[col]?.map((item) => item.value);
    const textValue = String(contactFilterValue);
    // console.log(objectValues,col,contactFilterValue,operator)
    if (operator === "exists") {
      if (isArrayFilledWith(objectValues, undefined)) {
        return false;
      }
      return true;
    }

    if (operator === "does not exist") {
      if (isArrayFilledWith(objectValues, undefined)) {
        return true;
      }
      return false;
    }

    if (isArrayFilledWith(objectValues, undefined)) {
      return false;
    }

    if (operator === "is") {
      return objectValues.every((value) => value === textValue);
    }

    if (operator === "isNot") {
      return objectValues.every((value) => value !== textValue);
    }

    if (operator === "contains") {
      return objectValues.every((value) => value?.includes(textValue));
    }

    if (operator === "doesNotContain") {
      return objectValues.every((value) => !value?.includes(textValue));
    }

    if (operator === "startsWith") {
      return objectValues.every((value) => value?.startsWith(textValue));
    }

    if (operator === "endsWith") {
      return objectValues.every((value) => value?.endsWith(textValue));
    }

    return true

  }

  // Apply each filter to the data
  let filteredData = data;

  filters.forEach((filter) => {
    const { category, combination } = filter;
    if (category) {
      const type = getTypeFromFilterAndArrTypes(filter, attrtypes);
      let intermediateData;
      if (type === "MultiSelectAttribute" || type === "UserAttribute") {
        intermediateData = filteredData.filter((object) =>
          hasMatchingValues(object, filter, category, type)
        );
      }
      else if (type === "Numbers") {
        intermediateData = filteredData.filter((object) =>
          hasMatchingNumericValues(object, filter, category, type)
        );
      }
      else if (type !== "MultiSelectAttribute" && type !== "UserAttribute" && type !== "Numbers") {
        intermediateData = filteredData.filter((object) =>
          hasMatchingTextValues(object, filter, category, type)
        );
      }
      else {
        return
      }
      if (combination === "or") {
        // Combine with "OR" condition
        filteredData = filteredData.concat(intermediateData);
      } else {
        // Combine with "AND" condition (default)
        filteredData = intermediateData;
      }
    }
  });

  return filteredData;

};

export function getTypeFromFilterAndArrTypes(filter, attrtypes) {
  const { category, subCategory, operator } = filter;

  if(category === "Channel"){
    return "MultiSelectAttribute"
  }

  if (category === "Contact field") {
    const attribute = attrtypes.find((attr) => attr.id === subCategory);

    if (attribute) {
      if (attribute.attrType !== "") {
        return attribute.attrType;
      }

      return attribute.type;
    }
  } else {
    const attribute = attrtypes.find((attr) => attr.name === category);

    if (attribute) {
      return attribute.type;
    }
  }

  return "";
}
