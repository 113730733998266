import React from "react";

import { useTranslation } from "react-i18next";

import WhatsappIcon from "src/assets/Group.png";

// import WhatsappDesktopIcon from "src/assets/desktop.png";
const ChannelCell = ({
  value,
  waChannel, waDesktopChannel
}) => {
  const { t } = useTranslation();
  // console.log(value)
 
  return (
    <>
      {value && <span>
        {/* <div className={styles.ContactChannels} data-testid="ContactChannels"> */}
        {value[0]?.type =="whatsappOfficial" &&<> <span> <img src={WhatsappIcon} /> {value[0]?.val}</span></>}
        {/* {isWhatsappDesktopEnabled && <img src={WhatsappDesktopIcon} />} */}
        {/* {!isWhatsappDesktopEnabled && !isWhatsappEnabled && <span>---</span>} */}
        {/* </div> */}
      </span>}
    </>
  );
};

export default ChannelCell;
