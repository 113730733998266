import React from "react";
import { Route, Routes } from "react-router-dom";
import Chat from "../../components/Chat/Chat.lazy";
import Settings from "../../components/Settings/Settings";
import Search from "../Search/Search";
import Dashboard from "../Dashboard/Dashboard";
import Layout from "../Layout/Layout.lazy";
import UserProfile from "../../components/UserProfile/UserProfile";
import MessageTemplates from "../MessageTemplates/MessageTemplates";
import ErrorUi from "../ErrorUI/ErrorUI";
import NodeShell from "../Conversation/components/NodeShell/NodeShell";
import ContactsPage from "../ContactsPage/ContactsPage";
import CreateOrganization from "../CreateOrganization/CreateOrganization";
import Login from "../Login/Login";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import ResetPassword from "../ResetPassword/ResetPassword";
import Signup from "../Signup/Signup";
import withBackgroundTimer from "../Conversation/components/HOC/withBackgroundTimer";
import Analytics from "../CasterAnalytics/Analytics";
import ChatbotPage from "../ChatbotPage/ChatbotPage.lazy";
import TrainingInterfaceLayout from "../TrainingInterface/Layout/Layout.lazy";

const AppContainer = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/chat" element={<Chat />} />
        <Route path="/chat/:username" element={<Chat />} />
        <Route path="/settings/*" element={<Settings />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/contacts" element={<ContactsPage />} />
        <Route path="/node" element={<NodeShell />} />
        <Route path="/node/:id" element={<NodeShell />} />
        <Route path="/search" element={<Search />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/training-interface" element={<TrainingInterfaceLayout />} />
        <Route
          path="/channels/message-templates"
          element={<MessageTemplates />}
        />
        <Route path="/chatbots" element={<ChatbotPage />} />
      </Route>
      <Route path="/create/organization" element={<CreateOrganization />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route
        path="/reset-password/:staffId/:resetPasswordId"
        element={<ResetPassword />}
      />
      <Route path="/signup" element={<Signup />} />
      <Route path="/signup/:invitationId" element={<Signup />} />
      <Route path="*" element={<ErrorUi />} />
    </Routes>
  );
};

AppContainer.propTypes = {};

AppContainer.defaultProps = {};

export default withBackgroundTimer(AppContainer);
