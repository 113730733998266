import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectRoute = ({ children, invert = false }) => {
  const location = useLocation();
  const access_token = useSelector((state) => state?.auth?.data?.access_token);

  if (!access_token && invert === false) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  
  if (Boolean(access_token) && invert === true) {
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectRoute;
