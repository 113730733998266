import { gql } from "@apollo/client";

export const GET_ALL_SEGMENTS = gql`
  subscription ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      id
      segments {
        id
        name
        segmentInformation {
          id
        }
      }
    }
  }
`;

export const GET_ONE_SEGMENT = gql`
  query ($segmentId: ID!) {
    getSegments(id: $segmentId) {
      id
      name
      segmentInformation {
        id
        category
        subCategory
        operator
        values
        combination
        contactFilterValue
      }
    }
  }
`;
