// logActivityWorker.js
export default () => {
    let logInterval;

    self.addEventListener('message', (e) => {
        if (!e) return;
        let type = e.data;
        if (type === 'start') {

            logInterval = setInterval(() => {
            //    console.log('post message log - ');
                self.postMessage('log');
            }, 10000);
        }
        else if (type === 'stop') {
            // console.log("timer stopped")
            clearInterval(logInterval);
            postMessage('logging stopped');
        }
    });
}