import React from "react"

const MergeContactIconbar = () => {
  return (
    <svg width={44} height={44} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={44} height={44} rx={4} fill="#E6F7FF" />
      <path d="M21.3016 21.9156L17.7648 19.1172C17.6945 19.0609 17.5938 19.1125 17.5938 19.2016V21.2031H14.3125V14.3125H19.0938V17.3125C19.0938 17.3641 19.1359 17.4062 19.1875 17.4062H20.5938C20.6453 17.4062 20.6875 17.3641 20.6875 17.3125V13.375C20.6875 13.0117 20.3945 12.7188 20.0312 12.7188H13.375C13.0117 12.7188 12.7188 13.0117 12.7188 13.375V30.625C12.7188 30.9883 13.0117 31.2812 13.375 31.2812H20.0312C20.3945 31.2812 20.6875 30.9883 20.6875 30.625V26.6875C20.6875 26.6359 20.6453 26.5938 20.5938 26.5938H19.1875C19.1359 26.5938 19.0938 26.6359 19.0938 26.6875V29.6875H14.3125V22.7969H17.5938V24.7984C17.5938 24.8875 17.6969 24.9391 17.7648 24.8828L21.3016 22.0844C21.3578 22.0422 21.3578 21.9578 21.3016 21.9156ZM30.625 12.7188H23.9688C23.6055 12.7188 23.3125 13.0117 23.3125 13.375V17.3125C23.3125 17.3641 23.3547 17.4062 23.4062 17.4062H24.8125C24.8641 17.4062 24.9062 17.3641 24.9062 17.3125V14.3125H29.6875V21.2031H26.4062V19.2016C26.4062 19.1125 26.3055 19.0609 26.2352 19.1172L22.6961 21.9156C22.6422 21.9578 22.6422 22.0398 22.6961 22.082L26.2352 24.8828C26.3031 24.9367 26.4062 24.8875 26.4062 24.7984V22.7969H29.6875V29.6875H24.9062V26.6875C24.9062 26.6359 24.8641 26.5938 24.8125 26.5938H23.4062C23.3547 26.5938 23.3125 26.6359 23.3125 26.6875V30.625C23.3125 30.9883 23.6055 31.2812 23.9688 31.2812H30.625C30.9883 31.2812 31.2812 30.9883 31.2812 30.625V13.375C31.2812 13.0117 30.9883 12.7188 30.625 12.7188Z" fill='var(--primary-color)' />
    </svg>  )
}

export default MergeContactIconbar