import React from "react";
import PropTypes from "prop-types";
import styles from "./AutomationCard.module.scss";
import { SwapOutlined } from "@ant-design/icons";

const AutomationCard = ({ data }) => (
  <div className={styles.AutomationCard__Card}>
    <div className={styles.AutomationCard__CardHeader}>
      <div className={styles.AutomationCard__CardIcon}>
      </div>
      <SwapOutlined style={{ width: "36px", height: "36px", lineHeight: "36px" }} />
      <div className={styles.AutomationCard__CardIcon}>
      </div>
    </div>
    <div className={styles.AutomationCard__CardContent}>
      <div className={styles.AutomationCard__CardTitle}>
        {data?.title}
      </div>
      <div className={styles.AutomationCard__CardSubTitle}>
        {data.content}
      </div>
    </div>
  </div>
);

AutomationCard.propTypes = {};

AutomationCard.defaultProps = {};

export default AutomationCard;
