import React, { useEffect, useState } from "react";
import "antd/dist/reset.css";

import PropTypes from "prop-types";
import styles from "./ChannelForm.module.scss";
import { DeleteOutlined } from "@ant-design/icons";
import { Form, Input, Button, Select, Modal, message } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import countries from "src/helpers/countries.json";

const { Option } = Select;

const ChannelForm = ({
  initialValues,
  loadingFlag,
  action,
  onSubmit,
  onCancel,
  handleDeleteWhatsappOfficial,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const defaultCountryCode = `${initialValues?.dialCode},${initialValues?.isoCountryCode}`;
  
  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: {
      dialCodeText: defaultCountryCode,
      phoneNumber: initialValues?.phone,
      channelName: initialValues?.name,
      twilioSID: initialValues?.sid,
      twilioAuthToken: initialValues?.authToken,
    },
    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string()
        .matches(/^[0-9]*$/, "Only numbers are allowed")
        .min(10, "Invalid Phone Number")
        .required("Required field"),
      channelName: Yup.string()
        .min(2, "Too Short!")
        .max(70, "Too Long!")
        .required("Required field"),
      twilioSID: Yup.string()
        .min(32, "Invalid Twilio SID")
        .required("Required field"),
      twilioAuthToken: Yup.string()
        .required("Required field")
        .test(
          "len",
          "Twilio Auth Token length must be equal to 32",
          (val) => val?.length === 32
        ),
    }),
    onSubmit: async (formValues) => {
      const [dialCode, isoCountryCode] = formValues.dialCodeText.split(",");
      let success = false;
      if (action === "EDIT") {
        success = await onSubmit({
          dialCode,
          isoCountryCode,
          id: initialValues?.id,
          ...formValues,
        });
        if (success) {
          message.success("Channel updated successfully.Reloading..");
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          message.error("Something went wrong . Please try again");
        }
      } else {
        success = await onSubmit({
          dialCode,
          isoCountryCode,
          ...formValues,
        });
      }
    },
  });

  useEffect(() => {
    setFieldValue(
      "dialCodeText",
      `${initialValues?.dialCode},${initialValues?.isoCountryCode}`
    );
    setFieldValue("phoneNumber", initialValues?.phone);
    setFieldValue("channelName", initialValues?.name);
    setFieldValue("twilioSID", initialValues?.sid);
    setFieldValue("twilioAuthToken", initialValues?.authToken);
  }, [initialValues, defaultCountryCode]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    const success = await handleDeleteWhatsappOfficial({
      id: initialValues?.id,
    });
    if (success) {
      setIsModalOpen(false);
      message.success("Successfully Deleted Channel");
      window.location.reload();
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const prefixSelector = (
    <Select
      showSearch
      style={{ width: "120px", fontSize: "14px", padding: "0 5px" }}
      placeholder="Search to Select"
      optionFilterProp="children"
      onChange={(value) => setFieldValue("dialCodeText", value)}
      value={values.dialCodeText}
      defaultValue={values.dialCodeText}
      name="dialCodeText"
    >
      {countries.map((country) => (
        <Option
          key={country.isoCode}
          value={`${country.dialCode},${country.isoCode}`}
        >
          {country.dialCode}{" "}
          <img style={{ width: "20px" }} src={country.flag} />
        </Option>
      ))}
    </Select>
  );

  const handleValidationStatus = (key) => {
    return !touched[key] && !errors[key]
      ? ""
      : touched[key] && !errors[key]
      ? "success"
      : touched[key] && errors[key]
      ? "error"
      : "";
  };

  useEffect(() => {
    if (
      values?.dialCodeText?.trim() === "" ||
      values?.phoneNumber?.trim() === "" ||
      values?.channelName?.trim() === "" ||
      values?.twilioSID?.trim() === "" ||
      values?.twilioAuthToken?.trim() === ""
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [values]);

  return (
    <div className={styles.ChannelForm}>
      <div className={styles.ChannelForm__header}>
        <h3>{action === "EDIT" ? "Edit" : "Create"} Whatsapp Official Inbox</h3>
        {action === "EDIT" && (
          <Button
            danger
            type="error"
            icon={<DeleteOutlined />}
            onClick={showModal}
          >
            Delete
          </Button>
        )}
      </div>
      {action === "Create" && (
        <div className={styles.ChannelForm__HelperText}>
          Setting up an WhatsApp inbox requires an WhatsApp enabled Twilio
          number. Copy & paste Twilio Account SID and Auth Token here to get
          started.
        </div>
      )}{" "}
      <form
        className={styles.ChannelForm__formContainer}
        onSubmit={handleSubmit}
      >
        <Form.Item
          name="channelName"
          label="Channel Name"
          className={styles.ChannelForm__formContainer}
          validateStatus={handleValidationStatus("channelName")}
          hasFeedback
        >
          <Input
            name="channelName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.channelName}
            placeholder="Enter inbox name"
          />
          {touched.channelName && errors.channelName ? (
            <div className={styles.ChannelForm__formContainer__error}>
              {errors.channelName}
            </div>
          ) : null}
        </Form.Item>
        <Form.Item
          name="twilioSID"
          label="Twilio SID"
          className={styles.ChannelForm__formContainer}
          validateStatus={handleValidationStatus("twilioSID")}
          hasFeedback
        >
          <Input
            name="twilioSID"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.twilioSID}
            placeholder="ACXXXXXXXXXXXXXXXXXXXXXXXXXXXXXLIR"
          />
          {touched.twilioSID && errors.twilioSID ? (
            <div className={styles.ChannelForm__formContainer__error}>
              {errors.twilioSID}
            </div>
          ) : null}
        </Form.Item>
        <Form.Item
          name="twilioAuthToken"
          label="Twilio Auth Token"
          className={styles.ChannelForm__formContainer}
          validateStatus={handleValidationStatus("twilioAuthToken")}
          hasFeedback
        >
          <Input.Password
            id="twilioAuthToken"
            name="twilioAuthToken"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.twilioAuthToken}
            placeholder="XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
          />
          {touched.twilioAuthToken && errors.twilioAuthToken ? (
            <div className={styles.ChannelForm__formContainer__error}>
              {errors.twilioAuthToken}
            </div>
          ) : null}
        </Form.Item>
        <Form.Item
          name="phoneNumber"
          label="Phone Number"
          className={styles.ChannelForm__formContainer}
          validateStatus={handleValidationStatus("phoneNumber")}
          hasFeedback
        >
          <Input
            id="phoneNumber"
            addonBefore={prefixSelector}
            name="phoneNumber"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.phoneNumber}
            disabled={false}
            placeholder="9048084872"
          />

          {touched.phoneNumber && errors.phoneNumber ? (
            <div className={styles.ChannelForm__formContainer__error}>
              {errors.phoneNumber}
            </div>
          ) : null}
        </Form.Item>

        <Form.Item className={styles.ChannelForm__formContainer}>
          <div className={styles.ChannelForm__formContainer__btnContainer}>
            <Button
              type="ghost"
              className={
                styles.ChannelForm__formContainer__btnContainer__cancelBtn
              }
              onClick={onCancel}
            >
              Back
            </Button>
            <Button
              data-testid="ChannelForm__submit"
              type="primary"
              htmlType="submit"
              className={
                action === "EDIT"
                  ? styles.ChannelForm__formContainer__btnContainer__editChannelBtn
                  : styles.ChannelForm__formContainer__btnContainer__createChannelBtn
              }
              loading={loadingFlag}
              disabled={isDisabled}
              style={{backgroundColor:'var(--primary-color)'}}
            >
              {action === "EDIT" ? "Save" : "Create Whatsapp Official Channel"}
            </Button>
          </div>
        </Form.Item>
      </form>
      <Modal
        header={null}
        footer={[
          <Button type="ghost" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            // className={styles.ChannelForm__editContainer__delete}
            danger
            type="primary"
            onClick={handleOk}
            style={{backgroundColor:'var(--primary-color)'}}
          >
            Delete Channel
          </Button>,
        ]}
        title={null}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <h4 style={{ textAlign: "left" }}>
          Delete Channel <span>{`${initialValues?.name} ?`}</span>
        </h4>
        <p style={{ textAlign: "left" }}>
          Once you delete this channel,you will not be able to restore customer
          data chat logs, settings and statistics, Are you sure you want to
          delete this channel?
        </p>
      </Modal>
    </div>
  );
};

ChannelForm.propTypes = {
  initialValues: PropTypes.object,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

ChannelForm.defaultProps = {
  initialValues: {
    phoneNumber: "",
    channelName: "",
    twilioSID: "",
    twilioAuthToken: "",
    dialCode: "+91",
    isoCountryCode: "IN",
  },
  action: "CREATE",
  loading: false,
  onSubmit: (formValues) => console.log("Values: ", formValues),
  onCancel: () => console.log("Canceling"),
};

export default ChannelForm;
