import React from "react";
import styles from "./billing.module.scss";

import {
  createCheckoutSession,
  getActiveOrganisationPlan,
  getOrganisationPlans,
  getStripeCustomerById,
} from "src/api/OrganizationApi";

import { useSubscription } from "@apollo/client";
import { LoadingOutlined } from "@ant-design/icons";
import getStripe from "./loadStripe";
import PricingTable from "./pricingTable";
import { useTranslation } from "react-i18next";
import { GET_ORG_BILLING_DETAILS } from "src/graphql/billing";
import { GET_STAFF_ORGANISATION } from "src/graphql/organization";
import { getActiveOrgId } from "src/store/slices/userSlice";
import { useSelector } from "react-redux";
import { checkFeatureAvailability } from "src/helpers/checkFeatureAvailability";

function Billing() {
  const { t } = useTranslation();
  const organizationId = useSelector(getActiveOrgId);
  var loadScript = function (src) {
    var tag = document.createElement("script");
    tag.async = false;
    tag.src = src;
    document.body.appendChild(tag);
  };
  const [plans, setPlans] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [activePlan, setActivePlan] = React.useState(null);
  // const [selectedPlan, setSelectedPlan] = React.useState(null);
  const { data: billing_details } = useSubscription(GET_ORG_BILLING_DETAILS, {
    variables: {
      organizationId: organizationId,
    },
  });

  const { data: staff_details } = useSubscription(GET_STAFF_ORGANISATION, {
    variables: {
      organizationId: organizationId,
    },
  });
  const {domain:orgUrl} = useSelector((state) => state?.user?.data?.staffOrganization[0]?.organization);


  const [subscribedText, setSubscribedText] = React.useState(null);
  const [counter, setCounter] = React.useState(1);
  const incrementCount = (plan) => {
    if (getBtnText(plan) === "Subscribed") {
      setSubscribedText({ plan: plan.name, text: "Subscribe now" });
    } else {
      setSubscribedText(null);
    }
    setCounter(counter + 1);
  };
  const decrementCount = (plan) => {
    if (counter > staff_details?.getOrganization?.organizationStaff.length) {
      if (getBtnText(plan) === "Subscribed") {
        setSubscribedText({ plan: plan.name, text: "Subscribe now" });
      } else {
        setSubscribedText(null);
      }
      setCounter(counter - 1);
    }
  };
  const [sessionLoading, setSessionLoading] = React.useState({});
  React.useEffect(() => {
    async function setPlan() {
      try {
        const res = await getOrganisationPlans();
        if (res && res.data && res.data.plans) { 
          const reversedArray = [...res.data.plans].reverse().map((item) => item);
          setPlans(reversedArray);
        } else {
          setPlans([]);
        }
        setLoading(false);
      } catch (e) {
        setPlans([]);
        setLoading(false);
      }
    }
    setPlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (staff_details && staff_details?.getOrganization?.organizationStaff) {
      setCounter(staff_details?.getOrganization?.organizationStaff.length);
    } else setCounter(1);
  }, [staff_details]);

  React.useEffect(() => {
    async function getBilling() {
      if (billing_details && billing_details?.getOrganization?.billing) {
        try {
          const res = await getActiveOrganisationPlan(
            billing_details?.getOrganization?.billing?.current_price_id
          );
          if (res?.data) {
            setActivePlan(res?.data?.activePlan);
          } else {
            setActivePlan(null);
          }
        } catch (e) {
          setActivePlan(null);
        }
      }
    }
    getBilling();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billing_details]);

  React.useEffect(() => {}, [activePlan]);

  loadScript("https://js.stripe.com/v3/pricing-table.js");
  const [selectedOption, setSelectedOption] = React.useState("monthly");
  const handleTabClick = (option) => {
    setSelectedOption(option);
  };

  const getStatus = () => {
    if (
      billing_details &&
      billing_details?.getOrganization?.billing &&
      billing_details?.getOrganization?.billing?.status === "trialing"
    ) {
      return "trial";
    } else {
      return "plan";
    }
  };

  const getBtnText = (plan) => {
    if (plan.name.toLowerCase() === "enterprises plan") {
      return "Contact us";
    } else {
      if (
        billing_details?.getOrganization?.billing?.status === "active" &&
        plan?.name.toLowerCase() === activePlan?.name.toLowerCase()
      ) {
        return "Subscribed";
      } else {
        if (plan?.name.toLowerCase() === "pro plan") return "Upgrade plan";
        else if (plan?.name.toLowerCase() === "basic plan")
          return "Downgrade plan";
      }
    }
  };

  const handleButtonClick = (plan) => {
    if (plan.name.toLowerCase() === "enterprises plan") {
      // setSelectedPlan(plan.name);
      setSessionLoading({ plan: plan.name, loading: false });
      window.open("https://calendly.com/hybrid-chat/15min", "_blank");
    } else {
      // setSelectedPlan(plan.name);
      handleCheckout(plan.name);
    }
  };

  async function getCustomerEmail(customerId) {
    try {
      const res = await getStripeCustomerById(customerId);
      if (res?.data) {
        return res?.data?.customer?.email;
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  }

  async function getSelectedPriceId(plan_name) {
    let selected_plan = plans.filter((plan) => plan?.name === plan_name);
    if (selected_plan) {
      if (selectedOption === "monthly") {
        return selected_plan[0].monthly_price_id;
      } else return selected_plan[0].yearly_price_id;
    } else return null;
  }

  async function handleCheckout(plan) {
    let customerId = billing_details?.getOrganization?.billing?.customerId;
    let subscription_id =
      billing_details?.getOrganization?.billing?.subscriptionId;
    let customerEmail = await getCustomerEmail(customerId);
    // get active number of agents
    let priceId = await getSelectedPriceId(plan);
    const stripe = await getStripe();
    try {
      let data = {
        customerId: customerId,
        customerEmail: customerEmail,
        subscriptionId: subscription_id,
        priceId: priceId,
        agents: counter,
      };
      const res = await createCheckoutSession(data);
      const session = await res?.data?.session;
      stripe.redirectToCheckout({
        sessionId: session.id,
      });
      setSessionLoading({ plan: plan.name, loading: false });
    } catch (e) {
      setSessionLoading({ plan: plan.name, loading: false });
      console.log(e);
    }
  }

  return (
    <div className={styles.BillingPage}>
      <div className={styles.BillingPage__header}>
        <h3>{t("Billing")}</h3>
        <p>
          {t(
            "Manage the different messaging channels used to communicate with your contacts."
          )}
        </p>
      </div>
      <div className={styles.BillingPage__body}>
        <div
          className={styles.PricingTable}
          data-testid="pricing-table-container"
          >
          <div className={styles.PricingTable__tabsContainer}>
          {checkFeatureAvailability("upgradeBilling",orgUrl) && <div className={styles.BillingPage__body__DisabledOverlay} />}
            <div className={styles.PricingTable__tabsContainer__tabsList}>
              <div
                className={styles.PricingTable__tabsContainer__tabsList__item}
                onClick={() => handleTabClick("monthly")}
                style={{
                  backgroundColor: selectedOption === "monthly" && 'var(--primary-color)',
                  color: selectedOption === "monthly" && "white",
                }}
              >
                Monthly
              </div>
              <div
                className={styles.PricingTable__tabsContainer__tabsList__item}
                onClick={() => handleTabClick("yearly")}
                style={{
                  backgroundColor: selectedOption === "yearly" && 'var(--primary-color)',
                  color: selectedOption === "yearly" && "white",
                }}
              >
                Yearly
              </div>
            </div>
          </div>
          {loading ? (
            <>
              <LoadingOutlined
                style={{
                  fontSize: 24,
                }}
                spin
              />
            </>
          ) : (
            <div className={styles.PricingTable__container}>
              {Array.isArray(plans) &&
                plans.length > 0 &&
                plans.map((plan, i) => {
                  return (
                    <PricingTable
                      plan={plan}
                      billing_details={billing_details}
                      getStatus={getStatus}
                      selectedOption={selectedOption}
                      counter={counter}
                      staff_details={staff_details}
                      decrementCount={decrementCount}
                      incrementCount={incrementCount}
                      getBtnText={getBtnText}
                      handleButtonClick={handleButtonClick}
                      activePlan={activePlan}
                      setSessionLoading={setSessionLoading}
                      sessionLoading={sessionLoading}
                      setSubscribedText={setSubscribedText}
                      subscribedText={subscribedText}
                    />
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Billing;
