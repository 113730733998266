import { gql } from "@apollo/client";
export const UPDATE_TIMER_STATUS = gql`
    mutation UpdateTimerStatus($staffId: ID!, $timerStatus: Boolean!) {
        updateHC_Staff(
        input: {
            filter: { id: [$staffId] }
            set: { timerStatus: $timerStatus }
        }
        ) {
        hC_Staff {
            id
            timerStatus
        }
        }
    }
    `;
export const GET_TIMER_STATUS = gql`
  query GetTimerStatus($staffId: ID!) {
    getHC_Staff(id: $staffId) {
      id
      timerStatus
    }
  }
`;