import CoreApiClient from "./clients/CoreApiClient";

export const addNewContact = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/contact",
    data: body,
  });
};

export const updateContact = async (body, id) => {
  return CoreApiClient({
    method: "PUT",
    url: `/contact/${id}`,
    data: body,
  });
};

export const deleteAttribute = async (body) => {
  return CoreApiClient({
    method: "DELETE",
    url: "/contact/attribute",
    data: body,
  });
};

export const deleteAttributeOrg = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/contact/attribute/delete",
    data: body,
  });
};

export const addNewAttribute = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/contact/attribute",
    data: body,
  });
};

export const renameAttribute = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/contact/attribute/rename",
    data: body,
  });
};

export const deleteContact = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/contact/delete_multiple_contacts",
    data: body,
  });
};

export const importContactAPI = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/contact/import-contact",
    data: body,
  });
};

export const removeAttributeFromContact = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/contact/remove/attribute",
    data: body,
  });
};

export const addAttributeToContact = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/contact/add/attribute",
    data: body,
  });
};

export const getContactsByUsername = async (username) => {
  return CoreApiClient({
    method: "GET",
    url: `/leads?username=${username}`,
  });
};
