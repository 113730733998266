import React from "react";
import PropTypes from "prop-types";
import styles from "./Search.module.scss";

import { ChatShell } from "../Conversation/Conversation";

const Search = () => {
  return (
    <div className={styles.container}>
      <ChatShell />
    </div>
  );
};

Search.propTypes = {};

Search.defaultProps = {};

export default Search;
