import React from "react";
import styles from "./UserAutomationCard.module.scss";
import {
  // SwapOutlined,
  TagOutlined,
  MoreOutlined,
  CheckCircleFilled,
  ExclamationCircleFilled,
  PauseCircleFilled,
  // EditFilled
} from "@ant-design/icons";
import { Switch } from "antd";
const UserAutomationCard = ({ data }) => {
  let StatusType = {
    Active: "Active",
    Error: "Error",
    InActive: "Inactive",
    Draft: "Draft"
  };
  const onActiveChange = (e) => {
  }
  return (
    <div className={styles.UserAutomationCard__Card}>
      <div className={styles.UserAutomationCard__CardHeader}>
        <div className={styles.UserAutomationCard__CardIcon}>
          <TagOutlined style={{ "color": "#ffffff", fontSize: "22px" }} />
        </div>
        <div className={styles.UserAutomationCard__CardHeader__Right}>
          <Switch size="small" defaultChecked onChange={onActiveChange} />
          <MoreOutlined style={{ fontSize: "20px" }} />
        </div>
      </div>
      <div className={styles.UserAutomationCard__SubHeader}>
        {data?.status !== null && data.status !== undefined
          ?
          data?.status === StatusType.Active ?
            <div className={styles.UserAutomationCard__SubHeader__Success}>
              <CheckCircleFilled /> Active
            </div>
            : data?.status === StatusType.Error ?
              <div className={styles.UserAutomationCard__SubHeader__Error}>
                <ExclamationCircleFilled /> Error
              </div>
              : data?.status === StatusType.InActive ?
                <div className={styles.UserAutomationCard__SubHeader__InActive}>
                  <PauseCircleFilled /> Inactive
                </div>
                : <div className={styles.UserAutomationCard__SubHeader__InActive}>
                  <PauseCircleFilled /> Inactive
                </div>
          : <div className={styles.UserAutomationCard__SubHeader__Success}>
            <CheckCircleFilled /> Active
          </div>}
      </div>
      <div className={styles.UserAutomationCard__CardContent}>
        <div className={styles.UserAutomationCard__CardTitle}>
          {data?.title}
        </div>
        <div className={styles.UserAutomationCard__CardSubTitle}>
          {data.name}
        </div>
      </div>
    </div>
  );
}

UserAutomationCard.propTypes = {};

UserAutomationCard.defaultProps = {};

export default UserAutomationCard;
