import React, { lazy, Suspense } from "react";

const LazyChatbotPage = lazy(() => import("./ChatbotPage"));

const ChatbotPage = props => (
  <Suspense fallback={<div>Loading ....</div>}>
    <LazyChatbotPage {...props} />
  </Suspense>
);

export default ChatbotPage;
