import CoreApiClient from "./clients/CoreApiClient";

export const createEmailExtraction = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/emailextraction/create",
    data: body,
  });
};

export const createAIParsing = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/emailextraction/ai-parsing",
    data: body,
  });
};

export const createAttributeMapping = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/emailextraction/attribute-mapping",
    data: body,
  });
};
