import React, {
  useEffect,
  useState,
} from "react";

import {
  Button,
  notification,
  Spin,
} from "antd";

import { updateContact } from "src/api/ContactApi";
import { patterns } from "../../../helpers/patterns";
import styles from "./Cell.module.scss";

const handleErrorMessage = (c) => {
  switch (c) {
  case "Text":
    return "The attribute must be a text";
  case "Numbers":
    return "The attribute must be a number";
  case "PhoneNumber":
    return "The attribute must be a Phone number";
  case "EmailAddress":
    return "Enter a valid email address";
  case "SocialMedia":
    return "Enter a valid username";
  case "URL":
    return "Enter a valid URL";
  default:
    return "";
  }
};

const Cell = ({ allAttrData, value, row, accessor, allRefetch }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputValues, setInputValues] = useState(value?.filter((v) => v?.value));
  const [newInput, setNewInput] = useState({
    id: 1,
    value: "",
    name: "",
    type: "",
    errorMessage: "",
    attributeId: "",
  });
  const [errorMessages, setErrorMessages] = useState([]);
  const [formEdited, setFormEdited] = useState(false); // New state variable

  
  // console.log(formEdited)

  useEffect(() => {
    const attribute = findAttribute(accessor, allAttrData);
    if (attribute !== null && attribute !== undefined) {
      const { id, name, type } = attribute;
      setNewInput((prevInput) => ({
        ...prevInput,
        id: 1,
        type: type || "",
        name: accessor,
        attributeId: id || "",
      }));
    }
  }, [allAttrData, accessor]);
  

  const getAttrIdByKey = (data, key) => {
    // Iterate over each key in the data object
    for (const dataKey in data) {
      // Check if the current key matches the provided key
      if (dataKey === key) {
        // Return the attrId of the first item in the array
        return data[key][0];
      }
    }

    // Return null if no matching key is found
    return null;
  };

  // console.log(row?.original,accessor)

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleChange = (e, index) => {
    const newInputValues = inputValues.map((inputValue, i) => {
      if (i === index) {
        return { ...inputValue, value: e.target.value };
      }
      return inputValue;
    });
    const pattern = patterns[value[0]?.attributeType];
    if (!pattern) {
      return;
    }

    const newErrorMessages = [...errorMessages];
    newErrorMessages[index] = pattern.test(e.target.value)
      ? ""
      : handleErrorMessage(value[0]?.attributeType);

    setErrorMessages(newErrorMessages);
    setInputValues(newInputValues);
    setFormEdited(true); // Set formEdited to true when the form is edited
  };

  const handleSubmit = () => {
    const hasNonEmptyError = errorMessages.some(
      (message) => message !== "" && message !== undefined
    );

    if (!hasNonEmptyError) {
      onSubmit();
      setIsEditing(false);
    }
  };

  const handleOnNewSubmit = () => {
    const hasNonEmptyError = errorMessages.some(
      (message) => message !== "" && message !== undefined
    );

    if (!hasNonEmptyError) {
      onNewSubmit();
      setIsEditing(false);
    }
  };

  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);

  // const handleClickOutside = (e) => {
  //   if (
  //     !cellRef.current.contains(e.target) &&
  //     !Array.from(cellRef.current.querySelectorAll("input")).includes(e.target)
  //   ) {
  //     if (formEdited) {
  //       handleSubmit();
  //     } else {
  //       setIsEditing(false);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (!isEditing && formEdited) {
  //     if (errorMessages.some((message) => message !== "")) {
  //       return;
  //     } else {
  //       onSubmit(state);
  //       setIsEditing(false);
  //     }
  //   }
  // }, [isEditing, formEdited, state]);

  // console.log(newInput, accessor, row, allAttrData);

  function findAttribute(payload, data) {
    const attributes = [
      ...(data?.getOrganization?.userAttribute || []),
      ...(data?.getOrganization?.multiSelectAttribute || []),
      ...(data?.getOrganization?.normalAttribute || []),
    ];
  
    for (const attribute of attributes) {
      if (attribute && attribute.name === payload) {
        return {
          name: attribute.name || "",
          id: attribute.id || "",
          type: attribute.type || "",
        };
      }
    }
  
    return null;
  }
  

  // console.log(findAttribute(accessor, allAttrData));
  // console.log(allAttrData, accessor);

  // console.log(id,name)

  const handleAddFieldChange = (e) => {
    setNewInput({
      ...newInput,
      value: e.target.value,
    });

    // console.log(pattern, newInput);
    const pattern = patterns[newInput.type];
    if (!pattern) {
      return;
    }

    const newErrorMessages = [...errorMessages];
    newErrorMessages[0] = pattern.test(e.target.value)
      ? ""
      : handleErrorMessage(newInput.type);

    setErrorMessages(newErrorMessages);
    setFormEdited(true); // Set formEdited to true when the form is edited
  };

  const onNewSubmit = async () => {
    let value = [];
    value.push(newInput);
    try {
      if (value.length === 0) {
        return;
      }
      const data = {
        normalAttribute: value,
      };
      let res = await submitForm(data);
    } catch (err) {
      console.log(err);
      return;
    }
  };

  const onSubmit = async () => {
    let value = inputValues.map((item) => {
      const updatedItem = {
        ...item,
        attributeId: item?.attrId,
        errorMessage: "",
        name: accessor,
        type: item?.attributeType,
      };

      delete updatedItem.attrId;
      delete updatedItem.attributeType;
      delete updatedItem.leadId;
      delete updatedItem.leadUsername;
      delete updatedItem.mainType;

      return updatedItem;
    });

    try {
      if (value.length === 0) {
        return;
      }
      const data = {
        normalAttribute: value,
      };
      let res = await submitForm(data);
    } catch (err) {
      console.log(err);
      return;
    }
  };

  const submitForm = async (data) => {
    setLoading(true);
    setFormEdited(false);
    try {
      let res = await updateContact(data, row?.original?.leadId);
      if (!res?.data?.success) {
        setLoading(false);
        setFormEdited(false);
        throw new Error("Encountered error while updating contact");
      } else {
        allRefetch();
        setLoading(false);
        setFormEdited(false);
      }
    } catch (err) {
      notification.error({
        message: <b>Notification</b>,
        description: <b>Encountered server error while updating</b>,
      });
      setLoading(false);
      setFormEdited(false);
      return;
    }
  };

  const cancelEditing = (e)=>{
    e.stopPropagation();
    e.preventDefault();
    setIsEditing(false)
  }

  return (
    <span className={styles.Cell} onClick={handleDoubleClick}>
      {isEditing ? (
        <div className={styles.InputContainer}>
          {inputValues.length > 0 ? (
            <div className={styles.InputContainer}>
              {inputValues.map((inputValue, index) => (
                <div key={index} className={styles.InputFieldContainer}>
                  <input
                    type="text"
                    value={inputValue?.value}
                    onChange={(e) => handleChange(e, index)}
                    autoFocus={index === 0}
                    className={
                      errorMessages[index]
                        ? styles.InputFieldError
                        : styles.InputField
                    }
                  />
                  {errorMessages[index] && (
                    <small className={styles.ErrorMessage}>
                      {errorMessages[index]}
                    </small>
                  )}
                </div>
              ))}
              <Button
                onClick={cancelEditing}
                size="small"
                type="ghost"
              >
                Cancel
              </Button>{" "}
              {/* New submit button */}
              <Button
                onClick={handleSubmit}
                size="small"
                type="primary"
                disabled={!formEdited}
              >
                save
              </Button>{" "}
              {/* New submit button */}
            </div>
          ) : (
            <div className={styles.InputFieldContainer}>
              <input
                type="text"
                value={newInput?.value}
                onChange={(e) => handleAddFieldChange(e)}
                autoFocus
                className={
                  errorMessages[0] ? styles.InputFieldError : styles.InputField
                }
              />
              {errorMessages[0] && (
                <small className={styles.ErrorMessage}>
                  {errorMessages[0]}
                </small>
              )}
              <Button
                onClick={cancelEditing}
                size="small"
                type="ghost"
              >
                Cancel
              </Button>{" "}
              {/* New submit button */}
              <Button
                onClick={handleOnNewSubmit}
                size="small"
                type="primary"
                disabled={!formEdited}
              >
                save
              </Button>{" "}
              {/* New submit button */}
            </div>
          )}
        </div>
      ) : (
        <>
          {!loading ? (
            <>
              {value !== undefined && value[0]?.value !== undefined ? (
                value.length > 1 ? (
                  <span className={styles.Cell}>{`${value[0].value} +${
                    value.length - 1
                  }`}</span>
                ) : (
                  <span className={styles.Cell}>{value[0].value}</span>
                )
              ) : (
                <span className={styles.Cell}>---</span>
              )}
            </>
          ) : (
            <Spin />
          )}
        </>
      )}
    </span>
  );
};

export default Cell;
