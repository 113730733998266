import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  data: {
    primary: "#096DD9",
    secondary: "#91d5ff",
    tertiary: "#FFFFFF"
  },
  error: null,
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    resetColorScheme: (state) => Object.assign(state, initialState),
    setColorScheme: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
  },
});

export const { resetColorScheme, setColorScheme } = themeSlice.actions;
export default themeSlice.reducer;
