// All defined widget types
// dashboard.config.js

import { UserOutlined, LaptopOutlined } from "@ant-design/icons";

export const items2 = [
  {
    key: "sub1",
    icon: "UserOutlined",
    label: "Timesheet",
    children: [
      {
        key: "view-and-edit",
        label: "View and edit",
      },
    ],
  },
  {
    key: "sub2",
    icon: "LaptopOutlined",
    label: "Reports",
    children: [
      {
        key: "timeandactivity",
        label: "Time and Activity",
      },
    ],
  },
];

export const iconMapping = {
  UserOutlined,
  LaptopOutlined,
};

  








export const widgetTypes= [
  {
    "rangePicker": ["timerConfig"]
  },
  {
    "dropdown": ["dropdownConfig"]
  },
  {
    "editButton": ["buttonConfig"]
  },
  {
    "table": ["tableConfig","dataSourceType","dataSource","loading","error"]
  }
]

//  defualt widgets for timesheet
export const defaultWidgets = {"timesheet": [
  { type: "rangePicker", fullWidth: false },
  { type: "dropdown", fullWidth: false },
  {type: "button",fullWidth: false},
  {type: "reportButton",fullWidth: false},
  { type: "table", fullWidth: true }
]}


// timer configuration timesheet

const currentDate = new Date();
const localTimezoneOffset = currentDate.getTimezoneOffset() * 60000; // Offset in milliseconds

const startOfDay = new Date(currentDate.getTime() - localTimezoneOffset);
startOfDay.setUTCHours(0, 0, 0, 0);

const endOfDay = new Date(startOfDay);
endOfDay.setUTCHours(23, 59, 59, 999);

export const timeSheetTimerConfig = {
  "startDate": startOfDay.toISOString(),
  "endDate": endOfDay.toISOString()
};
export const addManualTimeButtonConfig = {
  type: "primary"
}
export const addDownloadReportsButtonConfig = {
  type: "primary"
}


// Dropdown configuration for timesheet
export const userDropdownConfig = {
  dropdownConfig: {
    options: [
      { value: "1", label: "Option 1" },
      { value: "2", label: "Option 2" },
      { value: "3", label: "Option 3" },
    ],
    placeholder: "Choose other member"
  }
};  
export const addManualTimeFormConfig = {
  "fields": [
    { "label": "Email", "name": "email", "type": "select" },
    { "label": "Start Date", "name": "startDate", "type": "datetime-local" },
    { "label": "End Date", "name": "endDate", "type": "datetime-local" },
    { "label": "Project", "name": "project", "type": "text"},
  ]
};


// table configuration for timesheets
export const timesheetConfig = {
  "columns": [
    {"title": "Username", "field": "username","dataIndex": "username", "key": "username", "type": "text"},
    {"title": "Project", "field": "project","dataIndex": "project", "key": "project", "type": "text"},
    {"title": "Activity", "field": "activity","dataIndex": "activity", "key": "activity","type": "text"},
    {"title": "Duration", "field": "duration", "dataIndex": "duration","key": "duration","type": "text"},
    {"title": "Time", "field": "time","dataIndex": "time","key": "time", "type": "text"},
    // {"title": "Delete", "field": "delete","dataIndex": "delete","key": "delete", "type": "button"}
  ]
}
export const formatTotalTime = (totalTime) => {
  const [yearStr, monthStr, dateStr, hoursStr, minutesStr, secondsStr, millisecondsStr] = totalTime.split(":").map(str => parseFloat(str));

  const paddedYear = Math.floor(yearStr).toString().padStart(4, "0");
  const paddedMonth = Math.floor(monthStr).toString().padStart(2, "0");
  const paddedDate = Math.floor(dateStr).toString().padStart(2, "0");
  const paddedHours = Math.floor(hoursStr).toString().padStart(2, "0");
  const paddedMinutes = Math.floor(minutesStr).toString().padStart(2, "0");
  
  let formattedTime = `${paddedHours}:${paddedMinutes}`;

  if (!isNaN(secondsStr)) {
    const paddedSeconds = Math.floor(secondsStr).toString().padStart(2, "0");
    formattedTime += `:${paddedSeconds}`;
  }
  
  if (!isNaN(millisecondsStr)) {
    const paddedMilliseconds = Math.floor(millisecondsStr).toString().padStart(3, "0");
    formattedTime += `.${paddedMilliseconds}`;
  }

  return formattedTime;
};

// API endpoint for collecting data for table
export const timeSheetTableDataSource = "http://example.com/api/v1/data"