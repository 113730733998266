// sendDataToBackendWorker.js
export default () => {
    let sendDataInterval;
    self.addEventListener('message', (e) => {
        if (!e) return;
        let type = e.data;
        if (type === 'sendData') {
            console.log("message rec")
            sendDataInterval = setInterval(() => {
                console.log('post message - sendData ');
                self.postMessage('send');
            }, 60000);
        }
    });
}