import React, { useState, useEffect } from "react";
import styles from "./OfflineBadge.module.scss"; // Create a CSS file for styling
 
const OfflineBadge = () => {
  const [isOffline, setIsOffline] = useState(!navigator.onLine);
 
  useEffect(() => {
    const handleOnlineStatus = () => setIsOffline(false);
    const handleOfflineStatus = () => setIsOffline(true);
 
    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOfflineStatus);
 
    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOfflineStatus);
    };
  }, []);
 
  return isOffline ? (
    <div className={styles.offlineBadge}>
      <p className={styles.offlineBadgeMessage}>You are currently offline. Syncing...</p>
    </div>
  ) : null;
};
 
export default OfflineBadge;
 