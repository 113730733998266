import React, { lazy, Suspense } from "react";

const LazyShowContactDrawer = lazy(() => import("./EditContactDrawer"));

const EditContactDrawer = props => (
  <Suspense fallback={null}>
    <LazyShowContactDrawer {...props} />
  </Suspense>
);

export default EditContactDrawer;
