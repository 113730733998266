import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import PropTypes from "prop-types";
import styles from "./UserSelectInlineField.module.scss";
import { Select, Avatar, Tag, Tooltip, Modal } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useQuery, useMutation } from "@apollo/client";
import { GET_ORGANIZATION_STAFF } from "src/graphql/staff";
import { useTranslation } from "react-i18next";
import { getActiveOrgId } from "src/store/slices/userSlice";
import { DELETE_ASIGNEE } from "src/graphql/contact";

const { Option } = Select;

const UserSelectInlineField = ({
  isCreate = false,
  item,
  handleUserChange,
  isDefault,
  onDelete,
  isTableElement = false,
}) => {
  const { t } = useTranslation();
  const organizationId = useSelector(getActiveOrgId);
  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [search, setSearch] = useState("");
  const [isDropdown, setIsDropdown] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const [deleteAssignee] = useMutation(DELETE_ASIGNEE) 
  const [leadId, setLeadId] = useState('');

  useEffect(() => {
    const url = window.location.href;
    const extractedId = url.split('/').pop();
    setLeadId(extractedId);
  }, [location.pathname]);
  

  const deleteSingleAssignee = async (data)=>{
    const payload = { id : "0x"+data.toString(16)} 
    try{
      await deleteAssignee({
        variables: { id: leadId, assignees: payload },
      });
      console.log("successfully deleted")
    }catch (error) {
      console.error("error updating territory", error)
    }
  }

  const randomColor = useCallback(
    () => "#" + Math.random().toString(16).substr(-6),
    []
  );
  const divRef = useRef(null);
  const handleDropdownVisible = (e) => {
    // const containerRect = divRef.current.getBoundingClientRect();
    // const { clientX, clientY } = e;
    const selectElement = divRef.current;
    if (selectElement) {
      const selectRect = selectElement.getBoundingClientRect();
      const { top, left } = selectRect;
      setModalPosition({ left: left - 20, top: top - 70 });
    }
    setIsDropdown(!isDropdown);
    setIsModalOpen(true);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const showMoreModal = () => {
    setIsModalOpen(true);
  };
  const handleMoreOk = () => {
    setIsModalOpen(false);
  };
  const handleMoreCancel = () => {
    // handleInputChangeState(inputValue)
    setIsModalOpen(false);
  };

  // const options = useMemo(() => options, [options]);
  // const filteredOptions = useMemo(() => filteredOptions, [filteredOptions]);

  const { loading, refetch, error, data } = useQuery(GET_ORGANIZATION_STAFF, {
    variables: {
      organizationId: organizationId,
    },
  });
  

  useEffect(() => {
    if (data?.length !== 0) {
      const transformedOptions = transform(
        data?.getOrganization?.organizationStaff
      );
      setOptions(transformedOptions);
      setFilteredOptions(transformedOptions);
    }
  }, [data]);

  const transform = useCallback(
    (data) => {
      return data?.map(({ staff: { id, firstName, lastName, ...rest } }) => ({
        ...rest,
        userId: id,
        color: randomColor(),
        id: parseInt(id, 16),
        value: parseInt(id, 16),
        label: firstName + " " + lastName,
        user: firstName + " " + lastName,
      }));
    },
    [randomColor]
  );

  const updateData = useCallback(
    (currentSelectedItems) => {
      const updatedItems = options
        .filter((item) => currentSelectedItems.includes(item.id))
        .map(({ userId, username, ...item }) => ({
          id: userId,
          agentId: userId,
          agentName: username,
        }));
      handleUserChange(item.id, updatedItems, item);
    },
    [options, handleUserChange, item]
  );
  // const handleDropdownVisible = () => setIsDropdown(!isDropdown);

  const handleOptionClick = useCallback(
    (val) => {
      if (!selectedItems.includes(val)) {
        const newSelectedItems = [...selectedItems, val];
        setSelectedItems(newSelectedItems);
        updateData(newSelectedItems);
      }
    },
    [selectedItems, updateData]
  );

  const handleCreateTag = () => {
    if (!selectedItems.includes(search)) {
      const newSelectedItems = [...selectedItems, search];
      setSelectedItems(newSelectedItems);
      updateData(newSelectedItems);
      setSearch("");
      setFilteredOptions(options);
    }
  };

  const handleSelectSearch = useCallback(
    (val) => {
      setSearch(val);
      const res = options.filter((option) =>
        option.user.toLowerCase().includes(val.toLowerCase())
      );
      setFilteredOptions(res);
    },
    [options]
  );

  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if (!isCreate) {
      if (item?.values?.length !== 0) {
        const fil = item?.options?.map((i) => i.id);
  
        const currentSelectedItems = fil
          ?.map((itemId) => options?.find((option) => option?.userId === itemId))
          .filter(Boolean)
          .map((option) => option.id);
  
  
        if (currentSelectedItems?.length !== 0 && options?.length !== 0) {
          setSelectedItems(currentSelectedItems);
        } else {
          setSelectedItems([]);
        }
      } else {
        setSelectedItems([]);
        setFilteredOptions([]);
      }
      setFlag(false);
    }
  }, [item, options, isCreate]);
  

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={()=>deleteSingleAssignee(value)}
        style={{
          marginRight: 3,
        }}
      >
        {/* <Avatar
          size="small"
          className={
            styles.UserSelect__container__body__options__option__left__avatarsm
          }
          style={{
            backgroundColor: options?.find((item) => item.value === value)
              ?.color,
            verticalAlign: "middle",
          }}
        >
          {options?.find((item) => item.value === value)?.user[0]}
        </Avatar> */}
        {label}
      </Tag>
    );
  };

  const tagRenderClose = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={false}
        onClose={onclose}
        style={{
          marginRight: 3,
        }}
      >
        <Avatar
          size="small"
          className={
            styles.UserSelect__container__body__options__option__left__avatarsm
          }
          style={{
            backgroundColor: options?.filter((item) => item.value === value)[0]
              ?.color,
            verticalAlign: "middle",
          }}
        >
          {options?.filter((item) => item.value === value)[0]?.user[0]}
        </Avatar>
        {label}
      </Tag>
    );
  };

  return (
    <div
      className={styles.UserSelect}
      ref={divRef}
      style={{ position: "relative" }}
    >
      {!isTableElement && (
        <div className={styles.UserSelect__Container}>
          <div className={styles.UserSelect__Label}>Assignee</div>
          <div className={styles.UserSelect__IconContainer}>
            <Tooltip title="Source: Internal UserAttribute">
              <InfoCircleOutlined className={styles.UserSelect__InfoIcon} />
            </Tooltip>
          </div>
        </div>
      )}
      <Select
        mode="multiple"
        placeholder={t("Select option")}
        required={false}
        value={selectedItems}
        className={styles.UserSelect__container__select}
        popupClassName={styles.UserSelect__container__popup}
        style={{
          width: "100%",
        }}
        tagRender={tagRenderClose}
        options={options}
        onClick={(e) => handleDropdownVisible(e)}
      />
      {/* {isDropdown && ( */}
      {isTableElement ? (
        <Modal
          mask={false}
          open={isModalOpen}
          onOk={handleMoreOk}
          onCancel={handleMoreCancel}
          // maskClosable={false}
          footer={null}
          closeIcon={false}
          className={styles.UserSelect__tablecontainer__modal}
          wrapClassName={styles.UserSelect__tablecontainer__wrapmodal}
          width={350}
          style={{ position: "absolute", ...modalPosition }}
        >
          <div
            className={styles.UserSelect__tablecontainer}
            style={{ position: isTableElement ? "relative" : "absolute" }}
          >
            <div className={styles.UserSelect__tablecontainer__header}>
              <span>{t("Add User")}</span>
            </div>
            {options?.length !== 0 && (
              <div className={styles.UserSelect__tablecontainer__body}>
                <Select
                  // mode="multiple"
                  options={filteredOptions}
                  placeholder={t("Search or select user")}
                  required={false}
                  value={selectedItems}
                  onSearch={handleSelectSearch}
                  onChange={(e) => {
                    setSelectedItems(e);
                    updateData(e);
                  }}
                  // tagRender={tagRender}
                  popupClassName={styles.UserSelect__tablecontainer__popup}
                  style={{
                    width: "100%",
                  }}
                ></Select>
                <div
                  className={styles.UserSelect__tablecontainer__body__options}
                >
                  {filteredOptions?.map((item) => (
                    <div
                      key={item.id}
                      className={
                        styles.UserSelect__tablecontainer__body__options__option
                      }
                      onClick={() => handleOptionClick(item.id)}
                    >
                      <div
                        className={
                          styles.UserSelect__tablecontainer__body__options__option__left
                        }
                      >
                        <Avatar
                          size="26px"
                          className={
                            styles.UserSelect__tablecontainer__body__options__option__left__avatar
                          }
                          style={{
                            backgroundColor: item?.color,
                            verticalAlign: "middle",
                          }}
                        >
                          {item?.user[0]}
                        </Avatar>
                        <div
                          className={
                            styles.UserSelect__tablecontainer__body__options__option__left__text
                          }
                        >
                          <h6>{item?.user}</h6>
                          <span>{item?.email}</span>
                        </div>
                      </div>
                      {selectedItems?.includes(item.id) && <CheckOutlined />}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </Modal>
      ) : (
        <Modal
          mask={false}
          open={isModalOpen}
          onOk={handleMoreOk}
          onCancel={handleMoreCancel}
          // maskClosable={false}
          footer={null}
          closeIcon={false}
          className={styles.UserSelect__container__modal}
          wrapClassName={styles.UserSelect__container__wrapmodal}
          width={250}
          style={{ position: "absolute", ...modalPosition }}
        >
          <div
            className={styles.UserSelect__container}
            style={{ position: isTableElement ? "relative" : "absolute" }}
          >
            <div className={styles.UserSelect__container__header}>
              <span>{t("Add User")}</span>
            </div>
            {options?.length !== 0 && (
              <div className={styles.UserSelect__container__body}>
                <Select
                  mode="multiple"
                  placeholder={t("Search or select user")}
                  required={false}
                  options={filteredOptions}
                  value={selectedItems}
                  onSearch={handleSelectSearch}
                  className={styles.UserSelect__container__select2}
                  onChange={(e) => {
                    setSelectedItems(e);
                    updateData(e);
                  }}
                  tagRender={tagRender}
                  popupClassName={styles.UserSelect__container__popup}
                  style={{
                    width: "100%",
                  }}
                ></Select>
                <div className={styles.UserSelect__container__body__options}>
                  {filteredOptions?.map((item) => (
                    <div
                      key={item.id}
                      className={
                        styles.UserSelect__container__body__options__option
                      }
                      onClick={() => handleOptionClick(item.id)}
                    >
                      <div
                        className={
                          styles.UserSelect__container__body__options__option__left
                        }
                      >
                        <Avatar
                          size="26px"
                          className={
                            styles.UserSelect__container__body__options__option__left__avatar
                          }
                          style={{
                            backgroundColor: item?.color,
                            verticalAlign: "middle",
                          }}
                        >
                          {item?.user[0]}
                        </Avatar>
                        <div
                          className={
                            styles.UserSelect__container__body__options__option__left__text
                          }
                        >
                          <h6>{item?.user}</h6>
                          <span>{item?.email}</span>
                        </div>
                      </div>
                      {selectedItems?.includes(item.id) && <CheckOutlined />}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </Modal>
      )}
      {/* )} */}
    </div>
  );
};

UserSelectInlineField.propTypes = {};

UserSelectInlineField.defaultProps = {};

export default UserSelectInlineField;
