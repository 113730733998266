import React from "react";
import styles from "./billing.module.scss";
import { Button } from "antd";
import Tick from "src/assets/BlueTick.svg";
import Agent from "src/assets/agent.svg";
import TickIcon from "src/assets/svgs/TickIcon";

function PricingTable({
  plan,
  billing_details,
  getStatus,
  selectedOption,
  counter,
  staff_details,
  decrementCount,
  incrementCount,
  getBtnText,
  handleButtonClick,
  activePlan,
  setSessionLoading,
  sessionLoading,
  setSubscribedText,
  subscribedText,
}) {
  return (
    <div
      key={plan.name}
      className={styles.PricingTable__container__priceColumn}
      style={{
        border:
          billing_details?.getOrganization?.billing?.status === "trialing" ||
          (billing_details?.getOrganization?.billing?.status === "active" &&
            plan?.name.toLowerCase() === activePlan?.name.toLowerCase() &&
            "1px solid #BAE7FF"),
      }}
    >
      {billing_details?.getOrganization?.billing?.status === "trialing" ||
        (billing_details?.getOrganization?.billing?.status === "active" &&
          plan?.name.toLowerCase() === activePlan?.name.toLowerCase() && (
          <div className={styles.PricingTable__container__priceColumn__flag}>
              Current {getStatus()}
          </div>
        ))}
      <div className={styles.PricingTable__container__priceColumn__header}>
        {plan.name}
      </div>
      <div className={styles.PricingTable__container__priceColumn__content}>
        <div
          className={
            styles.PricingTable__container__priceColumn__content__priceContainer
          }
        >
          <span
            className={
              styles.PricingTable__container__priceColumn__content__priceContainer__price
            }
          >
            {plan?.name === "Enterprises plan" ? (
              ""
            ) : (
              <>
                $
                {selectedOption === "monthly"
                  ? plan.prices[0]?.monthly * counter
                  : plan?.prices[1]?.yearly * counter}
              </>
            )}
          </span>
          <span
            className={
              styles.PricingTable__container__priceColumn__content__priceContainer__unit
            }
          >
            {plan?.name === "Enterprises plan" ? (
              "Enterprise contracts are annual and can have more flexible terms."
            ) : (
              <>per agent per month</>
            )}
          </span>
        </div>

        <div
          className={
            styles.PricingTable__container__priceColumn__content__input
          }
        >
          {plan?.name !== "Enterprises plan" && (
            <>
              <button
                style={
                  counter ===
                  staff_details?.getOrganization?.organizationStaff.length
                    ? {
                      backgroundColor: "#eeeeee",
                      color: "#9d9d9d",
                    }
                    : {}
                }
                disabled={
                  counter ===
                  staff_details?.getOrganization?.organizationStaff.length
                }
                onClick={() => decrementCount(plan)}
              >
                -
              </button>
              <div
                className={
                  styles.PricingTable__container__priceColumn__content__input__wrapper
                }
              >
                <img src={Agent} alt="agent" />
                <input
                  type="text"
                  placeholder="Agents"
                  value={counter}
                  // onChange={handleChange}
                />
              </div>
              <button onClick={() => incrementCount(plan)}>+</button>
            </>
          )}
        </div>

        <Button
          type="primary"
          style={{backgroundColor:'var(--primary-color)'}}
          disabled={
            (getBtnText(plan) === "Subscribed" && subscribedText === null) ||
            (getBtnText(plan) === "Subscribed" &&
              subscribedText?.plan === plan.name &&
              subscribedText?.text !== "Subscribe now")
              ? true
              : false
          }
          onClick={() => {
            setSessionLoading({ plan: plan.name, loading: true });
            handleButtonClick(plan);
          }}
        >
          {sessionLoading.plan === plan.name && sessionLoading.loading
            ? "Loading..."
            : subscribedText?.plan === plan.name
              ? subscribedText?.text
              : getBtnText(plan)}
        </Button>
        <div
          className={
            styles.PricingTable__container__priceColumn__content__featureList
          }
          style={{
            borderColor:
              billing_details?.getOrganization?.billing?.status ===
                "trialing" ||
              (billing_details?.getOrganization?.billing?.status === "active" &&
                plan?.name.toLowerCase() === activePlan?.name.toLowerCase() &&
                "#BAE7FF"),
          }}
        >
          {plan?.features &&
            Array.isArray(plan?.features) &&
            plan.features.map((feature, i) => {
              return (
                <div key={i}>
                  <TickIcon /> &nbsp;
                  {feature}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default PricingTable;
