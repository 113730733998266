import { gql } from "@apollo/client";

export const GET_NODEVIEW_ENABLED = gql`
  subscription ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      id
      treeViewConfiguration {
        isEnabled
      }
    }
  }
`;

export const CHECK_NODE_EXIST = gql`
  query ($organizationId: ID!, $nodeId: [ID!]) {
    getOrganization(id: $organizationId) {
      id
      nodes(filter: { id: $nodeId }) {
        id
        uniqueIdentifier
      }
    }
  }
`;

export const PIN_NODE = gql`
  mutation AddPinnedNodesToStaff($staffId: ID!, $nodeId: ID!) {
    updateHC_Staff(
      input: {
        filter: { id: [$staffId] }
        set: { pinnedNodes: { id: $nodeId } }
      }
    ) {
      hC_Staff {
        id
      }
    }
  }
`;

export const UNPIN_NODE = gql`
  mutation RemovePinnedNodesFromStaff($staffId: ID!, $nodeId: ID!) {
    updateHC_Staff(
      input: {
        filter: { id: [$staffId] }
        remove: { pinnedNodes: { id: $nodeId } }
      }
    ) {
      hC_Staff {
        id
      }
    }
  }
`;

export const QUERY_PINNED_NODES = gql`
  query GetPinnedNodes($staffId: ID!) {
    getHC_Staff(id: $staffId) {
      id
      pinnedNodes {
        id
        title
        uniqueIdentifier
      }
    }
  }
`;
