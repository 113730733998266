import React, {useState} from "react";
import PropTypes from "prop-types";
import RocketIcon from "src/assets/RocketIcon.png";
import { Button, Modal, message } from "antd";
import { useNavigate } from "react-router-dom";
import styles from "./ErrorUI.module.scss";

const ErrorUi = ({errorMessage}) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigateToHomeScreen = () => {
    navigate("/");  
    navigate(0);
  }
  const handleOk=()=>{
    setIsModalOpen(false);
  }
  const handleCancel=()=>{
    setIsModalOpen(false)
  }

  const openModal=()=>{
    setIsModalOpen(true);
  }

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(errorMessage===undefined?"404! Invalid URL":errorMessage)
      .then(() => {
        message.success("Logs copied successfully");
      })
      .catch(err => {
        console.log(err);
      })
  }

  return (
    <>
      <Modal 
        title="Error Logs" 
        open={isModalOpen} 
        onOk={handleOk} 
        onCancel={handleCancel}
        footer={null}
      >
        <div className={styles.ErrorText}>

          <div className={styles.ErrorText__ButtonContainer}>
            <Button type="link" style={{marginTop:"0px"}} onClick={copyToClipBoard}>COPY</Button>
          </div>
          <div className={styles.ErrorText__Container}>
            {errorMessage===undefined?"404! Invalid URL":errorMessage}
          </div>
        </div>
      </Modal>
      <div className={styles.ErrorUi} data-testid="ErrorUi">
        <img src={RocketIcon} />
        <h1>OOPS!</h1>
        <p>Looks like we&apos;re experiencing some technical problems.<br />Try refreshing the page or hit the button below to navigate to home page.</p>
        <Button onClick={openModal} type="secondary">View Error Logs</Button>
        <Button onClick={navigateToHomeScreen} type="primary">Return to home screen</Button>
      </div>
    </>
  );
}

ErrorUi.propTypes = {};

ErrorUi.defaultProps = {};

export default ErrorUi;
