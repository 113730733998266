import React from "react"
import styles from "./Tags.module.scss";
import { Tag, Popover } from "antd";

const Tags = (props) => {

  const { value } = props;


  if (Object.keys(value[0]).length > 2) {
    return <div className={styles.Tags}>{value.map((item) => {
      return (
        <div>
          <Tag color={item?.color}>{item?.name}</Tag>
        </div>
      )
    })}
    </div>

  } else {
    return <span>---</span>
  }

}

export default Tags
