import React, { useEffect, useState } from "react";
import { Tag } from "antd";
import PropTypes from "prop-types";
import styles from "./AutomationTagsContainer.module.scss";

const AutomationTagsContainer = ({ onTagsChange }) => {
  const [tags, setTags] = useState([]);
  function handleKeyDown(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      const textContent = event.target.textContent;
      if (textContent.trim() !== "") {
        let currentTags = [...tags, textContent];
        setTags(currentTags);
        event.target.textContent = "";
      }
    }
  }
  function removeTags(index) {
    let ind = parseInt(index);
    let filteredItems = tags.filter((_, i) => i !== ind);
    setTags(filteredItems);
  }
  useEffect(() => {
    onTagsChange(tags);
  }, [tags])
  return (
    <div className={styles.AutomationTagsContainer__Container}>
      {tags.map((tx, index) => <Tag
        key={index}
        index={index}
        className={styles.AutomationTagsContainer__Tags}
        closable
        onClose={(e) => {
          e.preventDefault();
          let index = e.target?.parentNode?.parentNode?.getAttribute("index");
          if (index === null || index === undefined)
            return;
          removeTags(index);
        }}>
        {tx}
      </Tag>)}
      <div
        className={styles.AutomationTagsContainer}
        contentEditable="true"
        onKeyDown={handleKeyDown}
      >

      </div>
    </div>
  );
}

AutomationTagsContainer.propTypes = {};

AutomationTagsContainer.defaultProps = {};

export default AutomationTagsContainer;
