import React, { useEffect, useMemo, useState } from "react";

import { notification } from "antd";
import { useTranslation } from "react-i18next";

import { updateContact } from "src/api/ContactApi";
import { MultiSelectInlineFields } from "src/components/Conversation/Conversation";

const MultiSelectInlineFieldsCell = ({
  allRefetch,
  value,
  row,
  accessor,
  allAttrData,
  attrId,
}) => {
  const { t } = useTranslation();

  const onMultiSelectSubmit = async (value) => {
    try {
      let data = {};
      let attributeId;
      if (value.length === 0) {
        attributeId = attrId;
        data = {
          multiSelectAttribute: {
            attributeId: attributeId,
            multiSelectAttributeValue: [],
          },
        };
      } else {
        attributeId = attrId;
        data = {
          multiSelectAttribute: {
            attributeId: attrId,
            multiSelectAttributeValue: value,
          },
        };
      }
      let res = await submitForm(data);
    } catch (err) {
      return;
    }
  };

  const submitForm = async (data) => {
    try {
      let res = await updateContact(data, row?.original?.leadId);
      if (!res?.data?.success) {
        throw new Error("Encountered error while updating contact");
      }
    } catch (err) {
      notification.error({
        message: <b>Notification</b>,
        description: <b>Encountered server error while updating</b>,
      });
      return;
    }
  };

  const [id, setId] = useState("");
  const [attributeType, setAttributeType] = useState("");
  const [label, setLabel] = useState("");
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (value?.length !== 0 && value !== undefined) {
      setId(value[0]?.attributeId);
      setAttributeType(value[0]?.attributeType);
      setLabel(accessor);
      setTags(
        value.map((item) => ({
          color: item?.color,
          id: item?.labelId,
          value: item?.name,
          valueid: item?.id,
        }))
      );
    }
  }, [value, accessor]);

  function getAttributeId(data, attributeName) {
    const attributeData = Object.entries(data).find(
      ([key, value]) => key === attributeName
    );
    if (attributeData) {
      const attributeValues = attributeData[1];
      if (attributeValues.length > 0) {
        return attributeValues[0].attributeId;
      }
    }
    return null;
  }

  return useMemo(
    () => (
      <>
        {/* <div>MultiSelectInlineFieldsCell</div> */}
        <MultiSelectInlineFields
          isTableElement={true}
          attributeId={attrId}
          label={label}
          tags={tags[0]?.id !== undefined ? tags : []}
          t={t}
          onMultiSelectSubmit={onMultiSelectSubmit}
          onDeleteForm={() => {}}
          isEdit={true}
          isDefault={
            attributeType === "Default" ||
            attributeType === "DefaultOutsideDrawer"
          }
          allRefetch={allRefetch}
        />
      </>
    ),
    [id, label, tags, t, onMultiSelectSubmit, attributeType]
  );
};

export default MultiSelectInlineFieldsCell;
