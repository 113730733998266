import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./CreateCannedUploadRow.module.scss";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { CloseOutlined } from "@ant-design/icons";
import { Image } from "antd";

const DragHandle = sortableHandle(() => <span className={styles.DraggableTag__draghandle}>::</span>);


const CreateCannedUploadRow = ({ value, getCannedResponse, clickRemove }) => {
  const [isImage, setIsImage] = useState({ "shown": false, url: "" });
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  useEffect(() => {
    const image_extension = ["image/jpg", "image/jpeg", "image/png"];
    if (image_extension.includes(value?.type)) {
      setIsImage({
        "shown": true,
        url: value?.url === undefined ? URL.createObjectURL(value) : value?.url
      });
    }
  }, []);

  return (
    <li className={styles.DraggableTag__dragtag}>
      <div>
        <DragHandle />
        <p onClick={() => setPreviewVisible(!isPreviewVisible)}> {(value.url !== null && value.url !== undefined) ? getCannedResponse(value?.url) : value?.name}</p>
        {isImage.shown === true && <Image preview={{
          visible: isPreviewVisible,
          onVisibleChange: (visible, prevVisible) => setPreviewVisible(visible),
        }} style={{ display: "none" }} src={isImage.url} />}
        <CloseOutlined onClick={(e) => clickRemove(value)} className={styles.DraggableTag__CloseIcon} />
      </div>
    </li>
  );
}

CreateCannedUploadRow.propTypes = {};

CreateCannedUploadRow.defaultProps = {};

export default CreateCannedUploadRow;
