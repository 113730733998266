import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./PreviewGenerator.module.scss";
import {
  EllipsisOutlined,
  CheckCircleOutlined,
  FileWordOutlined,
  FilePdfOutlined,
  AudioOutlined,
  StarOutlined,
  StarFilled,
  LoadingOutlined,
  DownloadOutlined,
  SecurityScanTwoTone,
  FilePptOutlined,
  FileTextOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import Select from "react-select";

const MessageType = {
  FirstImage: "firstImage",
  TextOnly: "textOnly",
  FirstDocument: "firstDocument"
};
const PreviewGenerator = ({ clickEvent, attachmentList, message }) => {



  const [messageType, setMessageType] = useState(MessageType.TextOnly);

  const attachmentRow = (icon, file_name) => {
    return (
      <div className={styles.PreviewGenerator__Message__AttachmentRow}>
        <div className={styles.PreviewGenerator__Message__AttachmentRow__AttachmentLeft}>{icon}</div>
        <div className={styles.PreviewGenerator__Message__AttachmentRow__AttachmentName}>{file_name}</div>
      </div>
    );
  }

  const returnUploadedAttachment = (attachment, onClick) => {
    const url = attachment?.url.split(".");
    const file_extension = url[url.length - 1];
    const url_split_by_hash = attachment.url.split("/");
    const file_name = url_split_by_hash[url_split_by_hash.length - 1];
    const image_extension = ["jpg", "jpeg", "png"];
    const audio_extension = ["ogg", "mp3"];
    const doc_extension = ["doc", "docx"];
    const ppt_extension = ["ppt", "pptx"];
    const xls_extension = ["xls", "xlsx"];
    if (attachment.url === undefined || attachment.url === null) {
      return <></>;
    } else if (image_extension.includes(file_extension)) {
      return (
        <>

          {/* <a href={attachment.url}> */}
          <img
            // width={200}
            src={attachment.url}
            className="attachment-image"
          />
          {/* <img
              data-testid="message-image"
              className="attachment-image"
              src={attachment.url}
            /> */}
          {/* </a> */}
        </>
      );
    } else if (file_extension === "pdf") {
      return attachmentRow(
        <FilePdfOutlined data-testid="message-pdf" style={{ color: "red" }} />,
        file_name,
        attachment.url
      );
    } else if (file_extension === "mp4") {
      return (
        <video
          data-testid="message-video"
          className="attachment-video"
          controls
        >
          <source src={attachment.url} type="video/mp4" />
        </video>
      );
    } else if (audio_extension.includes(file_extension)) {
      return (
        <audio data-testid="message-audio" controls>
          <source src={attachment.url} type="audio/mp3" />
        </audio>
      );
    } else if (file_extension === "amr") {
      return attachmentRow(
        <AudioOutlined style={{ color: "green" }} />,
        file_name,
        attachment.url
      );
    } else if (doc_extension.includes(file_extension)) {
      return attachmentRow(
        <FileWordOutlined
          data-testid="message-word"
          style={{ color: "blue" }}
        />,
        file_name,
        attachment.url
      );
    } else if (ppt_extension.includes(file_extension)) {
      return attachmentRow(
        <FilePptOutlined
          data-testid="message-ppt"
          style={{ color: "orange" }}
        />,
        file_name,
        attachment.url
      );
    } else if (xls_extension.includes(file_extension)) {
      return attachmentRow(
        <FileExcelOutlined
          data-testid="message-xls"
          style={{ color: "green" }}
        />,
        file_name,
        attachment.url
      );
    }
    return attachmentRow(
      <FileTextOutlined style={{ color: "blue" }} />,
      file_name,
      attachment.url
    );
  };

  const returnAttachment = (attachment_type) => {
    if (attachment_type?.url !== undefined && attachment_type?.url !== null) {
      return returnUploadedAttachment(attachment_type);
    }
    const file_extension = attachment_type?.type;
    const file_name = attachment_type?.name;
    const image_extension = ["image/jpg", "image/jpeg", "image/png"];
    const audio_extension = ["video/ogg", "audio/mpeg"];
    const doc_extension = ["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
    const ppt_extension = ["application/vnd.ms-powerpoint", "application/vnd.openxmlformats-officedocument.presentationml.presentation"];
    const xls_extension = ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
    if (file_extension === undefined || file_name === null) {
      return <></>;
    } else if (image_extension.includes(file_extension)) {
      return (
        <img
          src={URL.createObjectURL(attachment_type)}
          className="attachment-image"
        />

      );
    }
    else if (file_extension === "application/pdf") {
      return attachmentRow(
        <FilePdfOutlined style={{ color: "red" }} />,
        file_name
      );
    } else if (file_extension === "video/mp4") {
      return (
        <video
          data-testid="message-video"
          className="attachment-video"
          controls
        >
          <source src={URL.createObjectURL(attachment_type)} type="video/mp4" />
        </video>
      );
    } else if (audio_extension.includes(file_extension)) {
      return (
        <audio data-testid="message-audio" controls>
          <source src={URL.createObjectURL(attachment_type)} type="audio/mp3" />
        </audio>
      );


    } else if (file_extension === "audio/AMR") {
      return attachmentRow(
        <AudioOutlined style={{ color: "green" }} />,
        file_name
      );
    }
    else if (doc_extension.includes(file_extension)) {
      return attachmentRow(
        <FileWordOutlined
          data-testid="message-word"
          style={{ color: "blue" }}
        />,
        file_name
      );
    }
    else if (ppt_extension.includes(file_extension)) {
      return attachmentRow(
        <FilePptOutlined
          data-testid="message-ppt"
          style={{ color: "orange" }}
        />,
        file_name
      );
    } else if (xls_extension.includes(file_extension)) {
      return attachmentRow(
        <FileExcelOutlined
          data-testid="message-xls"
          style={{ color: "green" }}
        />,
        file_name
      );
    }
    return attachmentRow(
      <FileTextOutlined style={{ color: "blue" }} />,
      file_name
    );
  };

  useEffect(() => {
    if (attachmentList.length === 0) {
      setMessageType(MessageType.TextOnly);
      return;
    }
    const firstAttachmentType = ["image/jpg", "image/jpeg", "image/png"];
    if (firstAttachmentType.includes(attachmentList[0].type)) {
      setMessageType(MessageType.FirstImage);
    } else {
      setMessageType(MessageType.FirstDocument);
    }
  }, [attachmentList, message]);

  return (
    <div className={styles.PreviewGenerator} onClick={clickEvent}>
      {messageType === MessageType.TextOnly ?
        message.trim() !== "" && <div className={styles.PreviewGenerator__Message}>
          <p>{message}</p>
        </div>
        : messageType === MessageType.FirstImage ?
          attachmentList.map((attachment, index) =>
            <div className={styles.PreviewGenerator__Message}>
              {returnAttachment(attachment)}
              {index === 0 ? message : null}
            </div>)
          :
          <>
            {message.trim() !== "" &&
              <div className={styles.PreviewGenerator__Message}>
                <p>{message}</p>
              </div>
            }
            {attachmentList.map((attachment, index) =>
              <div className={styles.PreviewGenerator__Message}>
                {returnAttachment(attachment)}

              </div>)}
          </>}
    </div>
  );
}

PreviewGenerator.propTypes = {};

PreviewGenerator.defaultProps = {};

export default PreviewGenerator;
