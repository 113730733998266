import React from "react";
import PropTypes from "prop-types";
import styles from "./OnboardingTour.module.scss";

import { TourProvider } from "@reactour/tour";
import { useSelector, useDispatch } from "react-redux";
import { setOpened } from "src/store/slices/onboardingSlice";
import OnboardingPopover from "../OnboardingPopover/OnboardingPopover";

const OnboardingTour = ({ children }) => {
  const dispatch = useDispatch();

  const { currentStepIndex } = useSelector(({ onboarding: { data } }) => data);

  return (
    <TourProvider
      steps={[]}
      showNavigation={false}
      currentStep={currentStepIndex}
      ContentComponent={OnboardingPopover}
      afterOpen={() => dispatch(setOpened(true))}
      beforeClose={() => dispatch(setOpened(false))}
    >
      {children}
    </TourProvider>
  );
};

OnboardingTour.propTypes = {};

OnboardingTour.defaultProps = {};

export default OnboardingTour;
