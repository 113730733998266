import CoreApiClient from "./clients/CoreApiClient";

export const getNestedNodes = async () => {
  return CoreApiClient({
    method: "GET",
    url: "/data/extract-dgraph-nested-view",
  });
};

export const createNode = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/chat/create-node",
    data: body,
  });
};
