  import React from "react";
  import styles from "./RangePickerWidget.module.scss"
  import { DatePicker, Space } from "antd";
  import dayjs from 'dayjs';

  const dateFormat = 'YYYY/MM/DD';
  const { RangePicker } = DatePicker;

  const RangePickerWidget = ({ onDateRangeChange, value }) => {
    const handleDateRangeChange = (dates) => {
      onDateRangeChange(dates);
    };
    const currentDate = dayjs().startOf('day');

  return (
    <Space direction="vertical" size={12}>
      <RangePicker
        className={styles.rangepicker}
        onChange={handleDateRangeChange}
        defaultValue={[currentDate, currentDate]}
        format={dateFormat}
        // placeholder={[defaultStartDate, defaultStartDate]}
      />
    </Space>
  );
};

export default RangePickerWidget;
