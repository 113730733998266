import {
  ApolloClient as AC,
  from,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";

import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";

import getConfig from "src/helpers/config";

const ApolloClient = () => {
  const { REACT_APP_CONNECTION_URI, REACT_APP_SUBSCRIPTION_URI } = getConfig();

  const errorLink = onError(({ graphqlErrors }) => {
    if (graphqlErrors) {
      graphqlErrors.map(({ message }) => {
        return alert(`Graphql error ${message}`);
      });
    }
  });

  const httpLink = from([
    errorLink,
    new HttpLink({
      uri: REACT_APP_CONNECTION_URI,
    }),
  ]);

  const wsLink = new WebSocketLink({
    uri: REACT_APP_SUBSCRIPTION_URI,
    options: {
      lazy: true,
      minTimeout: 1000 * 60 * 25,
    },
  });

  const authLink = setContext(() => {
    return {};
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    authLink.concat(httpLink)
  );

  return new AC({
    link: splitLink,
    cache: new InMemoryCache(),
  });
};

export default ApolloClient;
