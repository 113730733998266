import CoreApiClient from "./clients/CoreApiClient";

export const getActionCardDetailsData = async (url, body, headers) => {
  return CoreApiClient({
    method: "POST",
    url: url,
    data: body,
    headers: headers ,
  });
};

export const updateOrganizationActionCard = async (body) =>{
  return CoreApiClient({
    method: "POST",
    url: "/organization/actionCard",
    data: body
  })
}