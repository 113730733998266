import React, { useState } from 'react';
import style from './Header.module.scss';
import RangePickerWidget from 'src/components/Dashboard/components/timesheetview/timesheet-view-widgets/widgets/RangePickerWidget/RangePickerWidget';
import { timeSheetTimerConfig } from 'src/helpers/dashboardConfig';
import DropdownMenu from 'src/utils/DropdownMenu';
import { useTranslation } from 'react-i18next';
import SideNavMobile from 'src/components/SideNavMobile/SideNavMobile';

const Header = ({handleRangeChange,selectedRange,setSelectedRange}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <header className={style.header}>
        <div>Analytics</div>
        <div className={style['menu-icon']} onClick={toggleMenu}>
          ☰
        </div>
        <div className={style['range-picker-desktop']}>
          <RangePickerWidget value={selectedRange} onDateRangeChange={handleRangeChange} />
        </div>
      </header>
      <DropdownMenu isOpen={menuOpen} toggleMenu={toggleMenu} />
      <div className={style['range-picker-mobile']}>
        <RangePickerWidget value={selectedRange} onDateRangeChange={handleRangeChange} />
      </div>
    </>
  );
};

export default Header;
