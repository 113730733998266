import React from "react";
import PropTypes from "prop-types";
import styles from "./ErrorPage.module.scss";
import ErrorUi from "../ErrorUI/ErrorUI";

class ErrorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: "" };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error: error.message };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      error: error.message,
    });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorUi errorMessage={this.state.error} />;
    }
    return this.props.children;
  }
}

// ErrorPage.propTypes = {};

// ErrorPage.defaultProps = {};

export default ErrorPage;
