import AuthApiClient from "./clients/AuthApiClient";
import CoreApiClient from "./clients/CoreApiClient";

export const resetPasswordEmail = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/staff/reset-password",
    data: body,
  });
};

export const setNewPassword = async (body) => {
  return CoreApiClient({
    method: "PUT",
    url: "/staff/update/password",
    data: body,
  });
};

export const sendOtpRequest = async (url, body) => {
  return CoreApiClient({
    method: "POST",
    url: `${url}?phone=${body}`,
  });
};

export const getTokenBySignup = async (body) => {
  return AuthApiClient({
    method: "POST",
    url: "/staff",
    data: body,
  });
};

export const getTokenBySignupInvitation = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/staff/signup-with-invitation",
    data: body,
  });
};

export const getTokenByLogin = async (body) => {
  return AuthApiClient({
    method: "POST",
    url: "/auth",
    data: body,
  });
};

export const refreshToken = async (body) => {
  return AuthApiClient({
    method: "GET",
    url: "/auth/refresh",
    data: body,
  });
};
