import DocsApiClient from "./clients/DocsApiClient";

export const createNewProject = async (body) => {
    return DocsApiClient({
      method: "POST",
      url: "/wiki-pages/create",
      data: body,
    });
};

