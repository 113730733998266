import React, { useEffect, useState } from "react";

import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { DeleteOutlined, EyeOutlined, PlusOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";

import { createWhatsappTemplate } from "src/api/WhatsappApi";
import EmptyMessageTemplate from "src/assets/svgs/EmptyMessageTemplate";
import WhatsappIcon from "src/assets/svgs/WhatsappIcon";
import CreateMessageTemplate from "../CreateMessageTemplate/CreateMessageTemplate";
import styles from "./MessageTemplates.module.scss";
import { GET_ORGANIZATION_WHATSAPP_TEMPLATES } from "src/graphql/whatsapp";
import { LOAD_CHANNELS } from "src/graphql/channel";
import { getActiveOrgId } from "src/store/slices/userSlice";

const { Search } = Input;

const MessageTemplates = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const nameValue = Form.useWatch("body", form);
  const organizationId = useSelector(getActiveOrgId);
  const [waChannel, setWaChannel] = useState([]);

  // const [data, setData] = useState(data1);
  const [dataSource, setDataSource] = useState([]);
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);

  const {
    loading,
    refetch: refetchWhatsappTemplates,
    error,
    data,
  } = useQuery(GET_ORGANIZATION_WHATSAPP_TEMPLATES, {
    variables: {
      organizationId: organizationId,
    },
  });

  const {
    data: data1,
    loading: loading1,
    error: error1,
  } = useQuery(LOAD_CHANNELS, {
    variables: {
      organizationId: organizationId,
    },
  });

  useEffect(() => {
    if (!data1) {
      setWaChannel([]);
    } else {
      setWaChannel(data1?.getOrganization?.channelData?.waChannel);
    }
  }, [data1]);

  useEffect(() => {
    if (!data) {
      setDataSource([]);
    } else {
      setDataSource(data?.getOrganization?.whatsappTemplate);
    }
  }, [data]);

  const onFinish = (values) => {
    let { name, category, language, body } = values;
    const statusColors = {
      "In Progress": "orange",
      Approved: "green",
      Rejected: "red",
    };
    let organization = { id: organizationId };
    let status = "In Progress";
    let color = statusColors[status];

    createWhatsappTemplate({
      name,
      category,
      status,
      color,
      language,
      body,
      organization,
      createdAt: new Date(),
    })
      .then((res) => {
        message.success("Template created & Sent for approval");
        refetchWhatsappTemplates();
        onClose();
      })
      .catch((e) => {
        message.error("Something went wrong . Please try again.");
      });
  };

  const deleteTemplate = (key) => {
    dataSource.splice(
      dataSource.findIndex((date) => date.key === key),
      1
    );

    setDataSource((prev) => [...prev]);
  };

  const convertDate = (d) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let date = new Date(d);
    let shortMonth = monthNames.map((m) => m.slice(0, 3));
    const month = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes + " " + ampm;
    return `${shortMonth[month]} ${day},${year} ${strTime}`;
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const columns = [
    {
      title: "Template Name",
      dataIndex: "name",
      key: "name",
      width: "45%",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Last Updated",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, { createdAt }) => convertDate(createdAt),
    },
    {
      title: "Language",
      dataIndex: "language",
      key: "language",
      render: (_, { language, color, status }) => (
        <>
          <Tooltip title={status} placement="bottom">
            <Tag color={color} key={language}>
              {language}
            </Tag>
          </Tooltip>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="small">
          <EyeOutlined className={styles.MessageTemplates__eye} />
          <DeleteOutlined
            onClick={() => deleteTemplate(record.key)}
            className={styles.MessageTemplates__delete}
          />
        </Space>
      ),
    },
  ];
  let locale = {
    emptyText: (
      <div className={styles.MessageTemplates__empty_state}>
        <EmptyMessageTemplate />
        <div>There is no message Template</div>
      </div>
    ),
  };

  const onSearch = (e) => {
    const currValue = e.target.value;
    setValue(currValue);
    const filteredData = data?.getOrganization?.whatsappTemplate.filter(
      (entry) => entry.name.toLowerCase().includes(currValue)
    );
    setDataSource(filteredData);
  };

  return (
    <div className={styles.MessageTemplates} data-testid="MessageTemplates">
      <div className={styles.MessageTemplates__container}>
        <div className={styles.MessageTemplates__header}>
          <div className={styles.MessageTemplates__left}>
            <h2>Message Templates</h2>
            <p>Manage pre-approved message templates here</p>
          </div>
          <div className={styles.MessageTemplates__right}>
            <Button type="primary" ghost="true" onClick={() => navigate(-1)}>
              Back
            </Button>
          </div>
        </div>
        <Divider />
        <div className={styles.MessageTemplates__table_container}>
          <h3>
            <WhatsappIcon /> {waChannel[0]?.name}
          </h3>
          <div className={styles.MessageTemplates__activity_buttons}>
            <Search
              className={styles.MessageTemplates__activity_buttons__searchbar}
              placeholder="Search Template"
              value={value}
              allowClear
              onChange={(e) => onSearch(e)}
            />
            <Button
              type="primary"
              icon={<PlusOutlined />}
              className={styles.MessageTemplates__activity_buttons__button}
              onClick={showDrawer}
            >
              Add New Template
            </Button>
          </div>
          <Table
            locale={locale}
            pagination={{ pageSize: 6, position: ["bottomCenter"] }}
            dataSource={dataSource}
            columns={columns}
          />
        </div>
      </div>
      <CreateMessageTemplate
        title="Create Message Template"
        placement="right"
        onClose={onClose}
        open={open}
        form={form}
        onFinish={onFinish}
        nameValue={nameValue}
        onFinishFailed={onFinishFailed}
      />
    </div>
  );
};

MessageTemplates.propTypes = {};

MessageTemplates.defaultProps = {};

export default MessageTemplates;
