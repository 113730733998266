import React, { useEffect } from "react";
import { Modal, Form, Input, Button, Select, Radio, message } from "antd";
import { useDispatch } from "react-redux";
import { createNewProject } from "src/api/Project";
import { resetNode } from "src/store/slices/nodeSlice";
import { syncWikiCategoryData } from "src/api/OrganizationApi";


const AddNodeModal = ({ visible, onCancel, onSave, parentProject }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const getDefaultParentProject = (parentProject) => {
    if (!parentProject) {
      return { uniqueKey: 'Our Organisation', name: 'Our Organisation' };
    }

    if (parentProject.uniqueKey === parentProject.name && parentProject.type === 'Article') {
      return { uniqueKey: 'Our Organisation', name: 'Our Organisation' };
    }

    if (parentProject.type === 'Article') {
      const parts = parentProject.uniqueKey.split('/');
      const parentCategory = parts.length > 1 ? parts[parts.length - 2] : 'Our Organisation';
      return { uniqueKey: parentCategory, name: parentCategory };
    }

    return parentProject;
  };

  const defaultParentProject = getDefaultParentProject(parentProject);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        parent_category: defaultParentProject.uniqueKey
      });
    }
  }, [visible, defaultParentProject, form]);

  
  const handleProjectSave = async () => {
    try {
      const values = await form.validateFields();
      const projectData = {
        ...values,
        parent_category: defaultParentProject.uniqueKey,
      };

      message.loading('Saving project...', 6);
      const res = await createNewProject(projectData);
      console.log(res);
      message.destroy();
      message.success('Project saved successfully.');

      await handleSyncDocsData();

      form.resetFields();
      onSave(projectData);
    } catch (error) {
      console.log('Validate Failed:', error);
      message.error("Error saving project. Please try again later.");
    }
  };

  const handleSyncDocsData = async () => {
    try {
      message.loading('Syncing data...',6);
      dispatch(resetNode());
      const res = await syncWikiCategoryData()
      console.log(res)
      message.destroy();
      message.success('Project synced succesfully. Reloading the page...')
      setTimeout(() => {
        window.location.reload();
      }, 1000)
    } catch(e) {
      console.log('Error populating data');
      message.error("Error syncing data. Please try again later.");
    }
  };

  return (
    <Modal
      visible={visible}
      title="Add New Project"
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleProjectSave}>
          Save
        </Button>
      ]}
    >
      <Form form={form} layout="vertical" name="add_node_form">
        <Form.Item
          name="parent_category"
          label="Parent Project"
          initialValue={defaultParentProject.uniqueKey}
          rules={[{ required: true, message: 'Please select the parent project!' }]}
        >
          <Select placeholder="Select parent project" disabled>
            <Select.Option value={defaultParentProject.uniqueKey}>
              {defaultParentProject.name}
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="page_name"
          label="Project Name"
          rules={[{ required: true, message: 'Please input the project name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="page_type"
          label="Type"
          rules={[{ required: true, message: 'Please select the type!' }]}
        >
          <Radio.Group>
            <Radio value="Category">Category</Radio>
            <Radio value="Article">Article</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddNodeModal;
