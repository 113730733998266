import CoreApiClient from "./clients/CoreApiClient";

export const startNodeTime = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/timesheet/start-timer",
    data: body,
  });
};

export const stopNodeTime = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/timesheet/stop-timer",
    data: body,
  });
};

export const activityLog = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/timesheet/log-activity",
    data: body,
  });
};

export const extractTimeLogs = async (payload) => {
  return CoreApiClient({
    method: "GET",
    url: "/timesheet/user-time-logs",
    params: payload  ,
  });
};

export const deleteTimeLogs = async (body) => {
  return CoreApiClient({
    method: "DELETE",
    url: "/timesheet/clear-logs",
    params: body,
  });
};

export const addManualTimesheetData = async (body) => {
  return CoreApiClient({
    method: "PUT",
    url: "/timesheet/log-manual-time",
    data: body,
  });
};

export const fetchNodetimer = async () => {
  return CoreApiClient({
    method: "GET",
    url: "/timesheet/node-timer-data",
  });
};

export const extractNestedTimeAndActivityReports = async (payload) => {
  return CoreApiClient({
    method: "GET",
    url: "/timesheet/nested-time-logs",
    params: payload,
  });
};
