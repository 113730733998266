import { gql } from "@apollo/client";


export const UPDATE_TERRITORY = gql`
    mutation updateHCStafft($staffId: ID!, $contries:[String!] , $states: [String!]) {
        updateHC_Staff(
            input: { filter: { id: [$staffId] }, set: { territory:{ states: $states , contries: $contries} } }
        ) {
            hC_Staff{
                email
                territory{
                    contries
                    states
                }
            }
        }
    }  
`;



export const FETCH_TERRITORY = gql`
query QueryHC_Staff($staffId: [ID!]) {
    queryHC_Staff(filter: { id: $staffId }) {
        territory {
            contries
            states
        }
    }
}
`