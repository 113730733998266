import CoreApiClient from "./clients/CoreApiClient";

export const updateSelectedChannelId = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/leads/selectedChannel",
    data: body,
  });
};

export const createChannel = async (body, channelKey) => {
  return CoreApiClient({
    method: "POST",
    url: `organization/${channelKey}`,
    data: body,
  });
};
export const updateChannel = async (body, channelKey) => {
  return CoreApiClient({
    method: "PUT",
    url: `organization/${channelKey}`,
    data: body,
  });
};

export const createChannelWhatsapp = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: `organization/wachannel`,
    data: body,
  });
};

export const createCustomAPIChannel = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/channeldata/apichannel",
    data: body,
  });
};

export const createEmailChannel = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/channeldata/email-channel",
    data: body,
  });
};

export const updateCustomAPIChannel = async (body) => {
  return CoreApiClient({
    method: "PUT",
    url: "/channeldata/apichannel",
    data: body,
  });
};

export const deleteCustomAPIChannel = async (body) => {
  return CoreApiClient({
    method: "POST",
    url: "/channeldata/delete-apichannel",
    data: body,
  });
};

export const fetchChannels = async () => {
  return CoreApiClient({
    method: "GET",
    url: "/organization/channels",
  });
};

export const deleteChannelWhatsapp = async (body) => {
  return CoreApiClient({
    method: "DELETE",
    url: "/organization/wachannel",
    data: body,
  });
};

export const updateChannelWhatsapp = async (body) => {
  return CoreApiClient({
    method: "PUT",
    url: "/organization/wachannel",
    data: body,
  });
};
