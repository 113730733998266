import React from "react";
import ReactDOM from "react-dom/client";
import packageJson from "../package.json";
import App from "./App";
import { setupTracer } from "./tracing.js";
import { LoadingOutlined } from '@ant-design/icons';

import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Spin } from "antd";

import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";

import "./index.css";

process.env.REACT_APP_SENTRY_DSN &&
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: packageJson?.version || "0.0.0",
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || "localhost",
    tracesSampleRate: 0,
  });
if (window.Cypress) {
  window.store = store
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <React.Suspense fallback={<Spin fullscreen={true} size="large" indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}>
            <App />
          </React.Suspense>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

setupTracer();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();