import React, { useState } from "react";

import {
  message,
  Tag,
} from "antd";
import { arrayMoveImmutable } from "array-move";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";

import { updateLabel } from "src/api/LabelApi";
import CheckIcon from "src/assets/svgs/CheckIcon";
import EditTag from "../EditTag/EditTag";
import styles from "./DraggableTag.module.scss";
import { getActiveOrgId } from "src/store/slices/userSlice";
import { useSelector } from "react-redux";

const DragHandle = sortableHandle(() => (
  <span className={styles.DraggableTag__draghandle}>::</span>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <ul>{children}</ul>;
});

const DraggableTag = (props) => {
  const {
    tags,
    handleTagClick,
    selectedItems,
    handleFetchOptions,
    allRefetch,
    reftechData,
  } = props;
  // const [tags, handleFetchOptions] = useState([]);
  const organizationId = useSelector(getActiveOrgId);

  const [showColorDrawer, setShowcolorDrawer] = useState(false);
  const [selectedColor, setselectedColor] = useState("");
  const [selectedId, setSelectedId] = useState("");

  const onSortEnd = ({ oldIndex, newIndex }) => {
    handleFetchOptions((prevItems) =>
      arrayMoveImmutable(prevItems, oldIndex, newIndex)
    );
  };

  const handleInputChange = (val, itemId) => {
    if (tags?.filter((item) => item.id === itemId)[0]?.name === val) {
      return;
    }
    let color = tags?.filter((item) => item.id === itemId)[0]?.color;
    let updateObject = {
      id: itemId,
      name: val,
      color: color,
      organizationId: organizationId,
    };
    if (itemId && color && val && organizationId) {
      updateLabel(updateObject)
        .then(() => {
          let res = tags?.map((item) =>
            item.id === itemId ? { ...item, label: val, name: val } : item
          );
          handleFetchOptions(res);
          if(typeof allRefetch === "function"){
            allRefetch();
          } else {
            reftechData();
          }
        })
        .catch((e) => {
          message.error(e?.response?.data?.message[0]);
        })
        ?.then(() => {
          if(typeof allRefetch === "function"){
            allRefetch();
          } else {
            reftechData();
          }
        });
    }
  };

  const handleColorChange = (val, itemId) => {
    let name = tags?.filter((item) => item.id === itemId)[0]?.name;
    let updateObject = {
      id: itemId,
      name: name,
      color: val,
      organizationId: organizationId,
    };
    if (itemId && name && val && organizationId) {
      updateLabel(updateObject)
        .then(() => {
          let res = tags?.map((item) =>
            item.id === itemId ? { ...item, color: val } : item
          );
          handleFetchOptions(res);
        })
        .catch((e) => {
          message.error(e?.response?.data?.message[0]);
        })
        ?.then(() => {
          if(typeof allRefetch === "function"){
            allRefetch();
          } else {
            reftechData();
          }
        });
    }
  };

  const handleDelete = (itemId) => {
    let res = tags?.filter((item) => item.id !== itemId);
    handleFetchOptions(res);
    if(typeof allRefetch === "function"){
      allRefetch();
    } else {
      reftechData();
    }
  };

  const handleSelectedId = (v) => {
    setSelectedId(v);
  };

  const SortableItem = sortableElement(({ value }) => {
    return (
      <li className={styles.DraggableTag__dragtag}>
        <div>
          <DragHandle />
          <Tag
            className={styles.DraggableTag__dragtag__tag}
            onClick={() => handleTagClick(value)}
            color={value?.color}
          >
            {" "}
            {value?.label}
          </Tag>
        </div>
        <span>
          <span>
            {selectedItems?.findIndex((tx) => tx.id === value?.id) !== -1 && (
              <CheckIcon />
            )}
          </span>
          <EditTag
            reftechData={reftechData}
            allRefetch={allRefetch}
            organizationId={organizationId}
            handleColorChange={handleColorChange}
            handleDelete={handleDelete}
            handleInputChangeState={handleInputChange}
            tags={tags}
            itemId={value?.id}
            handleSelectedId={handleSelectedId}
          />
        </span>
      </li>
    );
  });

  return (
    <div className={styles.DraggableTag}>
      <SortableContainer lockAxis="y" onSortEnd={onSortEnd} useDragHandle>
        {tags.map((value, index) => (
          <SortableItem key={`item-${value?.id}`} index={index} value={value} />
        ))}
      </SortableContainer>
    </div>
  );
};

DraggableTag.propTypes = {};

DraggableTag.defaultProps = {};

export default DraggableTag;
