import React, { useState, useEffect } from "react";
import {
  Drawer,
  Form,
  Input,
  Button,
  Select,
  notification,
  Modal,
  message,
} from "antd";
import { CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import styles from "./EditCustomAPIChannel.module.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  deleteCustomAPIChannel,
  updateCustomAPIChannel,
} from "src/api/ChannelApi";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { LOAD_CHANNELS } from "src/graphql/channel";
import { getActiveOrgId } from "src/store/slices/userSlice";

const EditCustomAPIChannel = ({ detail, isVisible, setShowDrawer }) => {
  const organizationId = useSelector(getActiveOrgId);

  const { data, loading, refetch } = useQuery(LOAD_CHANNELS, {
    variables: {
      organizationId: organizationId,
    },
  });

  const [apiChannelData, setApiChannelData] = useState([]);

  useEffect(() => {
    if (data === null || data === undefined) return;
    setApiChannelData(data?.getOrganization?.channelData?.apiChannel);
    refetch();
  }, [data]);

  const [allowedOrigins, setAllowedOrigins] = useState([]);
  const [isValidAllowedOrigins, setIsValidAllowedOrigins] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleDelete = async () => {
    let data = {
      channelId: detail?.id,
      organizationId: organizationId,
    };

    try {
      const success = await deleteCustomAPIChannel(data);

      if (success) {
        setIsModalOpen(!isModalOpen);
        setShowDrawer(false);
        message.success("Successfully Deleted Channel");
        // window.location.reload();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleChangeSelect = (value) => {
    setAllowedOrigins([...value]);
    setIsValidAllowedOrigins(!(value.length === 0));
  };

  const submitForm = async (formValues) => {
    let data = {
      organizationId: organizationId,
      channelId: detail?.id,
      sendMessageWebhookURL: formValues?.sendMessageWebhookURL,
      postLeadSaveWebhookURL: formValues?.postLeadSaveWebhookURL,
      name: formValues?.channelName,
      allowedOrigins: [...allowedOrigins],
      active: "true",
      resolveChatWebhookURL: formValues?.resolveChatWebhookURL,
    };

    try {
      const res = await updateCustomAPIChannel(data);
      if (res.data.success === true) {
        notification.success({
          message: <b>Notification</b>,
          description: res.data.message,
        });
        refetch();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    isValid,
    // resetForm,
  } = useFormik({
    initialValues: {
      sendMessageWebhookURL: `${detail?.sendMessageWebhookURL}`,
      postLeadSaveWebhookURL: `${detail?.postLeadSaveWebhookURL}`,
      channelName: `${detail?.name}`,
      resolveChatWebhookURL: `${
        detail?.resolveChatWebhookURL === null
          ? ""
          : detail?.resolveChatWebhookURL
      }`,
    },
    validationSchema: Yup.object().shape({
      sendMessageWebhookURL: Yup.string()
        .url("Invalid URL")
        .required("Channel Name is required"),
      postLeadSaveWebhookURL: Yup.string()
        .url("Invalid URL")
        .required("Send Message WebhookURL is required"),
      channelName: Yup.string().required("Channel Name is required"),
      resolveChatWebhookURL: Yup.string()
        .url("Invalid URL")
        .required("Resolve Chat Webhook is required"),
    }),
    onSubmit: (formValues) => {
      submitForm(formValues);
      setShowDrawer(false);
      // resetForm();
    },
  });

  const handleValidationStatus = (key) => {
    return !touched[key] && !errors[key]
      ? ""
      : touched[key] && !errors[key]
        ? "success"
        : touched[key] && errors[key]
          ? "error"
          : "";
  };

  const copyToClipBoard = (data) => {
    navigator.clipboard
      .writeText(data)
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Drawer
        // width={534}
        className={styles.EditCustomAPIChannel__drawer}
        headerStyle={{ display: "none" }}
        placement="right"
        open={isVisible}
        onClose={() =>
          setShowDrawer({
            detail: {},
            shown: false,
          })
        }
      >
        <div className={styles.EditCustomAPIChannel}>
          <div className={styles.EditCustomAPIChannel__Header}>
            <h3>Edit Custom API Inbox</h3>
            <Button
              danger
              type="error"
              icon={<DeleteOutlined />}
              onClick={showModal}
            >
              Delete
            </Button>
          </div>

          <Form
            layout="vertical"
            className={styles.ChannelForm}
            onFinish={handleSubmit}
          >
            <Form.Item
              name="channelName"
              label="Channel Name"
              validateStatus={handleValidationStatus("channelName")}
            >
              <Input
                name="channelName"
                placeholder="Enter the Channel Name"
                onChange={handleChange}
                value={values.channelName}
                defaultValue={detail.name}
                addonAfter={
                  <CopyOutlined onClick={() => copyToClipBoard(detail.name)} />
                }
              />
            </Form.Item>

            <Form.Item
              name="sendMessageWebhookURL"
              label="Send Message WebhookURL"
              validateStatus={handleValidationStatus("sendMessageWebhookURL")}
            >
              <Input
                name="sendMessageWebhookURL"
                placeholder="https://google.com"
                onChange={handleChange}
                value={values.sendMessageWebhookURL}
                defaultValue={detail.sendMessageWebhookURL}
                addonAfter={
                  <CopyOutlined
                    onClick={() =>
                      copyToClipBoard(detail.sendMessageWebhookURL)
                    }
                  />
                }
              />
            </Form.Item>

            <Form.Item
              name="postLeadSaveWebhookURL"
              label="Post Lead Save WebhookURL"
              validateStatus={handleValidationStatus("postLeadSaveWebhookURL")}
            >
              <Input
                name="postLeadSaveWebhookURL"
                placeholder="https://google.com"
                onChange={handleChange}
                value={values.postLeadSaveWebhookURL}
                defaultValue={detail.postLeadSaveWebhookURL}
                addonAfter={
                  <CopyOutlined
                    onClick={() =>
                      copyToClipBoard(detail.postLeadSaveWebhookURL)
                    }
                  />
                }
              />
            </Form.Item>

            <Form.Item
              name="resolveChatWebhookURL"
              label="Resolve Chat Webhook"
              validateStatus={handleValidationStatus("resolveChatWebhookURL")}
            >
              <Input
                name="resolveChatWebhookURL"
                placeholder="https://google.com"
                onChange={handleChange}
                value={values.postLeadSaveWebhookURL}
                defaultValue={
                  detail?.resolveChatWebhookURL === null
                    ? ""
                    : detail?.resolveChatWebhookURL
                }
                addonAfter={
                  <CopyOutlined
                    onClick={() =>
                      copyToClipBoard(
                        detail?.resolveChatWebhookURL === null
                          ? ""
                          : detail?.resolveChatWebhookURL
                      )
                    }
                  />
                }
              />
            </Form.Item>
            <Form.Item
              name="allowedOrigin"
              label="Allowed Origin"
              validateStatus={handleValidationStatus("allowedOrigin")}
            >
              <Select
                mode="tags"
                placeholder="https://google.com"
                onChange={handleChangeSelect}
                tokenSeparators={[","]}
                defaultValue={[...detail.allowedOrigins]}
              />
            </Form.Item>

            <div className={styles.ChannelForm__ButtonContainer}>
              <Button
                type="secondary"
                onClick={() =>
                  setShowDrawer({
                    detail: {},
                    shown: false,
                  })
                }
              >
                Cancel
              </Button>

              <Button
                type="primary"
                disabled={isValid && isValidAllowedOrigins}
                htmlType="submit"
                style={{backgroundColor:'var(--primary-color)'}}
              >
                Edit
              </Button>
            </div>
          </Form>
          <Modal
            header={null}
            footer={[
              <Button type="ghost" onClick={handleCancel}>
                Cancel
              </Button>,
              <Button danger type="primary" onClick={handleDelete}>
                Delete Channel
              </Button>,
            ]}
            title={null}
            open={isModalOpen}
            onOk={handleDelete}
            onCancel={handleCancel}
          >
            <h4 style={{ textAlign: "left" }}>
              Delete Channel
              {/* <span >
            {`${initialValues?.name} ?`}
            </span> */}
            </h4>
            <p style={{ textAlign: "left" }}>
              Once you delete this channel,you will not be able to restore
              customer data chat logs, settings and statistics, Are you sure you
              want to delete this channel?
            </p>
          </Modal>
        </div>
      </Drawer>
    </>
  );
};

EditCustomAPIChannel.propTypes = {};

EditCustomAPIChannel.defaultProps = {};

export default EditCustomAPIChannel;
