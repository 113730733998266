import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Tag } from "antd";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import styles from "./CreateCannedUpload.module.scss";
import PlaceholderImage from "src/assets/Icon/EmptyState.svg";
import CheckIcon from "src/assets/svgs/CheckIcon";
import { CloseOutlined } from "@ant-design/icons";
import CreateCannedUploadRow from "../CreateCannedUploadRow/CreateCannedUploadRow";

const SortableContainer = sortableContainer(({ children }) => {
  return <ul>{children}</ul>;
});


const CreateCannedUpload = ({ attachmentList, addAttachment, removeAttachmentByIndex, reOrderAttachment }) => {
  const tags = [{ "id": 1, "label": "Attachment 1" }, { "id": 2, "label": "Attachment 2" }, { "id": 3, "label": "Attachment 3" }];
  const [selectedId, setSelectedId] = useState("");
  const onSortEnd = ({ oldIndex, newIndex }) => {
    reOrderAttachment(oldIndex, newIndex);
    // handleFetchOptions((prevItems) => arrayMoveImmutable(prevItems, oldIndex, newIndex));
  };
  const clickRemove = (value) => {
    removeAttachmentByIndex(value);
  }
  const getCannedResponse = (title) => {
    let arr = title.split("/");
    return arr[arr.length - 1];
  }
  const SortableItem = sortableElement(({ value }) => {
    
    return (
      <CreateCannedUploadRow
        value={value}
        getCannedResponse={getCannedResponse}
        clickRemove={clickRemove}
      />
    );
  });

  const InputChange = (e) => {
    addAttachment(e.target.files);
    e.target.value = null;
  }
  return (
    <div className={styles.CreateCannedUpload}>
      <div className={styles.CreateCannedUpload__Header}>
        <div className={styles.CreateCannedUpload__Title}>
          Attach media
        </div>
        <input multiple onChange={InputChange} type="file" name="file" id="createCanned" style={{ display: "none" }} className={styles.CreateCannedUpload__None}></input>
        <label htmlFor="createCanned">

          <div type="secondary" className={styles.CreateCannedUpload__Header__Button} >Upload</div>
        </label>
      </div>
      {attachmentList.length === 0 ?
        <div className={styles.CreateCannedUpload__EmptyState}>
          <img src={PlaceholderImage} />
          <p>Maximum file size should not exceed 16MB</p>
        </div> :
        <div className={styles.CreateCannedUpload__Attachments}>
          <SortableContainer onSortEnd={onSortEnd} useDragHandle>
            {attachmentList.map((value, index) => (
              <SortableItem key={`item-${value?.lastModified}`} index={index} value={value} />
            ))}
          </SortableContainer>
        </div>
      }

    </div>
  );
}

CreateCannedUpload.propTypes = {};

CreateCannedUpload.defaultProps = {};

export default CreateCannedUpload;
