import React from "react";
import PropTypes from "prop-types";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import styles from "./CannedTemplateVariables.module.scss";
import Select from "react-select";
import { Tag } from "antd";
const CannedTemplateVariables = ({ insertTextAtPlace }) => {
  const options = [
    { value: "Common", label: "Common" }
  ];

  const insertWord = (e) => {
    const label = e?.target?.innerText;
    insertTextAtPlace(label);
  }
  return (
    <div className={styles.CannedTemplateVariables}>
      <div className={styles.CannedTemplateVariables__Header}>
        <div className={styles.CannedTemplateVariables__Header__Title}>
          Variables <QuestionCircleOutlined />
        </div>
        <div style={{ width: "122px", height: "32px", marginBottom: "16px" }}>
          <Select
            options={options}
            defaultValue={{ label: "Common", value: "Common" }}
            styles={{
              indicatorSeparator: () => ({ display: "none" }),
            }}
            isDisabled={true}
          />
        </div>
      </div>
      <div className={styles.CannedTemplateVariables__Container}>
        <Tag onClick={insertWord}>{"{{name}}"}</Tag>
        <Tag onClick={insertWord}>{"{{operator name}}"}</Tag>
      </div>
    </div>
  );
}

CannedTemplateVariables.propTypes = {};

CannedTemplateVariables.defaultProps = {};

export default CannedTemplateVariables;
