import React, {
  useRef,
  useState,
} from "react";

import {
  Button,
  Input,
  message,
  Modal,
} from "antd";
import { CirclePicker } from "react-color";

import {
  DeleteOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";

import { deleteLabel } from "src/api/LabelApi";
import styles from "./EditTag.module.scss";
import { getActiveOrgId } from "src/store/slices/userSlice";
import { useSelector } from "react-redux";

const EditTag = ({
  reftechData,
  allRefetch,
  tags,
  itemId,
  handleInputChangeState,
  handleDelete,
  handleColorChange,
}) => {
  const organizationId = useSelector(getActiveOrgId);
  const [blockPickerColor, setBlockPickerColor] = useState("");
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);
  const divRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const showMoreModal = () => {
    setIsModalOpen(true);
  };
  const handleMoreOk = () => {
    setIsModalOpen(false);
  };
  const handleMoreCancel = () => {
    handleInputChangeState(inputValue, itemId);
    setIsModalOpen(false);
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    let body = {
      id: itemId,
      organizationId: organizationId,
    };
    deleteLabel(body)
      ?.then(() => {
        message.success("Label deleted successfully");
        handleCancel();
        if(typeof allRefetch === "function"){
          allRefetch();
        } else {
          reftechData();
        }
      })
      .catch((e) => {
        message.error(e?.response?.data?.message[0]);
        handleCancel();
        if(typeof allRefetch === "function"){
          allRefetch();
        } else {
          reftechData();
        }
      });
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    setInputValue(e.target.value);
  };

  const handleOnOpen = (e) => {
    const selectElement = divRef.current;
    if (selectElement) {
      const selectRect = selectElement.getBoundingClientRect();
      const { top, left } = selectRect;
      setModalPosition({ left: left-300, top: top -150 });   
    }
    let text = tags?.filter((item) => item.id === itemId)[0]?.label;
    let color = tags?.filter((item) => item.id === itemId)[0]?.label;
    setInputValue(text);
    setBlockPickerColor(color);
    showMoreModal();
  };


  return (
    <div className={styles.EditTag} ref={divRef}>
      <div onClick={(e) => handleOnOpen(e)}>
        <EllipsisOutlined />
      </div>

      <Modal
        mask={false}
        width={250}
        open={isModalOpen}
        onOk={handleMoreOk}
        onCancel={handleMoreCancel}
        footer={null}
        style={{ position: "absolute", ...modalPosition }}
        className={styles.EditTag__modal}
      >
        <div className={styles.EditTag__picker}>
          <Input
            ref={inputRef}
            onChange={handleInputChange}
            value={inputValue}
            placeholder="Tag value"
            className={styles.EditTag__picker__input}
          />
          <div className={styles.EditTag__picker__delete} onClick={showModal}>
            <DeleteOutlined />
            <span>Delete</span>
          </div>
          <div className={styles.EditTag__picker__picker}>
            <span>Pick a Color</span>
            <CirclePicker
              width="100%"
              color={blockPickerColor}
              onChange={(color) => {
                handleColorChange(color.hex, itemId);
                setBlockPickerColor(color.hex);
              }}
            />
          </div>
        </div>
      </Modal>

      <Modal
        open={open}
        title="Delete"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="Cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="Delete" type="primary" onClick={handleOk}>
            Delete
          </Button>,
        ]}
      >
        Are you sure you want to delete this option?
      </Modal>
    </div>
  );
};

export default EditTag;
