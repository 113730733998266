import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";

import { useFormik } from "formik";
import { Alert, Button, Col, Form, Input, Row } from "antd";
import { LoginOutlined } from "@ant-design/icons";
import { signinApiHeaders } from "../../helpers/constant";

import * as Yup from "yup";

import styles from "./SignupForm.module.scss";
import "antd/dist/reset.css";
import "./SignupForm.css";
import { useDispatch } from "react-redux";
import { setAuthdata } from "src/store/slices/authSlice";
import getConfig from "src/helpers/config";
import { getTokenBySignup, getTokenBySignupInvitation } from "src/api/AuthApi";

const { REACT_APP_AUTHHANDLER_URL, REACT_APP_BACKEND_URL } = getConfig();

const SignupForm = ({ background, textColor, label }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { invitationId } = useParams();

  const [loading, setLoading] = useState(false);
  const [isAuthenticate, setAuthenticationStatus] = useState({
    status: false,
    message: "",
  });

  const SIGNUP_API = !invitationId
    ? `${REACT_APP_AUTHHANDLER_URL}/staff`
    : `${REACT_APP_BACKEND_URL}/staff/signup-with-invitation`;

  const submitSignupForm = async (body) => {
    let res = {};

    setLoading(true);
    if (invitationId) {
      body = { ...body, invitationId };
      res = await getTokenBySignupInvitation(body);
    } else {
      res = await getTokenBySignup(body);
    }
    setLoading(false);

    if (!res?.data?.success) {
      setAuthenticationStatus({
        status: false,
        message: res?.data?.error || "Unknown network error",
      });
    } else {
      let { access_token, refresh_token } = res.data;
      dispatch(setAuthdata({ access_token, refresh_token }));
      navigate("/");
    }
  };

  const handleValidationStatus = (key) => {
    return !touched[key] && !errors[key]
      ? ""
      : touched[key] && !errors[key]
        ? "success"
        : touched[key] && errors[key]
          ? "error"
          : "";
  };

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
      },
      validationSchema: Yup.object().shape({
        firstName: Yup.string()
          .matches(/^[\p{L}][[ \p{L}0-9]*$/u, "Invalid First Name")
          .required("First name required"),
        lastName: Yup.string().matches(
          /^[\p{L}0-9][[ \p{L}0-9]*$/u,
          "Invalid Last Name"
        ),
        email: Yup.string()
          .email("Invalid email address format")
          .required("Email required"),
        password: Yup.string()
          .matches(
            /^(?=.*[A-Za-z])(?=.*[@$!%*#?&])[A-Za-z0-9@$!%*#?&]{6,}$/,
            "Password should be atleast 6 characters long and should contain a special symbol!"
          )
          .required("Password required"),
      }),
      onSubmit: submitSignupForm,
    });

  return (
    <div className={styles.SignupForm}>
      <div className={styles.SignupForm__signUpContainer}>
        <h3>Create account</h3>
        <div className={styles.SignupForm__signUpContainer__helpMsg}>
          Already have an account?{" "}
          <a onClick={() => navigate("/login")}>Login</a>
        </div>
        <form onSubmit={handleSubmit}>
          {/*Full name section*/}
          <Row
            className={styles.SignupForm__signUpContainer__name}
            gutter={[16]}
          >
            <Col span={12}>
              <Form.Item
                className={`${styles.SignupForm__signUpContainer__formItem} signUp__form`}
                label={
                  <span className={styles.SignupForm__InputLabel}>
                    First name
                  </span>
                }
                name="firstName"
                validateStatus={handleValidationStatus("firstName")}
                hasFeedback
              >
                <Input
                  id="firstName"
                  name="firstName"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  placeholder="input first name"
                />
                {touched.firstName && errors.firstName ? (
                  <div
                    role="alert"
                    className={`${styles.SignupForm__signUpContainer__formItem__error} signUp__form`}
                  >
                    {errors.firstName}
                  </div>
                ) : null}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className={`${styles.SignupForm__signUpContainer__formItem} signUp__form`}
                label={
                  <span className={styles.SignupForm__InputLabel}>
                    Last name
                  </span>
                }
                name="lastName"
                hasFeedback
                validateStatus={handleValidationStatus("lastName")}
              >
                <Input
                  id="lastName"
                  name="lastName"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  placeholder="input last name"
                />
                {touched.lastName && errors.lastName ? (
                  <div
                    role="alert"
                    className={`${styles.SignupForm__signUpContainer__formItem__error} signUp__form`}
                  >
                    {errors.lastName}
                  </div>
                ) : null}
              </Form.Item>
            </Col>
          </Row>
          {/*Sub-component:-Email*/}
          <Form.Item
            className={`${styles.SignupForm__signUpContainer__formItem} signUp__form`}
            label={
              <span className={styles.SignupForm__InputLabel}>
                Email address
              </span>
            }
            name="email"
            validateStatus={handleValidationStatus("email")}
            hasFeedback
          >
            <Input
              id="email"
              name="email"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              placeholder="example@gmail.com"
            />
            {touched.email && errors.email ? (
              <div
                role="alert"
                className={styles.SignupForm__signUpContainer__formItem__error}
              >
                {errors.email}
              </div>
            ) : null}
          </Form.Item>

          {/*Sub-component:-Password*/}
          <Form.Item
            className={`${styles.SignupForm__signUpContainer__formItem} signUp__form`}
            label={
              <span className={styles.SignupForm__InputLabel}>
                Create a password
              </span>
            }
            name="password"
            validateStatus={handleValidationStatus("password")}
            hasFeedback
          >
            <Input.Password
              id="password"
              name="password"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              placeholder="Enter password"
            />
            {touched.password && errors.password ? (
              <div
                role="alert"
                className={styles.SignupForm__signUpContainer__formItem__error}
              >
                {errors.password}
              </div>
            ) : null}
          </Form.Item>
          <Form.Item
            className={`${styles.SignupForm__signUpContainer__formItem} signUp__form`}
          >
            {/*Sub-component:-Accept Invite Button*/}
            <div
              className={
                styles.SignupForm__signUpContainer__formItem__signUpBtnContainer
              }
            >
              <Button
                data-testid="sign-up-btn"
                style={{ background: background, color: textColor }}
                loading={loading}
                htmlType="submit"
                icon={<LoginOutlined />}
              >
                {invitationId ? label : "Create account"}
              </Button>
            </div>
          </Form.Item>
          {isAuthenticate.message?.length ? (
            <div className={styles.alert}>
              <Alert
                message="Error"
                description={isAuthenticate.message}
                className={styles.SignupForm__signUpContainer__errorMsg}
                type="error"
                showIcon
              />
            </div>
          ) : null}
        </form>
        <footer className={styles.SignupForm__footer} data-testid="footer">
          Need help? <span>Feel free to contact us</span>&nbsp;
          <a
            href="https://calendly.com/hybrid-chat/15min"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact Us!
          </a>
        </footer>
      </div>
    </div>
  );
};
SignupForm.propTypes = {
  background: PropTypes.string,
  textColor: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
};

SignupForm.defaultProps = {
  background: "rgba(9, 109, 217, 1)",
  textColor: "white",
  label: "Accept Invite",
  size: "medium",
};

export default SignupForm;
