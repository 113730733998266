import React from "react";
import propTypes from "prop-types";
import styles from "./Profile.module.scss";
import "antd/dist/reset.css";
// import { useDispatch } from "react-redux";
import { Row, Col, Button, Form, Typography } from "antd";
import { PageHeader } from "@ant-design/pro-components";

import { useNavigate } from "react-router-dom";
// import InvitePeopleModal from "../../../../invitepeoplemodal";
import {
  // CameraOutlined,
  UserOutlined,
  PhoneOutlined,
  MailOutlined,
  LaptopOutlined,
  // EditOutlined,
  // UserAddOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

import ProfilePicPlaceholder from "../ProfilePicPlaceholder/ProfilePicPlaceholder";
import { useTranslation } from "react-i18next";
import { resetAuth } from "src/store/slices/authSlice";
import { resetChannels } from "src/store/slices/channelSlice";
import { resetPeople } from "src/store/slices/peopleSlice";
import { resetUser } from "src/store/slices/userSlice";
import { useDispatch } from "react-redux";
import featureFlag from "src/helpers/featureFlag";
import { resetTimer } from "src/store/slices/timerSlice";
const { Paragraph } = Typography;

const Loader = () => {
  return (
    <span
      className={`ant-typography ${styles.Profile__center__description__content__loading_layout}`}
    >
      Loading...
    </span>
  );
};
const Profile = () => {
  const { t } = useTranslation();
  const { id, name, email, phone, role: designation } = {};
  // const [activeOrg, setActiveOrg] = React.useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSignOut = () => {
    dispatch(resetAuth());
    dispatch(resetChannels());
    dispatch(resetPeople());
    dispatch(resetUser());
    dispatch(resetTimer());
    navigate("/");
  };

  const shouldNavigateTo = featureFlag.ChatBar ? "/chat" : "/node";

  return (
    <div className={styles.Profile}>
      <div className={styles.site_page_header_ghost_wrapper}>
        <PageHeader
          ghost={false}
          onBack={() => navigate(shouldNavigateTo)}
          title={t("Profile")}
          extra={[
            // <InvitePeopleModal
            //   triggerElement={
            //     activeOrg?.roleName === "admin" ?
            //       <Button
            //         key="invite"
            //         type="primary"
            //         icon={<UserAddOutlined />}
            //         size="large"
            //       >
            //         Invite People
            //       </Button> : ""
            //   }
            //   uri={backendUri}
            //   organizationname={organizationname}
            //   organizationId={
            //     activeOrg !==null ? activeOrg.organization.id : null
            //   }
            // />
            // ,

            // />
            <Button
              onClick={handleSignOut}
              key="logout"
              icon={<LogoutOutlined />}
              size="large"
            >
              {t("Logout")}
            </Button>,
          ]}
        ></PageHeader>
      </div>
      <Row className={styles.Profile__center}>
        <Col span={24}>
          <Col span={24}>
            <Row>
              <Col className={styles.Profile__center__pic} span={8}>
                <div className={styles.Profile__center__pic__items}>
                  {/* <img src={props.image} alt="user-img" /> */}
                  <ProfilePicPlaceholder id={id} name={name} />
                  {/* <div>
                    <Button
                      className={styles.Profile__center__pic__items__uploadImg}
                      icon={<CameraOutlined />}
                      size="large"
                      shape="circle"
                    ></Button>
                  </div> */}
                </div>
              </Col>

              <Col className={styles.Profile__center__description} span={16}>
                <Form
                  name="basic"
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                >
                  <div className={styles.Profile__center__description__icon}>
                    <UserOutlined
                      className={
                        styles.Profile__center__description__icon__fixed
                      }
                    />
                    <Form.Item
                      className={styles.Profile__center__description__form}
                      label={t("Name")}
                      name="username"
                      colon={false}
                    >
                      {name ? (
                        <Paragraph
                          className={
                            styles.Profile__center__description__content
                          }
                          // editable={{
                          //   onChange: (value) =>
                          //     handleProfileEdit("name", value),
                          //   icon: (
                          //     <EditOutlined
                          //       className={
                          //         styles.Profile__center__description__icon__edit
                          //       }
                          //     />
                          //   ),
                          // }}
                        >
                          {name}
                        </Paragraph>
                      ) : (
                        <Loader />
                      )}
                    </Form.Item>
                  </div>
                  {/******************Email-section**************************/}
                  <div className={styles.Profile__center__description__icon}>
                    <MailOutlined
                      className={
                        styles.Profile__center__description__icon__fixed
                      }
                    />
                    <Form.Item
                      className={styles.Profile__center__description__form}
                      label={t("Email")}
                      colon={false}
                    >
                      {email ? (
                        <Paragraph
                          className={
                            styles.Profile__center__description__content
                          }
                          // editable={{
                          //   onChange: (value) =>
                          //     handleProfileEdit("email", value),
                          //   icon: (
                          //     <EditOutlined
                          //       className={
                          //         styles.Profile__center__description__icon__edit
                          //       }
                          //     />
                          //   ),
                          // }}
                        >
                          {email}
                        </Paragraph>
                      ) : (
                        <Loader />
                      )}
                    </Form.Item>
                  </div>
                  {/*********************phone no. section***********************/}
                  <div className={styles.Profile__center__description__icon}>
                    <PhoneOutlined
                      className={
                        styles.Profile__center__description__icon__fixed
                      }
                    />
                    <Form.Item
                      className={styles.Profile__center__description__form}
                      label={t("Phone no.")}
                      colon={false}
                    >
                      {phone?.length >= 0 ? (
                        <Paragraph
                          className={
                            styles.Profile__center__description__content
                          }
                          // editable={{
                          //   onChange: (value) =>
                          //     handleProfileEdit("phone", value),
                          //   icon: (
                          //     <EditOutlined
                          //       className={
                          //         styles.Profile__center__description__icon__edit
                          //       }
                          //     />
                          //   ),
                          // }}
                        >
                          {phone}
                        </Paragraph>
                      ) : (
                        <Loader />
                      )}
                    </Form.Item>
                  </div>
                  {/*****************************Designation section**************************************/}
                  <div className={styles.Profile__center__description__icon}>
                    <LaptopOutlined
                      className={
                        styles.Profile__center__description__icon__fixed
                      }
                    />
                    <Form.Item
                      className={styles.Profile__center__description__form}
                      label={t("Designation")}
                      colon={false}
                    >
                      {designation ? (
                        <Paragraph
                          className={
                            styles.Profile__center__description__content
                          }
                        >
                          {designation}
                        </Paragraph>
                      ) : (
                        <Loader />
                      )}
                    </Form.Item>
                  </div>
                </Form>
              </Col>
            </Row>
          </Col>
        </Col>
      </Row>
    </div>
  );
};

Profile.propTypes = {
  /*used to set the username*/
  profileData: propTypes.shape({
    image: propTypes.string,
    name: propTypes.string,
    email: propTypes.string,
    phone: propTypes.string,
    role: propTypes.string,
  }),
  /*used to set the email for the user*/
  handleProfileEdit: propTypes.func,
};

Profile.defaultProps = {
  profileData: {
    image: "",
    name: "",
    email: "",
    phone: "",
    role: "",
  },
  handleProfileEdit: () => {},
};

export default Profile;
