import React, { useEffect, useState } from "react";

import { Button, Dropdown, Modal, notification } from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  ArrowRightOutlined,
  BorderlessTableOutlined,
  CalendarOutlined,
  ExclamationCircleOutlined,
  FontSizeOutlined,
  GlobalOutlined,
  LinkOutlined,
  MailOutlined,
  MoreOutlined,
  PhoneOutlined,
  PlusOutlined,
  ReloadOutlined,
  TagsOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useQuery, useSubscription, useMutation } from "@apollo/client";


import { deleteContact, updateContact } from "src/api/ContactApi";
import { removeAttributeFromContact } from "src/api/ContactApi";
import ContactDrawerAccordion from "../ContactDrawerAccordion/ContactDrawerAccordion";
import ContactDrawerAttributeButton from "../ContactDrawerAttributeButton/ContactDrawerAttributeButton";
import CreateAttributeDrawer from "../CreateAttributeDrawer/CreateAttributeDrawer";
import DateAttributeInlineEditingField from "../DateAttributeInlineEditingField/DateAttributeInlineEditingField";
import MultiSelectInlineFields from "../MultiSelectInlineFields/MultiSelectInlineFields";
import NormalAttributeInlineEditingField from "../NormalAttributeInlineEditingField/NormalAttributeInlineEditingField";
import UserSelectInlineField from "../UserSelectInlineField/UserSelectInlineField";
import styles from "./ContactDrawer.module.scss";
import { GET_ORGANIZATION_ATTRIBUTES } from "src/graphql/organization";
import { GET_ASSIGNEES, GET_CONTACT_DETAILS_BY_ID, UPDATE_ASSIGNEES } from "src/graphql/contact";
import { getActiveOrgId } from "src/store/slices/userSlice";
import { ProfilePicPlaceholder } from "src/components/Profile/Profile";
import { checkFeatureAvailability } from "src/helpers/checkFeatureAvailability";
import { DrawerSorting } from "src/helpers/organizationFeatures";

const ContactDrawer = ({
  contactId,
  setVisiblilty,
  isVisible,
  t,
  // fetchUserName,
}) => {
  const organizationId = useSelector(getActiveOrgId);

  const [attributeDrawer, setAttributeDrawer] = useState(false);
  const [expandAttributeList, setExpandAttributeList] = useState(false);
  const [contactData, setContactData] = useState(null);
  const [multiSelectAttribute, setMultiSelectAttribute] = useState([]);
  const [dynamicAttributes, setDynamicAttributes] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [assigneeList, setAssigneeList] = useState()
  const [selectedAssignee, setSelectedAssignee] = useState()
  const [leadId, setLeadId] = useState('');
  const navigate = useNavigate();

  const orgUrl = useSelector((state) => state?.user?.data?.staffOrganization?.[0]?.organization?.domain || '');
  const attributeOrder = DrawerSorting[orgUrl] || []
  const isFeatureAvailable = checkFeatureAvailability("AIResponseFeedback", orgUrl)
  const [updateAssignee] = useMutation(UPDATE_ASSIGNEES) 


  useEffect(() => {
    const url = window.location.href;
    const extractedId = url.split('/').pop();
    setLeadId(extractedId);
  }, [location.pathname]);

  // Syncing assigneeList
  const { data: assigneeData, loading: assigneeLoading, error: assigneeError , refetch} = useQuery(GET_ASSIGNEES, {
    variables: { id: leadId },
    skip: !leadId,
  });

  useEffect(() => {
    if(!assigneeLoading && assigneeData && assigneeData?.queryHC_Lead){
      setAssigneeList(assigneeData?.queryHC_Lead[0]?.assignee)
    }
  }, [assigneeData, assigneeLoading])

  useEffect(() => {
    if (leadId) {
      refetch({ id: leadId });
    }
  }, [leadId, refetch]);

  const transformArray = (array) => {
    return array.map(item => {
      const [firstName, lastName] = item.agentName.split('-');
      return {
        id: item.agentId,
        firstName: firstName || '',
        lastName: lastName || ''
      };
    });
  };

  const handleUpdateAssignee = async (itemId, updatedItems, item)=>{
    const transformedArray = transformArray(updatedItems);
    setAssigneeList(transformedArray)
    try{
      await updateAssignee({
        variables: { id: leadId, assignees: transformedArray },
      });
      console.log("successfully updated")
    }catch (error) {
      console.error("error updating territory", error)
    }
  }


  const [name, setName] = useState(contactData?.username);
  const {
    data,
    loading,
    error,
    refetch: refetchContactDetails,
  } = useSubscription(GET_CONTACT_DETAILS_BY_ID, {
    variables: {
      organizationId: organizationId,
      contacts: [contactId],
    },
  });
  const [isShowAttribute, setIsShowAttribute] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const {
    data: organizationAttribute,
    loading: attributeLoading,
    error: attributeError,
    refetch: reftechData,
  } = useQuery(GET_ORGANIZATION_ATTRIBUTES, {
    variables: {
      organizationId: organizationId,
    },
  });
  const [attributeListData, setAttributeListData] = useState(undefined);
  const [allAttributes, setAllAttributes] = useState(undefined);
  const [filteredAttributes, setFilteredAttributes] = useState(undefined);
  const expandByLoadMore = () => {
    setExpandAttributeList(true);
  };

  useEffect(() => {
    setName(contactData?.username);
    // fetchUserName(contactData?.username);
  }, [contactData]);

  useEffect(() => {
    if (!attributeListData || !allAttributes) return;
    let filteredAttributesData = [];
    //attributeListData
    allAttributes.map((tx) => {
      let index = attributeListData.findIndex(
        (t) => t.__typename === tx.__typename && t.id === tx.id
      );
      if (index === -1) {
        filteredAttributesData.push(tx);
      }
    });
    setFilteredAttributes(filteredAttributesData);
  }, [attributeListData, allAttributes]);
  const getFieldValue = (contact_data, field) => {
    if (contact_data?.getOrganization?.leads.length === 0) {
      return;
    }
    const contact = contact_data?.getOrganization?.leads[0];
    if (field.__typename === "NormalAttribute") {
      const field_values = contact?.normalAttributeValue?.filter(
        (tx) => field?.id === tx?.normalAttribute?.id
      );
      let values = [];
      field_values.map((tx) => {
        values.push({
          id: tx?.id,
          value: tx?.value,
        });
      });
      return values;
    } else if (field.__typename === "UserAttribute") {
      const field_values = contact?.userAttributeValue?.filter(
        (tx) => field?.id === tx?.userAttribute?.id
      );
      const extracted_fields = field_values.map((tx) => ({
        ...tx?.value[0],
        label: tx?.value[0].id,
        value: tx?.value[0].id,
      }));
      let values = field_values.length === 0 ? [] : extracted_fields;
      return values;
    } else if (field.__typename === "MultiSelectAttribute") {
      const field_values = contact?.multiSelectAttributeValue?.filter(
        (tx) => field?.id === tx?.multiSelectAttribute?.id
      );
      let values = [];

      field_values.map((tx) => {
        values.push({
          id: tx?.label.id,
          value: tx?.label?.name,
          color: tx?.label?.color,
          valueId: tx?.id,
        });
      });
      return values;
    }
  };
  const fillDefaultAttribute = (attribute, attributeList, data) => {
    attribute
      .filter(
        (t) =>
          t.attributeType === "DefaultOutsideDrawer" ||
          t.attributeType === "Default"
      )
      .map((tx) => {
        const values = getFieldValue(data, tx);
        const attrObj = { ...tx, values: values };
        attributeList.push(attrObj);
      });
    return attributeList;
  };
  useEffect(() => {
    if (!organizationAttribute || !data) {
      return;
    }
    let currentListData = [];
    let allAttributesData = [];
    const organization = organizationAttribute?.getOrganization;
    if (organization) {
      if (organization.normalAttribute) {
        allAttributesData.push(...organization.normalAttribute);
      }
      if (organization.userAttribute) {
        allAttributesData.push(...organization.userAttribute);
      }
      if (organization.multiSelectAttribute) {
        allAttributesData.push(...organization.multiSelectAttribute);
      }
}
    setAllAttributes(allAttributesData);
    currentListData = fillDefaultAttribute(
      organizationAttribute?.getOrganization?.normalAttribute,
      currentListData,
      data
    );
    currentListData = fillDefaultAttribute(
      organizationAttribute?.getOrganization?.userAttribute,
      currentListData,
      data
    );
    currentListData = fillDefaultAttribute(
      organizationAttribute?.getOrganization?.multiSelectAttribute,
      currentListData,
      data
    );
    if (data?.getOrganization?.leads.length === 0) {
      return;
    }
    const contact = data?.getOrganization?.leads[0];

    setContactData(contact);
    contact?.normalAttributeValue?.map((tx) => {
      const attributeId = tx?.normalAttribute?.id;
      const foundIndex = currentListData.findIndex(
        (tx) => tx.id === attributeId
      );
      if (foundIndex === -1) {
        //attribute does not exist in list
        const attrObject = {
          __typename: "NormalAttribute",
          ...tx?.normalAttribute,
          values: [
            {
              id: tx?.id,
              value: tx?.value,
            },
          ],
        };
        currentListData.push(attrObject);
      } else {
        //attribute already exist
        const isValueExist = currentListData[foundIndex].values.findIndex(
          (t) => t.value === tx.value
        );
        if (isValueExist === -1) {
          currentListData[foundIndex].values.push({
            id: tx?.id,
            value: tx?.value,
          });
        }
      }
    });
    contact?.userAttributeValue?.map((tx) => {
      const attributeId = tx?.userAttribute?.id;
      const foundIndex = currentListData.findIndex(
        (tx) => tx.id === attributeId
      );
      if (foundIndex === -1) {
        //attribute does not exist in list
        const attrObject = {
          __typename: "UserAttribute",
          ...tx?.userAttribute,
          values: tx?.value,
        };
        currentListData.push(attrObject);
      }
    });
    contact?.multiSelectAttributeValue?.map((tx) => {
      const attributeId = tx?.multiSelectAttribute?.id;
      const foundIndex = currentListData.findIndex(
        (tx) => tx.id === attributeId
      );
      if (foundIndex === -1) {
        //attribute does not exist in list
        const attrObject = {
          __typename: "MultiSelectAttribute",
          ...tx?.multiSelectAttribute,
          values: [
            {
              valueid: tx?.id,
              id: tx?.label?.id,
              value: tx?.label?.name,
              color: tx?.label?.color,
            },
          ],
        };
        currentListData.push(attrObject);
      } else {
        //attribute already exist
        const isValueExist = currentListData[foundIndex].values.findIndex(
          (t) => t?.id === tx?.label?.id
        );
        if (isValueExist === -1) {
          currentListData[foundIndex].values.push({
            valueid: tx?.id,
            id: tx?.label?.id,
            value: tx?.label?.name,
            color: tx?.label?.color,
          });
        }
      }
    });

    let filteredAttributesData = [];

    allAttributesData.map((tx) => {
      let index = currentListData.findIndex(
        (t) => t.__typename === tx.__typename && t.id === tx.id
      );
      if (index === -1) {
        filteredAttributesData.push({ ...tx, values: [] });
      }
    });

    setAttributeListData([...currentListData, ...filteredAttributesData]);


    // filteredAttributes.map((tx) => {
    //     addAttribute(tx?.id, tx?.type, tx, tx.__typename)
    // })
  }, [organizationAttribute, data]);
  // useEffect(() => {
  //   if (attributeListData?.length !== 0 && filteredAttributes?.length !== 0) {
  //     filteredAttributes?.forEach((tx) => {
  //       addAttribute(tx?.id, tx?.type, tx, tx.__typename);
  //     });
  //   }
  // }, [attributeListData, filteredAttributes]);

  const onDateSubmit = (val) => {
    const { id, name, value, valueId } = val;
    if (!id || !name) {
      return;
    }
    onSubmit([
      {
        id: valueId,
        name: name,
        value: value,
        attributeId: id,
        errorMessage: "",
        type: "Date",
      },
    ]);
  };
  const onSubmit = async (value) => {
    try {
      if (value.length === 0) {
        return;
      }
      const data = {
        normalAttribute: value,
      };
      let isValid = true;
      value.map((tx) => {
        if (tx?.errorMessage.trim() !== "") {
          isValid = false;
        }
      });
      if (!isValid) {
        return;
      }
      let res = await submitForm(data);
    } catch (err) {
      console.log(err);
      return;
    }
  };
  const onMultiSelectSubmit = async (value, id) => {
    try {
      let data = {};
      let attributeId;
      if (value.length === 0) {
        attributeId = id;
        data = {
          multiSelectAttribute: {
            attributeId: attributeId,
            multiSelectAttributeValue: [],
          },
        };
      } else {
        attributeId = value[0].multiSelectAttribute?.id;
        data = {
          multiSelectAttribute: {
            attributeId: attributeId,
            multiSelectAttributeValue: value,
          },
        };
      }
      let res = await submitForm(data);
    } catch (err) {
      return;
    }
  };
  const onMultiSelectDelete = (attributeType, labels, attributeId, label) => {
    try {
      if (!attributeType || !labels || !attributeId || !label) {
        return;
      }
      onDeleteForm(attributeType, attributeId, label);
    } catch (err) {
      return err;
    }
  };
  const onNormalDelete = (attributeType, attributeId, label) => {
    try {
      if (!attributeType || attributeId.length !== 1 || !label) {
        return;
      }
      onDeleteForm(attributeType, attributeId[0], label);
    } catch (err) {
      return err;
    }
  };
  const onDeleteForm = async (attributeType, attributeId, label) => {
    if (!attributeType || !attributeId || !contactId || !label) {
      return;
    }
    const data = {
      attributeType: attributeType,
      attributeId: attributeId,
      contactId: contactId,
      attributeName: label,
    };
    const res = await removeAttributeFromContact(data);
  };

  // useEffect(()=>{
  //   fetchUserName(name)
  // },[contactId,name])

  const submitForm = async (data) => {
    try {
      let res = await updateContact(data, contactId);
      if (!res?.data?.success) {
        throw new Error("Encountered error while updating contact");
      }
    } catch (err) {
      notification.error({
        message: <b>Notification</b>,
        description: <b>Encountered server error while updating</b>,
      });
      return;
    }
  };
  const returnTextAttribute = (
    attributeId,
    label,
    value,
    attributeType,
    type
  ) => {
    if (!label || !attributeId || !attributeType) {
      return <></>;
    }
    return (
      <NormalAttributeInlineEditingField
        attributeId={attributeId}
        label={label}
        initialValue={value}
        attributeType={attributeType}
        onSubmit={onSubmit}
        onDeleteForm={onNormalDelete}
        isDefault={type === "Default" || type === "DefaultOutsideDrawer"}
      />
      // <div className={styles.ContactDrawer__DataField}>
      //   <div className={styles.ContactDrawer__DataField__Label}>{label}</div>
      //   <div className={styles.ContactDrawer__DataField__Value}>{value}</div>
      // </div>
    );
  };
  const returnDateAttribute = (attributeId, label, value, tx) => {
    if (!label || !attributeId) {
      return <></>;
    }
    const val = tx?.values[0];

    return (
      <DateAttributeInlineEditingField
        attributeId={attributeId}
        label={label}
        valueId={val?.id}
        initialValue={val?.value}
        onSubmit={onDateSubmit}
        t={t}
        onDeleteForm={onNormalDelete}
        isEdit={true}
      />
      // <div className={styles.ContactDrawer__DataField}>
      //   <div className={styles.ContactDrawer__DataField__Label}>{label}</div>
      //   <div className={styles.ContactDrawer__DataField__Value}>{value}</div>
      // </div>
    );
  };
  const returnMultiSelectAttribute = (id, label, tags, attributeType) => {
    if (!label || !Array.isArray(tags) || checkFeatureAvailability("contactTags",orgUrl)) {
      return <></>;
    }
    return (
      <MultiSelectInlineFields
        attributeId={id}
        label={label}
        tags={tags}
        t={t}
        onMultiSelectSubmit={onMultiSelectSubmit}
        onDeleteForm={onMultiSelectDelete}
        isEdit={true}
        isDefault={
          attributeType === "Default" ||
          attributeType === "DefaultOutsideDrawer"
        }
        reftechData={reftechData}
      />
      // <div className={styles.ContactDrawer__DataField}>
      //   <div className={styles.ContactDrawer__DataField__Label}>{label}</div>
      //   <div className={`${styles.ContactDrawer__DataField__Value} ${styles.ContactDrawer__DataField__TagsContainer}`}>
      //     {tags.map((tx) => <span className={styles.ContactDrawer__DataField__Tags}>{tx}</span>)}
      //   </div>
      // </div>
    );
  };
  let attributeData = [
    "Delivery Address",
    "Email",
    "Contact",
    "Whatsapp",
    "ID",
  ];

  const returnIcon = (scope, type) => {
    switch (type) {
    case "Text":
      return <FontSizeOutlined className={styles.ContactDrawer__icons} />;
    case "Numbers":
      return (
        <BorderlessTableOutlined className={styles.ContactDrawer__icons} />
      );
    case "MultiSelect":
      return <TagsOutlined className={styles.ContactDrawer__icons} />;
    case "Status":
      return <ReloadOutlined className={styles.ContactDrawer__icons} />;
    case "Date":
      return <CalendarOutlined className={styles.ContactDrawer__icons} />;
    case "User":
      return <UserOutlined className={styles.ContactDrawer__icons} />;
    case "PhoneNumber":
      return <PhoneOutlined className={styles.ContactDrawer__icons} />;
    case "EmailAddress":
      return <MailOutlined className={styles.ContactDrawer__icons} />;
    case "SocialMedia":
      return <GlobalOutlined className={styles.ContactDrawer__icons} />;
    case "URL":
      return <LinkOutlined className={styles.ContactDrawer__icons} />;
    default:
      return null;
    }
  };

  const content = (
    <div className={styles.PopoverContent}>
      <ContactDrawerAttributeButton
        setAttributeDrawer={() => {
          setAttributeDrawer(true);
        }}
      />

      <div
        className={`${styles.ContactDrawer__AttributeList} ${
          expandAttributeList === true
            ? styles.ContactDrawer__AttributeList__Expand
            : ""
        }`}
      >
        {filteredAttributes === undefined ? (
          <></>
        ) : filteredAttributes.length === 0 ? (
          <div className={styles.ContactDrawer__Placeholder}>
            No Attributes are available for the contact
          </div>
        ) : (
          filteredAttributes.map((tx) => {
            return (
              <div
                onClick={() =>
                  addAttribute(tx?.id, tx?.type, tx, tx.__typename)
                }
                className={styles.ContactDrawer__AttributeList__Tag}
              >
                {returnIcon(tx?.scope, tx?.type)}
                {tx?.name}
              </div>
            );
          })
        )}
      </div>

      {!expandAttributeList &&
        filteredAttributes !== undefined &&
        filteredAttributes.length > 4 && (
        <div className={styles.ContactDrawer__AttributeList__Footer}>
          <span onClick={expandByLoadMore}>Load more</span>
        </div>
      )}
    </div>
  );

  const hideDrawer = () => {
    setVisiblilty();
  };

  const getPlaceholder = (type) => {
    switch (type) {
    case "Text":
      return "Demo Text";
    case "Numbers":
      return "123";
    case "MultiSelect":
      return "label1";
    case "Date":
      return moment(Date.now()).format("MMMM DD, YYYY");
    case "PhoneNumber":
      return "9876541302";
    case "EmailAddress":
      return "user@gmail.com";
    case "SocialMedia":
      return "user_name";
    case "URL":
      return "https://google.com";
    default:
      return null;
    }
  };

  const addAttribute = async (id, type, attribute, typename) => {
    try {
      if (typename === "NormalAttribute") {
        if (!id || !type || !contactId || !organizationId) {
          return;
        }
        let currentAttributeList = attributeListData;
        currentAttributeList.push({ ...attribute, values: [] });
        setAttributeListData([...currentAttributeList]);
      } else if (
        typename === "MultiSelectAttribute" &&
        attributeListData !== undefined
      ) {
        let currentAttributeList = attributeListData;
        currentAttributeList.push({ ...attribute, values: [] });
        setAttributeListData([...currentAttributeList]);
      } else if (
        typename === "UserAttribute" &&
        attributeListData !== undefined
      ) {
        let currentAttributeList = attributeListData;
        currentAttributeList.push({ ...attribute, values: [] });
        setAttributeListData([...currentAttributeList]);
      }
    } catch (err) {
      notification.error({
        message: <b>Notification</b>,
        description: <b>{err.message}</b>,
      });
      return;
    }
  };
  const handleUserChange = async (id, agent, fieldInfo) => {
    const { name } = fieldInfo;
    if (!name) {
      return;
    }
    let payload = {
      userAttribute: {
        attributeId: id,
        name: name,
        value: agent,
      },
    };
    let res = await submitForm(payload);
  };
  const onUserDelete = (item) => {
    if (!item) {
      return;
    }
    const { id, name } = item;
    if (!id || !name) {
      return;
    }
    onDeleteForm("UserAttributeValue", id, name);
  };

  const returnUserAttribute = () =>{
    const userSchema = {
      attributeType: "DefaultOutsideDrawer",
      options: assigneeList,
      value: assigneeList,
    };
    return (
      <UserSelectInlineField
        t={t}
        handleUserChange={handleUpdateAssignee}
        item={userSchema}
        isDefault={true}
        onDelete={handleUpdateAssignee}
      />
    )
  }

  const updateUsername = async (val) => {
    if (val.trim() === "") {
      return;
    }
    const data = {
      username: val,
    };
    let res = await submitForm(data);
  };

  const handleAddAttribute = (val) => {
    setIsShowAttribute(val);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.target.blur(); // Trigger the onBlur event
    }
  };

  const items = [
    {
      label: (
        <span
          onClick={() => {
            setIsDeleteModalOpen(true);
          }}
        >
          Delete
        </span>
      ),
      key: "1",
      danger: true,
    },
  ];

  const deleteModalAPIRequest = async () => {
    try {
      let data = {
        contacts: [contactId],
        organizationId: organizationId,
      };
      const res = await deleteContact(data);
      if (res?.data?.success === true) {
        notification.success({
          message: <b>Notification</b>,
          description: <b>Contact deleted successfully!</b>,
        });
      } else {
        if (res?.data?.message) {
          notification.error({
            message: <b>Notification</b>,
            description: <b>{res?.data?.message}</b>,
          });
        } else {
          notification.error({
            message: <b>Notification</b>,
            description: <b>Internal Server Error!</b>,
          });
        }
      }
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, [3000]);
      setIsDeleteModalOpen(false);
      navigate("/contacts");
    } catch (err) {
      console.log(err);
      notification.error({
        message: <b>Notification</b>,
        description: <b>Internal Server Error!</b>,
      });
    }
  };

  return (
    <>
      {attributeDrawer === true && (
        <CreateAttributeDrawer
          showDrawer={attributeDrawer}
          setShowDrawer={setAttributeDrawer}
          refetchAttributes={() => {}}
          showContactDrawer={() => {}}
        />
      )}
      <div className={styles.ContactDrawer}>
        <div className={styles.ContactDrawer__Header}>
          <div className={styles.ContactDrawer__Header__Prefix}>
            {/* <ProfilePicPlaceholder
              name={
                contactData !== null && contactData !== undefined
                  ? contactData?.username
                  : ""
              }
              id={contactId}
              height="34px"
              width="34px"
              fontSize="13px"
            /> */}
            <p>Contact Details</p>
          </div>
          <div className={styles.ContactDrawer__Header__Body}>
            {/* {contactData !== null && contactData !== undefined && (
              <input
                value={name}
                className={styles.UsernameContainer__Input}
                type="text"
                onChange={handleNameChange}
                onBlur={() => updateUsername(name)}
                onKeyPress={handleKeyPress} // Add the key press event handler
              />
            )} */}
            {/* <p>Created on {contactData?.createdDate !== null && contactData?.createdDate !== undefined ? contactData?.createdDate : "23 Jan 2023"}</p> */}
          </div>
          <div className={styles.ContactDrawer__Header__Suffix}>
            <Dropdown menu={{ items }} trigger={["click"]}>
              <a onClick={(e) => e.preventDefault()}>
                <MoreOutlined style={{cursor:'pointer'}} />
              </a>
            </Dropdown>
          </div>
        </div>
        <div
          onClick={hideDrawer}
          className={styles.ContactDrawer__TriggerButton}
        >
          <ArrowRightOutlined  style={{ color: 'var(--secondary-color)' }} />
        </div>
        <div className={styles.ContactDrawer__Body}>
          {attributeListData
            ?.filter(
              (tx) =>
                tx?.attributeType === "DefaultOutsideDrawer" &&
                tx.__typename === "MultiSelectAttribute"
            )
            .map((tx) => {
              return returnMultiSelectAttribute(
                tx?.id,
                tx?.name,
                tx?.values,
                tx?.attributeType
              );
            })}
          {attributeListData
            ?.filter(
              (tx) =>
                tx?.attributeType === "DefaultOutsideDrawer" &&
                tx.__typename === "UserAttribute"
            )
            ?.map((tx) => {
              return returnUserAttribute(tx, tx?.attributeType);
            })}
          {attributeListData
            ?.filter(
              (tx) =>
                tx?.attributeType === "DefaultOutsideDrawer" &&
                tx.__typename === "NormalAttribute"
            )
            ?.map((tx) => {
              return returnTextAttribute(
                tx?.id,
                tx?.name,
                tx?.values,
                tx?.type,
                tx?.attributeType
              );
            })}

          <hr style={{ color: '#F2F2F2', backgroundColor: '#F2F2F2', height: 1,border:'none' }} />
          {contactData !== null && contactData !== undefined && (
            <div style = {{padding:'10px',marginTop:'4px'}}>

              <p style={{color:'#808080',fontSize:'14px',fontWeight:400,lineHeight:"22px"}}>Name</p>
                <input
                  value={name}
                  className={styles.UsernameContainer__Input}
                  type="text"
                  onChange={handleNameChange}
                  onBlur={() => updateUsername(name)}
                  onKeyPress={handleKeyPress} // Add the key press event handler
                />
            </div>
            )}
            {isFeatureAvailable && attributeOrder.length > 0 ? 
              <>
              {attributeListData?.sort((a, b) => {
                const aIndex = attributeOrder.indexOf(a?.name);
                const bIndex = attributeOrder.indexOf(b?.name);
                if (aIndex !== -1 && bIndex !== -1) {
                  return aIndex - bIndex;
                }
                if (aIndex !== -1) {
                  return -1; 
                }
                if (bIndex !== -1) {
                  return 1; 
                }
                return 0;
              })?.map((tx) => {
                return tx?.type === "Date"
                  ? returnDateAttribute(tx?.id, tx?.name, "", tx)
                  : returnTextAttribute(
                    tx?.id,
                    tx?.name,
                    tx?.values,
                    tx?.type,
                    tx?.attributeType
                  );
              })
            }
            </> :

            <>

            {attributeListData
              ?.filter(
                (tx) =>
                  tx?.attributeType === "Default" &&
                  tx.__typename === "MultiSelectAttribute"
              )
              .map((tx) => {
                return returnMultiSelectAttribute(
                  tx?.id,
                  tx?.name,
                  tx?.values,
                  tx?.attributeType
                );
              })}
            {/* {attributeListData
              ?.filter(
                (tx) =>
                  tx?.attributeType === "Default" &&
                  tx.__typename === "UserAttribute"
              )
              ?.map((tx) => {
                return returnUserAttribute(tx, tx?.attributeType);
              })} */}

            {attributeListData
              ?.filter(
                (tx) =>
                  tx?.attributeType === "Default" &&
                  tx.__typename === "NormalAttribute"
              )
              ?.map((tx) => {
                return tx?.type === "Date"
                  ? returnDateAttribute(tx?.id, tx?.name, "", tx)
                  : returnTextAttribute(
                    tx?.id,
                    tx?.name,
                    tx?.values,
                    tx?.type,
                    tx?.attributeType
                  );
              })}
            {attributeListData
              ?.filter(
                (tx) =>
                  tx?.attributeType === "Normal" &&
                  tx.__typename === "MultiSelectAttribute"
              )
              .map((tx) => {
                return returnMultiSelectAttribute(
                  tx?.id,
                  tx?.name,
                  tx?.values,
                  tx?.attributeType
                );
              })}
            {attributeListData
              ?.filter(
                (tx) =>
                  tx?.attributeType === "Normal" &&
                  tx.__typename === "UserAttribute"
              )
              ?.map((tx) => {
                return returnUserAttribute(tx, tx?.attributeType);
              })}
            {attributeListData
              ?.filter(
                (tx) =>
                  tx?.attributeType === "Normal" &&
                  tx.__typename === "NormalAttribute"
              )
              ?.map((tx) => {
                return tx?.type === "Date"
                  ? returnDateAttribute(tx?.id, tx?.name, "", tx)
                  : returnTextAttribute(
                    tx?.id,
                    tx?.name,
                    tx?.values,
                    tx?.type,
                    tx?.attributeType
                  );
              })}
              </>}
          {/* </ContactDrawerAccordion> */}
          <div className={styles.AddAttributeButtonContainer}>
            {/* <Popover content={content} title={null} trigger="click"> */}
            <Button
              className={styles.AddAttributeButton}
              type="dashed"
              onClick={() => {
                setAttributeDrawer(true);
              }}
            >
              <PlusOutlined /> New attribute
            </Button>
            {/* </Popover> */}
          </div>
        </div>
      </div>
      <Modal
        className={styles.DeleteModal__Modal}
        open={isDeleteModalOpen}
        footer={null}
        closable={false}
        header={false}
      >
        <div className={styles.DeleteModal}>
          <div className={styles.DeleteModal__Body}>
            <div className={styles.DeleteModal__Body__IconContainer}>
              <ExclamationCircleOutlined
                style={{ color: "#FF4D4F", fontSize: "21px" }}
              />
            </div>
            <div className={styles.DeleteModal__Body__ContentContainer}>
              <h5>Delete contact</h5>
              <p>
                The contact will be deleted permanently. Are you sure you want
                to delete?
              </p>
            </div>
          </div>
          <div className={styles.DeleteModal__Footer}>
            <Button
              onClick={() => {
                deleteModalAPIRequest();
              }}
              type="primary"
              delete
              danger
            >
              Delete
            </Button>
            <Button
              onClick={() => {
                setIsDeleteModalOpen(false);
              }}
              type="ghost"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

ContactDrawer.propTypes = {};

ContactDrawer.defaultProps = {};

export default ContactDrawer;
