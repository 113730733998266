import { WebTracerProvider } from "@opentelemetry/sdk-trace-web";
import { getWebAutoInstrumentations } from "@opentelemetry/auto-instrumentations-web";
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";
import { BatchSpanProcessor } from "@opentelemetry/sdk-trace-base";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { ZoneContextManager } from "@opentelemetry/context-zone";
import { Resource } from "@opentelemetry/resources";

const serviceName = "sc-hc-inbox-chat";
const exporter = new OTLPTraceExporter({
  url: "https://signoz-http.smarter.codes/v1/traces",
});
const provider = new WebTracerProvider({
  resource: new Resource({ "service.name": serviceName }),
});
const webTracerWithZone = provider.getTracer(serviceName);
let bindingSpan;

window.startBindingSpan = (traceId, spanId, traceFlags) => {
  bindingSpan = webTracerWithZone.startSpan("");
  bindingSpan.spanContext().traceId = traceId;
  bindingSpan.spanContext().spanId = spanId;
  bindingSpan.spanContext().traceFlags = traceFlags;
};

export function setupTracer() {
  provider.addSpanProcessor(new BatchSpanProcessor(exporter));
  provider.register({
    contextManager: new ZoneContextManager(),
  });

  registerInstrumentations({
    instrumentations: [
      getWebAutoInstrumentations({
        // load custom configuration for xml-http-request instrumentation
        "@opentelemetry/instrumentation-xml-http-request": {
          propagateTraceHeaderCorsUrls: [/.+/g],
        },
        // load custom configuration for fetch instrumentation
        "@opentelemetry/instrumentation-fetch": {
          propagateTraceHeaderCorsUrls: [
            //  /.+/g,
          ],
        },
      }),
    ],
  });
}
