import { gql } from "@apollo/client";

export const LEAD_SUBSCRIPTION = gql`
  subscription ($leadId: ID!) {
    getHC_Lead(id: $leadId) {
      id
      selectedChannelId
      selectedChannelType
      unRepliedCount
      threads {
        readBy
        currentStatus
      }
    }
  }
`;

export const GET_LEAD_DATA = gql`
  query ($leadId: [ID!]) {
    queryHC_Lead(filter: { id: $leadId }) {
      id
      username
    }
  }
`;

export const CURSOR_LOAD_LEADS = gql`
  query ($organizationId: ID!, $lastMessageTimestamp: String!, $pageSize: Int) {
    getOrganization(id: $organizationId) {
      leads(
        order: { desc: lastMessageTimestamp }
        first: $pageSize
        filter: { lastMessageTimestamp: { lt: $lastMessageTimestamp } }
      ) {
        id
        username
        phone
        uniqueId
        unRepliedCount
        lastMessageTimestamp
        threads {
          id
          readBy
          currentStatus
          messages(order: { desc: timestamp }, offset: 0, first: 1) {
            id
            from
            to
            timestamp
            body
            username
            channelId
          }
        }
      }
    }
  }
`;

export const GET_NUMBER_OF_LEADS = gql`
  query ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      leadsAggregate {
        count
      }
    }
  }
`;

export const GET_LEAD_ATTR_DATA = gql`
  query ($leadId: [ID!]) {
    queryHC_Lead(filter: { id: $leadId }) {
      id
      username
      normalAttributeValue {
        id
        value
        normalAttribute {
          id
        }
      }
      multiSelectAttributeValue {
        id
        multiSelectAttribute {
          id
          labels {
            id
            name
            color
          }
        }
      }
      userAttributeValue {
        id
        value {
          id
          username
        }
        userAttribute {
          id
        }
      }
    }
  }
`;

export const LOAD_LEAD = gql`
  subscription ($leadId: ID!) {
    getHC_Lead(id: $leadId) {
      id
      username
      phone
      uniqueId
      unRepliedCount
      lastMessageTimestamp
      selectedChannelId
      selectedChannelType
      vdiIdentifier
      createdDate
      threads {
        id
        readBy
        currentStatus
        messages(order: { desc: timestamp }, offset: 0, first: 1) {
          id
          from
          to
          timestamp
          body
          username
          channelId
        }
      }
    }
  }
`;

export const LOAD_CHANNELS_LEAD_DATA = gql`
  query {
    queryHC_Lead {
      id
      username
      selectedChannelId
      selectedChannelType
    }
  }
`;

export const GET_LEAD_ATTRIBUTES = gql`
  query ($leadId: [ID!]) {
    queryHC_Lead(filter: { id: $leadId }) {
      id
      username
      normalAttributeValue {
        value
        normalAttribute {
          name
        }
      }
    }
  }
`;

export const LOAD_LEADS = gql`
  subscription ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      leads(order: { desc: lastMessageTimestamp }, first: 25) {
        id
        username
        phone
        uniqueId
        unRepliedCount
        lastMessageTimestamp
        threads {
          id
          readBy
          currentStatus
          messages(order: { desc: timestamp }, offset: 0, first: 1) {
            id
            from
            to
            timestamp
            body
            username
            channelId
            type
          }
        }
        userAttributeValue {
          id
          value {
            id
            username
          }
          userAttribute {
            id
            name
            attributeType
          }
        }
      }
    }
  }
`;

export const LIST_LEADS = gql`
  query ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      leads(order: { desc: lastMessageTimestamp }, first: 15) {
        id
        username
      }
    }
  }
`;
