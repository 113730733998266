import React, { useEffect, useState } from "react";

import { Button, message, Modal, Upload } from "antd";
import Papa from "papaparse";
import { useSelector } from "react-redux";

import {
  CloseOutlined,
  InfoCircleFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import { useQuery } from "@apollo/client";

import { importContactAPI } from "src/api/ContactApi";
import SuccessIcon from "src/assets/Success.svg";
import { patterns } from "../../helpers/patterns.js";
import CsvTable from "../CSVTable/CSVTable";
import styles from "./ImportContact.module.scss";
import { GET_ORGANIZATION_ATTRIBUTES } from "src/graphql/organization";
import { GET_ORGANIZATION_ALL_LABELS } from "src/graphql/contact";
import { getActiveOrgId } from "src/store/slices/userSlice";

// import { CSVLink, CSVDownload } from "react-csv";
const ImportContact = ({
  showContactDrawer,
  setShowContactDrawer,
  refreshingData,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [tableData, setTableData] = useState({
    filename: "",
    columns: [],
    data: [],
  });
  const [validatedTableData, setValidatedTableData] = useState({
    filename: [],
    columns: [],
    data: [],
  });
  const [tableDataValidation, setTableDataValidation] = useState({
    totalContacts: 0,
    addedContacts: 0,
    failedContacts: 0,
    totalColumnsMapped: 0,
    skippedColumns: 0,
  });
  const [screenNumber, setScreenNumber] = useState(0);
  const modalWidth = [440, 1000];
  const organizationId = useSelector(getActiveOrgId);
  const [attributeList, setAttributeList] = useState(undefined);
  const [invalidContacts, setInvalidContacts] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const convertToCSV = (data) => {
    const csv = Papa.unparse(data);
    return csv;
  };
  const handleDownload = () => {
    let data = invalidContacts;
    const csvData = convertToCSV(data);
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const csvUrl = URL.createObjectURL(csvBlob);
    const link = document.createElement("a");
    link.href = csvUrl;
    link.download = "data.csv";
    link.click();
    URL.revokeObjectURL(csvUrl);
  };
  const {
    data: attributeData,
    loading: attributeLoading,
    error: attributeError,
  } = useQuery(GET_ORGANIZATION_ATTRIBUTES, {
    variables: {
      organizationId: organizationId,
    },
  });

  const { data: labelData } = useQuery(GET_ORGANIZATION_ALL_LABELS, {
    variables: {
      organizationId: organizationId,
    },
  });

  useEffect(() => {
    if (!attributeData) {
      return;
    }
    const currentAttributeList = [];
    attributeData?.getOrganization?.multiSelectAttribute?.map((tx) => {
      currentAttributeList.push({
        id: tx?.id,
        name: tx?.name,
        type: "multi-select",
      });
    });
    attributeData?.getOrganization?.normalAttribute?.map((tx) => {
      currentAttributeList.push({
        id: tx?.id,
        name: tx?.name,
        type: tx?.type,
      });
    });
    currentAttributeList.push({
      id: "username",
      name: "Title",
      type: "contactUsername",
    });
    setAttributeList(currentAttributeList);
  }, [attributeData]);

  const validateTableData = () => {
    if (
      attributeList === undefined ||
      (tableData.columns.length === 0 && tableData.data.length === 0)
    ) {
      return;
    }

    //removing attributes which are not in organization right now.
    const validateColumns = tableData.columns.filter((t) => {
      return attributeList.findIndex((tx) => tx.name === t?.Header) !== -1;
    });

    //cleaning data
    tableData.data.map((tx) => {
      for (let key in tx) {
        let isValidated = validateColumns.findIndex((t) => t.Header === key);
        if (isValidated === -1) {
          delete tx[key];
        }
      }
    });

    //validating contacts
    let invalid_contacts = [];
    let valid_contacts = [];
    tableData.data.map((tx) => {
      if (tx?.["Title"]?.trim() === "") {
        invalid_contacts.push(tx);
        return;
      }
      let has_atttributes = false;
      let has_invalid_attributes = false;
      let error_message = "";
      for (let key in tx) {
        if (key !== "Title" && tx[key].trim() !== "") {
          has_atttributes = true;
          let attribute_type = attributeList.find(
            (tx) => tx.name === key
          )?.type;
          if (attribute_type !== "multi-select") {
            const pattern = patterns[attribute_type];
            if (!pattern) {
              return;
            }
            if (!pattern.test(tx[key])) {
              has_invalid_attributes = true;
              error_message = `${key}: value provided is invalid`;
              break;
            }
          }
        }
      }
      if (!has_atttributes || has_invalid_attributes) {
        if (error_message === "") {
          error_message = "Contact must have atleast one attribute";
        }
        tx.error = error_message;
        invalid_contacts.push(tx);
        return;
      }
      valid_contacts.push(tx);
    });

    setValidatedTableData({
      filename: tableData.filename,
      data: valid_contacts,
      columns: validateColumns,
    });

    setTableDataValidation({
      totalContacts: tableData.data.length,
      addedContacts: valid_contacts.length,
      failedContacts: invalid_contacts.length,
      totalColumnsMapped: validateColumns.length,
      skippedColumns: tableData.columns.length - validateColumns.length,
    });
    setInvalidContacts(invalid_contacts);
  };

  const onCancel = () => {
    setShowContactDrawer(false);
  };

  const handleFileUpload = (file) => {};

  const parseCSVFile = (file) => {
    Papa.parse(file, {
      header: true,
      complete: function (results) {
        const columns = Object.keys(results.data[0]).map((column) => ({
          Header: column,
          accessor: column,
        }));

        const data = results.data;
        const filename = file.name;
        setTableData({ filename, columns, data });
      },
      error: function (error) {
        message.error("Unable to parse CSV... Check your CSV");
        console.error("Error parsing CSV:", error);
        setIsUploading(false);
      },
    });
  };

  const beforeUpload = (file) => {
    setIsUploading(true);
    const fileType = file.type;
    const allowedTypes = ["text/csv"];
    if (!allowedTypes.includes(fileType)) {
      message.error("Only CSV files are allowed!");
      setIsUploading(false);
    } else {
      parseCSVFile(file);
    }
  };

  useEffect(() => {
    if (tableData.columns.length === 0 && tableData.data.length === 0) {
      return;
    }
    setIsUploading(false);
    if (tableData.columns.length === 0) {
      message.error("CSV file doesn't have any columns");
      setTableData({ filename: "", columns: [], data: [] });
      return;
    }
    if (tableData.data.length === 0) {
      message.error("CSV file doesn't have any contacts!!!");
      setTableData({ filename: "", columns: [], data: [] });
      return;
    }
    validateTableData();
    if (screenNumber === 0) {
      setScreenNumber(1);
    }
  }, [tableData]);

  const returnLabel = (labelname, attributeId) => {
    if (!labelData || !labelname || !attributeId) {
      return null;
    }
    const existingAttribute =
      labelData?.getOrganization?.multiSelectAttribute.find(
        (tx) => tx?.id === attributeId
      );
    if (existingAttribute == undefined) {
      return null;
    }
    const existingLabels = existingAttribute?.labels;
    const fetchedLabel = existingLabels?.find((tx) => tx?.name === labelname);
    if (fetchedLabel === undefined) {
      return {
        id: "abcd",
        color: "#477608",
        name: labelname,
        labelId: "abcd",
      };
    }
    return {
      ...fetchedLabel,
      labelId: fetchedLabel?.id,
    };
  };

  const submitContacts = async (data) => {
    try {
      setIsProcessing(true);
      const response = await importContactAPI(data);
      if (response?.status === 201) {
        setScreenNumber(2);
        refreshingData();
        setIsProcessing(false);
      }
    } catch (err) {
      console.log(err);
      message.error("Encountering issue while communicating with server!!!");
      setIsProcessing(false);
      return;
    }
  };

  const importContact = () => {
    try {
      let contacts = [];
      validatedTableData.data.map((tx) => {
        let normalAttributes = [];
        let multiSelectAttributes = [];
        let username = "";
        for (let key in tx) {
          let attribute = attributeList.find((tx) => tx.name === key);
          if (
            attribute.type !== "multi-select" &&
            attribute.type !== "contactUsername" &&
            tx[key].trim() !== ""
          ) {
            normalAttributes.push({
              id: attribute.id,
              name: attribute.name,
              type: attribute.type,
              value: tx[key],
            });
          } else if (attribute.type === "multi-select") {
            let parsedValues = [];
            tx[key].split(", ").map((tx) => {
              const res = returnLabel(tx, attribute.id);
              if (res !== undefined) {
                parsedValues.push(res);
              }
            });
            multiSelectAttributes.push({
              id: attribute.id,
              name: attribute.name,
              type: attribute.type,
              value: parsedValues,
            });
          } else if (attribute.type === "contactUsername") {
            username = tx[key];
          }
        }
        contacts.push({
          username: username,
          contactAttributes:
            normalAttributes.length > 0 ? normalAttributes : undefined,
          multiSelectAttribute:
            multiSelectAttributes.length > 0
              ? multiSelectAttributes
              : undefined,
        });
      });
      const data = {
        contacts: contacts,
        organization: {
          id: organizationId,
        },
      };
      submitContacts(data);
      //setScreenNumber(2);
    } catch (err) {
      console.log(err);
      return;
    }
  };

  return (
    <Modal
      visible={showContactDrawer}
      footer={null}
      closable={false}
      width={modalWidth[screenNumber]}
    >
      {screenNumber === 0 && (
        <>
          <div className={styles.ImportContact__Header}>
            <div className={styles.ImportContact__Header__Text}>
              Import contacts
            </div>

            <CloseOutlined
              color="#262626"
              onClick={onCancel}
              className="close-icon"
            />
          </div>
          <div className={styles.ImportContact__Body}>
            <span className={styles.ImportContact__Body__Text}>
              Upload a CSV file to add contacts.
            </span>
            <ul className={styles.ImportContact__Body__List}>
              <li>Make sure “Column title” same as “Attribute name”</li>
              <li>Max. size of the file is 16 MB</li>
            </ul>
            <Upload beforeUpload={beforeUpload} showUploadList={false}>
              <Button type="primary" style={{ backgroundColor: 'var(--primary-color)' }}>
                {isUploading === true ? <LoadingOutlined /> : "Upload CSV file"}
              </Button>
            </Upload>
          </div>
        </>
      )}
      {screenNumber === 1 && (
        <>
          <div className={styles.ImportContact__Header}>
            <div className={styles.ImportContact__Header__Text}>
              {tableData.filename}
            </div>
            <CloseOutlined
              color="#262626"
              onClick={onCancel}
              className="close-icon"
            />
          </div>
          <div className={styles.ImportContact__Body}>
            <div className={styles.ImportContact__Body__Status}>
              <div className={styles.ImportContact__Body__Status__Text}>
                <span
                  className={styles.ImportContact__Body__Status__Text__span1}
                >
                  {tableDataValidation.totalContacts} Contact
                </span>{" "}
                record found
              </div>
              <div className={styles.ImportContact__Body__Status__Text}>
                <span
                  className={styles.ImportContact__Body__Status__Text__span2}
                >
                  {tableDataValidation.addedContacts} Contact
                </span>{" "}
                will be added
              </div>
              <div className={styles.ImportContact__Body__Status__Text}>
                <span
                  className={styles.ImportContact__Body__Status__Text__span3}
                >
                  {tableDataValidation.failedContacts} Contact
                </span>{" "}
                will be skipped due to error
              </div>
            </div>
            <CsvTable
              columns={validatedTableData.columns}
              data={validatedTableData.data}
            />
            <div className={styles.ImportContact__Footer}>
              <div className={styles.ImportContact__AttributeSkippingMessage}>
                <InfoCircleFilled
                  className={
                    styles.ImportContact__AttributeSkippingMessage__Icon
                  }
                />
                {tableDataValidation.totalColumnsMapped} columns mapped,{" "}
                {tableDataValidation.skippedColumns} columns will be skipped.
              </div>
              <Button
                className={styles.ImportContact__Footer__Button}
                type="primary"
                disabled={isProcessing}
                onClick={() => {
                  importContact();
                }}
              >
                {isProcessing === true ? <LoadingOutlined /> : "Import"}
              </Button>
            </div>
          </div>
        </>
      )}
      {screenNumber === 2 && (
        <>
          <img className={styles.Completed__Image} src={SuccessIcon} />
          <div className={styles.Completed__SuccessMessage}>
            Successfully imported!
          </div>
          <div className={styles.Completed__Subtitle}>
            You can see the imported contacts
            <br />
            in contacts section
          </div>
          <div className={styles.Completed__AttributeSkippingMessage}>
            <InfoCircleFilled
              className={styles.ImportContact__AttributeSkippingMessage__Icon}
            />
            3 Contact added successfully
          </div>
          {invalidContacts.length > 0 && (
            <div
              onClick={handleDownload}
              className={styles.Completed__SkippedContact}
            >
              Skipped contact list
            </div>
          )}
          <Button type="primary" onClick={setShowContactDrawer}>
            Done
          </Button>
        </>
      )}
    </Modal>
  );
};

ImportContact.propTypes = {};

ImportContact.defaultProps = {};

export default ImportContact;
