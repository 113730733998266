import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import styles from "./ProfilePicPlaceholder.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setProfileIds } from "src/store/slices/peopleSlice";

const ProfilePicPlaceholder = ({ name, id, width, height, fontSize }) => {
  const dispatch = useDispatch();
  const { profileIds } = useSelector((state) => state?.people?.data);
  const [styleData, setStyleData] = useState({});

  useEffect(() => {
    const randomColorGenerator = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1) + min);
    };

    const addBgandFontcolorInIDB = (id) => {
      const hue = randomColorGenerator(0, 360);
      const saturation = randomColorGenerator(20, 40); // Reduced saturation for subtle colors
      const lightness = randomColorGenerator(70, 85); // Higher lightness for more pastel-like colors
      const bgColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
      const fontColor = "#1E1E1E"; // Darker font color for contrast

      const data = {
        bgColor,
        fontColor,
      };

      dispatch(setProfileIds({ [id]: data }));
      return data;
    };

    if (!(id in profileIds)) {
      const data = addBgandFontcolorInIDB(id);
      setStyleData(data);
    } else {
      setStyleData(profileIds[id]);
    }
  }, [profileIds, id]);

  let [firstname, lastname] = name.toUpperCase().split(" ");
  if (!firstname) {
    firstname = "";
  }
  if (!lastname) {
    lastname = "";
  }

  return (
    <div
      style={{
        width: width,
        height: height,
        backgroundColor: styleData?.bgColor,
        color: styleData?.fontColor,
        fontSize,
      }}
      className={styles.ProfilePicPlaceholder}
      id="initials-profile-pic"
      data-testid="initials"
    >
      {`${firstname[0] ? firstname[0] : ""} ${lastname[0] ? lastname[0] : ""}`}
    </div>
  );
};

ProfilePicPlaceholder.propTypes = {
  name: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fontSize: PropTypes.string,
};

ProfilePicPlaceholder.defaultProps = {
  name: "",
  id: "0",
  width: "180px",
  height: "180px",
  fontSize: "5em",
};

export default ProfilePicPlaceholder;
