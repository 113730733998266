import React from "react";
import { Tree } from "react-arborist";
import Node from "../Node/Node";

const NodeTree = ({ height, pinnedNodes, initialData, selectedNode, setSelectedNode, handleNodeClick, addNodeToPinned, isSearching, selectedNodeId, isClicked, searchQuery, showModal }) => {

  return (
    <Tree
      key={initialData.length}
      initialData={initialData}
      indent={24}
      openByDefault={false}
      rowHeight={30}
      height={height}
      width="100%"
      selection={selectedNodeId}
      searchTerm={searchQuery}
      searchMatch={(node, searchTerm) =>
        node.data.name.toLowerCase().includes(searchTerm.toLowerCase())
      }

    >
      {(nodeProps) => (
        <Node
          {...nodeProps}
          handleNodeClick={handleNodeClick}
          selectedNode={selectedNode}
          pinnedNodes={pinnedNodes}
          addNodeToPinned={addNodeToPinned}
          isClicked={isClicked}
          showModal={showModal}
        />
      )}
    </Tree>
  );
};

export default NodeTree;
