import { gql } from "@apollo/client";

export const GET_ORGANIZATION_WHATSAPP_TEMPLATES = gql`
  query ($organizationId: ID!) {
    getOrganization(id: $organizationId) {
      id
      name
      whatsappTemplate {
        id
        name
        body
        category
        language
        status
        color
        createdAt
        organization {
          id
          name
        }
      }
    }
  }
`;
