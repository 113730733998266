import React from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useAbsoluteLayout,
  useColumnOrder,
} from "react-table";
import "../Table/Table.scss";
import styles from "./CSVTable.module.scss";

const CsvTable = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });
  return (
    <div className={styles.CsvTable__TableContainer}>
      <table {...getTableProps()} className={styles.Table}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key="header">
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} key={column.id}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={row.index}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} key={cell.column.id}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

CsvTable.propTypes = {};

CsvTable.defaultProps = {};

export default CsvTable;
