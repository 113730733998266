import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ExclamationCircleOutlined,InfoCircleOutlined, CloseOutlined } from "@ant-design/icons";
import styles from "./DateAttributeInlineEditingField.module.scss";
import { Tooltip } from "antd";
import DateSelect from "../../../ContactsPage/components/DateSelect/DateSelect";
import moment from "moment";

const DateAttributeInlineEditingField = ({
  attributeId,
  label,
  initialValue,
  onSubmit,
  t,
  onDeleteForm,
  isEdit,
  valueId,
  isTableElement = false
}) => {

  const [isHovering, setIsHovering] = useState(false);
  const [textContent, setTextContent] = useState("");
  useEffect(() => {
    if (!initialValue) {
      setTextContent("");
      return;
    }
    setTextContent(initialValue);
  }, [initialValue]);

  // console.log(textContent)
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const onInputChange = (e) => {
    if (isEdit === true) {
      onSubmit({ "id": attributeId, "name": label, "value": !moment(e.trim()).isValid() ? "" : e.trim(), valueId: valueId });
    } else {
      onSubmit({ "id": attributeId, "name": label, "value": !moment(e.trim()).isValid() ? "" : e.trim() });
    }
    setTextContent(e.trim());
  }

  const onDeleteClick = () => {
    if (onDeleteForm === undefined) {
      return;
    }
    onDeleteForm("NormalAttributeValue", [attributeId], label);
  }



  return (
    <div onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      className={!isTableElement?  styles.DateAttributeInlineEditingField :styles.DateAttributeInlineEditingFieldTable}>
      <div className={styles.DateAttributeInlineEditingField__AttributeContainer}>
        {!isTableElement && <div className={styles.DateAttributeInlineEditingField__Label}>
          {label}
        </div>}
        {isHovering && !isTableElement &&
          <div className={styles.DateAttributeInlineEditingField__IconContainer}>
            <Tooltip title="Source: Internal (Date)">
              <InfoCircleOutlined className={styles.DateAttributeInlineEditingField__InfoIcon} />
            </Tooltip>
          </div>}
      </div>
      <div className={styles.DateAttributeInlineEditingField__FieldContainer}>
        {/* {isHovering === true ? */}
        {/* <Input onChange={onInputChange} defaultValue={initialValue} className={styles.DateAttributeInlineEditingField__InputField} type="text" />  */}
        <DateSelect
          isTableElement={isTableElement}
          defaultValue={moment(initialValue, "MMMM DD, YYYY")}
          handleDateChange={onInputChange}
          item={initialValue}
          onPressEnter={(e) => e.preventDefault()}
          t={t}
        /> 
        {/* :
          <div className={styles.DateAttributeInlineEditingField__FieldContainer__FieldTrigger}>
            {textContent}
          </div> */}
        {/* } */}
      </div>

    </div>
  );
}

DateAttributeInlineEditingField.propTypes = {};

DateAttributeInlineEditingField.defaultProps = {};

export default DateAttributeInlineEditingField;
