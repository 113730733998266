import React from "react";
import style from './ConversationStatus.module.scss';
import { UserOutlined,FrownOutlined } from '@ant-design/icons';
import PieChartStats from '../Charts/PieChart';
import DropdownBox from "src/components/Dashboard/components/timesheetview/timesheet-view-widgets/widgets/DropdownBox/DropdownBox";
import { Empty } from 'antd';

const COLORS = ['#0088FE', '#00C49F'];

const ConversationStatus = ({ data, staffList, selectedSalesRepEmail, setSelectedSalesRepEmail }) => {
  const chartData = [
    { name: 'No. of Conversations resolved', value: data?.conversation_statistics?.resolved_conversations || 0 },
    { name: 'Conversations Still Open', value: data?.conversation_statistics?.conversations_still_open || 0 },
  ];
  
  const isDataEmpty = chartData[0].value === 0 && chartData[1].value === 0;
  let memberStaffList = staffList?.filter((member) => member.roleName === 'member');

  return (
    <div className={style.statistics}>
      <div className={style.statItem}>
        <div>
          <h3 className={style.chartHeader}>CONVERSATION STATUS</h3>
        </div>
        <div>
          <DropdownBox
            staffList={memberStaffList}
            setSelectedSalesRepEmail={setSelectedSalesRepEmail}
          />
        </div>
      </div>
      <div>
        {isDataEmpty ? (
         <div className={style.noData}>
         <Empty description={
      <span>
        No conversation status
      </span>
    } />
       </div>
        ) : (
          <PieChartStats 
            data={chartData} 
            COLORS={COLORS} 
            label={`${parseFloat(data?.conversation_statistics?.percentage_of_chats_resolved).toFixed(2)} %`} 
            labelTitle="Conversations resolved"
          />
        )}
      </div>
      <div className={style.statItem}>
        <div>
          <h1>{chartData[0].value} 
            <span style={{ backgroundColor: COLORS[0], width: '20px', height: '26px', display: 'inline-block', marginLeft: '10px',borderRadius:'4px'}}></span>
          </h1>
          <h3 className={style.chartHeader}>{chartData[0].name}</h3>
        </div>
        <div>
          <h1>{chartData[1].value} 
            <span style={{ backgroundColor: COLORS[1], width: '20px', height: '26px', display: 'inline-block', marginLeft: '10px',borderRadius:'4px' }}></span>
          </h1>
          <h3 className={style.chartHeader}>{chartData[1].name}</h3>
        </div>
      </div>
    </div>
  );
};

export default ConversationStatus;
