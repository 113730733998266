import { UserProfile as ProfileSettings } from "src/components/Profile/Profile";
import React from "react";

const UserProfile = () => {
  return (
    <div className="App">
      <ProfileSettings />
    </div>
  );
};

UserProfile.propTypes = {};

UserProfile.defaultProps = {};

export default UserProfile;
