import React, { useState } from "react";
import { Table, Tag, Spin, Alert, message } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import { deleteTimeLogs } from "src/api/TimesheetApi";
import { timesheetConfig } from "../../../../../configs/dashboardConfig";
import { GET_ORGANIZATION_STAFF } from "src/graphql/staff";
import { useQuery } from "@apollo/client";


const DataTable = ({
  fetchTableData,
  tableData,
  config,
  selectedUserId,
  isLoading,
}) => {

  
  const renderTags = (_, { tags }) => (
    <>
      {tags.map((tag) => {
        let color = tag.length > 5 ? "geekblue" : "green";
        if (tag === "loser") {
          color = "volcano";
        }
        return (
          <Tag color={color} key={tag}>
            {tag.toUpperCase()}
          </Tag>
        );
      })}
    </>
  );

  const renderDeleteButton = (_, record) => {
    return (
      <DeleteFilled
        style={{ color: "#ab4646" }}
        type="danger"
        onClick={() => handleDelete(record.delete)}
      />
    );
  };

  const columns = [
    ...timesheetConfig.columns.map((column) => {
      if (column.field === "tags") {
        return { ...column, render: renderTags };
      }
      if (column.field === "delete") {
        return { ...column, render: renderDeleteButton };
      }
      return column;
    }),
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      render: renderDeleteButton,
    },
  ];

  const handleDelete = async (key) => {
    try {
      const timeLogToDelete = tableData.data.find((item) => item.id === key);

      const { startTime, endTime } = timeLogToDelete;
      if (typeof startTime !== "string" || typeof endTime !== "string") {
        throw new Error("Invalid startTime or endTime");
      }
      console.log(startTime,endTime)
      let payload = { userid: selectedUserId, startDate: startTime, endDate: endTime };
      await deleteTimeLogs(payload);
      await fetchTableData();
      message.success("Time log deleted successfully");
    } catch (error) {
      console.error("Error deleting time log:", error);
    }
  };

  // if (error) {
  //   return <Alert message="Error fetching data" type="error" />;
  // }
  //
  const formatDuration = (totalTime) => {
    const [
      yearStr,
      monthStr,
      dateStr,
      hoursStr,
      minutesStr,
      secondsStr,
      millisecondsStr,
    ] = totalTime.split(":").map((str) => parseFloat(str));

    const paddedYear = Math.floor(yearStr).toString().padStart(4, "0");
    const paddedMonth = Math.floor(monthStr).toString().padStart(2, "0");
    const paddedDate = Math.floor(dateStr).toString().padStart(2, "0");
    const paddedHours = Math.floor(hoursStr).toString().padStart(2, "0");
    const paddedMinutes = Math.floor(minutesStr).toString().padStart(2, "0");

    let formattedTime = `${paddedHours}:${paddedMinutes}`;

    if (!isNaN(secondsStr)) {
      const paddedSeconds = Math.floor(secondsStr).toString().padStart(2, "0");
      formattedTime += `:${paddedSeconds}`;
    }

    if (!isNaN(millisecondsStr)) {
      const paddedMilliseconds = Math.floor(millisecondsStr)
        .toString()
        .padStart(3, "0");
      formattedTime += `.${paddedMilliseconds}`;
    }

    return formattedTime;
  };

  // Function to format date and time
  const formatDate = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "UTC",
    };
    return date.toLocaleString("en-US", options);
  };

  return (
    <Spin spinning={isLoading}>
      <Table
        columns={columns}
        dataSource={
          tableData.data
            ? tableData.data.map((item) => ({
                username: item.username,
                project: item.node.title,
                activity: `${item.activityPercentage} %`,
                duration: formatDuration(item.duration),
                time: formatDate(item.startTime),
                delete: item.id,
              }))
            : []
        }
      />
    </Spin>
  );
};

export default DataTable;
