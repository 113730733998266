import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Radio, Button, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styles from "./AutomationRuleBlock.module.scss";
import AutomationIndividualCondition from "../AutomationIndividualCondition/AutomationIndividualCondition";
import { useQuery, useSubscription } from "@apollo/client";
import { GET_ALL_SEGMENTS } from "src/graphql/segment";
import { getActiveOrgId } from "src/store/slices/userSlice";
import { useSelector } from "react-redux";
import { generateRandomString } from "src/components/ContactsPage/components/FilterContactDrawer/helpers/helpers";
export const combinationOptions = [
  {
    label: "AND",
    value: "AND",
  },
  {
    label: "OR",
    value: "OR",
  },
];

const AutomationRuleBlock = ({
  setCondition,
  setConditionRelation,
  item,
  handleInputChange,
  handleNewItem,
  addConditionItem,
}) => {
  const organizationId = useSelector(getActiveOrgId);
  const {
    loading,
    refetch: refetchAttributes,
    error,
    data,
    networkStatus,
  } = useSubscription(GET_ALL_SEGMENTS, {
    variables: {
      organizationId: organizationId,
      notifyOnNetworkStatusChange: true,
    },
  });
  const [showAddFilterButton, setShowAddFilterButton] = useState(true);
  const onConditionChange = (e) => {
    handleInputChange(item?.id, "blockCondition", e.target.value);
  };
  const onFilterChange = (val) => {
    handleInputChange(item?.id, "automationRuleCondition", val);
  };

  const onIndividualConditionChange = (id, key, value, subkey, subId) => {
    handleInputChange(id, key, value, subkey, subId);
  };

  const onClickAddCondition = (e) => {
    addConditionItem(item.id, {
      id: generateRandomString(),
      scope: "Segment",
      trigger: "",
      segment: "",
      action: "",
    });
  };

  const onNewItem = () => {
    handleNewItem();
    setShowAddFilterButton(false);
  };

  return (
    <div className={styles.AutomationRuleBlock__container}>
      <div className={styles.AutomationRuleBlock}>
        <div className={styles.AutomationRuleBlock__Header}>
          <Radio.Group
            onChange={onConditionChange}
            value={item?.blockCondition}
          >
            <Radio
              className={styles.AutomationRuleBlock__Placeholder}
              value={"ANY"}
            >
              Match{" "}
              <span className={styles.AutomationRuleBlock__Radio}>ANY</span>{" "}
              condition
            </Radio>
            <Radio
              className={styles.AutomationRuleBlock__Placeholder}
              value={"ALL"}
            >
              Match{" "}
              <span className={styles.AutomationRuleBlock__Radio}>ALL</span>{" "}
              condition
            </Radio>
          </Radio.Group>
        </div>

        <div className={styles.AutomationRuleBlock__Body}>
          {item?.conditions?.map((tx, index) => (
            <AutomationIndividualCondition
              parentItem={item}
              item={tx}
              index={index}
              onConditionChange={onIndividualConditionChange}
              segmentOptions={data?.getOrganization?.segments}
            />
          ))}
        </div>

        <div
          className={`${styles.AutomationRuleBlock__Header} ${styles.AutomationRuleBlock__Footer}`}
        >
          <div
            className={styles.AutomationRuleBlock__AddCondition}
            onClick={onClickAddCondition}
          >
            <PlusOutlined style={{ paddingRight: "10px",  color:'var(--primary-color)' }} />
            Add new condition
          </div>
        </div>
      </div>
      <div className={styles.AutomationRuleBlock__filters}>
        {showAddFilterButton ? (
          <Button
            onClick={onNewItem}
            type="dashed"
            icon={<PlusOutlined />}
            style={{ zIndex: 2 }}
            
          >
            Add Filter
          </Button>
        ) : (
          <Select
            placeholder="Operator"
            value={item?.automationRuleCondition}
            defaultValue="and"
            style={{
              width: 150,
              zIndex: 2,
            }}
            onChange={(value) => onFilterChange(value)}
            options={combinationOptions}
          />
        )}
        <div className={styles.AutomationRuleBlock__filters__line}></div>
      </div>
    </div>
  );
};

AutomationRuleBlock.propTypes = {};

AutomationRuleBlock.defaultProps = {};

export default AutomationRuleBlock;
