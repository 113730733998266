import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  data: {
    channels: [],
  },
  error: null,
};

const channelSlice = createSlice({
  name: "channel",
  initialState,
  reducers: {
    resetChannels: () => initialState,
    setChannels: (state, action) => {
      state.data.channels = action.payload;
    },
  },
});

export const { resetChannels, setChannels, getChannels } = channelSlice.actions;
export default channelSlice.reducer;
