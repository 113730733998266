import React, { useState, useEffect } from "react";
import { Card, Space, Select, Button, Input } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import styles from "./QuerySelector.module.scss";
import TagsSelect from "./TagsSelect/TagsSelect";
import WhatsappIcon from "src/assets/svgs/WhatsappIcon";
import { SettingOutlined } from "@ant-design/icons";
import {
  basicOptions,
  categories,
  combinationOptions,
  contactOptions,
  numericOptions,
  textOptions,
} from "./constants/constants";
import {
  extractLabels,
  getAttributeIdFromAttrAndTypes,
  getMultiTagId,
} from "../FilterContactDrawer/helpers/helpers";
import { useQuery, useSubscription } from "@apollo/client";
import { GET_ORGANIZATION_LABELS } from "src/graphql/contact";
import { getActiveOrgId } from "src/store/slices/userSlice";
import { useSelector } from "react-redux";

const QuerySelector = ({
  filterId,
  onRemoveFilter,
  items,
  addFilter,
  labels,
  allAssignees,
  handleChangeFilter,
  allChannels,
  filter,
  segmentData,
  attrAndTypes,
  handleChangeFilterSpecific,
  setPropertyToEmptyInLastItem,
  isEdit = false,
  multiSelectsData,
}) => {
  // const [filter?.category, setChooseCategory] = useState(null);
  // const [filter?.operator, setChooseOperator] = useState(null);
  // const [filter?.subCategory, setChooseSubCategory] = useState(null);
  // const [chooseContactFitlerValue, setChooseContactFitlerValue] = useState("");
  // const [chooseCombination, setChooseCombination] = useState("and");
  const organizationId = useSelector(getActiveOrgId);
  const [showAddFilterButton, setShowAddFilterButton] = useState(true);
  const [operatorOptions, setOperatorOptions] = useState(basicOptions);
  const [values, setValues] = useState([]);
  const [allLabels, setAllLabels] = useState([]);

  const {
    data: tagsData,
    loading: attributeLoading,
    error: attributeError,
    refetch,
  } = useQuery(GET_ORGANIZATION_LABELS, {
    variables: {
      organizationId: organizationId,
      multiselectId: filter?.subCategory,
    },
    skip: !organizationId || !filter?.subCategory,
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (tagsData !== undefined && Object.keys(tagsData)?.length !== 0) {
      setAllLabels(extractLabels(tagsData));
    }
  }, [tagsData]);

  const handleFetchValues = (val) => {
    // console.log(val,filterId,"values")
    handleChangeFilterSpecific(val, filterId, "values");
    // setValues(val);
  };

  const handleChange = (value) => {
    handleChangeFilterSpecific(value, filterId, "values");

    // setValues(value);
  };

  // useEffect(() => {
  //   if(filter?.category==""){
  //     setChooseCategory(null);
  //      handleChangeFilterSpecific(null,filterId,"operator")
  //     setChooseSubCategory(null);
  //     setChooseContactFitlerValue("");
  //     setValues([]);
  //   }
  // }, [filter]);

  // useEffect(() => {
  //   if (filter?.category !== null) {
  //     // console.log("here",filter.category)
  //     handleChangeFilter(
  //       {
  //         category: filter?.category,
  //         subCategory: filter?.subCategory,
  //         operator: filter?.operator,
  //         values: values,
  //         combination:chooseCombination,
  //         contactFilterValue:chooseContactFitlerValue
  //       },
  //       filterId
  //     );
  //   }
  // }, [filter?.category,filter?.operator, filter?.subCategory, values,chooseCombination,chooseContactFitlerValue]);

  useEffect(() => {
    if (
      filter?.category !== null &&
      filter?.subCategory !== null &&
      segmentData?.getSegments?.segmentInformation?.length !== 0
    ) {
      if (filter?.category === "Contact field") {
        handleSetAttributeOptionsSegment(filter?.subCategory);
      } else {
        setOperatorOptions(basicOptions);
      }
    } else {
      if (filter?.category === "Contact field") {
        handleSetAttributeOptions(filter?.subCategory);
        handleChangeFilterSpecific([], filterId, "values");
        // setValues([])
      } else {
        setOperatorOptions(basicOptions);
        //  handleChangeFilterSpecific(null,filterId,"operator")
        handleChangeFilterSpecific([], filterId, "values");
        // setValues([])
      }
    }
  }, [filter?.category, filter?.subCategory, segmentData]);

  // useEffect(()=>{
  //   if(isEdit){
  //     setPropertyToEmptyInLastItem('combination')
  //   }
  // },[])

  const handleAddFilter = () => {
    addFilter();
    setShowAddFilterButton(false);
  };

  const handleSetAttributeOptions = (val) => {
    let res = attrAndTypes?.filter((item) => item.id == val);
    if (res[0]?.type == "NormalAttribute") {
      if (res[0]?.attrType == "Numbers") {
        setOperatorOptions(numericOptions);
        //  handleChangeFilterSpecific(null,filterId,"operator")
      } else {
        setOperatorOptions(textOptions);
        //  handleChangeFilterSpecific(null,filterId,"operator")
      }
    } else {
      setOperatorOptions(basicOptions);
      //  handleChangeFilterSpecific(null,filterId,"operator")
    }
  };

  const handleSetAttributeOptionsSegment = (val) => {
    let res = attrAndTypes?.filter((item) => item.id == val);
    if (res[0]?.type == "NormalAttribute") {
      if (res[0]?.attrType == "Numbers") {
        setOperatorOptions(numericOptions);
      } else {
        setOperatorOptions(textOptions);
      }
    } else {
      setOperatorOptions(basicOptions);
    }
  };

  const getIconByType = (type) => {
    switch (type) {
    case "whatsappOfficial":
      return <WhatsappIcon />; // Replace with the appropriate icon for WhatsApp
    case "apiChannel":
      return <SettingOutlined />; // Replace with the appropriate icon for API Channel
    default:
      return <WhatsappIcon />; // Replace with a default icon or handle other types
    }
  };

  // console.log(filter)

  // useEffect(()=>{
  //   if(segmentData && segmentData?.getSegments?.segmentInformation?.length !==0 ){
  //     // console.log(segmentData?.getSegments?.segmentInformation)
  //     console.log(filter)
  //     console.log(filter?.operator)
  //     console.log(filter?.operator)
  //          setChooseCategory(filter?.category);
  //     setChooseOperator(filter?.operator);
  //     setChooseSubCategory(filter?.subCategory);
  //     setChooseContactFitlerValue(filter?.contactFilterValue);
  //     setValues(filter?.values);
  //     setChooseCombination(filter?.combination)
  //   }
  // },[segmentData])

  useEffect(() => {
    // console.log(filterId,items[items?.length-1]?.id,items)
    if (filterId == items[items?.length - 1]?.id && isEdit) {
      // console.log(filterId,items[items?.length-1]?.id,"here inside the if")
      setPropertyToEmptyInLastItem("combination");
      setShowAddFilterButton(true);
    } else {
      setShowAddFilterButton(false);
    }
  }, []);

  // console.log(filterId,items[items?.length-1]?.id,items)

  // console.log(operatorOptions,basicOptions)

  // console.log(filter)

  // console.log(showAddFilterButton,items)

  // console.log(filter?.category === "Contact field" , filter?.operator!==null , filter?.subCategory !==null , !["exists","does not exist"].includes(filter?.operator) , operatorOptions!==basicOptions)

  return (
    <div className={styles.QuerySelector}>
      <Card className={styles.QuerySelector__Card}>
        <div className={styles.QuerySelector__Card__body}>
          <div className={styles.QuerySelector__Card__body__allcontainers}>
            <Select
              placeholder="Choose a Category"
              value={filter.category}
              style={{
                width: 170,
              }}
              onChange={(value) => {
                // setChooseCategory(value);
                handleChangeFilterSpecific(value, filterId, "category");
              }}
              options={categories}
            />
            {filter?.category === "Contact field" && (
              <Select
                value={filter.subCategory}
                style={{ width: "50%" }}
                placeholder="Select sub field"
                onChange={(value) => {
                  handleChangeFilterSpecific(value, filterId, "subCategory");
                  // setChooseSubCategory(value)
                }}
                options={attrAndTypes?.filter(
                  (item) => item.name !== "Tags" && item.name !== "Assignee"
                )}
              />
            )}
            <Select
              placeholder="Choose an Operator"
              style={{
                width: 170,
              }}
              onChange={(value) => {
                // setChooseOperator(value);
                handleChangeFilterSpecific(value, filterId, "operator");
              }}
              value={filter.operator}
              options={filter?.category !== null ? operatorOptions : []}
            />
            {filter?.category === "Contact field" &&
              filter?.operator !== null &&
              filter?.subCategory !== null &&
              !["exists", "does not exist"].includes(filter?.operator) &&
              operatorOptions !== basicOptions && (
              <Input
                value={filter.contactFilterValue}
                style={{ width: "50%" }}
                placeholder="Enter filter value"
                onChange={(e) => {
                  // setChooseContactFitlerValue(e.target.value)
                  handleChangeFilterSpecific(
                    e.target.value,
                    filterId,
                    "contactFilterValue"
                  );
                }}
              />
            )}
          </div>
          {(filter?.category === "Tags" ||
            (filter?.category === "Contact field" &&
              getAttributeIdFromAttrAndTypes(
                attrAndTypes,
                filter?.subCategory
              ) === "MultiSelectAttribute")) &&
            filter?.operator !== null &&
            !["exists", "does not exist"].includes(filter?.operator) &&
            operatorOptions == basicOptions && (
            <TagsSelect
              options={filter?.category === "Tags" ? labels : allLabels}
              handleFetchValues={handleFetchValues}
              value={filter.values}
            />
          )}
          {(filter?.category === "Assignee" ||
            (filter?.category === "Contact field" &&
              getAttributeIdFromAttrAndTypes(
                attrAndTypes,
                filter?.subCategory
              ) === "UserAttribute")) &&
            !["exists", "does not exist"].includes(filter?.operator) &&
            filter?.operator !== null && (
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Please select"
              onChange={handleChange}
              options={allAssignees}
              value={filter.values}
            />
          )}
          {filter?.category === "Channel" &&
            filter?.operator !== null &&
            !["exists", "does not exist"].includes(filter?.operator) && (
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              value={filter.values}
              onChange={handleChange}
              options={allChannels.map((option) => ({
                ...option,
                label: (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      style={{ marginRight: "12px", marginBottom: "-4px" }}
                    >
                      {getIconByType(option.type)}
                    </span>{" "}
                    {/* Add the icon element */}
                    {`${option.label}`} {/* Modify the label format here */}
                  </div>
                ),
              }))}
              placeholder={"Select channel"}
            />
          )}
        </div>
        <Button
          disabled={items.length === 1}
          onClick={() => onRemoveFilter(filterId)}
          icon={<CloseOutlined />}
          style={{ marginLeft: "auto" }}
        />
      </Card>

      <div className={styles.QuerySelector__filters}>
        {showAddFilterButton || items.length === 1 ? (
          <Button
            onClick={handleAddFilter}
            type="dashed"
            icon={<PlusOutlined />}
            style={{ zIndex: 2 }}
          >
            Add Filter
          </Button>
        ) : (
          <Select
            placeholder="Operator"
            value={filter?.combination}
            defaultValue="and"
            style={{
              width: 150,
              zIndex: 2,
            }}
            onChange={(value) => {
              // setChooseCombination(value)
              handleChangeFilterSpecific(value, filterId, "combination");
            }}
            options={combinationOptions}
          />
        )}
        <div className={styles.QuerySelector__filters__line}></div>
      </div>
    </div>
  );
};

export default QuerySelector;
