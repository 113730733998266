import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import PropTypes from "prop-types";
import styles from "./ResetPassword.module.scss";

import AuthLayout from "../AuthLayout/AuthLayout";

import { useFormik } from "formik";
import { setNewPassword } from "src/api/AuthApi";
import { Link, useParams } from "react-router-dom";
import { Button, Form, Input, notification } from "antd";
import { faArrowAltCircleRight } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as Yup from "yup";
import "antd/dist/reset.css";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  const { resetPasswordId } = useParams();

  const handleNewPassword = async ({ email, password }) => {
    setLoading(true);
    let payload = { email, password, resetPasswordId };
    let res = await setNewPassword(payload);
    setLoading(false);

    if (res.data.success) {
      notification["success"]({
        message: "Success",
        description: "Password is successfully updated",
        duration: 5.5,
      });
      navigate("/");
    } else {
      notification["error"]({
        message: "Failure",
        description: "Failed to update the password",
        duration: 5.5,
      });
    }
  };

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: { email: "", password: "", passwordConfirmation: "" },
      validationSchema: Yup.object().shape({
        email: Yup.string()
          .email("Invalid email address format")
          .required("Email required"),
        password: Yup.string()
          .matches(
            /^(?=.*[A-Za-z])(?=.*[@$!%*#?&])[A-Za-z0-9@$!%*#?&]{6,}$/,
            "Password should be atleast 6 characters long and should contain a special symbol!"
          )
          .required("Password required"),
        passwordConfirmation: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Confirm password"),
      }),
      onSubmit: handleNewPassword,
    });

  const handleValidationStatus = (key) => {
    return !touched[key] && !errors[key]
      ? ""
      : touched[key] && !errors[key]
      ? "success"
      : touched[key] && errors[key]
      ? "error"
      : "";
  };

  const printValidationMsg = (key) => {
    if (touched[key] && errors[key]) {
      return (
        <div
          role="alert"
          className={styles.ResetPassword__signInContainer__formItem__error}
        >
          {errors[key]}
        </div>
      );
    } else return null;
  };

  return (
    <AuthLayout>
      <div className={styles.ResetPassword} data-testid="sign-in-form">
        <div className={styles.ResetPassword__signInContainer}>
          <h3 data-testid="SigninForm_heading">Reset your Password</h3>
          <form onSubmit={handleSubmit}>
            <Form.Item
              className={`${styles.ResetPassword__signInContainer__formItem} signIn__form`}
              label="Enter email address"
              name="email"
              validateStatus={handleValidationStatus("email")}
              help={printValidationMsg("email")}
            >
              <Input
                id="email"
                name="email"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                placeholder="example@gmail.com"
              />
            </Form.Item>
            <Form.Item
              className={`${styles.ResetPassword__signInContainer__formItem} signIn__form`}
              label={"Enter new password"}
              name="password"
              validateStatus={handleValidationStatus("password")}
              help={printValidationMsg("password")}
            >
              <Input.Password
                id="password"
                name="password"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item
              className={`${styles.ResetPassword__signInContainer__formItem} signIn__form`}
              label="Renter the password"
              name="passwordConfirmation"
              validateStatus={handleValidationStatus("passwordConfirmation")}
              help={printValidationMsg("passwordConfirmation")}
            >
              <Input.Password
                id="passwordConfirmation"
                name="passwordConfirmation"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.passwordConfirmation}
                placeholder="Reenter password"
              />
            </Form.Item>
            <div
              className={styles.ResetPassword__signInContainer__btn_pwd_link}
            >
              <Button
                style={{
                  width: "100%",
                  background: "rgba(9, 109, 217, 1)",
                  color: "white",
                }}
                loading={loading}
                htmlType="submit"
              >
                <span>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} />
                </span>
                Submit
              </Button>
            </div>
          </form>
          <footer className={styles.ResetPassword__footer} data-testid="footer">
            Need help? <span>Feel free to contact us</span>&nbsp;
            <a
              href="https://calendly.com/hybrid-chat/15min"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact Us!
            </a>
          </footer>
        </div>
      </div>
    </AuthLayout>
  );
};

ResetPassword.propTypes = {};

ResetPassword.defaultProps = {};

export default ResetPassword;
