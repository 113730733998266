import React from 'react';
import style from './Quotations.module.scss';
import PieChartStats from '../Charts/PieChart';
import { Empty } from 'antd';

const COLORS = ['#0088FE', '#00C49F'];

const Quotations = ({ data }) => {
  const chartData = [
    { name: 'Visitors Requested Quotation', value: data.requested_quotations || 0 },
    { name: 'Visitors Didn\'t Request Quotation', value: data.users_not_requested || 0 },
  ];
  const percentageOfUsersRequestedQuatation = data.percentage_of_users_requested_quatation

  const isDataEmpty = chartData[0].value === 0 && chartData[1].value === 0;
  return (
    <div className={style.statistics}>
      <div className={style.statItem}>
        <div>
          <h3 className={style.chartHeader}>NO. OF VISITORS WHO REQUESTED QUOTATION W/CHATBOT</h3>
        </div>
      </div>
       <div>
        {isDataEmpty ? (
          <div className={style.noData}>
            <Empty description={
               <span>
               No quotation statistics
             </span>
            } />
          </div>
        ) : (
          <PieChartStats data={chartData} COLORS={COLORS} label={`${parseFloat(percentageOfUsersRequestedQuatation).toFixed(2)} % `} labelTitle=" Quotations requested" />
        )}
      </div>
      <div className={style.statItem}>
        <div>
          <h1>{chartData[0].value} 
            <span style={{ backgroundColor: COLORS[0], width: '20px', height: '26px', display: 'inline-block', marginLeft: '10px',borderRadius:'4px' }}></span>
          </h1>
          <h3 className={style.chartHeader}>{chartData[0].name}</h3>
        </div>
        <div>
          <h1>{chartData[1].value} 
            <span style={{ backgroundColor: COLORS[1], width: '20px', height: '26px', display: 'inline-block', marginLeft: '10px',borderRadius:'4px' }}></span>
          </h1>
          <h3 className={style.chartHeader}>{chartData[1].name}</h3>
        </div>
      </div>
    </div>
  );
};

export default Quotations;
