import React from "react"

const StopIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 22 22" fill="none">
      <path d="M20 1H2C1.44772 1 1 1.44772 1 2V20C1 20.5523 1.44772 21 2 21H20C20.5523 21 21 20.5523 21 20V2C21 1.44772 20.5523 1 20 1Z" fill="white" stroke="white"/>
    </svg>
  )
}

export default StopIcon