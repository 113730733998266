import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedAgentTimezone: {},
};

export const timezoneSlice = createSlice({
  name: 'timezone',
  initialState,
  reducers: {
    setSelectedAgentTimezone: (state, action) => {
      state.selectedAgentTimezone = action.payload;
    },
    clearAgentTimezone: (state) => {
      state.selectedAgentTimezone = {};
    },
  },
});

export const { setSelectedAgentTimezone, clearAgentTimezone } = timezoneSlice.actions;

export default timezoneSlice.reducer;
