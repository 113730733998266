import React from "react";

import { Avatar, Card,Typography } from "antd";
import {  useSelector } from "react-redux";

const { Meta } = Card;
const { Text } = Typography;

const ProfileDropdown = () => {

  const { firstName, username } = useSelector((state) => state?.user?.data);

  return (
    <div
      style={{
        display: "flex",
        padding: "10px 0px 18px 18px",
        backgroundColor: "",
        maxWidth: "377px",
      }}
    >
      <Avatar
        style={{ backgroundColor: "rgb(116 156 173)", borderRadius: "9px" }}
      >
        {firstName ? firstName.charAt(0).toUpperCase() : ""}
      </Avatar>

      <p
        style={{
          width: 400,
          margin: 'auto',
          paddingLeft: '10px'
        }}
      >
        <Text style={{ fontWeight: 'bold',fontSize:'medium' }} type="secondary">{username + "'s Workspace"}</Text>
      </p>
    </div>
  );
};
export default ProfileDropdown;
