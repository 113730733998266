import React, { useEffect, useState } from "react";
import styles from "./DateSelect.module.scss";
import { DatePicker } from "antd";
import moment from "moment";

const DateSelect = ({
  handleDateChange,
  item,
  t,
  defaultValue,
  isTableElement,
}) => {
  const [date, setDate] = useState("");

  const onChange = (date, dateString) => {
    handleDateChange(dateString, item);
    setDate(dateString);
  };

  return (
    <div
      className={!isTableElement ? styles.DateSelect : styles.DateSelectTable}
    >
      {!moment(defaultValue).isValid() ? (
        <DatePicker
          allowClear={false}
          onChange={onChange}
          format="MMMM DD, YYYY"
          className={
            !isTableElement
              ? styles.DateSelect__select
              : styles.DateSelectTable__select
          }
        />
      ) : (
        <DatePicker
          allowClear={false}
          defaultValue={defaultValue}
          onChange={onChange}
          format="MMMM DD, YYYY"
          className={
            !isTableElement
              ? styles.DateSelect__select
              : styles.DateSelectTable__select
          }
        />
      )}
    </div>
  );
};

export default DateSelect;
